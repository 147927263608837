import { IntlShape } from 'react-intl';
import { FormError } from 'src/forms/types/FormError';

export function formatCheckoutTravelerNameError(
  intl: IntlShape,
  error: FormError,
): string {
  switch (error.code) {
  case 'name':
    return intl.formatMessage(
      { id: 'Form.Checkout.BillingAddress.TravelerNameInputPattern' },
      { pattern: error.context.pattern },
    );

  case 'maxLength':
    return intl.formatMessage(
      { id: 'Form.Checkout.BillingAddress.TravelerNameInputLength' },
      { length: error.context.max },
    );

  default:
    return '';
  }
}

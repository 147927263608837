import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/ReadonlyArray';
import { createSelector } from 'reselect';
import { BASKET_PRODUCT_ORD } from 'src/constants/ord';
import { getPaymentData } from 'src/selectors/getPaymentData';
import { getProductTypes } from 'src/selectors/getProductTypes';
import { PaymentProduct } from 'src/types/PaymentProduct';
import { countFamilyCards } from 'src/utils/basket/countFamilyCards';
import { showFamilyCardDiscount } from 'src/utils/basket/showFamilyCardDiscount';
import { isNotNull } from 'src/utils/guard';

export const getPaymentProducts = createSelector([
  getProductTypes,
  getPaymentData,
], (productTypes, paymentData): ReadonlyArray<PaymentProduct> => {
  return pipe(
    productTypes,
    A.map(({ code }) => {
      const product = paymentData.basket[code];
      if (!product) {
        return null;
      }
      const prices = paymentData.total.ticketPrices[code];
      if (!prices) {
        return null;
      }

      return {
        code: code,
        prices: prices,
        formData: product.formData,
        position: product.position,
      };
    }),
    A.filter(isNotNull),
    A.sort(BASKET_PRODUCT_ORD),
    A.map((product): PaymentProduct => {
      return {
        code: product.code,
        form: product.formData,

        prices: product.prices,

        familyCardCount: countFamilyCards(paymentData.basket),
        familyCardDiscount: showFamilyCardDiscount(product.code, product.formData),
      };
    }),
  );
});

import { PAYMENT_FETCH, PAYMENT_RESET } from 'src/actions/PaymentActions';
import { PaymentState } from 'src/types/PaymentState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: PaymentState = {
  data: null,
};

export const paymentReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (PAYMENT_FETCH.is(action)) {
    return {
      ...state,
      data: action.data,
    };
  }
  if (PAYMENT_RESET.is(action)) {
    return {
      ...state,
      data: null,
    };
  }

  return state;
});

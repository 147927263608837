import { identity } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

const DECODER: D.Decoder<unknown, unknown> = {
  decode: D.success,
};

const ENCODER: E.Encoder<unknown, unknown> = {
  encode: identity,
};

export const UNKNOWN: C.Codec<unknown, unknown, unknown> = C.make(DECODER, ENCODER);

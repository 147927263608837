import './PageLayout.scss';
import classNames from 'classnames';
import React from 'react';
import { PageContainer } from 'src/components/layout/PageContainer';
import { PageFooter } from 'src/components/layout/PageFooter';
import { PageHeader } from 'src/components/layout/PageHeader';

type Props = {
  readonly variant?: 'default' | 'clean';
  readonly children: React.ReactNode;
};

export function PageLayout({ variant = 'default', children }: Props): React.ReactElement {
  return (
    <div className={classNames('sts-ui-page-layout', `sts-ui-page-layout--${variant}`)}>
      <div className="sts-ui-page-layout__header">
        <PageHeader/>
      </div>

      <div className="sts-ui-page-layout__content">
        {children}
      </div>

      <div className="sts-ui-page-layout__footer">
        <PageContainer>
          <PageFooter/>
        </PageContainer>
      </div>
    </div>
  );
}

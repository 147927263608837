import { SagaIterator } from 'redux-saga';
import { CHECKOUT_PERSONAL_CHANGE } from 'src/actions/CheckoutActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getCheckoutData } from 'src/selectors/getCheckoutData';
import { CheckoutData } from 'src/types/CheckoutData';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* checkoutPersonalChangeSaga(
  action: GetRequestActionType<typeof CHECKOUT_PERSONAL_CHANGE>,
): SagaIterator<void> {
  const form = action.data;
  const formData = form.currentState.values;

  try {
    yield* call(logDebug, 'Changing Checkout Personal form…', formData);
    yield* put(CHECKOUT_PERSONAL_CHANGE.pending());

    yield* delay(500);

    const current = yield* select(getCheckoutData);
    const updated: CheckoutData = {
      ...current,
      totalPrice: null,
      formData: {
        ...current.formData,
        personal: formData,
      },
    };

    yield* call(logDebug, 'Changing Checkout Personal form… done', updated);
    yield* put(CHECKOUT_PERSONAL_CHANGE.success(updated));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not change Checkout Personal form',
      { formData },
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Changing Checkout Personal form… error', error);
    yield* put(CHECKOUT_PERSONAL_CHANGE.failure(NativeError.wrap(error)));
  }
}

import { SagaIterator } from 'redux-saga';
import { BaseError } from 'src/errors/BaseError';
import { ROUTING_ERROR_HOOKS } from 'src/routing/constants/RoutingHooks';
import { Transition } from 'src/routing/types/Transition';
import { call } from 'typed-redux-saga';

export function* handleTransitionError(transition: Transition, error: BaseError): SagaIterator<void> {
  for (const errorHook of ROUTING_ERROR_HOOKS) {
    yield* call(errorHook, transition, error);
  }
}

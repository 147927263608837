import './InfoModal.scss';
import React, { useCallback, useState } from 'react';
import ReactModal, { Classes, Styles } from 'react-modal';
import { InfoFilledIcon } from 'src/components/icons/InfoFilledIcon';
import { RemoveIcon } from 'src/components/icons/RemoveIcon';

type Props = {
  readonly children: React.ReactNode;
};

export function InfoModal({ children }: Props): React.ReactElement {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <button
        type="button"
        onClick={handleOpen}
        className="sts-ui-info-modal__trigger"
      >
        <InfoFilledIcon/>
      </button>

      <ReactModal
        isOpen={open}
        style={NO_STYLE}
        className={CONTENT_CLASES}
        overlayClassName={OVERLAY_CLASES}
        bodyOpenClassName="sts-ui-root--no-scroll"
        htmlOpenClassName="sts-ui-root--no-scroll"
        preventScroll={true}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleClose}
      >
        <button
          type="button"
          onClick={handleClose}
          className="sts-ui-info-modal__close"
        >
          <RemoveIcon/>
        </button>

        <div className="sts-ui-info-modal__body">
          {children}
        </div>
      </ReactModal>
    </>
  );
}

const NO_STYLE: Styles = {
  overlay: {},
  content: {},
};
const OVERLAY_CLASES: Classes = {
  base: 'sts-ui-info-modal__overlay',
  afterOpen: 'sts-ui-info-modal__overlay--open',
  beforeClose: 'sts-ui-info-modal__overlay--close',
};
const CONTENT_CLASES: Classes = {
  base: 'sts-ui-info-modal__content',
  afterOpen: 'sts-ui-info-modal__content--open',
  beforeClose: 'sts-ui-info-modal__content--close',
};

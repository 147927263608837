import { format } from 'date-fns/format';
import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';
import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

const DATE_FORMAT = 'dd.MM.yyyy';
const REFERENCE_DATE = new Date(0);

const DECODER: D.Decoder<unknown, Date> = pipe(
  D.string,
  D.parse((input) => {
    const d = parse(input, DATE_FORMAT, REFERENCE_DATE);
    return isValid(d) ? D.success(d) : D.failure(input, 'DateOnly');
  }),
);

const ENCODER: E.Encoder<string, Date> = {
  encode: (value) => {
    return format(value, DATE_FORMAT);
  },
};

export const DATE_ONLY: C.Codec<unknown, string, Date> = C.make(
  DECODER,
  ENCODER,
);

import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router';
import { getCurrentLocation } from 'src/routing/selectors/getCurrentLocation';

type Props = {
  readonly children: React.ReactNode;
};

export function RouteSwitch({ children }: Props): React.ReactElement | null {
  const location = useSelector(getCurrentLocation);

  return location
    ? <Switch location={location}>{children}</Switch>
    : null;
}

import { JsonRecord } from 'fp-ts/Json';
import * as E from 'io-ts/Encoder';
import { DATE_ONLY } from 'src/codecs/DateOnly';
import { DISCOUNT_CODE } from 'src/codecs/DiscountCode';
import { INTEGER } from 'src/codecs/Integer';
import { TICKET_CLASS } from 'src/codecs/TicketClass';
import { TICKET_DURATION } from 'src/codecs/TicketDuration';
import { ProductFormData } from 'src/types/ProductFormData';

export const PRODUCT_PRICE_REQUEST: E.Encoder<JsonRecord, ProductFormData> = E.struct({
  startDate: E.nullable(DATE_ONLY),
  returnDate: E.nullable(DATE_ONLY),

  class: E.nullable(TICKET_CLASS),
  duration: E.nullable(TICKET_DURATION),

  adult: E.nullable(INTEGER),
  child: E.nullable(INTEGER),
  youth: E.nullable(INTEGER),

  cardType: E.nullable(DISCOUNT_CODE),
  familyCard: E.nullable(E.id<boolean>()),
  agreement: E.nullable(E.id<boolean>()),
});

import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { Switcher } from 'src/forms/components/Switcher';
import { FormError } from 'src/forms/types/FormError';

type Option<TValue extends string | number> = {
  readonly value: TValue;
  readonly label: React.ReactNode;
};

type Props<TData, TValue extends string | number> = {
  readonly field: FieldPath<TData, FormError, TValue | null>;
  readonly options: ReadonlyArray<Option<TValue>>;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly formatError?: (error: FormError) => string;
};

export function SwitcherField<TData, TValue extends string | number>({
  field,
  options,
  disabled,
  className,
  formatError,
}: Props<TData, TValue>): React.ReactElement {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);
  const validity = error !== undefined && formatError !== undefined ? formatError(error) : undefined;

  const handleChange = useCallback((nextValue: TValue) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(nextValue),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <Switcher<TValue>
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      value={value}
      options={options}
      onBlur={handleBlur}
      onChange={handleChange}
      invalid={invalid}
      disabled={disabled}
      validity={validity}
      className={className}
    />
  );
}

import { formatISO } from 'date-fns/formatISO';
import { isValid } from 'date-fns/isValid';
import { parseISO } from 'date-fns/parseISO';
import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

const DECODER: D.Decoder<unknown, Date> = pipe(
  D.string,
  D.parse((input) => {
    const d = parseISO(input);
    return isValid(d) ? D.success(d) : D.failure(input, 'DateTime');
  }),
);

const ENCODER: E.Encoder<string, Date> = {
  encode: (value) => {
    return formatISO(value);
  },
};

export const DATE_TIME: C.Codec<unknown, string, Date> = C.make(
  DECODER,
  ENCODER,
);

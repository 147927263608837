import { createSelector } from 'reselect';
import { getAffiliateData } from 'src/selectors/getAffiliateData';
import { assertNotEmpty } from 'src/utils/assert';

export const getAffiliateCurrency = createSelector([
  getAffiliateData,
], (affiliate) => assertNotEmpty(
  affiliate.currency,
  'Affiliate data is not loaded',
  { affiliate },
));

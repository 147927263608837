import './PageHeader.scss';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { MenuDropdownCurrency } from 'src/components/common/MenuDropdownCurrency';
import { MenuDropdownLanguage } from 'src/components/common/MenuDropdownLanguage';
import { BasketIndicator } from 'src/components/layout/BasketIndicator';
import { MobileMenu } from 'src/components/layout/MobileMenu';
import { PageContainer } from 'src/components/layout/PageContainer';
import { DEFAULT_LOGO } from 'src/constants/logo';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { getCurrentTransitionId } from 'src/routing/selectors/getCurrentTransitionId';
import { getAffiliateData } from 'src/selectors/getAffiliateData';
import { getBasketProductCount } from 'src/selectors/getBasketProductCount';
import { getIsIframeMode } from 'src/selectors/getIsIframeMode';
import { throttle } from 'src/utils/throttle';

export function PageHeader(): React.ReactElement {
  const isIframe = useSelector(getIsIframeMode);
  const transitionId = useSelector(getCurrentTransitionId);
  const affiliate = useSelector(getAffiliateData);
  const basketCount = useSelector(getBasketProductCount);

  const [scrolled, setScrolled] = useState(isPageScrolled);

  useEffect(() => {
    const [handler, cancel] = throttle(() => setScrolled(isPageScrolled()), 50);
    window.addEventListener('scroll', handler, { passive: true });

    return () => {
      cancel();
      window.removeEventListener('scroll', handler);
    };
  });

  return (
    <div className={classNames(
      'sts-ui-page-header',
      scrolled ? 'sts-ui-page-header--scrolled' : null,
    )}
    >
      <PageContainer>
        <div className="sts-ui-page-header__content">
          {!isIframe && (
            <div className="sts-ui-page-header__logo">
              <RouteLink route="homepage">
                <img src={affiliate.appThemeSettings.logoUrl || DEFAULT_LOGO} alt="Logo"/>
              </RouteLink>
            </div>
          )}

          <ul className="sts-ui-page-header__nav">
            {isIframe && (
              <li>
                <RouteLink route="homepage">
                  <FormattedMessage id="Menu.Navigation.Homepage"/>
                </RouteLink>
              </li>
            )}
            <li>
              <RouteLink route="product:swissPass">
                <FormattedMessage id="Menu.Navigation.SwissTravelPass"/>
              </RouteLink>
            </li>
            <li>
              <RouteLink route="product:swissFlexiPass">
                <FormattedMessage id="Menu.Navigation.SwissTravelPassFlex"/>
              </RouteLink>
            </li>
            <li>
              <RouteLink route="product:swissHalfFareCard">
                <FormattedMessage id="Menu.Navigation.SwissHalfFareCard"/>
              </RouteLink>
            </li>
            <li>
              <RouteLink route="excursions">
                <FormattedMessage id="Menu.Navigation.Excursions"/>
              </RouteLink>
            </li>
          </ul>

          {!isIframe && (
            <ul className="sts-ui-page-header__top">
              <li className="sts-ui-page-header__contact">
                <RouteLink route="contact">
                  <FormattedMessage id="Layout.Header.Contact"/>
                </RouteLink>
              </li>
              <li className="sts-ui-page-header__currency">
                <MenuDropdownCurrency/>
              </li>
              <li className="sts-ui-page-header__language">
                <MenuDropdownLanguage/>
              </li>
            </ul>
          )}

          {basketCount > 0 && (
            <div className="sts-ui-page-header__basket">
              <RouteLink route="basket" className="sts-ui-page-header__basket-link">
                <BasketIndicator count={basketCount}/>
              </RouteLink>
            </div>
          )}

          <div className="sts-ui-page-header__mobile">
            <MobileMenu key={transitionId}/>
          </div>
        </div>
      </PageContainer>
    </div>
  );
}

function isPageScrolled(): boolean {
  const scrollTop = window.document.documentElement.scrollTop;
  return scrollTop > 10;
}

import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchBasketProductDetails } from 'src/sagas/basket/utils/fetchBasketProductDetails';
import { getProductTypes } from 'src/selectors/getProductTypes';
import { BasketProduct } from 'src/types/BasketProduct';
import { BasketProductDetails } from 'src/types/BasketProductDetails';
import { Currency } from 'src/types/Currency';
import { Language } from 'src/types/Language';
import { ProductMap } from 'src/types/ProductMap';
import { isNotNull } from 'src/utils/guard';
import { all, select } from 'typed-redux-saga';

export function* fetchBasketProducts(
  currency: Currency,
  language: Language,
  basket: ProductMap<BasketProduct>,
): SagaIterator<BasketProductDetails[]> {
  try {
    const productTypes = yield* select(getProductTypes);

    return yield* all(
      productTypes
        .map((it) => (basket[it.code] ? ({ type: it.code, ...basket[it.code]! }) : null))
        .filter(isNotNull)
        .map(({ type, formData, position }) => callEffect(
          fetchBasketProductDetails,
          currency,
          language,
          basket,
          position,
          type,
          formData,
        )),
    );
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Basket products',
      { currency, language, basket },
      NativeError.wrap(error),
    );
  }
}

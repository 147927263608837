import { Decimal } from 'decimal.js-light';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/ReadonlyArray';
import { createSelector } from 'reselect';
import { BASKET_PRODUCT_ORD } from 'src/constants/ord';
import { getBasketData } from 'src/selectors/getBasketData';
import { getBasketShiftedProducts } from 'src/selectors/getBasketShiftedProducts';
import { getBasketUnavailableProducts } from 'src/selectors/getBasketUnavailableProducts';
import { BasketProductListItem } from 'src/types/BasketProductListItem';

export const getBasketProductList = createSelector([
  getBasketData,
  getBasketShiftedProducts,
  getBasketUnavailableProducts,
], (data, shifted, unavailable): ReadonlyArray<BasketProductListItem> => {
  return pipe(
    data.products,
    A.sort(BASKET_PRODUCT_ORD),
    A.map((product) => ({
      code: product.code,
      form: product.form,

      prices: product.prices,
      totalPrice: product.prices.reduce((sum, it) => sum.add(it.price), new Decimal(0)),

      invalid: shifted.has(product.code),
      unavailable: unavailable.has(product.code),

      familyCardCount: product.familyCardCount,
      familyCardDiscount: product.familyCardDiscount,
    })),
  );
});

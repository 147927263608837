import { SagaIterator } from 'redux-saga';
import { REGIONAL_PASSES_FETCH } from 'src/actions/RegionalPassesActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { Transition } from 'src/routing/types/Transition';
import { fetchProductListData } from 'src/sagas/common/utils/fetchProductListData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getProductTypes } from 'src/selectors/getProductTypes';
import { ProductCode } from 'src/types/ProductCode';
import { call, put, select } from 'typed-redux-saga';

export function* fetchRegionalPasses(transition: Transition): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching Regional Passes data…');

    const currency = transition.to.currency;
    const products = yield* select(getProductTypes);

    const productList = yield* call(
      fetchProductListData,
      currency,
      products
        .filter((it) => PRODUCT_CODES.includes(it.code))
        .map((it) => it.code),
      null,
    );

    yield* call(logDebug, 'Fetching Regional Passes data… done', productList);
    yield* put(REGIONAL_PASSES_FETCH.trigger(productList));
  } catch (error) {
    yield* call(logError, 'Fetching Regional Passes data… error', error);
    throw new RuntimeError('Could not fetch Regional Passes data', {}, NativeError.wrap(error));
  }
}

const PRODUCT_CODES: ReadonlyArray<ProductCode> = [
  'regionalBerneseOberlandPass',
  'regionalCentralSwissPass',
];

import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { INTEGER } from 'src/codecs/Integer';
import { PRODUCT_FORM_DATA } from 'src/codecs/ProductFormData';
import { BasketProduct } from 'src/types/BasketProduct';

export const BASKET_PRODUCT: C.Codec<unknown, JsonRecord, BasketProduct> = C.struct({
  position: INTEGER,
  formData: PRODUCT_FORM_DATA,
});

import './CheckoutPaymentAgreementFormView.scss';
import { FormController } from '@form-ts/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckboxField } from 'src/forms/fields/CheckboxField';
import { FormError } from 'src/forms/types/FormError';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { CheckoutPaymentFormData } from 'src/types/CheckoutFormData';

type Props = {
  readonly form: FormController<CheckoutPaymentFormData, FormError>;
  readonly optInControl: boolean;
};

export function CheckoutPaymentAgreementFormView({ form, optInControl }: Props): React.ReactElement {
  const linkPrivacyPolicy = (
    <RouteLink route="privacypolicy" target="_blank" rel="noopener noreferrer">
      <FormattedMessage id="Form.Checkout.Payment.IPrivacyPolicyLink"/>
    </RouteLink>
  );
  const linkTermsAndConditions = (
    <RouteLink route="gtc" target="_blank" rel="noopener noreferrer">
      <FormattedMessage id="Form.Checkout.Payment.IConfirmTermsLink"/>
    </RouteLink>
  );
  const linkSpecialTermsAndConditions = (
    <RouteLink route="conditions" target="_blank" rel="noopener noreferrer">
      <FormattedMessage id="Form.Checkout.Payment.ISpecialTermsLink"/>
    </RouteLink>
  );

  return (
    <div className="sts-ui-checkout-payment-agreement-form-view">
      <div className="sts-ui-checkout-payment-agreement-form-view__check">
        <CheckboxField
          field={form.field.at('agreement')}
          label={(
            <FormattedMessage
              id="Form.Checkout.Payment.IConfirmTerms"
              values={{
                link: linkTermsAndConditions,
                linkSpecial: linkSpecialTermsAndConditions,
              }}
            />
          )}
        />
      </div>

      {optInControl && (
        <div className="sts-ui-checkout-payment-agreement-form-view__check">
          <CheckboxField
            field={form.field.at('optIn')}
            label={<FormattedMessage id="Form.Checkout.Payment.OptInTerms"/>}
          />
        </div>
      )}

      <div className="sts-ui-checkout-payment-agreement-form-view__information">
        <FormattedMessage
          id="Form.Checkout.Payment.InformationBox"
          values={{
            link: linkPrivacyPolicy,
          }}
        />
      </div>
    </div>
  );
}

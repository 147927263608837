import { createSelector } from 'reselect';
import { getCheckoutCustomerTitles } from 'src/selectors/getCheckoutCustomerTitles';
import { getIntlCollator } from 'src/selectors/getIntlCollator';
import { CustomerTitle } from 'src/types/CustomerTitle';
import { ListOption } from 'src/types/ListOption';

export const getCheckoutCustomerTitleList = createSelector([
  getCheckoutCustomerTitles,
  getIntlCollator,
], (titleMap, collator): ReadonlyArray<ListOption<CustomerTitle>> => {
  return Object.entries(titleMap)
    .map(([value, title]) => ({ value, title }))
    .sort((a, b) => collator.compare(a.title, b.title));
});

import './MobileMenu.scss';
import React, { useCallback, useState } from 'react';
import { MobileMenuContent } from 'src/components/layout/MobileMenuContent';
import { MobileMenuIndicator } from 'src/components/layout/MobileMenuIndicator';
import { MobileMenuModal } from 'src/components/layout/MobileMenuModal';

export function MobileMenu(): React.ReactElement {
  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div className="sts-ui-mobile-menu">
      <button type="button" onClick={handleToggle} className="sts-ui-mobile-menu__trigger">
        <div className="sts-ui-mobile-menu__trigger-text">Menu</div>
        <div className="sts-ui-mobile-menu__trigger-icon">
          <MobileMenuIndicator open={open}/>
        </div>
      </button>

      <MobileMenuModal open={open} onClose={handleToggle}>
        <div className="sts-ui-mobile-menu__content">
          <MobileMenuContent/>
        </div>
      </MobileMenuModal>
    </div>
  );
}

import './PageContent.scss';
import React from 'react';

type Props = {
  readonly children: React.ReactNode;
};

export function PageContent({ children }: Props): React.ReactElement {
  return (
    <div className="sts-ui-page-content">
      {children}
    </div>
  );
}

import './PaymentSuccessCrossSell.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CheckoutOrder } from 'src/types/CheckoutOrder';

type Props = {
  readonly order: CheckoutOrder;
};

export function PaymentSuccessCrossSell({ order }: Props): React.ReactElement {
  return (
    <div className="sts-ui-payment-success-cross-sell">
      <div className="sts-ui-payment-success-cross-sell__card">
        <div className="sts-ui-payment-success-cross-sell__title">
          <h4><FormattedMessage id="Payment.Success.SwissPassUpgradeHead"/></h4>
          <div className="sts-ui-payment-success-cross-sell__discount">
            <FormattedHTMLMessage id="Payment.Success.SwissPassUpgradeDiscount"/>
          </div>
        </div>
        <div className="sts-ui-payment-success-cross-sell__description">
          <FormattedMessage
            id="Payment.Success.SwissPassUpgradeDescription"
            defaultMessage="_payment_success_swisspassupgradedescription_"
          />
        </div>
        <div className="sts-ui-payment-success-cross-sell__action">
          <FormattedHTMLMessage
            id="Payment.Success.SwissPassUpgradeLink"
            values={{ affiliateId: order.data.affiliateId, currency: order.data.currency }}
          />
        </div>
      </div>

      <div className="sts-ui-payment-success-cross-sell__card">
        <div className="sts-ui-payment-success-cross-sell__title">
          <h4><FormattedMessage id="Payment.Success.ExcursionsHead"/></h4>
        </div>
        <div className="sts-ui-payment-success-cross-sell__description">
          <FormattedMessage id="Payment.Success.ExcursionsDescription"/>
        </div>
        <div className="sts-ui-payment-success-cross-sell__action">
          <FormattedHTMLMessage
            id="Payment.Success.ExcursionsLink"
            values={{ affiliateId: order.data.affiliateId, currency: order.data.currency }}
          />
        </div>
      </div>
    </div>
  );
}

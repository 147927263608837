import { pipe } from 'fp-ts/function';
import * as D from 'io-ts/Decoder';
import { CURRENCY } from 'src/codecs/Currency';
import { DECIMAL } from 'src/codecs/Decimal';
import { EMPTY } from 'src/codecs/Empty';
import { JSON_STRING } from 'src/codecs/JsonString';
import { LANGUAGE } from 'src/codecs/Language';
import { UNKNOWN } from 'src/codecs/Unknown';
import { Affiliate, AffiliateThemeSettings } from 'src/types/Affiliate';

const AFFILIATE_THEME: D.Decoder<unknown, Partial<AffiliateThemeSettings>> = D.partial({
  buttonBackgroundColor: D.string,
  buttonHoverColor: D.string,
  buttonTextColor: D.string,

  headerColor: D.string,
  headerTextColor: D.string,
  headerTextHoverColor: D.string,

  logoUrl: D.string,
  faviconUrl: D.string,

  hideFooterPartners: D.boolean,
  hideMainBackground: D.boolean,
  hideCookieBanner: D.boolean,

  pageTitle: D.string,

  provenExpertSeal: D.boolean,
  fusedeckSnippet: D.boolean,
});

const AFFILIATE_GTM_ITEM = D.struct({
  key: D.string,
  value: UNKNOWN,
});
const AFFILIATE_GTM_PARAMS: D.Decoder<unknown, Record<string, unknown>> = pipe(
  D.string,
  D.parse(JSON_STRING.decode),
  D.parse(D.array(AFFILIATE_GTM_ITEM).decode),
  D.parse((array) => D.success(Object.fromEntries(array.map((it) => [it.key, it.value])))),
);

export const AFFILIATE: D.Decoder<unknown, Affiliate> = D.struct({
  affiliateId: D.string,
  lifeTime: D.nullable(DECIMAL),

  language: LANGUAGE,
  currency: CURRENCY,

  gtmTrackingId: D.union(EMPTY, D.string),
  gtmCustomParams: D.union(EMPTY, AFFILIATE_GTM_PARAMS),

  optIn: D.boolean,
  isIframe: D.boolean,
  showAdvBanner: D.boolean,
  appThemeSettings: AFFILIATE_THEME,
});

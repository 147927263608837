import React from 'react';
import { useSelector } from 'react-redux';
import { NewsletterScreen } from 'src/components/newsletter/NewsletterScreen';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';

export function NewsletterPage(): React.ReactElement {
  const language = useSelector(getCurrentLanguage);

  return (
    <NewsletterScreen language={language}/>
  );
}

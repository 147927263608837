import { RuntimeError } from 'src/errors/RuntimeError';

export function injectScript(attrs: Readonly<Record<string, string>>, content?: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const el = document.createElement('script');
    Object.entries(attrs).forEach(([key, val]) => el.setAttribute(key, val));
    if (content) {
      el.appendChild(document.createTextNode(content));
    }
    el.onload = (): void => resolve();
    el.onerror = (): void => reject(new RuntimeError('Could not initialize script', attrs));

    document.head.appendChild(el);
  });
}

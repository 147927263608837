import {
  CHECKOUT_FETCH,
  CHECKOUT_PAYMENT_CHANGE,
  CHECKOUT_PAYMENT_SUBMIT,
  CHECKOUT_PERSONAL_CHANGE,
  CHECKOUT_PERSONAL_SUBMIT,
  CHECKOUT_RESET,
} from 'src/actions/CheckoutActions';
import { CheckoutState } from 'src/types/CheckoutState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: CheckoutState = {
  data: null,
  loading: false,
};

export const checkoutReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (CHECKOUT_FETCH.is(action)) {
    return {
      ...state,
      data: action.data,
      loading: false,
    };
  }
  if (CHECKOUT_RESET.is(action)) {
    return {
      ...state,
      data: null,
      loading: false,
    };
  }

  if (CHECKOUT_PERSONAL_CHANGE.isSuccess(action)) {
    return {
      ...state,
      data: action.data,
      loading: false,
    };
  }
  if (CHECKOUT_PERSONAL_SUBMIT.isPending(action)) {
    return {
      ...state,
      loading: true,
    };
  }
  if (CHECKOUT_PERSONAL_SUBMIT.isSuccess(action)) {
    return {
      ...state,
      data: action.data,
      loading: true,
    };
  }
  if (CHECKOUT_PERSONAL_SUBMIT.isFailure(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  if (CHECKOUT_PAYMENT_CHANGE.isPending(action)) {
    return {
      ...state,
      loading: true,
    };
  }
  if (CHECKOUT_PAYMENT_CHANGE.isSuccess(action)) {
    return {
      ...state,
      data: action.data,
      loading: false,
    };
  }
  if (CHECKOUT_PAYMENT_CHANGE.isFailure(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  if (CHECKOUT_PAYMENT_SUBMIT.isPending(action)) {
    return {
      ...state,
      loading: true,
    };
  }
  if (CHECKOUT_PAYMENT_SUBMIT.isSuccess(action)) {
    return {
      ...state,
      data: action.data,
      loading: true,
    };
  }
  if (CHECKOUT_PAYMENT_SUBMIT.isFailure(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  return state;
});

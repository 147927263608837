import { RefObject, useEffect } from 'react';

export function useCustomValidity(
  input: RefObject<HTMLInputElement | HTMLButtonElement>,
  validity: string,
): void {
  useEffect(() => {
    if (input.current !== null) {
      input.current.setCustomValidity(validity);
    }
  }, [input, validity]);
}

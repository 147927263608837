import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchBasketProducts } from 'src/sagas/basket/utils/fetchBasketProducts';
import { fetchCheckoutCountries } from 'src/sagas/checkout/utils/fetchCheckoutCountries';
import { fetchCheckoutPaymentTypes } from 'src/sagas/checkout/utils/fetchCheckoutPaymentTypes';
import { fetchCheckoutTitles } from 'src/sagas/checkout/utils/fetchCheckoutTitles';
import { fetchCheckoutTravelerCountries } from 'src/sagas/checkout/utils/fetchCheckoutTravelerCountries';
import { fetchCheckoutTravelerFields } from 'src/sagas/checkout/utils/fetchCheckoutTravelerFields';
import { loadCheckoutStore } from 'src/sagas/checkout/utils/storeCheckoutData';
import { BasketProduct } from 'src/types/BasketProduct';
import { CheckoutData } from 'src/types/CheckoutData';
import { Currency } from 'src/types/Currency';
import { Language } from 'src/types/Language';
import { ProductMap } from 'src/types/ProductMap';
import { createCheckoutFormData } from 'src/utils/checkout/createCheckoutFormData';
import { resolveCheckoutFormData } from 'src/utils/checkout/resolveCheckoutFormData';
import { all, call } from 'typed-redux-saga';

export function* fetchCheckoutPersonalData(
  currency: Currency,
  language: Language,
  basket: ProductMap<BasketProduct>,
): SagaIterator<CheckoutData | null> {
  try {
    const products = yield* call(
      fetchBasketProducts,
      currency,
      language,
      basket,
    );
    if (
      products.length === 0 ||
      products.some((it) => it.prices.length === 0)
    ) {
      return null;
    }

    const {
      titles,
      countries,
      paymentTypes,

      travelerFields,
      travelerCountries,
    } = yield* all({
      titles: callEffect(fetchCheckoutTitles, currency, language),
      countries: callEffect(fetchCheckoutCountries, currency, language),
      paymentTypes: callEffect(fetchCheckoutPaymentTypes, currency),

      travelerFields: callEffect(fetchCheckoutTravelerFields, basket),
      travelerCountries: callEffect(fetchCheckoutTravelerCountries, currency),
    });

    const checkoutStore = yield* call(loadCheckoutStore);
    const formData = checkoutStore
      ? yield* call(resolveCheckoutFormData, products, checkoutStore.formData)
      : yield* call(createCheckoutFormData, products);

    return {
      titles: titles,
      countries: countries,
      paymentTypes: paymentTypes,

      travelerFields: travelerFields,
      travelerCountries: travelerCountries,

      products: products,
      formData: formData,

      totalPrice: null,
    };
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Checkout Personal data',
      { currency, language, basket },
      NativeError.wrap(error),
    );
  }
}

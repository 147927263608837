import React from 'react';
import { DiscountName } from 'src/components/common/DiscountName';
import { DiscountCode } from 'src/types/DiscountCode';
import { ProductCode } from 'src/types/ProductCode';

type Props = {
  readonly product: ProductCode;
  readonly discounts: ReadonlyArray<DiscountCode>;
};

export function DiscountList({ product, discounts }: Props): React.ReactElement | null {
  if (discounts.length === 0) {
    return null;
  }
  if (discounts.length === 1) {
    return <DiscountName product={product} discount={discounts[0]}/>;
  }

  return (
    <>
      {discounts.map((discount, index) => (
        <React.Fragment key={discount}>
          {index > 0 ? ', ' : null}
          <DiscountName product={product} discount={discount}/>
        </React.Fragment>
      ))}
    </>
  );
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createSelector } from 'reselect';
import { getPromotionConfig } from 'src/selectors/getPromotionConfig';
import { PromotionPackage } from 'src/types/PromotionPackage';
import { PromotionService } from 'src/types/PromotionService';

export const getPromotionService = createSelector([
  getPromotionConfig,
], (config): PromotionService => {
  return {
    getPromotionPackage: (productCode): PromotionPackage | null => {
      for (const [type, attrs] of Object.entries(config)) {
        if (
          attrs.primaryProduct === productCode ||
          attrs.additionalProduct === productCode
        ) {
          return {
            type: type,
            name: <FormattedMessage id={`Promotion.${type}.ProductTeaser`}/>,
          };
        }
      }

      return null;
    },
  };
});

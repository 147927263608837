import { ROUTING_LANGUAGES } from 'src/routing/constants/RoutingLanguages';
import { fetchBasket } from 'src/routing/hooks/resolvers/fetchBasket';
import { fetchCheckoutPayment } from 'src/routing/hooks/resolvers/fetchCheckoutPayment';
import { fetchCheckoutPersonal } from 'src/routing/hooks/resolvers/fetchCheckoutPersonal';
import { fetchPaymentFailure } from 'src/routing/hooks/resolvers/fetchPaymentFailure';
import { fetchPaymentSuccess } from 'src/routing/hooks/resolvers/fetchPaymentSuccess';
import { passThrought } from 'src/routing/hooks/resolvers/passThrought';
import { resetBasket } from 'src/routing/hooks/resolvers/resetBasket';
import { resetCheckout } from 'src/routing/hooks/resolvers/resetCheckout';
import { resetPayment } from 'src/routing/hooks/resolvers/resetPayment';
import { RouteState } from 'src/routing/types/RouteState';
import { getRouteStatePath } from 'src/routing/utils/getRouteStatePath';

export const ROUTING_PRIVATE_STATES: ReadonlyArray<RouteState> = ROUTING_LANGUAGES.map((language): RouteState[] => [
  {
    url: getRouteStatePath(language, 'legalnotice', 'basepath'),
    name: `sts:ui:legalnotice:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'legalnotice-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'legalnotice-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'privacypolicy', 'basepath'),
    name: `sts:ui:privacypolicy:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'privacypolicy-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'privacypolicy-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'gtc', 'basepath'),
    name: `sts:ui:gtc:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'gtc-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'gtc-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'about', 'basepath'),
    name: `sts:ui:about:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'about-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'about-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
    },
  },

  {
    url: getRouteStatePath(language, 'basket', 'basepath'),
    name: `sts:ui:basket:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'basket-fetch',
        deps: ['globals', 'locale'],
        saga: fetchBasket.bind(null, language),
      }],
      onRetaining: [{
        name: 'basket-fetch',
        deps: ['globals', 'locale'],
        saga: fetchBasket.bind(null, language),
      }],
      onLeft: [{
        name: 'baset-reset',
        deps: [],
        saga: resetBasket,
      }],
    },
  },

  {
    url: getRouteStatePath(language, 'checkout:base', 'basepath'),
    name: `sts:ui:checkout:base:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onLeft: [{
        name: 'checkout-reset',
        deps: [],
        saga: resetCheckout,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'checkout:personal', 'checkout:base'),
    name: `sts:ui:checkout:personal:${language}`,
    parent: `sts:ui:checkout:base:${language}`,
    hooks: {
      onEntering: [{
        name: 'checkout-fetch',
        deps: ['globals', 'locale'],
        saga: fetchCheckoutPersonal.bind(null, language),
      }],
      onRetaining: [{
        name: 'checkout-fetch',
        deps: ['globals', 'locale'],
        saga: fetchCheckoutPersonal.bind(null, language),
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'checkout:payment', 'checkout:base'),
    name: `sts:ui:checkout:payment:${language}`,
    parent: `sts:ui:checkout:base:${language}`,
    hooks: {
      onEntering: [{
        name: 'checkout-fetch',
        deps: ['globals', 'locale'],
        saga: fetchCheckoutPayment.bind(null, language),
      }],
      onRetaining: [{
        name: 'checkout-fetch',
        deps: ['globals', 'locale'],
        saga: fetchCheckoutPayment.bind(null, language),
      }],
    },
  },

  {
    url: getRouteStatePath(language, 'payment:base', 'basepath'),
    name: `sts:ui:payment:base:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onLeft: [{
        name: 'payment-reset',
        deps: [],
        saga: resetPayment,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'payment:cancel', 'payment:base'),
    name: `sts:ui:payment:cancel:${language}`,
    parent: `sts:ui:payment:base:${language}`,
    hooks: {
      onEntering: [{
        name: 'payment-fetch',
        deps: ['globals', 'locale'],
        saga: fetchPaymentFailure.bind(null, language),
      }],
      onRetaining: [{
        name: 'payment-fetch',
        deps: ['globals', 'locale'],
        saga: fetchPaymentFailure.bind(null, language),
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'payment:success', 'payment:base'),
    name: `sts:ui:payment:success:${language}`,
    parent: `sts:ui:payment:base:${language}`,
    hooks: {
      onEntering: [{
        name: 'payment-fetch',
        deps: ['globals', 'locale'],
        saga: fetchPaymentSuccess.bind(null, language),
      }],
      onRetaining: [{
        name: 'payment-fetch',
        deps: ['globals', 'locale'],
        saga: fetchPaymentSuccess.bind(null, language),
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'payment:failure', 'payment:base'),
    name: `sts:ui:payment:failure:${language}`,
    parent: `sts:ui:payment:base:${language}`,
    hooks: {
      onEntering: [{
        name: 'payment-fetch',
        deps: ['globals', 'locale'],
        saga: fetchPaymentFailure.bind(null, language),
      }],
      onRetaining: [{
        name: 'payment-fetch',
        deps: ['globals', 'locale'],
        saga: fetchPaymentFailure.bind(null, language),
      }],
    },
  },
]).flat(1);

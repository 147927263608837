import { all, call, Effect } from 'redux-saga/effects';
import { LogicError } from 'src/errors/LogicError';
import { executeRouteHook } from 'src/routing/sagas/utils/executeRouteHook';
import { RouteHook } from 'src/routing/types/RouteHook';
import { RouteMatch } from 'src/routing/types/RouteMatch';
import { Transition } from 'src/routing/types/Transition';

type BoundHook = {
  readonly hook: RouteHook;
  readonly match: RouteMatch;
};

export function combineHooks(hooks: ReadonlyArray<BoundHook>, transition: Transition): Effect[] {
  const bound = new Map<string, BoundHook>();

  for (const { hook, match } of hooks) {
    if (bound.has(hook.name)) {
      throw new LogicError(`Duplicate hook "${hook.name}"`, { match });
    }

    bound.set(hook.name, { hook, match });
  }

  const effects: Effect[] = [];
  const executed = new Set<string>();

  do {
    const stage: BoundHook[] = hooks
      .filter((it) => !executed.has(it.hook.name))
      .filter((it) => it.hook.deps.every((dep) => executed.has(dep)));
    if (stage.length === 0) {
      return effects;
    }

    stage.forEach((it) => checkDependencies(bound, it));
    stage.forEach((it) => executed.add(it.hook.name));

    // we can check all the met dependencies after each hook if needed
    const calls = stage.map((it) => call(executeRouteHook, it.hook, transition, it.match));
    effects.push(all(calls));
    // eslint-disable-next-line no-constant-condition
  } while (true);
}

function checkDependencies(hooks: ReadonlyMap<string, BoundHook>, hook: BoundHook): void {
  const circular = new Set<string>();
  traverse(hook);

  function traverse(main: BoundHook): void {
    if (circular.has(main.hook.name)) {
      const path = [...circular].join(' -> ');
      throw new LogicError(`Hook "${main.hook.name}" has circular dependency "${path}"`);
    }

    circular.add(main.hook.name);
    for (const depName of main.hook.deps) {
      const depHook = hooks.get(depName);
      if (!depHook) {
        throw new LogicError(`Hook "${main.hook.name}" has unknown dependency "${depName}"`);
      }

      traverse(depHook);
    }
    circular.delete(main.hook.name);
  }
}

import './FaqScreen.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Typography } from 'src/components/common/Typography';
import { FaqSectionView } from 'src/components/faq/FaqSectionView';
import { FaqData } from 'src/types/FaqData';

type Props = {
  readonly data: FaqData;
};

export function FaqScreen({ data }: Props): React.ReactElement {
  return (
    <div className="sts-ui-faq-screen">
      <div className="sts-ui-faq-screen__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="FAQ.Page.Title"/>
        </Typography>
      </div>
      <div className="sts-ui-faq-screen__page-subtitle">
        <Typography variant="page-subtitle" as="p">
          <FormattedHTMLMessage id="FAQ.Page.Description"/>
        </Typography>
      </div>

      <div className="sts-ui-faq-screen__content">
        {data.map((chapter, chapterIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={chapterIndex} className="sts-ui-faq-screen__chapter">
            <h4 className="sts-ui-faq-screen__block-title">
              <Typography as="span" variant="block-title">
                <FormattedMessage id={chapter.title}/>
              </Typography>
            </h4>

            {chapter.sections.map((section, sectionIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={sectionIndex} className="sts-ui-faq-screen__section">
                <FaqSectionView section={section}/>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

import { replace } from 'redux-first-history';
import { SagaIterator } from 'redux-saga';
import { BASKET_SET_CURRENCY } from 'src/actions/BasketActions';
import { ROUTE_SYNCHRONOIZE } from 'src/routing/actions/RouteSynchronize';
import { getBrowserLocation } from 'src/routing/selectors/getBrowserLocation';
import { TransitionTarget } from 'src/routing/types/TransitionTarget';
import { saveBasketData } from 'src/sagas/basket/utils/saveBasketData';
import { getBasketCurrency } from 'src/selectors/getBasketCurrency';
import { call, put, select } from 'typed-redux-saga';

export function* syncTransitionTarget(target: TransitionTarget): SagaIterator<void> {
  yield* put(ROUTE_SYNCHRONOIZE.trigger(true, target));

  const currency = yield* select(getBasketCurrency);
  if (currency !== target.currency) {
    yield* put(BASKET_SET_CURRENCY.trigger(target.currency));
    yield* call(saveBasketData);
  }

  const location = yield* select(getBrowserLocation);
  if (
    location.pathname !== target.location.pathname ||
    location.search !== target.location.search ||
    location.hash !== target.location.hash
  ) {
    yield* put(replace(target.location));
  }

  yield* put(ROUTE_SYNCHRONOIZE.trigger(false, target));
}

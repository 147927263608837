import { SagaIterator } from 'redux-saga';
import { PRODUCT_CHANGE } from 'src/actions/ProductActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { modifyProductData } from 'src/sagas/product/utils/modifyProductData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put } from 'typed-redux-saga';

export function* productChangeSaga(
  action: GetRequestActionType<typeof PRODUCT_CHANGE>,
): SagaIterator<void> {
  const productType = action.meta;
  const productForm = action.data;

  try {
    yield* call(logDebug, `Changing product "${productType}"…`, productForm);
    yield* put(PRODUCT_CHANGE.pending(productType));

    yield* delay(500);
    const updated = yield* call(modifyProductData, productType, productForm);

    yield* call(logDebug, `Changing product "${productType}"… done`, updated);
    yield* put(PRODUCT_CHANGE.success(updated, productType));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not change product',
      { productType, productForm },
      NativeError.wrap(error),
    ));

    yield* call(logError, `Changing product "${productType}"… error`, error);
    yield* put(PRODUCT_CHANGE.failure(NativeError.wrap(error), productType));
  }
}

import { pipe } from 'fp-ts/function';
import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import * as E from 'io-ts/Encoder';
import { DATE_ONLY } from 'src/codecs/DateOnly';
import { DISCOUNT_CODE } from 'src/codecs/DiscountCode';
import { TICKET_AGE } from 'src/codecs/TicketAge';
import { TICKET_CLASS } from 'src/codecs/TicketClass';
import { TICKET_DURATION } from 'src/codecs/TicketDuration';
import { BasketProduct } from 'src/types/BasketProduct';
import { BasketProductRequest } from 'src/types/BasketProductRequest';
import { mapBasketToApi } from 'src/utils/basket/mapBasketToApi';

const TRANSFORMER: E.Encoder<BasketProductRequest[], BasketProduct> = {
  encode: (value) => mapBasketToApi(value.formData),
};

const ENCODER: E.Encoder<JsonRecord, BasketProductRequest> = E.struct({
  tariff: TICKET_AGE,

  startDate: E.nullable(DATE_ONLY),
  returnDate: E.nullable(DATE_ONLY),

  class: E.nullable(TICKET_CLASS),
  duration: E.nullable(TICKET_DURATION),

  cardType: E.nullable(DISCOUNT_CODE),
  familyCard: E.nullable(C.boolean),
});

export const BASKET_PRODUCT_REQUEST = pipe(
  TRANSFORMER,
  E.compose(E.array(ENCODER)),
);

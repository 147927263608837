import './CheckoutPaymentTypeSelectFormView.scss';
import { FormController } from '@form-ts/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckoutFormLabel } from 'src/components/checkout/CheckoutFormLabel';
import { RadioField } from 'src/forms/fields/RadioField';
import { FormError } from 'src/forms/types/FormError';
import { selectIfEqual } from 'src/forms/utils/transform';
import { CheckoutPaymentFormData } from 'src/types/CheckoutFormData';
import { PaymentType } from 'src/types/PaymentType';

type Props = {
  readonly form: FormController<CheckoutPaymentFormData, FormError>;
  readonly paymentTypes: ReadonlyArray<PaymentType>;
};

export function CheckoutPaymentTypeSelectFormView({ form, paymentTypes }: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-payment-type-select-form-view">
      <div className="sts-ui-checkout-payment-type-select-form-view__label">
        <CheckoutFormLabel
          field={form.field.at('paymentType')}
          required={true}
        >
          <FormattedMessage id="Form.Checkout.Payment.PaymentType"/>
        </CheckoutFormLabel>
      </div>

      {paymentTypes.map((paymentType) => (
        <div key={paymentType.name} className="sts-ui-checkout-payment-type-select-form-view__option">
          <RadioField
            field={form.field.at('paymentType').transform(selectIfEqual(paymentType.name))}
            label={<FormattedMessage id={`PaymentType.${paymentType.name}`}/>}
          />
        </div>
      ))}
    </div>
  );
}

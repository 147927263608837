import { format } from 'date-fns/format';
import React from 'react';
import { useSelector } from 'react-redux';
import { FORMAT_DATE_LONG } from 'src/constants/format';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { getDateLocale } from 'src/selectors/getDateLocale';

type Props = {
  readonly value: Date;
};

export const FormattedDate = React.memo(({ value }: Props) => {
  const locale = useSelector(getDateLocale);
  const language = useSelector(getCurrentLanguage);
  const formatted = format(value, FORMAT_DATE_LONG[language], { locale });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formatted}</>;
});

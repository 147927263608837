import { createSelector } from 'reselect';
import { getCheckoutState } from 'src/selectors/getCheckoutState';
import { assertNotEmpty } from 'src/utils/assert';

export const getCheckoutData = createSelector([
  getCheckoutState,
], (state) => assertNotEmpty(
  state.data,
  'Checkout state is not initialized',
  { state },
));

import { LOCATION_CHANGE, LocationChangeAction } from 'redux-first-history';
import { buffers, SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { ROUTE_CURRENCY } from 'src/routing/actions/RouteCurrency';
import { ROUTE_DESTROY } from 'src/routing/actions/RouteDestroy';
import { initAffiliate } from 'src/routing/hooks/initializers/initAffiliate';
import { initBasket } from 'src/routing/hooks/initializers/initBasket';
import { initCurrency } from 'src/routing/hooks/initializers/initCurrency';
import { initFuseDeck } from 'src/routing/hooks/initializers/initFuseDeck';
import { initOneTrust } from 'src/routing/hooks/initializers/initOneTrust';
import { currencyChangeSaga } from 'src/routing/sagas/currencyChangeSaga';
import { locationChangeSaga } from 'src/routing/sagas/locationChangeSaga';
import { routeChangeSaga } from 'src/routing/sagas/routeChangeSaga';
import { sentryCatch } from 'src/utils/sentryCatch';
import { actionChannel, call, fork, put, take, takeLatest } from 'typed-redux-saga';

export function* routingSaga(): SagaIterator<void> {
  // collect History navigation until we're ready
  const buffer = yield* call(buffers.sliding<LocationChangeAction>, 1);
  const history = yield* actionChannel(LOCATION_CHANGE, buffer);

  // NOTE: `LOCATION_CHANGE` initialized browser location
  // NOTE: we need to put minimal delay after that
  yield* call({
    fn: Promise.resolve,
    context: Promise,
  }, null);

  try {
    // initialize required data
    yield* call(initBasket);
    yield* call(initAffiliate);
    yield* call(initCurrency);
    yield* fork(initOneTrust);
    yield* fork(initFuseDeck);
  } catch (error) {
    yield* call(history.close);
    yield* put(ROUTE_DESTROY.trigger(NativeError.wrap(error)));
    yield* call(sentryCatch, error);
    return;
  }

  // handle global navigation
  yield* takeLatest(LOCATION_CHANGE, locationChangeSaga);
  yield* takeLatest(ROUTE_CURRENCY.is, currencyChangeSaga);
  yield* takeLatest(ROUTE_CHANGE.isRequest, routeChangeSaga);

  // delayed own routing initialization
  const navigate = yield* take(history);
  yield* put(navigate);
  yield* call(history.close);
}

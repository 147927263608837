import { RuntimeError } from 'src/errors/RuntimeError';
import { Location } from 'src/routing/types/Location';

export class RouteNotFoundError extends RuntimeError {
  public constructor(
    public readonly location: Location,
  ) {
    super(`Route "${location.pathname}" not found`, { location });
  }

  public get name(): string {
    return 'RouteNotFoundError';
  }
}

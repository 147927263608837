import './HomepageBanner.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { HOMEPAGE_BANNER } from 'src/constants/images';

export function HomepageBanner(): React.ReactElement {
  return (
    <FormattedMessage id="Homepage.Banner.Url">
      {(bannerUrl) => (
        <a href={bannerUrl.toString()} className="sts-ui-homepage-banner">
          <div className="sts-ui-homepage-banner__data">
            <h2 className="sts-ui-homepage-banner__title">
              <FormattedHTMLMessage id="Homepage.Banner.Title"/>
            </h2>
            <p className="sts-ui-homepage-banner__text">
              <FormattedHTMLMessage id="Homepage.Banner.Subtitle"/>
            </p>
            <div className="sts-ui-homepage-banner__buy">
              <FormattedHTMLMessage id="Homepage.Banner.Button.Text"/>
            </div>
          </div>
          <div className="sts-ui-homepage-banner__cover1"/>
          <div className="sts-ui-homepage-banner__cover2"/>
          <div className="sts-ui-homepage-banner__image">
            <img {...HOMEPAGE_BANNER} alt="STC Banner"/>
          </div>
        </a>
      )}
    </FormattedMessage>
  );
}

import { SagaIterator } from 'redux-saga';
import { PRODUCT_CHANGE, PRODUCT_FETCH, PRODUCT_SUBMIT } from 'src/actions/ProductActions';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { productPageSaga } from 'src/sagas/product/productPageSaga';
import { cancel, take, takeLatest } from 'typed-redux-saga';

export function* productSaga(): SagaIterator<void> {
  while (true) {
    yield* take(PRODUCT_FETCH.is);

    const task = yield* takeLatest([
      PRODUCT_CHANGE.isRequest,
      PRODUCT_SUBMIT.isRequest,
    ], productPageSaga);

    // freeze the page state when user triggers navigation
    yield* take(ROUTE_CHANGE.isPending);
    yield* cancel(task);
  }
}

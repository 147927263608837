import React from 'react';
import { useSelector } from 'react-redux';
import { getRoutingState } from 'src/routing/selectors/getRoutingState';
import { RouterError } from 'src/routing/views/RouterError';
import { RouterLoader } from 'src/routing/views/RouterLoader';

type Props = {
  readonly children: React.ReactElement;
};

export function Initialization({ children }: Props): React.ReactElement {
  const routing = useSelector(getRoutingState);

  if (routing.transitionError) {
    return <RouterError error={routing.transitionError}/>;
  }
  if (routing.currentState) {
    return children;
  }

  return <RouterLoader/>;
}

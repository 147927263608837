import {
  BASKET_EMPTY,
  BASKET_FETCH,
  BASKET_INIT_STORAGE,
  BASKET_REMOVE,
  BASKET_RESET,
  BASKET_SET_CURRENCY,
  BASKET_SET_PRODUCT,
  BASKET_UPGRADE,
} from 'src/actions/BasketActions';
import { BasketState } from 'src/types/BasketState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: BasketState = {
  currency: null,
  products: {},
  details: null,
};

export const basketReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (BASKET_INIT_STORAGE.is(action)) {
    return { ...state, currency: action.data.currency, products: action.data.products };
  }
  if (BASKET_SET_CURRENCY.is(action)) {
    return { ...state, currency: action.data };
  }

  if (BASKET_SET_PRODUCT.is(action)) {
    const prevProduct = state.products[action.meta];
    const nextPosition = prevProduct
      ? prevProduct.position
      : Object.values(state.products).reduce((max, it) => Math.max(max, it.position), 0) + 1;

    return {
      ...state,
      products: {
        ...state.products,
        [action.meta]: {
          formData: action.data,
          position: nextPosition,
        },
      },
    };
  }
  if (BASKET_EMPTY.is(action)) {
    return {
      ...state,
      products: {},
      details: null,
    };
  }

  if (BASKET_REMOVE.isPending(action)) {
    return {
      ...state,
      details: state.details
        ? { ...state.details, removing: action.meta, upgrading: null }
        : state.details,
    };
  }
  if (BASKET_REMOVE.isSuccess(action)) {
    const details = action.data;

    return {
      ...state,
      details: details,
      products: Object.fromEntries(
        details.products.map((product) => [product.code, {
          position: product.position,
          formData: product.form,
        }]),
      ),
    };
  }
  if (BASKET_REMOVE.isFailure(action)) {
    return {
      ...state,
      details: state.details
        ? { ...state.details, removing: null, upgrading: null }
        : state.details,
    };
  }

  if (BASKET_UPGRADE.isPending(action)) {
    return {
      ...state,
      details: state.details
        ? { ...state.details, upgrading: action.meta, removing: null }
        : state.details,
    };
  }
  if (BASKET_UPGRADE.isSuccess(action)) {
    const details = action.data;

    return {
      ...state,
      details: details,
      products: Object.fromEntries(
        details.products.map((product) => [product.code, {
          position: product.position,
          formData: product.form,
        }]),
      ),
    };
  }
  if (BASKET_UPGRADE.isFailure(action)) {
    return {
      ...state,
      details: state.details
        ? { ...state.details, removing: null, upgrading: null }
        : state.details,
    };
  }

  if (BASKET_FETCH.is(action)) {
    return { ...state, details: action.data };
  }
  if (BASKET_RESET.is(action)) {
    return { ...state, details: null };
  }

  return state;
});

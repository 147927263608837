import { createSelector } from 'reselect';
import { getBasketData } from 'src/selectors/getBasketData';
import { getProductTypes } from 'src/selectors/getProductTypes';
import { ProductCode } from 'src/types/ProductCode';

export const getBasketShiftedProducts = createSelector([
  getBasketData,
  getProductTypes,
], (data, productTypes): ReadonlySet<ProductCode> => {
  const result = new Set<ProductCode>();

  for (const product of data.products) {
    const shiftTickets = data.minimalStartDate[product.code]?.shiftTickets;
    if (shiftTickets == null) {
      continue;
    }

    for (const { code } of productTypes) {
      if (shiftTickets[code]) {
        result.add(code);
      }
    }
  }

  return result;
});

import './PageContainer.scss';
import React from 'react';

type Props = {
  readonly children: React.ReactNode;
};

export function PageContainer({ children }: Props): React.ReactElement {
  return (
    <div className="sts-ui-page-container">
      {children}
    </div>
  );
}

import { SagaIterator } from 'redux-saga';
import { RouteHook } from 'src/routing/types/RouteHook';
import { RouteMatch } from 'src/routing/types/RouteMatch';
import { Transition } from 'src/routing/types/Transition';
import { logDebug } from 'src/sagas/utils/logging';
import { call } from 'typed-redux-saga';

export function* executeRouteHook(hook: RouteHook, transition: Transition, match: RouteMatch): SagaIterator<void> {
  yield* call(logDebug, `ROUTER. Executing hook "${hook.name}" on state "${match.state.name}"…`);
  yield* call(hook.saga, transition, match);
}

import { REGIONAL_PASSES_FETCH, REGIONAL_PASSES_RESET } from 'src/actions/RegionalPassesActions';
import { RegionalPassesState } from 'src/types/RegionalPassesState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: RegionalPassesState = {
  data: null,
};

export const regionalPassesReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (REGIONAL_PASSES_FETCH.is(action)) {
    return { ...state, data: action.data };
  }
  if (REGIONAL_PASSES_RESET.is(action)) {
    return { ...state, data: null };
  }

  return state;
});

import './CheckoutPaymentTicketTraveler.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckoutCountryName } from 'src/components/checkout/CheckoutCountryName';
import { FormattedDate } from 'src/components/common/FormattedDate';
import { ProductName } from 'src/components/common/ProductName';
import { CheckoutTravelerFormData } from 'src/types/CheckoutFormData';
import { TicketAge } from 'src/types/TicketAge';

type Props = {
  readonly traveler: CheckoutTravelerFormData;
};

export function CheckoutPaymentTicketTraveler({ traveler }: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-payment-ticket-traveler">
      <div className="sts-ui-checkout-payment-ticket-traveler__title">
        <strong>
          <ProductName type={traveler.product}/>{', '}
          {TARIFF_NAMES[traveler.tariff]}
        </strong>
      </div>

      <div className="sts-ui-checkout-payment-ticket-traveler__details">
        <div className="sts-ui-checkout-payment-ticket-traveler__details-title">
          <FormattedMessage id="Form.Checkout.BillingAddress.FirstName"/>
        </div>
        <div className="sts-ui-checkout-payment-ticket-traveler__details-value">
          {traveler.firstName}
        </div>
      </div>

      <div className="sts-ui-checkout-payment-ticket-traveler__details">
        <div className="sts-ui-checkout-payment-ticket-traveler__details-title">
          <FormattedMessage id="Form.Checkout.BillingAddress.LastName"/>
        </div>
        <div className="sts-ui-checkout-payment-ticket-traveler__details-value">
          {traveler.lastName}
        </div>
      </div>

      {traveler.homeCountryCode !== null && (
        <div className="sts-ui-checkout-payment-ticket-traveler__details">
          <div className="sts-ui-checkout-payment-ticket-traveler__details-title">
            <FormattedMessage id="Form.Checkout.BillingAddress.CountryOfResidence"/>
          </div>
          <div className="sts-ui-checkout-payment-ticket-traveler__details-value">
            <CheckoutCountryName code={traveler.homeCountryCode}/>
          </div>
        </div>
      )}
      {traveler.homeFullCountryCode !== null && (
        <div className="sts-ui-checkout-payment-ticket-traveler__details">
          <div className="sts-ui-checkout-payment-ticket-traveler__details-title">
            <FormattedMessage id="Form.Checkout.BillingAddress.CountryOfResidence"/>
          </div>
          <div className="sts-ui-checkout-payment-ticket-traveler__details-value">
            <CheckoutCountryName code={traveler.homeFullCountryCode}/>
          </div>
        </div>
      )}

      {traveler.birthDate !== null && (
        <div className="sts-ui-checkout-payment-ticket-traveler__details">
          <div className="sts-ui-checkout-payment-ticket-traveler__details-title">
            <FormattedMessage id="Form.Checkout.BillingAddress.DateOfBirth"/>
          </div>
          <div className="sts-ui-checkout-payment-ticket-traveler__details-value">
            <FormattedDate value={traveler.birthDate}/>
          </div>
        </div>
      )}
    </div>
  );
}

const TARIFF_NAMES = {
  [TicketAge.ADULT]: <FormattedMessage id="Form.Checkout.Payment.TariffAdult"/>,
  [TicketAge.YOUTH]: <FormattedMessage id="Form.Checkout.Payment.TariffYouth"/>,
  [TicketAge.CHILD]: <FormattedMessage id="Form.Checkout.Payment.TariffChild"/>,
} as const;

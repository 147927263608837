import './CheckoutPersonalScreen.scss';
import { FormController } from '@form-ts/core';
import { useForm } from '@form-ts/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CheckoutNavigation } from 'src/components/checkout/CheckoutNavigation';
import { CheckoutPersonalFormView } from 'src/components/checkout/CheckoutPersonalFormView';
import { Typography } from 'src/components/common/Typography';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { useFormResolver } from 'src/forms/hooks/useFormResolver';
import { useFormValidator } from 'src/forms/hooks/useFormValidator';
import { FormError } from 'src/forms/types/FormError';
import { FormRule } from 'src/forms/types/FormRule';
import { validate } from 'src/forms/utils/validate';
import { CheckoutPersonalFormData } from 'src/types/CheckoutFormData';
import { CheckoutProduct } from 'src/types/CheckoutProduct';
import { CountryCode } from 'src/types/CountryCode';
import { CustomerTitle } from 'src/types/CustomerTitle';
import { ListOption } from 'src/types/ListOption';
import { syncCheckoutTravelerFormData } from 'src/utils/checkout/syncCheckoutTravelerFormData';

type Props = {
  readonly products: ReadonlyArray<CheckoutProduct>;
  readonly loading: boolean;

  readonly formData: CheckoutPersonalFormData;
  readonly formRule: FormRule<CheckoutPersonalFormData>;

  readonly onChange: (form: FormController<CheckoutPersonalFormData, FormError>) => void;
  readonly onSubmit: (form: FormController<CheckoutPersonalFormData, FormError>) => void;

  readonly customerTitles: ReadonlyArray<ListOption<CustomerTitle>>;
  readonly customerCountries: ReadonlyArray<ListOption<CountryCode>>;
  readonly travelerCountries: ReadonlyArray<ListOption<CountryCode>>;
};

export function CheckoutPersonalScreen({
  products,
  loading,
  formData,
  formRule,
  onChange,
  onSubmit,
  customerTitles,
  customerCountries,
  travelerCountries,
}: Props): React.ReactElement {
  const initialErrors = useMemo(() => validate(formData, formRule), [formData, formRule]);
  const form = useForm('checkout.personal', {
    reinitialize: false,
    initialValues: formData,
    initialErrors: initialErrors,
  });

  // FIXME: use field-based validation
  useFormResolver(form, syncCheckoutTravelerFormData);
  useFormValidator(form, formRule);

  useEffect(() => form.subscribe((prevState) => {
    const nextState = form.currentState;
    if (prevState.values !== nextState.values) {
      onChange(form);
    }
  }), [form, onChange]);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(form);
  }, [form, onSubmit]);

  return (
    <div className="sts-ui-checkout-personal-screen">
      <div className="sts-ui-checkout-personal-screen__navigation">
        <CheckoutNavigation
          current="personal"
        />
      </div>

      <div className="sts-ui-checkout-payment-screen__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="Form.Checkout.BillingAddress.PersonalData"/>
        </Typography>
      </div>

      <form className="sts-ui-checkout-personal-screen__content" onSubmit={handleSubmit}>
        <div className="sts-ui-checkout-personal-screen__form">
          <div className="sts-ui-checkout-personal-screen__block-title">
            <Typography variant="block-title" as="h5">
              <FormattedHTMLMessage id="Form.Checkout.BillingAddress.LeadTravelerInformation"/>
            </Typography>
          </div>

          <div className="sts-ui-checkout-personal-screen__form-content">
            <CheckoutPersonalFormView
              form={form}
              products={products}
              customerTitles={customerTitles}
              customerCountries={customerCountries}
              travelerCountries={travelerCountries}
            />
          </div>

        </div>
        <div className="sts-ui-checkout-personal-screen__actions">
          <button
            type="submit"
            className="sts-ui-primary-button"
            disabled={loading}
          >
            <FormattedMessage id="Form.Checkout.BillingAddress.Continue"/>
            {loading ? <LoadingIcon/> : <ArrowRightIcon/>}
          </button>
        </div>
      </form>
    </div>
  );
}

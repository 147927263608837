import * as C from 'io-ts/Codec';
import { Language } from 'src/types/Language';

export const LANGUAGE: C.Codec<unknown, Language, Language> = C.literal(
  'en',
  'de',
  'it',
  'es',
  'fr',
  'nl',
  'ja',
  'ko',
  'zh-Hans',
  'zh-Hant',
);

import { SagaIterator } from 'redux-saga';
import { ROUTING_PRE_HOOKS, ROUTING_POST_HOOKS } from 'src/routing/constants/RoutingHooks';
import { Transition } from 'src/routing/types/Transition';
import { combineHooks } from 'src/routing/utils/combineHooks';
import { getStatesToEnter, getStatesToLeave, getStatesToRetain } from 'src/routing/utils/transitionUtils';
import { isDefined } from 'src/utils/guard';
import { call } from 'typed-redux-saga';

export function* performPreTransition(transition: Transition): SagaIterator<void> {
  for (const preHook of ROUTING_PRE_HOOKS) {
    yield* call(preHook, transition);
  }

  const statesToLeave = yield* call(
    getStatesToLeave,
    transition.to.match,
    transition.from?.match ?? null,
  );
  const statesToEnter = yield* call(
    getStatesToEnter,
    transition.to.match,
    transition.from?.match ?? null,
  );
  const statesToRetain = yield* call(
    getStatesToRetain,
    transition.to.match,
    transition.from?.match ?? null,
  );

  const hooks = yield* call(combineHooks, [
    ...statesToLeave
      .map((match) => match.state.hooks.onLeaving?.map((hook) => ({ hook, match })))
      .filter(isDefined)
      .flat(1),
    ...statesToEnter
      .map((match) => match.state.hooks.onEntering?.map((hook) => ({ hook, match })))
      .filter(isDefined)
      .flat(1),
    ...statesToRetain
      .map((match) => match.state.hooks.onRetaining?.map((hook) => ({ hook, match })))
      .filter(isDefined)
      .flat(1),
  ], transition);
  for (const effect of hooks) {
    yield effect;
  }
}

export function* performPostTransition(transition: Transition): SagaIterator<void> {
  const statesToLeave = yield* call(
    getStatesToLeave,
    transition.to.match,
    transition.from?.match ?? null,
  );
  const statesToEnter = yield* call(
    getStatesToEnter,
    transition.to.match,
    transition.from?.match ?? null,
  );
  const statesToRetain = yield* call(
    getStatesToRetain,
    transition.to.match,
    transition.from?.match ?? null,
  );

  const hooks = yield* call(combineHooks, [
    ...statesToLeave
      .map((match) => match.state.hooks.onLeft?.map((hook) => ({ hook, match })))
      .filter(isDefined)
      .flat(1),
    ...statesToEnter
      .map((match) => match.state.hooks.onEntered?.map((hook) => ({ hook, match })))
      .filter(isDefined)
      .flat(1),
    ...statesToRetain
      .map((match) => match.state.hooks.onRetained?.map((hook) => ({ hook, match })))
      .filter(isDefined)
      .flat(1),
  ], transition);
  for (const effect of hooks) {
    yield effect;
  }

  for (const postHook of ROUTING_POST_HOOKS) {
    yield* call(postHook, transition);
  }
}

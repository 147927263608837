import { SagaIterator } from 'redux-saga';
import { validate } from 'src/forms/utils/validate';
import { CheckoutData } from 'src/types/CheckoutData';
import { createCheckoutPersonalFormRule } from 'src/utils/checkout/createCheckoutPersonalFormRule';
import { call } from 'typed-redux-saga';

export function* checkCheckoutPersonalData(
  checkoutData: CheckoutData,
): SagaIterator<boolean> {
  const formRule = yield* call(
    createCheckoutPersonalFormRule,
    checkoutData.products,
    checkoutData.titles,
    checkoutData.countries,
    checkoutData.travelerCountries,
    checkoutData.travelerFields,
  );
  const formErrors = yield* call(
    validate,
    checkoutData.formData.personal,
    formRule,
  );

  return Object.keys(formErrors).length === 0;
}

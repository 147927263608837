import React from 'react';
import { useSelector } from 'react-redux';
import { RegionalPassesScreen } from 'src/components/regionalPasses/RegionalPassesScreen';
import { getRegionalPassesData } from 'src/selectors/getRegionalPassesData';

export function RegionalPassesPage(): React.ReactElement {
  const data = useSelector(getRegionalPassesData);

  return (
    <RegionalPassesScreen products={data}/>
  );
}

import { LOCALIZATION_FETCH } from 'src/actions/LocalizationActions';
import { LocalizationState } from 'src/types/LocalizationState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: LocalizationState = {};

export const localizationReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (LOCALIZATION_FETCH.is(action)) {
    return { ...state, [action.meta]: action.data };
  }

  return state;
});

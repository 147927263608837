import { SEARCH_CONDITIONS, SEARCH_PRODUCTS } from 'src/constants/search';
import { SearchCondition } from 'src/types/SearchCondition';
import { SearchProduct } from 'src/types/SearchProduct';
import { SearchQuery } from 'src/types/SearchQuery';

export function searchMostSuitableProduct(query: SearchQuery): SearchProduct | null {
  let found = null;
  let weight = Number.POSITIVE_INFINITY;

  for (const product of SEARCH_PRODUCTS) {
    for (const condition of SEARCH_CONDITIONS[product]) {
      if (isConditionMet(query, condition)) {
        if (found === null || weight > condition.weight) {
          found = product;
          weight = condition.weight;
        }
      }
    }
  }

  return found;
}

function isConditionMet(query: SearchQuery, condition: SearchCondition): boolean {
  if (condition.period !== query.period) {
    return false;
  }
  if (condition.frequency !== query.frequency) {
    return false;
  }
  if (condition.excursion !== null && condition.excursion !== query.excursion) {
    return false;
  }

  return true;
}

import React from 'react';
import { Link } from 'react-router-dom';
import { LogicError } from 'src/errors/LogicError';
import { getRouteDescriptor } from 'src/routing/utils/getRouteDescriptor';

export function htmlToReact(html: string): React.ReactNode {
  const BASE = window.document.querySelector('base')?.href ?? '';
  const LANG = window.document.querySelector('html')?.lang ?? '';
  const HTML = [
    `<html lang="${escapeHtml(LANG)}">`,
    `<head><base href="${escapeHtml(BASE)}"/></head>`,
    `<body>${html}</body>`,
    '</html>',
  ].join('');

  const document = new DOMParser().parseFromString(HTML, 'text/html');
  return parseChildren(document.body);
}

function processNode(node: Node, key: number): React.ReactNode {
  if (node instanceof Text) {
    return processTextNode(node);
  } else if (node instanceof HTMLAnchorElement) {
    return processLinkElement(node, key);
  } else if (node instanceof HTMLImageElement) {
    return processImageElement(node, key);
  } else if (node instanceof HTMLElement) {
    return processGenericElement(node, key);
  } else {
    return null;
  }
}

function processLinkElement(node: HTMLAnchorElement, key: number): React.ReactElement {
  const isRelative = node.origin === window.origin;
  if (isRelative && isValidRoute(node.pathname)) {
    return (
      <Link
        key={key}
        to={{ pathname: node.pathname, search: node.search, hash: node.hash }}
        className={node.className}
        style={parseStyle(node)}
      >
        {parseChildren(node)}
      </Link>
    );
  } else {
    return (
      <a
        key={key}
        href={node.href}
        hrefLang={node.hreflang || undefined}
        target={node.target || undefined}
        rel={node.rel || undefined}
        className={node.className || undefined}
        style={parseStyle(node)}
      >
        {parseChildren(node)}
      </a>
    );
  }
}

function processImageElement(node: HTMLImageElement, key: number): React.ReactElement {
  return (
    <img
      key={key}
      src={node.src}
      srcSet={node.srcset || undefined}
      sizes={node.sizes || undefined}
      alt={node.alt}
      className={node.className}
      style={parseStyle(node)}
    />
  );
}

function processGenericElement(node: HTMLElement, key: number): React.ReactNode {
  const element = node.nodeName.toLowerCase();
  if (EMPTY_ELEMENTS.has(element)) {
    return React.createElement(element, {
      key: key,
      className: node.className,
      style: parseStyle(node),
    });
  }
  if (BASIC_ELEMENTS.has(element)) {
    return React.createElement(element, {
      key: key,
      className: node.className,
      style: parseStyle(node),
    }, parseChildren(node));
  }

  throw new LogicError(`Unsupported HTML tag "${node.nodeName}"`, { node });
}

function processTextNode(node: Text): string {
  return node.textContent ?? '';
}

function parseChildren(node: HTMLElement): React.ReactNode {
  if (node.childNodes.length === 0) {
    return undefined;
  }

  const childNodes: React.ReactNode[] = [];

  for (let i = 0; i < node.childNodes.length; ++i) {
    const child = node.childNodes.item(i);
    childNodes.push(processNode(child, i));
  }

  return childNodes;
}

function parseStyle(node: HTMLElement): Record<string, string> | undefined {
  const styleList = node.getAttribute('style')?.trim();
  if (!styleList) {
    return undefined;
  }

  const result: Record<string, string> = {};
  for (const styleRule of styleList.split(';')) {
    const [styleProp, styleValue = ''] = styleRule.trim().split(':', 2);

    const htmlStyleProp = styleProp.trim().toLowerCase();
    if (!VALID_STYLE_PROPS.has(htmlStyleProp)) {
      throw new LogicError(`Unsupported CSS style property "${htmlStyleProp}"`, { styleList });
    }

    const reactStyleProp = htmlStyleProp
      .split('-')
      .map((chunk, index) => (index === 0 ? chunk : chunk[0].toUpperCase() + chunk.slice(1)))
      .join('');

    result[reactStyleProp] = styleValue.trim();
  }
  return result;
}

function escapeHtml(raw: string): string {
  return raw.replace(/["'&<>]/g, escapeHtmlChar);
}

function escapeHtmlChar(char: string): string {
  switch (char) {
  case '"':
    return '&quot;';

  case '&':
    return '&amp;';

  case '\'':
    return '&#39;';

  case '<':
    return '&lt;';

  case '>':
    return '&gt;';

  default:
    return char;
  }
}

function isValidRoute(pathname: string): boolean {
  return pathname === '/' || getRouteDescriptor(pathname) !== null;
}

const EMPTY_ELEMENTS: ReadonlySet<string> = new Set([
  'br',
  'hr',
]);

const BASIC_ELEMENTS: ReadonlySet<string> = new Set([
  'dl',
  'dt',
  'dd',

  'ol',
  'ul',
  'li',

  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',

  'div',
  'span',

  'abbr',
  'code',
  'cite',
  'blockquote',

  'u',
  'i',
  's',
  'b',

  'em',
  'del',
  'small',
  'strong',

  'sub',
  'sup',

  'table',
  'tbody',
  'thead',
  'tfoot',
  'tr',
  'td',
  'th',
]);

const VALID_STYLE_PROPS = new Set([
  'top',
  'bottom',
  'left',
  'right',

  'float',
  'clear',

  'font-family',
  'font-size',
  'font-style',
  'font-weight',

  'list-style',
  'list-style-type',

  'line-break',
  'line-height',

  'object-fit',
  'object-position',

  'opacity',

  'text-wrap',
  'white-space',
  'white-space-collapse',

  'text-align',
  'vertical-align',

  'text-decoration',
  'text-decoration-color',
  'text-decoration-line',
  'text-decoration-style',
  'text-decoration-thickness',
]);

import './MenuDropdown.scss';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/common/Popover';
import { LANGUAGE_LIST } from 'src/constants/language';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';

export function MenuDropdownLanguage(): React.ReactElement {
  const language = useSelector(getCurrentLanguage);

  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Popover
      open={open}
      onToggle={setOpen}
      modal={true}
      placement="bottom"
    >
      <PopoverTrigger className="sts-ui-menu-dropdown">
        <div className="sts-ui-menu-dropdown__value">
          {LANGUAGE_LIST.find((it) => it.value === language)?.title}
        </div>
        <div className="sts-ui-menu-dropdown__caret"/>
      </PopoverTrigger>
      <PopoverContent className="sts-ui-menu-dropdown__menu">
        <ul>
          {LANGUAGE_LIST.map((item) => (
            <li key={item.value}>
              <RouteLink
                locale={item.value}
                className="sts-ui-menu-dropdown__item"
                onClick={handleClick}
              >
                {item.title}
              </RouteLink>
            </li>
          ))}
        </ul>
      </PopoverContent>
    </Popover>
  );
}

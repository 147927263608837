import { captureMessage, withScope, SeverityLevel } from '@sentry/browser';
import { SENTRY_LOG } from 'src/constants/application';

type SentryLogParams = {
  readonly level: SeverityLevel;
  readonly fingerprint: ReadonlyArray<string>;
  readonly context?: Readonly<Record<string, unknown>>;
};

export function sentryLog(message: string, params: SentryLogParams): void {
  if (!SENTRY_LOG) {
    return;
  }

  withScope((scope) => {
    scope.setLevel(params.level);
    scope.setExtra('message_context', params.context);
    scope.setFingerprint(params.fingerprint.slice());

    captureMessage(message);
  });
}

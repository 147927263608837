import { BasketProduct } from 'src/types/BasketProduct';
import { DiscountCode } from 'src/types/DiscountCode';
import { Integer } from 'src/types/Integer';
import { ProductFormData } from 'src/types/ProductFormData';
import { ProductMap } from 'src/types/ProductMap';
import { TicketAge } from 'src/types/TicketAge';
import { TicketClass } from 'src/types/TicketClass';
import { TicketDuration } from 'src/types/TicketDuration';
import { TicketFields } from 'src/types/TicketFields';
import { TicketType } from 'src/types/TicketType';
import { TicketValidity } from 'src/types/TicketValidity';

export function createProductFormData(
  productFields: TicketFields,
  productValidity: TicketValidity,
  basketProducts: ProductMap<BasketProduct>,
): ProductFormData {
  return {
    startDate: null,
    returnDate: null,

    class: getDefaultClass(),
    duration: getDefaultDuration(),

    adult: getTicketCount(TicketAge.ADULT, 1),
    youth: getTicketCount(TicketAge.YOUTH, 0),
    child: getTicketCount(TicketAge.CHILD, 0),

    cardType: getDefaultCardType(),
    familyCard: false,
    agreement: false,
  };

  function getTicketCount(age: TicketAge, min: Integer): Integer | null {
    return productFields.allowAges[age]
      ? Math.max(min, productFields.countPassengers.min[age] ?? 0)
      : null;
  }

  function getDefaultClass(): TicketClass | null {
    if (!productFields.displayFields.class) {
      return null;
    }

    return TicketClass.SECOND;
  }

  function getDefaultDuration(): TicketDuration | null {
    if (!productFields.displayFields.duration) {
      return null;
    }
    if (productValidity.duration === null || productValidity.duration.length === 0) {
      return null;
    }

    return productValidity.duration[0].value;
  }

  function getDefaultCardType(): DiscountCode | null {
    if (!productFields.displayFields.cardType) {
      return null;
    }

    const cardTypes = productValidity.cardType ?? productFields.displayFields.cardType;

    const isSwissPassDiscountAvailable = cardTypes.includes(TicketType.SWISS_TRAVEL_PASS) && (
      // FIXME: unsupported product types
      // basketProducts[TicketType.SWISS_TRAVEL_PASS_PROMO] != null ||
      // basketProducts[TicketType.SWISS_TRAVEL_PASS_FLEX_PROMO] != null ||
      basketProducts[TicketType.SWISS_TRAVEL_PASS] != null ||
      basketProducts[TicketType.SWISS_TRAVEL_PASS_FLEX] != null
    );
    const isHalfFareCardDiscountAvailable = cardTypes.includes(TicketType.SWISS_HALF_FARE_CARD) && (
      basketProducts[TicketType.SWISS_HALF_FARE_CARD] != null
    );

    if (isSwissPassDiscountAvailable && !isHalfFareCardDiscountAvailable) {
      return TicketType.SWISS_TRAVEL_PASS;
    }
    if (isHalfFareCardDiscountAvailable && !isSwissPassDiscountAvailable) {
      return TicketType.SWISS_HALF_FARE_CARD;
    }

    return null;
  }
}

import './CheckoutPersonalBillingFormView.scss';
import { FormController } from '@form-ts/core';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckoutFormLabel } from 'src/components/checkout/CheckoutFormLabel';
import { Col, Row } from 'src/components/common/Grid';
import { SelectField } from 'src/forms/fields/SelectField';
import { TextboxField } from 'src/forms/fields/TextboxField';
import { FormError } from 'src/forms/types/FormError';
import { CheckoutPersonalFormData } from 'src/types/CheckoutFormData';
import { CountryCode } from 'src/types/CountryCode';
import { CustomerTitle } from 'src/types/CustomerTitle';
import { ListOption } from 'src/types/ListOption';
import { formatCheckoutTravelerNameError } from 'src/utils/checkout/formatCheckoutError';

type Props = {
  readonly form: FormController<CheckoutPersonalFormData, FormError>;
  readonly customerTitles: ReadonlyArray<ListOption<CustomerTitle>>;
  readonly customerCountries: ReadonlyArray<ListOption<CountryCode>>;
};

export function CheckoutPersonalBillingFormView({
  form,
  customerTitles,
  customerCountries,
}: Props): React.ReactElement {
  const intl = useIntl();
  const formatNameError = useCallback((error: FormError) => formatCheckoutTravelerNameError(intl, error), [intl]);

  return (
    <div className="sts-ui-checkout-personal-billing-form-view">
      <div className="sts-ui-checkout-personal-billing-form-view__field">
        <Row>
          <Col xs={12} sm={2} md={3} lg={3}>
            <div className="sts-ui-checkout-personal-billing-form-view__label">
              <CheckoutFormLabel
                field={form.field.at('billing').at('title')}
                required={true}
              >
                <FormattedMessage id="Form.Checkout.BillingAddress.Title"/>
              </CheckoutFormLabel>
            </div>
          </Col>
          <Col xs={12} sm={10} md={2} lg={2}>
            <SelectField
              field={form.field.at('billing').at('title')}
              options={customerTitles}
            />
          </Col>
        </Row>
      </div>

      <div className="sts-ui-checkout-personal-billing-form-view__field">
        <Row>
          <Col xs={12} sm={2} md={3} lg={3}>
            <div className="sts-ui-checkout-personal-billing-form-view__label">
              <CheckoutFormLabel
                field={form.field.at('billing').at('firstName')}
                required={true}
              >
                <FormattedMessage id="Form.Checkout.BillingAddress.FirstName"/>
              </CheckoutFormLabel>
            </div>
          </Col>
          <Col xs={12} sm={10} md={5} lg={5}>
            <TextboxField
              type="text"
              field={form.field.at('billing').at('firstName')}
              formatError={formatNameError}
            />
          </Col>
        </Row>
      </div>

      <div className="sts-ui-checkout-personal-billing-form-view__field">
        <Row>
          <Col xs={12} sm={2} md={3} lg={3}>
            <div className="sts-ui-checkout-personal-billing-form-view__label">
              <CheckoutFormLabel
                field={form.field.at('billing').at('lastName')}
                required={true}
              >
                <FormattedMessage id="Form.Checkout.BillingAddress.LastName"/>
              </CheckoutFormLabel>
            </div>
          </Col>
          <Col xs={12} sm={10} md={5} lg={5}>
            <TextboxField
              type="text"
              field={form.field.at('billing').at('lastName')}
              formatError={formatNameError}
            />
          </Col>
        </Row>
      </div>

      <div className="sts-ui-checkout-personal-billing-form-view__field">
        <Row>
          <Col xs={12} sm={2} md={3} lg={3}>
            <div className="sts-ui-checkout-personal-billing-form-view__label">
              <CheckoutFormLabel
                field={form.field.at('billing').at('country')}
                required={true}
              >
                <FormattedMessage id="Form.Checkout.BillingAddress.Country"/>
              </CheckoutFormLabel>
            </div>
          </Col>
          <Col xs={12} sm={10} md={5} lg={5}>
            <SelectField
              field={form.field.at('billing').at('country')}
              options={customerCountries}
            />
          </Col>
        </Row>
      </div>

      <div className="sts-ui-checkout-personal-billing-form-view__field">
        <Row>
          <Col xs={12} sm={2} md={3} lg={3}>
            {null}
          </Col>
          <Col xs={12} sm={10} md={5} lg={5}>
            <div className="sts-ui-checkout-personal-billing-form-view__hint">
              <FormattedMessage id="Form.Checkout.DeliveryAddress.EmailAndTheTicketsWillBeSent"/>
            </div>
          </Col>
        </Row>
      </div>

      <div className="sts-ui-checkout-personal-billing-form-view__field">
        <Row>
          <Col xs={12} sm={2} md={3} lg={3}>
            <div className="sts-ui-checkout-personal-billing-form-view__label">
              <CheckoutFormLabel
                field={form.field.at('billing').at('email')}
                required={true}
              >
                <FormattedMessage id="Form.Checkout.BillingAddress.Email"/>
              </CheckoutFormLabel>
            </div>
          </Col>
          <Col xs={12} sm={10} md={5} lg={5}>
            <TextboxField
              field={form.field.at('billing').at('email')}
              type="text"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

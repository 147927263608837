import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchProductFields } from 'src/sagas/product/utils/fetchProductFields';
import { fetchProductPricePeriods } from 'src/sagas/product/utils/fetchProductPricePeriods';
import { fetchProductPrices } from 'src/sagas/product/utils/fetchProductPrices';
import { fetchProductValidity } from 'src/sagas/product/utils/fetchProductValidity';
import { BasketProduct } from 'src/types/BasketProduct';
import { BasketProductDetails } from 'src/types/BasketProductDetails';
import { Currency } from 'src/types/Currency';
import { Integer } from 'src/types/Integer';
import { Language } from 'src/types/Language';
import { ProductCode } from 'src/types/ProductCode';
import { ProductFormData } from 'src/types/ProductFormData';
import { ProductMap } from 'src/types/ProductMap';
import { countFamilyCards } from 'src/utils/basket/countFamilyCards';
import { showFamilyCardDiscount } from 'src/utils/basket/showFamilyCardDiscount';
import { all, call } from 'typed-redux-saga';

export function* fetchBasketProductDetails(
  currency: Currency,
  language: Language,
  basket: ProductMap<BasketProduct>,
  position: Integer,
  productType: ProductCode,
  productForm: ProductFormData,
): SagaIterator<BasketProductDetails> {
  try {
    const {
      fields,
      validity,
      pricePeriods,
    } = yield* all({
      fields: callEffect(
        fetchProductFields,
        productType,
      ),
      validity: callEffect(
        fetchProductValidity,
        productType,
        currency,
        language,
        productForm.startDate,
      ),
      pricePeriods: callEffect(
        fetchProductPricePeriods,
        productType,
        currency,
        basket,
      ),
    });

    const prices = yield* call(
      fetchProductPrices,
      productType,
      productForm,
      fields,
      pricePeriods,
      validity,
      currency,
    );

    return {
      code: productType,
      form: productForm,
      position: position,

      fields: fields,
      pricePeriods: pricePeriods,

      prices: prices,
      validity: validity,

      familyCardCount: yield* call(countFamilyCards, basket),
      familyCardDiscount: yield* call(showFamilyCardDiscount, productType, productForm),
    } satisfies BasketProductDetails;
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Basket product details',
      { productType, productForm },
      NativeError.wrap(error),
    );
  }
}

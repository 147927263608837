import './ErrorMessage.scss';
import React from 'react';
import { BaseError } from 'src/errors/BaseError';

type Props = {
  readonly error: BaseError;
  readonly errorInfo: React.ErrorInfo | undefined;
};

export function ErrorMessage({ error, errorInfo }: Props): React.ReactElement {
  return (
    <div className="sts-ui-error-message">
      <ErrorBlock
        error={error}
        errorInfo={errorInfo}
      />
    </div>
  );
}

function ErrorBlock({ error, errorInfo } : Props): React.ReactElement {
  return (
    <div className="sts-ui-error-message__block">
      <div className="sts-ui-error-message__title">
        <b>{error.name}</b>. {error.message}
      </div>

      <details open={false} className="sts-ui-error-message__details">
        <summary>Error</summary>
        <pre className="sts-ui-error-message__code">
          {JSON.stringify(error, null, 2)}
        </pre>
      </details>

      <details open={false} className="sts-ui-error-message__details">
        <summary>Stack</summary>
        <pre className="sts-ui-error-message__code">
          {error.stack}
        </pre>
      </details>

      {errorInfo && (
        <details open={false} className="sts-ui-error-message__details">
          <summary>Components</summary>
          <pre className="sts-ui-error-message__code">
            {errorInfo.componentStack}
          </pre>
        </details>
      )}

      {error.previous && (
        <div className="sts-ui-error-message__previous">
          <ErrorBlock
            error={error.previous}
            errorInfo={undefined}
          />
        </div>
      )}
    </div>
  );
}

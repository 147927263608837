import { absurd } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

const DECODER: D.Decoder<unknown, never> = {
  decode: (input) => D.failure(input, 'Never'),
};

const ENCODER: E.Encoder<never, never> = {
  encode: absurd,
};

export const NEVER: C.Codec<unknown, never, never> = C.make(DECODER, ENCODER);

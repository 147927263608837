import './PageFooter.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { PageFooterDefault } from 'src/components/layout/PageFooterDefault';
import { PageFooterIframe } from 'src/components/layout/PageFooterIframe';
import { getIsIframeMode } from 'src/selectors/getIsIframeMode';

export function PageFooter(): React.ReactElement {
  const isIframe = useSelector(getIsIframeMode);
  return isIframe ? <PageFooterIframe/> : <PageFooterDefault/>;
}

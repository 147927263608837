import { FormController } from '@form-ts/core';
import { FormError } from 'src/forms/types/FormError';
import { CheckoutData } from 'src/types/CheckoutData';
import { CheckoutPaymentFormData, CheckoutPersonalFormData } from 'src/types/CheckoutFormData';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const CHECKOUT_FETCH = createTrigger<CheckoutData>('CHECKOUT_FETCH');
export const CHECKOUT_RESET = createTrigger<void>('CHECKOUT_RESET');

export const CHECKOUT_PERSONAL_CHANGE =
  createActions<FormController<CheckoutPersonalFormData, FormError>, CheckoutData>('CHECKOUT_PERSONAL_CHANGE');
export const CHECKOUT_PERSONAL_SUBMIT =
  createActions<FormController<CheckoutPersonalFormData, FormError>, CheckoutData>('CHECKOUT_PERSONAL_SUBMIT');

export const CHECKOUT_PAYMENT_CHANGE =
  createActions<FormController<CheckoutPaymentFormData, FormError>, CheckoutData>('CHECKOUT_PAYMENT_CHANGE');
export const CHECKOUT_PAYMENT_SUBMIT =
  createActions<FormController<CheckoutPaymentFormData, FormError>, CheckoutData>('CHECKOUT_PAYMENT_SUBMIT');

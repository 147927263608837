import FACEBOOK from 'src/assets/img/social/facebook.png';
import GOOGLEPLUS from 'src/assets/img/social/googleplus.png';
import INSTAGRAM from 'src/assets/img/social/instagram.png';
import LINKEDIN from 'src/assets/img/social/linkedin.png';
import TWITTER from 'src/assets/img/social/twitter.png';
import { SocialLink } from 'src/types/SocialLink';

export const SOCIAL_LINKS: ReadonlyArray<SocialLink> = [
  {
    img: FACEBOOK,
    url: 'https://www.facebook.com/switzerlandtravelcentre.ch',
  },
  {
    img: TWITTER,
    url: 'https://twitter.com/STCZuerich',
  },
  {
    img: INSTAGRAM,
    url: 'https://www.instagram.com/switzerlandtravelcentre',
  },
  {
    img: GOOGLEPLUS,
    url: 'https://plus.google.com/101665634260350726476',
  },
  {
    img: LINKEDIN,
    url: 'https://www.linkedin.com/company/stc-switzerland-travel-centre-ag',
  },
] as const;

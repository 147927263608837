import * as C from 'io-ts/Codec';
import { TariffMap as Type } from 'src/types/TariffMap';

export function TariffMap<T, O>(codec: C.Codec<unknown, O, T>): C.Codec<unknown, Type<O>, Type<T>> {
  return C.partial({
    adult: codec,
    youth: codec,
    child: codec,
  });
}

import { SagaIterator } from 'redux-saga';
import { PRODUCT_RESET } from 'src/actions/ProductActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { RouteMatch } from 'src/routing/types/RouteMatch';
import { Transition } from 'src/routing/types/Transition';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* resetProduct(transition: Transition): SagaIterator<void> {
  if (isOnSameView(transition.to.match)) {
    // preserve other product that may be loaded
    return;
  }

  try {
    yield* call(logDebug, 'Resetting product…');
    yield* put(PRODUCT_RESET.trigger());
    yield* call(logDebug, 'Resetting product… done');
  } catch (error) {
    yield* call(logError, 'Resetting product… error', error);
    throw new RuntimeError('Could not reset product data', {}, NativeError.wrap(error));
  }
}

function isOnSameView(match: RouteMatch): boolean {
  if (match.state.name.startsWith('sts:ui:product:base:')) {
    return true;
  }

  return match.parent
    ? isOnSameView(match.parent)
    : false;
}

import { pipe } from 'fp-ts/function';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { CURRENCY } from 'src/codecs/Currency';
import { PRODUCT_CODE } from 'src/codecs/ProductCode';
import { PRODUCT_PRICE_REQUEST } from 'src/codecs/ProductPriceRequest';
import { TICKET_PRICE } from 'src/codecs/TicketPrice';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { validate } from 'src/forms/utils/validate';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { ProductFormData } from 'src/types/ProductFormData';
import { TicketFields } from 'src/types/TicketFields';
import { TicketPrice } from 'src/types/TicketPrice';
import { TicketPricePeriod } from 'src/types/TicketPricePeriod';
import { TicketValidity } from 'src/types/TicketValidity';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { createProductFormRule } from 'src/utils/product/createProductFormRule';
import { call } from 'typed-redux-saga';

export function* fetchProductPrices(
  code: ProductCode,
  form: ProductFormData,
  fields: TicketFields,
  pricePeriods: ReadonlyArray<TicketPricePeriod>,
  validity: TicketValidity,
  currency: Currency,
): SagaIterator<TicketPrice[]> {
  try {
    const formRule = yield* call(
      createProductFormRule,
      fields,
      validity,
      pricePeriods,
    );

    const formErrors = yield* call(validate, form, formRule);
    if (Object.keys(formErrors).length > 0) {
      return [];
    }

    const request = yield* call(REQUEST.encode, { currency: currency, type: code, product: form });
    const response = yield* call(makeGetRequest, `${HOST_API}/ticket-price`, request);
    return yield* call(decodeOrThrow, RESPONSE, response.content);
  } catch (error) {
    throw new RuntimeError('Could not fetch product prices', { code, form, currency }, NativeError.wrap(error));
  }
}

const REQUEST = pipe(
  E.struct({
    type: PRODUCT_CODE,
    currency: CURRENCY,
    product: PRODUCT_PRICE_REQUEST,
  }),
  E.compose({
    encode: ({ type, currency, product }) => ({
      type,
      currency,
      ...product,
    }),
  }),
);

const RESPONSE = D.array(TICKET_PRICE);

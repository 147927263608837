import './CheckoutFormLabel.scss';
import { FieldPath } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import classNames from 'classnames';
import React from 'react';
import { Typography } from 'src/components/common/Typography';
import { CheckIcon } from 'src/components/icons/CheckIcon';
import { FormError } from 'src/forms/types/FormError';

type Props<TData, TValue> = {
  readonly field: FieldPath<TData, FormError, TValue>;
  readonly required: boolean;
  readonly children: React.ReactNode;
};

export function CheckoutFormLabel<TData, TValue>({
  field,
  required,
  children,
}: Props<TData, TValue>): React.ReactElement {
  const error = useFormWatch(field.form, field.error.get);
  const touched = useFormWatch(field.form, field.touched.get);
  const submitted = useFormWatch(field.form, field.form.submitted.get);

  const invalid = error !== undefined && (touched || submitted);

  return (
    <div className={classNames({
      'sts-ui-checkout-form-label': true,
      'sts-ui-checkout-form-label--invalid': invalid,
    })}
    >
      <Typography
        as="label"
        variant="form-label"
        htmlFor={`${field.form.name}.${field.path}`}
        className="sts-ui-checkout-form-label__text"
      >
        {children}
      </Typography>

      {required && (
        <span className="sts-ui-checkout-form-label__required">*</span>
      )}
      {!error && (
        <span className="sts-ui-checkout-form-label__valid">
          <div className="sts-ui-checkout-form-label__check">
            <CheckIcon/>
          </div>
        </span>
      )}
    </div>
  );
}

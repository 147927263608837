import React from 'react';
import { useSelector } from 'react-redux';
import { ContactScreen } from 'src/components/contact/ContactScreen';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';

export function ContactPage(): React.ReactElement {
  const language = useSelector(getCurrentLanguage);

  return (
    <ContactScreen language={language}/>
  );
}

import './ProductFormViewValidity.scss';
import { FormController } from '@form-ts/core';
import React, { useMemo } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { FormattedMarkdown } from 'src/components/common/FormattedMarkdown';
import { Col, Row } from 'src/components/common/Grid';
import { InfoModal } from 'src/components/common/InfoModal';
import { Typography } from 'src/components/common/Typography';
import { SwitcherField } from 'src/forms/fields/SwitcherField';
import { FormError } from 'src/forms/types/FormError';
import { ProductCode } from 'src/types/ProductCode';
import { ProductFormData } from 'src/types/ProductFormData';
import { TicketFields } from 'src/types/TicketFields';
import { TicketValidity } from 'src/types/TicketValidity';

type Props = {
  readonly type: ProductCode;
  readonly form: FormController<ProductFormData, FormError>;
  readonly fields: TicketFields;
  readonly validity: TicketValidity;
};

export function ProductFormViewValidity({ type, form, fields, validity }: Props): React.ReactElement | null {
  const classOptions = useMemo(() => {
    return validity.class?.map((opt) => ({
      value: opt.value,
      label: (
        <FormattedHTMLMessage
          id="Form.Product.Class"
          values={{ class: opt.value }}
        />
      ),
    }));
  }, [validity.class]);

  const durationOptions = useMemo(() => {
    return validity.duration?.map((opt) => ({
      value: opt.value,
      label: (
        <FormattedHTMLMessage
          id="Form.Product.Duration"
          values={{ PRODUCT: type, DURATION: opt.duration }}
        />
      ),
    }));
  }, [type, validity.duration]);

  const displayFields = fields.displayFields;
  if (!displayFields.class && !displayFields.duration) {
    return null;
  }

  return (
    <div className="sts-ui-product-form-view-validity">
      <Row>
        {displayFields.class && (
          <Col xs={12} sm={4} md={3} lg="auto" className="sts-ui-product-form-view-validity__group">
            <div className="sts-ui-product-form-view-validity__label">
              <Typography as="label" variant="form-label">
                <FormattedMessage id="Form.Product.ClassLabel"/>
              </Typography>

              <div className="sts-ui-product-form-view-validity__info">
                <InfoModal>
                  <FormattedMarkdown id="products/classSection.md"/>
                </InfoModal>
              </div>
            </div>
            <div className="sts-ui-product-form-view-validity__field">
              <SwitcherField
                field={form.field.at('class')}
                options={classOptions ?? NO_OPTIONS}
              />
            </div>
          </Col>
        )}
        {displayFields.duration && (
          <Col xs={12} sm={12} md={9} lg="auto" className="sts-ui-product-form-view-validity__group">
            <div className="sts-ui-product-form-view-validity__label">
              <Typography as="label" variant="form-label">
                <FormattedMessage id="Form.Product.Validity"/>
              </Typography>
            </div>
            <div className="sts-ui-product-form-view-validity__field">
              <SwitcherField
                field={form.field.at('duration')}
                options={durationOptions ?? NO_OPTIONS}
                className="sts-ui-product-form-view-validity__validity"
              />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}

const NO_OPTIONS = [] as const;

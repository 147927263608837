import { BasketData } from 'src/types/BasketData';
import { BasketStore } from 'src/types/BasketStore';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { ProductFormData } from 'src/types/ProductFormData';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const BASKET_INIT_STORAGE = createTrigger<BasketStore>('BASKET_INIT_STORAGE');
export const BASKET_SET_CURRENCY = createTrigger<Currency>('BASKET_SET_CURRENCY');
export const BASKET_SET_PRODUCT = createTrigger<ProductFormData, ProductCode>('BASKET_SET_PRODUCT');

export const BASKET_FETCH = createTrigger<BasketData>('BASKET_FETCH');
export const BASKET_RESET = createTrigger<void>('BASKET_RESET');

export const BASKET_EMPTY = createTrigger<void>('BASKET_EMPTY');

export const BASKET_REMOVE = createActions<void, BasketData, ProductCode>('BASKET_REMOVE');
export const BASKET_UPGRADE = createActions<void, BasketData, ProductCode>('BASKET_UPGRADE');

export function throttle(
  procedure: () => void,
  delay: number,
): [throttled: () => void, cancel: () => void] {
  let timer = 0;

  return [
    (): void => {
      if (timer !== 0) {
        return;
      }

      timer = window.setTimeout(() => {
        procedure();
        timer = 0;
      }, delay);
    },
    (): void => {
      window.clearTimeout(timer);
    },
  ];
}

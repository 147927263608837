import { createSelector } from 'reselect';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { getLocalizationState } from 'src/selectors/getLocalizationState';
import { Localization } from 'src/types/Localization';
import { assertNotEmpty } from 'src/utils/assert';

export const getLocalizationData = createSelector([
  getLocalizationState,
  getCurrentLanguage,
], (state, language): Localization => assertNotEmpty(
  state[language],
  `Localization "${language}" data is not loaded`,
  { state },
));

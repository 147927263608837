import { push } from 'redux-first-history';
import { SagaIterator } from 'redux-saga';
import { CHECKOUT_PERSONAL_SUBMIT } from 'src/actions/CheckoutActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { formValidate } from 'src/forms/sagas/utils/formValidate';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { getRouteFullPath } from 'src/routing/utils/getRouteFullPath';
import { fetchCheckoutTotalPrice } from 'src/sagas/checkout/utils/fetchCheckoutTotalPrice';
import { saveCheckoutStore } from 'src/sagas/checkout/utils/storeCheckoutData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getBasketProducts } from 'src/selectors/getBasketProducts';
import { getCheckoutData } from 'src/selectors/getCheckoutData';
import { getCheckoutPersonalFormRule } from 'src/selectors/getCheckoutPersonalFormRule';
import { CheckoutData } from 'src/types/CheckoutData';
import { CheckoutFormData } from 'src/types/CheckoutFormData';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* checkoutPersonalSubmitSaga(
  action: GetRequestActionType<typeof CHECKOUT_PERSONAL_SUBMIT>,
): SagaIterator<void> {
  const form = action.data;
  const formData = form.currentState.values;

  const formRule = yield* select(getCheckoutPersonalFormRule);
  const valid = yield* call(formValidate, form, formRule);
  if (!valid) {
    return;
  }

  try {
    yield* call(logDebug, 'Submitting Checkout Personal form…', formData);
    yield* put(CHECKOUT_PERSONAL_SUBMIT.pending());

    yield* delay(1_000);

    const currentCheckout = yield* select(getCheckoutData);
    const basketProducts = yield* select(getBasketProducts);

    const currency = yield* select(getCurrentCurrency);
    const language = yield* select(getCurrentLanguage);

    const updatedFormData: CheckoutFormData = {
      ...currentCheckout.formData,
      personal: formData,
    };
    const totalPrice = yield* call(
      fetchCheckoutTotalPrice,
      currency,
      language,
      basketProducts,
      updatedFormData,
    );
    const updatedCheckout: CheckoutData = {
      ...currentCheckout,
      formData: updatedFormData,
      totalPrice: totalPrice,
    };

    yield* call(saveCheckoutStore, {
      formData: updatedCheckout.formData,
    });

    yield* call(logDebug, 'Submitting Checkout Personal form… done');
    yield* put(CHECKOUT_PERSONAL_SUBMIT.success(updatedCheckout));

    const pathname = yield* call(getRouteFullPath, language, 'checkout:payment');
    yield* put(push(pathname));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not submit Checkout Personal form',
      { formData },
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Submitting Checkout Personal form… error', error);
    yield* put(CHECKOUT_PERSONAL_SUBMIT.failure(NativeError.wrap(error)));
  }
}

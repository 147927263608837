import { Eq } from 'fp-ts/Eq';

const NO_VALUE: unique symbol = Symbol('NOT FOR USE');

export function memoize<A>(
  eq: Eq<A>,
) {
  return function wrap<B, F extends (a: A) => B>(fn: F): F {
    let lastA: A | typeof NO_VALUE = NO_VALUE;
    let lastB: B | typeof NO_VALUE = NO_VALUE;

    // @ts-expect-error A => B is stricter than F
    return (a) => {
      if (
        lastA !== NO_VALUE &&
        lastB !== NO_VALUE &&
        (lastA === a || eq.equals(lastA, a))
      ) {
        return lastB;
      }

      lastA = a;
      lastB = fn(a);

      return lastB;
    };
  };
}

import { createSelector } from 'reselect';
import { getExcursionsState } from 'src/selectors/getExcursionsState';
import { assertNotEmpty } from 'src/utils/assert';

export const getExcursionsData = createSelector([
  getExcursionsState,
], (state) => assertNotEmpty(
  state.data,
  'Excursions state is not initialized',
  { state },
));

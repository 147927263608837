import marked from 'marked';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PRODUCTION } from 'src/constants/application';
import { LogicError } from 'src/errors/LogicError';
import { NativeError } from 'src/errors/NativeError';
import { TranslationKey } from 'src/types/TranslationKey';
import { htmlToReact } from 'src/utils/htmlToReact';
import { sentryCatch } from 'src/utils/sentryCatch';

type Props = {
  readonly id: TranslationKey;
};

export function FormattedMarkdown({ id }: Props): React.ReactElement {
  const intl = useIntl();
  const children = useMemo(() => parseMarkdown(intl.formatMessage({ id }), id), [id, intl]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

function parseMarkdown(markdown: string, id: string): React.ReactNode {
  try {
    return htmlToReact(marked(markdown));
  } catch (error) {
    const wrapped = new LogicError(
      `Invalid Markdown message content "${id}"`,
      { id, markdown },
      NativeError.wrap(error),
    );
    if (!PRODUCTION) {
      throw wrapped;
    }

    sentryCatch(wrapped);
    return markdown;
  }
}

import { ROUTING_LANGUAGES } from 'src/routing/constants/RoutingLanguages';
import { ROUTING_PRODUCTS } from 'src/routing/constants/RoutingProducts';
import { fetchExcursions } from 'src/routing/hooks/resolvers/fetchExcursions';
import { fetchHomepage } from 'src/routing/hooks/resolvers/fetchHomepage';
import { fetchProduct } from 'src/routing/hooks/resolvers/fetchProduct';
import { fetchRegionalPasses } from 'src/routing/hooks/resolvers/fetchRegionalPasses';
import { passThrought } from 'src/routing/hooks/resolvers/passThrought';
import { resetExcursions } from 'src/routing/hooks/resolvers/resetExcursions';
import { resetHomepage } from 'src/routing/hooks/resolvers/resetHomepage';
import { resetProduct } from 'src/routing/hooks/resolvers/resetProduct';
import { resetRegionalPasses } from 'src/routing/hooks/resolvers/resetRegionalPasses';
import { RouteState } from 'src/routing/types/RouteState';
import { getRouteStatePath } from 'src/routing/utils/getRouteStatePath';

export const ROUTING_PUBLIC_STATES: ReadonlyArray<RouteState> = ROUTING_LANGUAGES.map((language): RouteState[] => [
  {
    url: getRouteStatePath(language, 'homepage', 'basepath'),
    name: `sts:ui:homepage:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'homepage-fetch',
        deps: ['globals', 'locale'],
        saga: fetchHomepage,
      }],
      onRetaining: [{
        name: 'homepage-fetch',
        deps: ['globals', 'locale'],
        saga: fetchHomepage,
      }],
      onLeft: [{
        name: 'homepage-reset',
        deps: [],
        saga: resetHomepage,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'excursions', 'basepath'),
    name: `sts:ui:excursions:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'excursions-fetch',
        deps: ['globals', 'locale'],
        saga: fetchExcursions,
      }],
      onRetaining: [{
        name: 'excursions-fetch',
        deps: ['globals', 'locale'],
        saga: fetchExcursions,
      }],
      onLeft: [{
        name: 'excursions-reset',
        deps: [],
        saga: resetExcursions,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'regionalpasses', 'basepath'),
    name: `sts:ui:regionalpasses:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'regionalPasses-fetch',
        deps: ['globals', 'locale'],
        saga: fetchRegionalPasses,
      }],
      onRetaining: [{
        name: 'regionalPasses-fetch',
        deps: ['globals', 'locale'],
        saga: fetchRegionalPasses,
      }],
      onLeft: [{
        name: 'regionalPasses-reset',
        deps: [],
        saga: resetRegionalPasses,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'faq', 'basepath'),
    name: `sts:ui:faq:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'faq-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'faq-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'guide', 'basepath'),
    name: `sts:ui:guide:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'guide-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'guide-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'conditions', 'basepath'),
    name: `sts:ui:conditions:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'conditions-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'conditions-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'contact', 'basepath'),
    name: `sts:ui:contact:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'contact-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'contact-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
    },
  },
  {
    url: getRouteStatePath(language, 'newsletter', 'basepath'),
    name: `sts:ui:newsletter:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'newsletter-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'newsletter-fetch',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
    },
  },

  {
    url: getRouteStatePath(language, 'product:base', 'basepath'),
    name: `sts:ui:product:base:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onLeft: [{
        name: 'product-reset',
        deps: [],
        saga: resetProduct,
      }],
    },
  },
  ...ROUTING_PRODUCTS.map((product): RouteState => ({
    url: getRouteStatePath(language, `product:${product}`, 'product:base'),
    name: `sts:ui:product:${product}:${language}`,
    parent: `sts:ui:product:base:${language}`,
    hooks: {
      onEntering: [{
        name: 'product-fetch',
        deps: ['globals', 'locale'],
        saga: fetchProduct.bind(null, product, language),
      }],
      onRetaining: [{
        name: 'product-fetch',
        deps: ['globals', 'locale'],
        saga: fetchProduct.bind(null, product, language),
      }],
    },
  })),
]).flat(1);

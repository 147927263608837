import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { CONFIG_FETCH } from 'src/actions/ConfigActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchPaymentTypes } from 'src/sagas/config/utils/fetchPaymentTypes';
import { fetchProductTypes } from 'src/sagas/config/utils/fetchProductTypes';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { all, call, put } from 'typed-redux-saga';

export function* fetchConfigData(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching config data…');

    const config = yield* all({
      productTypes: callEffect(fetchProductTypes),
      paymentTypes: callEffect(fetchPaymentTypes),
    });

    yield* call(logDebug, 'Fetching config data… done', config);
    yield* put(CONFIG_FETCH.trigger(config));
  } catch (error) {
    yield* call(logError, 'Fetching config data… error', error);
    throw new RuntimeError('Could not fetch config data', {}, NativeError.wrap(error));
  }
}

import { SagaIterator } from 'redux-saga';
import { BASKET_SET_CURRENCY } from 'src/actions/BasketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getAffiliateCurrency } from 'src/selectors/getAffiliateCurrency';
import { getBasketState } from 'src/selectors/getBasketState';
import { getIsBasketEmpty } from 'src/selectors/getIsBasketEmpty';
import { call, put, select } from 'typed-redux-saga';

export function* initCurrency(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Initializing currency…');

    const basket = yield* select(getBasketState);
    const isEmpty = yield* select(getIsBasketEmpty);

    const selectedCurrency = isEmpty ? null : basket.currency;
    const affiliateCurrency = yield* select(getAffiliateCurrency);
    const defaultCurrency = selectedCurrency ?? affiliateCurrency;

    yield* put(BASKET_SET_CURRENCY.trigger(defaultCurrency));
    yield* call(logDebug, 'Initializing currency… done', defaultCurrency);
  } catch (error) {
    yield* call(logError, 'Initializing currency… error', error);
    throw new RuntimeError('Could not initialize currency', {}, NativeError.wrap(error));
  }
}

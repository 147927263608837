import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { FaqScreen } from 'src/components/faq/FaqScreen';
import { getFaqData } from 'src/selectors/getFaqData';
import { getFaqSchemaOrg } from 'src/selectors/getFaqSchemaOrg';

export function FaqPage(): React.ReactElement {
  const faq = useSelector(getFaqData);
  const schema = useSelector(getFaqSchemaOrg);

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      </Helmet>
      <FaqScreen data={faq}/>
    </div>
  );
}

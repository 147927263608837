import { pipe } from 'fp-ts/function';
import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import * as E from 'io-ts/Encoder';
import { COUNTRY_CODE } from 'src/codecs/CountryCode';
import { CUSTOMER_TITLE } from 'src/codecs/CustomerTitle';
import { DATE_ONLY } from 'src/codecs/DateOnly';
import { PAYMENT_CODE } from 'src/codecs/PaymentCode';
import { PRODUCT_CODE } from 'src/codecs/ProductCode';
import { TICKET_AGE } from 'src/codecs/TicketAge';
import { CheckoutFormData } from 'src/types/CheckoutFormData';
import { OrderBillingRequest, OrderRequest, OrderTravelerRequest } from 'src/types/OrderRequest';
import { mapOrderToApi } from 'src/utils/basket/mapOrderToApi';

const TRANSFORMER: E.Encoder<OrderRequest, CheckoutFormData> = {
  encode: mapOrderToApi,
};

const ORDER_BILLING_REQUEST: E.Encoder<JsonRecord, OrderBillingRequest> = E.struct({
  email: C.string,
  title: E.nullable(CUSTOMER_TITLE),

  firstName: C.string,
  lastName: C.string,

  countryCode: E.nullable(COUNTRY_CODE),
});

const ORDER_TRAVELER_REQUEST: E.Encoder<JsonRecord, OrderTravelerRequest> = E.struct({
  type: PRODUCT_CODE,
  tariff: TICKET_AGE,

  firstName: C.string,
  lastName: C.string,
  birthDate: E.nullable(DATE_ONLY),
  homeCountryCode: E.nullable(COUNTRY_CODE),
});

const ENCODER: E.Encoder<JsonRecord, OrderRequest> = E.struct({
  paymentType: E.nullable(PAYMENT_CODE),
  cancellationProtection: C.boolean,

  billing: ORDER_BILLING_REQUEST,
  travelers: E.readonly(E.array(ORDER_TRAVELER_REQUEST)),
});

export const ORDER_REQUEST = pipe(
  TRANSFORMER,
  E.compose(ENCODER),
);

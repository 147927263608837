import './ProductFaqList.scss';
import React from 'react';
import { FaqSectionView } from 'src/components/faq/FaqSectionView';
import { FaqSection } from 'src/types/FaqSection';

type Props = {
  readonly sections: ReadonlyArray<FaqSection>;
};

export function ProductFaqList({ sections }: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-faq-list">
      {sections.map((faqSection, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="sts-ui-product-faq-list__item">
          <FaqSectionView section={faqSection}/>
        </div>
      ))}
    </div>
  );
}

import './BasketUpgradeView.scss';
import React, { useCallback } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { BASKET_UPGRADE_IMAGES } from 'src/constants/images';
import { SEASON } from 'src/constants/season';
import { useProductName } from 'src/hooks/useProductName';
import { BasketUpgradeListItem } from 'src/types/BasketUpgradeListItem';
import { ProductCode } from 'src/types/ProductCode';

type Props = {
  readonly product: BasketUpgradeListItem;
  readonly onUpgrade: (code: ProductCode) => void;
  readonly upgrading: boolean;
};

export function BasketUpgradeView({ product, onUpgrade, upgrading }: Props): React.ReactElement {
  const productName = useProductName(product.code);

  const handleUpgradeProduct = useCallback(() => {
    onUpgrade(product.code);
  }, [product, onUpgrade]);

  return (
    <div className="sts-ui-basket-upgrade-view">
      <img
        src={BASKET_UPGRADE_IMAGES[SEASON]}
        alt=""
        className="sts-ui-basket-upgrade-view__image"
      />

      <div className="sts-ui-basket-upgrade-view__title">
        <FormattedHTMLMessage id="Basket.Upsells.UpgradeTicketClass"/>
      </div>
      <div className="sts-ui-basket-upgrade-view__description">
        <FormattedHTMLMessage id="Basket.Upsells.UpgradeClassDescription"/>
      </div>

      <div className="sts-ui-basket-upgrade-view__price">
        <i>
          <FormattedHTMLMessage
            id="Basket.Upsells.UpgradeClassMessage"
            values={{ title: productName }}
          />
        </i>
        &nbsp;
        <strong><FormattedPrice value={product.price}/></strong>
      </div>

      <div className="sts-ui-basket-upgrade-view__action">
        <button
          type="button"
          className="sts-ui-primary-button"
          onClick={handleUpgradeProduct}
          disabled={upgrading}
        >
          <FormattedHTMLMessage id="Basket.Upsells.AddClassUpgrade"/>

          {upgrading
            ? <LoadingIcon/>
            : null}
        </button>
      </div>
    </div>
  );
}

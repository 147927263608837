import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PRODUCTION } from 'src/constants/application';
import { LogicError } from 'src/errors/LogicError';
import { NativeError } from 'src/errors/NativeError';
import { htmlToReact } from 'src/utils/htmlToReact';
import { sentryCatch } from 'src/utils/sentryCatch';

type Props = {
  readonly id: string;
  readonly values?: Record<string, string | number | boolean | Date | null | undefined>;
};

export function FormattedHTMLMessage({ id, values }: Props): React.ReactElement {
  const intl = useIntl();
  const message = intl.formatMessage({ id }, values, { ignoreTag: true });
  const children = useMemo(() => parseHtml(message, id), [id, message]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

function parseHtml(html: string, id: string): React.ReactNode {
  try {
    return htmlToReact(html);
  } catch (error) {
    const wrapped = new LogicError(`Invalid HTML message content "${id}"`, { id, html }, NativeError.wrap(error));
    if (!PRODUCTION) {
      throw wrapped;
    }

    sentryCatch(wrapped);
    return html;
  }
}

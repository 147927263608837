import { push } from 'redux-first-history';
import { SagaIterator } from 'redux-saga';
import { BASKET_SET_PRODUCT } from 'src/actions/BasketActions';
import { PRODUCT_SUBMIT } from 'src/actions/ProductActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { getRouteFullPath } from 'src/routing/utils/getRouteFullPath';
import { saveBasketData } from 'src/sagas/basket/utils/saveBasketData';
import { modifyProductData } from 'src/sagas/product/utils/modifyProductData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* productSubmitSaga(
  action: GetRequestActionType<typeof PRODUCT_SUBMIT>,
): SagaIterator<void> {
  const productType = action.meta;
  const productForm = action.data;

  try {
    yield* call(logDebug, `Submitting product "${productType}"…`, productForm);
    yield* put(PRODUCT_SUBMIT.pending(productType));

    yield* delay(1_000);
    const updated = yield* call(modifyProductData, productType, productForm);

    if (updated.ticketPrice.length === 0) {
      yield* call(logDebug, `Submitting product "${productType}"… skip (no tickets)`, { updated });
      yield* put(PRODUCT_SUBMIT.success(updated, productType));
      return;
    }

    yield* call(logDebug, `Submitting product "${productType}"… done`, productForm);
    yield* put(PRODUCT_SUBMIT.success(updated, productType));

    yield* put(BASKET_SET_PRODUCT.trigger(updated.formData, productType));
    yield* call(saveBasketData);

    const language = yield* select(getCurrentLanguage);
    const pathname = yield* call(getRouteFullPath, language, 'basket');
    yield* put(push(pathname));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not submit product',
      { productType, productForm },
      NativeError.wrap(error),
    ));

    yield* call(logError, `Submitting product "${productType}"… error`, error);
    yield* put(PRODUCT_SUBMIT.failure(NativeError.wrap(error), productType));
  }
}

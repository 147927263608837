import './ProductTeaserValidityMap.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ExternalLinkIcon } from 'src/components/icons/ExternalLinkIcon';
import { VALIDITY_MAPS } from 'src/constants/images';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { ProductAttibutesTeaserValidityMap } from 'src/types/ProductAttibutes';

type Props = {
  readonly validityMap: ProductAttibutesTeaserValidityMap;
};

export function ProductTeaserValidityMap({ validityMap }: Props): React.ReactElement {
  const language = useSelector(getCurrentLanguage);

  return (
    <FormattedMessage id={validityMap.url}>
      {(url) => (
        <div className="sts-ui-product-teaser-validity-map">
          <div className="sts-ui-product-teaser-validity-map__image">
            <a href={url.toString()} target="_blank" rel="noopener noreferrer">
              <img {...VALIDITY_MAPS[language]} alt="Map"/>
            </a>
          </div>
          <div className="sts-ui-product-teaser-validity-map__content">
            <div className="sts-ui-product-teaser-validity-map__title">
              <FormattedMessage id={validityMap.title}/>
            </div>
            <div className="sts-ui-product-teaser-validity-map__description">
              <FormattedHTMLMessage id={validityMap.description}/>
            </div>
            <div className="sts-ui-product-teaser-validity-map__link">
              <a href={url.toString()} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="ProductPage.ValidityMap.Open"/>
                <ExternalLinkIcon/>
              </a>
            </div>
          </div>
        </div>
      )}
    </FormattedMessage>
  );
}

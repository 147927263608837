import { Numeric } from 'decimal.js-light';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { formatPrice } from 'src/utils/format';

type Props = {
  readonly value: Numeric;
};

export const FormattedPrice = React.memo(({ value }: Props) => {
  const currency = useSelector(getCurrentCurrency);
  const formatted = formatPrice(value, currency);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formatted}</>;
});

import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/ReadonlyRecord';
import * as S from 'fp-ts/string';
import * as L from 'monocle-ts/Lens';
import * as T from 'monocle-ts/Traversal';
import { CheckoutPersonalFormData } from 'src/types/CheckoutFormData';
import { TicketAge } from 'src/types/TicketAge';
import { isDefined } from 'src/utils/guard';

export function syncCheckoutTravelerFormData(
  prevFormData: CheckoutPersonalFormData,
  nextFormData: CheckoutPersonalFormData,
): CheckoutPersonalFormData {
  return pipe(
    nextFormData,
    (currFormData) => synchronizeAll(
      currFormData,
      prevFormData,
      BILLING_FIRST_NAME,
      TRAVELER_FIRST_NAME,
    ),
    (currFormData) => synchronizeAll(
      currFormData,
      prevFormData,
      BILLING_LAST_NAME,
      TRAVELER_LAST_NAME,
    ),
  );
}

function synchronizeAll<F, V>(
  currFormData: F,
  prevFormData: F,
  sourceValue: L.Lens<F, V>,
  targetValue: T.Traversal<F, V>,
): F {
  const currSourceValue = sourceValue.get(currFormData);
  const prevSourceValue = sourceValue.get(prevFormData);
  if (currSourceValue === prevSourceValue) {
    return currFormData;
  }

  return pipe(
    currFormData,
    pipe(
      targetValue,
      T.modify((currTargetValue) => (
        currTargetValue === prevSourceValue
          ? currSourceValue
          : currTargetValue
      )),
    ),
  );
}

const BILLING_FIRST_NAME = pipe(
  L.id<CheckoutPersonalFormData>(),
  L.prop('billing'),
  L.prop('firstName'),
);
const BILLING_LAST_NAME = pipe(
  L.id<CheckoutPersonalFormData>(),
  L.prop('billing'),
  L.prop('lastName'),
);

const TRAVELER_ADULT = pipe(
  T.id<CheckoutPersonalFormData>(),
  T.prop('travelers'),
  T.traverse(R.getTraversable(S.Ord)),
  T.prop(TicketAge.ADULT),
  T.filter(isDefined),
  T.index(0),
);
const TRAVELER_FIRST_NAME = pipe(
  TRAVELER_ADULT,
  T.prop('firstName'),
);
const TRAVELER_LAST_NAME = pipe(
  TRAVELER_ADULT,
  T.prop('lastName'),
);

import { pipe } from 'fp-ts/function';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { BASKET_REQUEST } from 'src/codecs/BasketRequest';
import { ProductMap } from 'src/codecs/combinators/ProductMap';
import { CURRENCY } from 'src/codecs/Currency';
import { JSON_STRING } from 'src/codecs/JsonString';
import { PRODUCT_CODE } from 'src/codecs/ProductCode';
import { TICKET_MINIMAL_START_DATE } from 'src/codecs/TicketMinimalStartDate';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { BasketProduct } from 'src/types/BasketProduct';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { ProductMap as ProductMapType } from 'src/types/ProductMap';
import { TicketMinimalStartDate } from 'src/types/TicketMinimalStartDate';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchProductsMinimalStartDates(
  currency: Currency,
  products: ReadonlyArray<ProductCode>,
  basket: ProductMapType<BasketProduct> | null,
): SagaIterator<ProductMapType<TicketMinimalStartDate>> {
  try {
    const request = yield* call(REQUEST.encode, { type: products, currency: currency, basket: basket });
    const response = yield* call(makeGetRequest, `${HOST_API}/minimal-start-date`, request);
    return yield* call(decodeOrThrow, ProductMap(TICKET_MINIMAL_START_DATE), response.content);
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch products minimal start dates',
      { currency, products, basket },
      NativeError.wrap(error),
    );
  }
}

const REQUEST = E.struct({
  currency: CURRENCY,
  type: pipe(E.readonly(E.array(PRODUCT_CODE)), E.compose(JSON_STRING)),
  basket: E.nullable(pipe(BASKET_REQUEST, E.compose(JSON_STRING))),
});

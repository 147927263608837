import { PRODUCT_CHANGE, PRODUCT_FETCH, PRODUCT_RESET, PRODUCT_SUBMIT } from 'src/actions/ProductActions';
import { ProductState } from 'src/types/ProductState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: ProductState = {
  data: null,
  loading: false,
};

export const productReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (PRODUCT_FETCH.is(action)) {
    return {
      ...state,
      data: action.data,
      loading: false,
    };
  }
  if (PRODUCT_RESET.is(action)) {
    return {
      ...state,
      data: null,
      loading: false,
    };
  }

  if (PRODUCT_CHANGE.isPending(action)) {
    return {
      ...state,
      loading: true,
    };
  }
  if (PRODUCT_CHANGE.isSuccess(action)) {
    return {
      ...state,
      data: state.data && state.data.type === action.meta
        ? action.data
        : state.data,
      loading: false,
    };
  }
  if (PRODUCT_CHANGE.isFailure(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  if (PRODUCT_SUBMIT.isPending(action)) {
    return {
      ...state,
      loading: true,
    };
  }
  if (PRODUCT_SUBMIT.isSuccess(action)) {
    return {
      ...state,
      data: state.data && state.data.type === action.meta
        ? action.data
        : state.data,

      // NOTE: keep loading
      loading: true,
    };
  }
  if (PRODUCT_SUBMIT.isFailure(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  return state;
});

import * as C from 'io-ts/Codec';
import { Optional } from 'src/codecs/combinators/Optional';
import { TariffMap } from 'src/codecs/combinators/TariffMap';
import { DATE_ONLY } from 'src/codecs/DateOnly';
import { NEVER } from 'src/codecs/Never';
import { TravelFieldList, TravelFieldOptions, TravelFieldsContraints } from 'src/types/TravelFields';

const TRAVEL_FIELD_CONSTRAINTS: <T>(
  codec: C.Codec<unknown, unknown, T>,
) => C.Codec<unknown, unknown, TravelFieldsContraints<T>> = (codec) => C.struct({
  min: Optional(codec),
  max: Optional(codec),
});

const TRAVEL_FIELD_OPTIONS: <T>(
  codec: C.Codec<unknown, unknown, T>,
) => C.Codec<unknown, unknown, TravelFieldOptions<T>> = (codec) => C.struct({
  required: C.boolean,
  constraints: Optional(TRAVEL_FIELD_CONSTRAINTS(codec)),
});

const TRAVEL_FIELD_LIST: C.Codec<unknown, unknown, TravelFieldList> = C.struct({
  firstName: Optional(TRAVEL_FIELD_OPTIONS(NEVER)),
  lastName: Optional(TRAVEL_FIELD_OPTIONS(NEVER)),
  birthDate: Optional(TRAVEL_FIELD_OPTIONS(DATE_ONLY)),
  homeCountryCode: Optional(TRAVEL_FIELD_OPTIONS(NEVER)),
  homeFullCountryCode: Optional(TRAVEL_FIELD_OPTIONS(NEVER)),
});

export const TRAVELER_FIELDS = TariffMap(TRAVEL_FIELD_LIST);

import './FaqSectionStateIcon.scss';
import classNames from 'classnames';
import React from 'react';

type Props = {
  readonly expanded: boolean;
};

export function FaqSectionStateIcon({ expanded }: Props): React.ReactElement {
  return (
    <div className={classNames(
      'sts-ui-faq-section-state-icon',
      expanded
        ? 'sts-ui-faq-section-state-icon--expanded'
        : 'sts-ui-faq-section-state-icon--collapsed',
    )}
    />
  );
}

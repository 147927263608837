import { ROUTING_LANGUAGES } from 'src/routing/constants/RoutingLanguages';
import { fetchConfigData } from 'src/routing/hooks/resolvers/fetchConfigData';
import { fetchLocalization } from 'src/routing/hooks/resolvers/fetchLocalization';
import { passThrought } from 'src/routing/hooks/resolvers/passThrought';
import { redirectFromLegacyUrl } from 'src/routing/hooks/resolvers/redirectFromLegacyUrl';
import { redirectToDefaultPage } from 'src/routing/hooks/resolvers/redirectToDefaultPage';
import { RouteState } from 'src/routing/types/RouteState';
import { getRouteStatePath } from 'src/routing/utils/getRouteStatePath';

export const ROUTING_LAYOUT_STATES: ReadonlyArray<RouteState> = [
  {
    url: '',
    name: 'sts:ui:global',
    parent: null,
    hooks: {
      onEntering: [{
        name: 'globals',
        deps: [],
        saga: fetchConfigData,
      }],
      onRetaining: [{
        name: 'globals',
        deps: [],
        saga: passThrought,
      }],
    },
  },
  {
    url: '/',
    name: 'sts:ui:default',
    parent: null,
    hooks: {
      onEntering: [{
        name: 'redirect-legacy',
        deps: [],
        saga: redirectFromLegacyUrl,
      }, {
        name: 'redirect-default',
        deps: ['redirect-legacy'],
        saga: redirectToDefaultPage,
      }],
    },
  },
  ...ROUTING_LANGUAGES.map((language): RouteState => ({
    url: getRouteStatePath(language, 'basepath', null),
    name: `sts:ui:basepath:${language}`,
    parent: 'sts:ui:global',
    hooks: {
      onEntering: [{
        name: 'locale',
        deps: [],
        saga: fetchLocalization.bind(null, language),
      }, {
        name: 'trusted-shop',
        deps: ['locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'locale',
        deps: [],
        saga: passThrought,
      }],
    },
  })),
];

import './Alert.scss';
import React from 'react';

type Props = {
  readonly children: React.ReactNode;
};

export function Alert({ children }: Props): React.ReactElement {
  return (
    <div className="sts-ui-alert">
      {children}
    </div>
  );
}

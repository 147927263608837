import { createSelector } from 'reselect';
import { getBasketProducts } from 'src/selectors/getBasketProducts';
import { getProductFormData } from 'src/selectors/getProductFormData';
import { getProductType } from 'src/selectors/getProductType';
import { Integer } from 'src/types/Integer';
import { countFamilyCards } from 'src/utils/basket/countFamilyCards';
import { showFamilyCardDiscount } from 'src/utils/basket/showFamilyCardDiscount';

export const getProductFamilyCardCount = createSelector([
  getProductType,
  getProductFormData,
  getBasketProducts,
], (productType, productForm, basket): Integer => {
  return showFamilyCardDiscount(productType, productForm)
    ? countFamilyCards(basket)
    : 0;
});

import React from 'react';

export function LoadingIcon(): React.ReactElement {
  return (
    <svg viewBox="0 0 100 100" width="1em" height="1em">
      <circle fill="currentColor" stroke="none" cx="6" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/>
      </circle>
      <circle fill="currentColor" stroke="none" cx="26" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/>
      </circle>
      <circle fill="currentColor" stroke="none" cx="46" cy="50" r="6">
        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/>
      </circle>
    </svg>
  );
}

import { createSelector } from 'reselect';
import { getCheckoutData } from 'src/selectors/getCheckoutData';
import { assertNotNull } from 'src/utils/assert';

export const getCheckoutTotalPrice = createSelector([
  getCheckoutData,
], (data) => assertNotNull(
  data.totalPrice,
  'Checkout total price is not initialized',
  { data },
));

import { routeChangeReducer } from 'src/routing/reducers/routeChangeReducer';
import { routeDestroyReducer } from 'src/routing/reducers/routeDestroyReducer';
import { routeSynchonizeReducer } from 'src/routing/reducers/routeSynchonizeReducer';
import { RoutingState } from 'src/routing/types/RoutingState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: RoutingState = {
  isSynchronizing: false,
  isTransitioning: false,
  currentState: null,
  transitionError: null,
};

export const routingReducer = chainReducers(defaultState, [
  routeChangeReducer,
  routeDestroyReducer,
  routeSynchonizeReducer,
]);

import './ProductPrimaryCard.scss';
import { Decimal } from 'decimal.js-light';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProductCardFeatures } from 'src/components/common/ProductCardFeatures';
import { ProductName } from 'src/components/common/ProductName';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { ProductCardInformation } from 'src/components/product-list/ProductCardInformation';
import { ProductCardPriceValue } from 'src/components/product-list/ProductCardPriceValue';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { ProductCode } from 'src/types/ProductCode';

type Props = {
  readonly code: ProductCode;
  readonly price: Decimal;
  readonly promo?: React.ReactElement;
};

export function ProductPrimaryCard({ code, price, promo }: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-primary-card">
      <h2 className="sts-ui-product-primary-card__title">
        <ProductName type={code}/>
      </h2>

      <div className="sts-ui-product-primary-card__features">
        <ProductCardFeatures type={code}/>
      </div>

      <div className="sts-ui-product-primary-card__price">
        <div className="sts-ui-product-primary-card__value">
          <ProductCardPriceValue
            price={price}
            title={<FormattedMessage id="ProductCard.Primary.PriceFrom"/>}
          />
        </div>

        <div className="sts-ui-product-primary-card__info">
          <ProductCardInformation
            code={code}
            title={<FormattedMessage id="ProductCard.Primary.Info"/>}
          />
        </div>
      </div>

      <RouteLink route={`product:${code}`} className="sts-ui-product-primary-card__link sts-ui-primary-button">
        <div className="sts-ui-product-primary-card__more">
          <ArrowRightIcon/>
          <FormattedMessage id="ProductCard.Primary.View"/>
          <ArrowRightIcon/>
        </div>
      </RouteLink>

      {promo && (
        <div className="sts-ui-product-primary-card__promo">
          {promo}
        </div>
      )}
    </div>
  );
}

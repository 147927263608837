import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Integer } from 'src/types/Integer';
import { ListOption } from 'src/types/ListOption';

export function useMonthList(): ReadonlyArray<ListOption<Integer>> {
  const intl = useIntl();

  return useMemo(() => Array.from({ length: 12 }, (_, index) => ({
    value: index + 1,
    title: intl.formatDate(new Date(2000, index, 1, 0, 0, 0), { month: 'long' }),
  })), [intl]);
}

import { Json } from 'fp-ts/Json';
import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call } from 'typed-redux-saga';

export function* storageGet(key: string): SagaIterator<Json | undefined> {
  try {
    const stored = yield* call({
      context: window.localStorage,
      fn: window.localStorage.getItem,
    }, key);
    if (stored === null) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(stored);
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(`Could not get "${key}" storage value`, { key }, NativeError.wrap(error)));
    return undefined;
  }
}

export function* storageSet(key: string, val: Json | undefined): SagaIterator<void> {
  try {
    if (val === undefined) {
      yield* call({
        context: window.localStorage,
        fn: window.localStorage.removeItem,
      }, key);
    } else {
      yield* call({
        context: window.localStorage,
        fn: window.localStorage.setItem,
      }, key, JSON.stringify(val));
    }
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(`Could not set "${key}" storage value`, { key, val }, NativeError.wrap(error)));
  }
}

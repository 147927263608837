import { TICKETS_BY_GROUPS } from 'src/constants/tickets';
import { ProductCode } from 'src/types/ProductCode';
import { ProductFormData } from 'src/types/ProductFormData';
import { TicketGroup } from 'src/types/TicketGroup';
import { TicketType } from 'src/types/TicketType';

export function showFamilyCardDiscount(
  productType: ProductCode,
  productForm: ProductFormData,
): boolean {
  if (productForm.cardType === null) {
    return false;
  }
  if (productForm.child !== null && productForm.child > 0) {
    return false;
  }

  return AVAILABLE_TRAVEL_FREE_CHILD_TYPES.has(productType);
}

const AVAILABLE_TRAVEL_FREE_CHILD_TYPES = new Set([
  ...TICKETS_BY_GROUPS[TicketGroup.MOUNTAIN_EXCURSION],
  TicketType.REGIONAL_OBERLAND_PASS,
]);

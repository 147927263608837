import './CheckoutPaymentTotalPriceFormView.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { CheckoutTotal } from 'src/types/CheckoutTotal';

type Props = {
  readonly price: CheckoutTotal;
};

export function CheckoutPaymentTotalPriceFormView({ price }: Props): React.ReactElement {
  return (
    <table className="sts-ui-checkout-payment-total-price-form-view">
      <thead>
        <tr>
          <td className="sts-ui-checkout-payment-total-price-form-view__title">
            <FormattedMessage id="Form.Checkout.Payment.PriceTable.Title"/>
          </td>
          <td className="sts-ui-checkout-payment-total-price-form-view__price">
            <FormattedMessage id="Form.Checkout.Payment.PriceTable.Price"/>
          </td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td className="sts-ui-checkout-payment-total-price-form-view__title">
            <FormattedMessage id="Form.Checkout.Payment.Subtotal"/>
          </td>
          <td className="sts-ui-checkout-payment-total-price-form-view__price">
            <strong><FormattedPrice value={price.subtotal}/></strong>
          </td>
        </tr>
        {price.paymentFee.isPositive() && (
          <tr>
            <td className="sts-ui-checkout-payment-total-price-form-view__title">
              <FormattedMessage id="Form.Checkout.Payment.TransactionFee"/>
            </td>
            <td className="sts-ui-checkout-payment-total-price-form-view__price">
              <FormattedPrice value={price.paymentFee}/>
            </td>
          </tr>
        )}
        {price.bookingFee.isPositive() && (
          <tr>
            <td className="sts-ui-checkout-payment-total-price-form-view__title">
              <FormattedMessage id="Form.Checkout.Payment.BookingFee"/>
            </td>
            <td className="sts-ui-checkout-payment-total-price-form-view__price">
              <FormattedPrice value={price.bookingFee}/>
            </td>
          </tr>
        )}
      </tbody>

      <tfoot>
        <tr>
          <td className="sts-ui-checkout-payment-total-price-form-view__title">
            <FormattedMessage id="Form.Checkout.Payment.Total"/>
          </td>
          <td className="sts-ui-checkout-payment-total-price-form-view__price">
            <strong><FormattedPrice value={price.total}/></strong>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

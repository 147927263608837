import { EXCURSIONS_FETCH, EXCURSIONS_RESET } from 'src/actions/ExcursionsActions';
import { ExcursionsState } from 'src/types/ExcursionsState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: ExcursionsState = {
  data: null,
};

export const excursionsReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (EXCURSIONS_FETCH.is(action)) {
    return { ...state, data: action.data };
  }
  if (EXCURSIONS_RESET.is(action)) {
    return { ...state, data: null };
  }

  return state;
});

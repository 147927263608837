import { BaseError } from 'src/errors/BaseError';

export class HttpError extends BaseError {
  public constructor(
    public readonly response: Response,
    previous?: BaseError,
  ) {
    super(`HTTP error ${response.status} ("${response.statusText}")`, previous);
  }

  public get name(): string {
    return 'HttpError';
  }

  public get status(): number {
    return this.response.status;
  }

  public toJSON(): Record<string, unknown> {
    return {
      ...super.toJSON(),
      response: this.response,
    };
  }
}

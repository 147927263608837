import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchNonDeliverableProducts } from 'src/sagas/common/utils/fetchNonDeliverableProducts';
import { fetchProductsMinimalPrices } from 'src/sagas/common/utils/fetchProductsMinimalPrices';
import { BasketProduct } from 'src/types/BasketProduct';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { ProductListItem } from 'src/types/ProductListItem';
import { ProductMap as ProductMapType } from 'src/types/ProductMap';
import { isNotNull } from 'src/utils/guard';
import { all } from 'typed-redux-saga';

export function* fetchProductListData(
  currency: Currency,
  products: ReadonlyArray<ProductCode>,
  basket: ProductMapType<BasketProduct> | null,
): SagaIterator<ProductListItem[]> {
  try {
    const { prices, nonDeliverable } = yield* all({
      prices: callEffect(fetchProductsMinimalPrices, currency, products),
      nonDeliverable: callEffect(fetchNonDeliverableProducts, currency, products, basket),
    });

    return products.map((code) => {
      const price = prices[code]?.price;
      if (!price || nonDeliverable[code]) {
        return null;
      }

      return {
        code,
        price,
      };
    }).filter(isNotNull);
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch product list',
      { currency, products, basket },
      NativeError.wrap(error),
    );
  }
}

import React from 'react';
import { useSelector } from 'react-redux';
import { PaymentCancelScreen } from 'src/components/payment/PaymentCancelScreen';
import { getPaymentData } from 'src/selectors/getPaymentData';

export function PaymentCancelPage(): React.ReactElement {
  const payment = useSelector(getPaymentData);

  return (
    <PaymentCancelScreen
      payment={payment}
    />
  );
}

import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { CURRENCY } from 'src/codecs/Currency';
import { PAYMENT_TYPE } from 'src/codecs/PaymentType';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { CheckoutData } from 'src/types/CheckoutData';
import { Currency } from 'src/types/Currency';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchCheckoutPaymentTypes(
  currency: Currency,
): SagaIterator<CheckoutData['paymentTypes']> {
  try {
    const request = yield* call(REQUEST.encode, { currency });
    const response = yield* call(makeGetRequest, `${HOST_API}/payment-types`, request);
    const { group1 } = yield* call(decodeOrThrow, RESPONSE, response.content);
    return group1;
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Checkout payment types',
      { currency },
      NativeError.wrap(error),
    );
  }
}

const REQUEST = E.struct({
  currency: CURRENCY,
});

const RESPONSE = D.struct({
  group1: D.array(PAYMENT_TYPE),
});

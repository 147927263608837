import { Decimal } from 'decimal.js-light';
import { TARIFF_LIST } from 'src/constants/tariff';
import { ProductPrice } from 'src/types/ProductPrice';
import { ProductPriceList } from 'src/types/ProductPriceList';
import { TicketPrice } from 'src/types/TicketPrice';
import { isNotNull } from 'src/utils/guard';

export function mapProductPriceList(prices: ReadonlyArray<TicketPrice>): ProductPriceList {
  return {
    totalPrice: prices.reduce((sum, it) => sum.add(it.price), new Decimal(0)),
    tariffs: TARIFF_LIST.map((tariff): ProductPrice | null => {
      const tickets = prices.filter((it) => it.tariff === tariff);
      if (tickets.length === 0) {
        return null;
      }

      return {
        age: tariff,
        count: tickets.length,
        price: tickets.reduce((sum, it) => sum.add(it.price), new Decimal(0)),
        discounts: tickets
          .map((it) => it.discounts)
          .flat(1)
          .filter((it, ix, arr) => arr.indexOf(it) === ix),
      };
    }).filter(isNotNull),
  };
}

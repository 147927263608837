import './Typograhy.scss';
import classNames from 'classnames';
import React from 'react';

type VariantType =
  | 'page-title'
  | 'page-subtitle'
  | 'block-title'
  | 'text-primary'
  | 'text-secondary'
  | 'form-label';

type ElementType =
  | 'label'
  | 'span'
  | 'div'
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5';

type Props<T extends ElementType> =
  & Omit<React.ComponentProps<T>, 'as'>
  & { readonly as: T; readonly variant: VariantType };

export function Typography<T extends ElementType>({ variant, as, ...props }: Props<T>): React.ReactElement {
  const component = as ?? 'span';
  const className = classNames(
    props.className,
    'sts-ui-typography',
    `sts-ui-typography--${variant}`,
  );

  return React.createElement(component, { ...props, className });
}

import React from 'react';
import { useSelector } from 'react-redux';
import { ExcursionsScreen } from 'src/components/excursions/ExcursionsScreen';
import { getExcursionsData } from 'src/selectors/getExcursionsData';

export function ExcursionsPage(): React.ReactElement {
  const data = useSelector(getExcursionsData);

  return (
    <ExcursionsScreen products={data}/>
  );
}

import { Decimal } from 'decimal.js-light';
import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

const DECODER: D.Decoder<unknown, Decimal> = pipe(
  D.union(D.number, D.string),
  D.parse((input) => {
    try {
      return D.success(new Decimal(input));
    } catch {
      return D.failure(input, 'Decimal');
    }
  }),
);

const ENCODER: E.Encoder<string, Decimal> = {
  encode: (v) => v.toFixed(2),
};

export const DECIMAL: C.Codec<unknown, string, Decimal> = C.make(
  DECODER,
  ENCODER,
);

import './ProductFormViewTraveler.scss';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormattedMarkdown } from 'src/components/common/FormattedMarkdown';
import { Col, Row } from 'src/components/common/Grid';
import { InfoModal } from 'src/components/common/InfoModal';
import { Typography } from 'src/components/common/Typography';
import { TARIFF_LIMITS } from 'src/constants/tariff';
import { CheckboxField } from 'src/forms/fields/CheckboxField';
import { CounterField } from 'src/forms/fields/CounterField';
import { FormError } from 'src/forms/types/FormError';
import { defaultIfNull } from 'src/forms/utils/transform';
import { getConfigState } from 'src/selectors/getConfigState';
import { ProductCode } from 'src/types/ProductCode';
import { ProductFormData } from 'src/types/ProductFormData';
import { TicketAge } from 'src/types/TicketAge';
import { TicketFields } from 'src/types/TicketFields';
import { TicketType } from 'src/types/TicketType';

type Props = {
  readonly type: ProductCode;
  readonly form: FormController<ProductFormData, FormError>;
  readonly fields: TicketFields;
  readonly showFamilyCardOption: boolean;
  readonly showFamilyCardDiscount: boolean;
};

export function ProductFormViewTraveler({
  type,
  form,
  fields,
  showFamilyCardOption,
  showFamilyCardDiscount,
}: Props): React.ReactElement {
  const { allowAges, countPassengers } = fields;
  const { ageGroupsTranslations: { [type]: labels } } = useSelector(getConfigState);

  const adultValue = useFormWatch(form, form.field.at('adult').value.get) ?? 0;
  const youthValue = useFormWatch(form, form.field.at('youth').value.get) ?? 0;
  const childValue = useFormWatch(form, form.field.at('child').value.get) ?? 0;

  const adultMaxValue = countPassengers.max.adult ?? TARIFF_LIMITS[TicketAge.ADULT];
  const youthMaxValue = countPassengers.max.youth ?? TARIFF_LIMITS[TicketAge.YOUTH];
  const childMaxValue = countPassengers.max.child ?? TARIFF_LIMITS[TicketAge.CHILD];

  const adultMinValue = countPassengers.min.adult ?? 0;
  const youthMinValue = countPassengers.min.youth ?? 0;
  const childMinValue = countPassengers.min.child ?? 0;

  return (
    <div className="sts-ui-product-form-view-traveler">
      {type !== TicketType.SWISS_HALF_FARE_CARD && (
        <div className="sts-ui-product-form-view-traveler__title">
          <FormattedMessage id="Form.Product.WhoIsTravelling"/>
          <div className="sts-ui-product-form-view-traveler__divider"/>
        </div>
      )}

      {type === TicketType.SWISS_HALF_FARE_CARD && (
        <Row>
          <Col xs={12} sm={6} md={4} lg={3} className="sts-ui-product-form-view-traveler__group">
            <div className="sts-ui-product-form-view-traveler__label">
              <Typography as="label" variant="form-label">
                <FormattedMessage id="Form.Product.HalfFareCard"/>
              </Typography>
            </div>
            <div className="sts-ui-product-form-view-traveler__field">
              <CounterField
                field={form.field.at('adult').transform(defaultIfNull(0))}
                min={adultMinValue}
                max={adultMaxValue}
              />
            </div>

            {adultValue >= adultMaxValue && (
              <div className="sts-ui-product-form-view-traveler__note">
                <FormattedMessage id="Form.Product.YouCantAddMoreHalfFareCards"/>
              </div>
            )}
          </Col>

          <Col xs={12} sm={6} md={4} lg={3} className="sts-ui-product-form-view-traveler__group">
            <div className="sts-ui-product-form-view-traveler__label">
              <Typography as="label" variant="form-label">
                <FormattedMessage id="Form.Product.HalfFareFamilyCard"/>
              </Typography>

              <div className="sts-ui-product-form-view-traveler__info">
                <InfoModal>
                  <FormattedMarkdown id="products/familyCard.md"/>
                </InfoModal>
              </div>
            </div>
            <div className="sts-ui-product-form-view-traveler__field">
              <CounterField
                field={form.field.at('child').transform(defaultIfNull(0))}
                min={childMinValue}
                max={childMaxValue}
              />
            </div>

            {childValue >= childMaxValue && (
              <div className="sts-ui-product-form-view-traveler__note">
                <FormattedMessage id="Form.Product.YouCantAddMoreHalfFareFamilyCards"/>
              </div>
            )}
          </Col>
        </Row>
      )}
      {type !== TicketType.SWISS_HALF_FARE_CARD && (
        <Row>
          {allowAges[TicketAge.ADULT] && (
            <Col xs={12} sm={4} md={4} lg={3} className="sts-ui-product-form-view-traveler__group">
              <div className="sts-ui-product-form-view-traveler__label">
                <Typography as="label" variant="form-label">
                  <FormattedMessage id={labels?.adult ?? 'Form.Product.Adults'}/>
                </Typography>
              </div>
              <div className="sts-ui-product-form-view-traveler__field">
                <CounterField
                  field={form.field.at('adult').transform(defaultIfNull(0))}
                  min={adultMinValue}
                  max={adultMaxValue}
                />
              </div>

              {adultValue >= adultMaxValue && (
                <div className="sts-ui-product-form-view-traveler__note">
                  <FormattedMessage id="Form.Product.YouCantAddMoreAdults"/>
                </div>
              )}
            </Col>
          )}

          {allowAges[TicketAge.YOUTH] && (
            <Col xs={12} sm={4} md={4} lg={3} className="sts-ui-product-form-view-traveler__group">
              <div className="sts-ui-product-form-view-traveler__label">
                <Typography as="label" variant="form-label">
                  <FormattedMessage id={labels?.youth ?? 'Form.Product.Youth'}/>
                </Typography>
              </div>
              <div className="sts-ui-product-form-view-traveler__field">
                <CounterField
                  field={form.field.at('youth').transform(defaultIfNull(0))}
                  min={youthMinValue}
                  max={youthMaxValue}
                />
              </div>

              {youthValue >= youthMaxValue && (
                <div className="sts-ui-product-form-view-traveler__note">
                  <FormattedMessage id="Form.Product.YouCantAddMoreYouths"/>
                </div>
              )}
            </Col>
          )}

          {allowAges[TicketAge.CHILD] && !showFamilyCardDiscount && (
            <Col xs={12} sm={4} md={4} lg={3} className="sts-ui-product-form-view-traveler__group">
              <div className="sts-ui-product-form-view-traveler__label">
                <Typography as="label" variant="form-label">
                  <FormattedMessage id={labels?.child ?? 'Form.Product.Children'}/>
                </Typography>
              </div>
              <div className="sts-ui-product-form-view-traveler__field">
                <CounterField
                  field={form.field.at('child').transform(defaultIfNull(0))}
                  min={childMinValue}
                  max={childMaxValue}
                />
              </div>

              {childValue >= childMaxValue && (
                <div className="sts-ui-product-form-view-traveler__note">
                  <FormattedMessage id="Form.Product.YouCantAddMoreChildren"/>
                </div>
              )}
            </Col>
          )}
        </Row>
      )}

      {showFamilyCardOption && (
        <div className="sts-ui-product-form-view-traveler__confirm">
          <CheckboxField
            field={form.field.at('familyCard').transform(defaultIfNull(false))}
            label={(
              <div className="sts-ui-product-form-view-traveler__confirm-text">
                <FormattedMessage id="Form.Product.IConfirmOneParentIsTravelling"/>

                <div className="sts-ui-product-form-view-traveler__confirm-info">
                  <InfoModal>
                    <FormattedMarkdown id="products/swissPassFamilyCard.md"/>
                  </InfoModal>
                </div>
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
}

import * as C from 'io-ts/Codec';
import { Optional } from 'src/codecs/combinators/Optional';
import { DISCOUNT_CODE } from 'src/codecs/DiscountCode';
import { INTEGER } from 'src/codecs/Integer';
import { TICKET_CLASS } from 'src/codecs/TicketClass';
import { TICKET_DURATION } from 'src/codecs/TicketDuration';
import { TicketValidity, TicketValidityClass, TicketValidityDuration } from 'src/types/TicketValidity';

const TICKET_VALIDITY_CLASS: C.Codec<unknown, unknown, TicketValidityClass> = C.struct({
  value: TICKET_CLASS,
  text: C.string,
});

const TICKET_VALIDITY_DURATION: C.Codec<unknown, unknown, TicketValidityDuration> = C.struct({
  duration: INTEGER,
  text: C.string,
  value: TICKET_DURATION,
});

export const TICKET_VALIDITY: C.Codec<unknown, unknown, TicketValidity> = C.struct({
  class: Optional(C.readonly(C.array(TICKET_VALIDITY_CLASS))),
  duration: Optional(C.readonly(C.array(TICKET_VALIDITY_DURATION))),
  cardType: Optional(C.readonly(C.array(DISCOUNT_CODE))),
});

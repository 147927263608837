import './CheckoutPaymentCancellationFormView.scss';
import { FormController } from '@form-ts/core';
import { Decimal } from 'decimal.js-light';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { CheckboxField } from 'src/forms/fields/CheckboxField';
import { FormError } from 'src/forms/types/FormError';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { CheckoutPaymentFormData } from 'src/types/CheckoutFormData';

type Props = {
  readonly form: FormController<CheckoutPaymentFormData, FormError>;
  readonly price: Decimal;
};

export function CheckoutPaymentCancellationFormView({
  form,
  price,
}: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-payment-cancellation-form-view">
      <div className="sts-ui-checkout-payment-cancellation-form-view__input">
        <CheckboxField
          field={form.field.at('cancellationProtection')}
          label={(
            <FormattedMessage
              id="Form.Checkout.Payment.CancellationProtectionTerms"
              values={{
                link: (
                  <RouteLink route="conditions" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="Form.Checkout.Payment.CancellationProtectionLink"/>
                  </RouteLink>
                ),
              }}
            />
          )}
        />
      </div>
      <div className="sts-ui-checkout-payment-cancellation-form-view__price">
        <FormattedPrice value={price}/>
      </div>
    </div>
  );
}

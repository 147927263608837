import { FormController } from '@form-ts/core';
import { SagaIterator } from 'redux-saga';
import { formSubmitFailure } from 'src/forms/sagas/utils/formSubmitFailure';
import { FormError } from 'src/forms/types/FormError';
import { FormRule } from 'src/forms/types/FormRule';
import { validate } from 'src/forms/utils/validate';
import { call } from 'typed-redux-saga';

export function* formValidate<T>(
  form: FormController<T, FormError>,
  rule: FormRule<T>,
): SagaIterator<boolean> {
  const values = form.values.get(form.currentState);

  const errors = yield* call(validate, values, rule);
  if (Object.keys(errors).length === 0) {
    return true;
  }

  yield* call(formSubmitFailure, form, errors);
  return false;
}

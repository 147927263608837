import './MobileDropdown.scss';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { CURRENCY_LIST } from 'src/constants/currency';
import { useAction } from 'src/hooks/useAction';
import { ROUTE_CURRENCY } from 'src/routing/actions/RouteCurrency';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { Currency } from 'src/types/Currency';

export function MobileDropdownCurrency(): React.ReactElement {
  const currency = useSelector(getCurrentCurrency);
  const changeCurrency = useAction(ROUTE_CURRENCY.trigger);

  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);
  const handleSelect = useCallback((value: Currency) => {
    changeCurrency(value);
  }, [changeCurrency]);

  return (
    <div className="sts-ui-mobile-dropdown" data-state={open ? 'open' : undefined}>
      <button type="button" onClick={handleToggle} className="sts-ui-mobile-dropdown__button">
        <div className="sts-ui-mobile-dropdown__value">
          {currency}
        </div>
        <div className="sts-ui-mobile-dropdown__caret"/>
      </button>
      <ul className="sts-ui-mobile-dropdown__menu">
        {CURRENCY_LIST.map((item) => (
          <li key={item}>
            <button
              type="button"
              className="sts-ui-mobile-dropdown__item"
              onClick={() => handleSelect(item)}
            >
              {item}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

import { push } from 'redux-first-history';
import { SagaIterator } from 'redux-saga';
import { SEARCH_PERFORM } from 'src/actions/SearchActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { getRouteFullPath } from 'src/routing/utils/getRouteFullPath';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { assertNotEmpty } from 'src/utils/assert';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { searchMostSuitableProduct } from 'src/utils/searchMostSuitableProduct';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, put, select } from 'typed-redux-saga';

export function* searchPerformSaga(
  action: GetTriggerActionType<typeof SEARCH_PERFORM>,
): SagaIterator<void> {
  const query = action.data;

  try {
    yield* call(logDebug, 'Performing search…', query);

    const product = assertNotEmpty(
      yield* call(searchMostSuitableProduct, query),
      'Could not find any suitable product',
      { query },
    );

    const language = yield* select(getCurrentLanguage);
    const pathname = yield* call(getRouteFullPath, language, `product:${product}`);

    yield* call(logDebug, 'Performing search… done', product);
    yield* put(push(pathname));
  } catch (error) {
    yield* call(logError, 'Performing search… error', error);
    yield* call(sentryCatch, new RuntimeError('Could not perform search', { query }, NativeError.wrap(error)));
  }
}

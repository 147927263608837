import { SagaIterator } from 'redux-saga';
import { ONE_TRUST_READY } from 'src/actions/OneTrustActions';
import { ONETRUST_DOMAIN_ID } from 'src/constants/application';
import { ONETRUST_LANGUAGES } from 'src/constants/onetrust';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getAffiliateData } from 'src/selectors/getAffiliateData';
import { getOneTrustApi } from 'src/selectors/getOneTrustApi';
import { injectScript } from 'src/utils/injectScript';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, put, select, take } from 'typed-redux-saga';

export function* initOneTrust(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Initializing OneTrust widget…');

    const affiliate = yield* select(getAffiliateData);
    if (affiliate.appThemeSettings.hideCookieBanner) {
      yield* call(logDebug, 'Initializing OneTrust widget… skip');
      return;
    }

    // wait unit the page is rendered
    yield* take(ROUTE_CHANGE.isSuccess);

    // avoid re-initialization that leads to internal error
    const existingApi = yield* select(getOneTrustApi);
    if (existingApi == null) {
      yield* call(injectScript, {
        src: `https://cdn.cookielaw.org/consent/${encodeURIComponent(ONETRUST_DOMAIN_ID)}/OtAutoBlock.js`,
        type: 'text/javascript',
      });
      yield* call(injectScript, {
        'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'type': 'text/javascript',
        'charset': 'UTF-8',
        'data-domain-script': ONETRUST_DOMAIN_ID,
        'data-document-language': 'true',
      });
    }

    yield* call(logDebug, 'Initializing OneTrust widget… done');
    yield* put(ONE_TRUST_READY.trigger());

    // listen and change language of OneTrust widget
    do {
      const prevLanguage = yield* select(getCurrentLanguage);
      yield* take(ROUTE_CHANGE.isSuccess);
      const nextLanguage = yield* select(getCurrentLanguage);

      if (ONETRUST_LANGUAGES[nextLanguage] !== ONETRUST_LANGUAGES[prevLanguage]) {
        yield* call(logDebug, `Changing OneTrust language to "${nextLanguage}"…`);

        const globalApi = yield* select(getOneTrustApi);
        if (globalApi == null) {
          yield* call(logDebug, `Changing OneTrust language to "${nextLanguage}"… skip (API unavailable)`);
          continue;
        }

        yield* call({
          context: globalApi,
          fn: globalApi.changeLanguage,
        }, ONETRUST_LANGUAGES[nextLanguage]);

        yield* call(logDebug, `Changing OneTrust language to "${nextLanguage}"… done`);
      }
    } while (true);
  } catch (error) {
    yield* call(logError, 'Initializing OneTrust widget… error', error);
    yield* call(sentryCatch, new RuntimeError('Could not initialize OneTrust widget', {}, NativeError.wrap(error)));
  }
}

import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, Row } from 'src/components/common/Grid';
import { MenuDropdownCurrency } from 'src/components/common/MenuDropdownCurrency';
import { MenuDropdownLanguage } from 'src/components/common/MenuDropdownLanguage';
import { PaymentTypes } from 'src/components/layout/PaymentTypes';
import { DEFAULT_LOGO } from 'src/constants/logo';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { getAffiliateData } from 'src/selectors/getAffiliateData';

export function PageFooterIframe(): React.ReactElement {
  const affiliate = useSelector(getAffiliateData);
  const hideFooterPartners = affiliate.appThemeSettings.hideFooterPartners ?? false;

  return (
    <div className="sts-ui-page-footer">
      <Row className="sts-ui-page-footer__content">
        <Col xs={12} sm={4} md={4} lg={4} className="sts-ui-page-footer__column">
          <div className="sts-ui-page-footer__menu">
            <div className="sts-ui-page-footer__currency">
              <MenuDropdownCurrency/>
            </div>
            <div className="sts-ui-page-footer__language">
              <MenuDropdownLanguage/>
            </div>
          </div>

          <ul className="sts-ui-page-footer__contact-list">
            <li className="sts-ui-page-footer__contact-item">
              {hideFooterPartners && (
                <FormattedMessage id="SwissPass.Layout.Contact.Href">
                  {(chunks) => (
                    <a
                      href={String(chunks)}
                      data-rel="external"
                      className="sts-ui-page-footer__contact-link"
                    >
                      <FormattedMessage id="SwissPass.Layout.Contact.Button"/>
                    </a>
                  )}
                </FormattedMessage>
              )}
              {!hideFooterPartners && (
                <RouteLink route="contact" className="sts-ui-page-footer__contact-link">
                  <FormattedMessage id="Layout.Footer.ContactsLink"/>
                </RouteLink>
              )}
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} className="sts-ui-page-footer__column">
          <div className="sts-ui-page-footer__title">
            <FormattedMessage id="Layout.Footer.PaymentTypes"/>
          </div>
          <div className="sts-ui-page-footer__payment">
            <PaymentTypes/>
          </div>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} className="sts-ui-page-footer__column">
          {null}
        </Col>
      </Row>
      <Row className="sts-ui-page-footer__bottom">
        <Col xs={12} sm={4} md={4} lg={4} className="sts-ui-page-footer__logo">
          {!hideFooterPartners && (
            <a href="https://switzerlandtravelcentre.com" target="_self">
              <img src={DEFAULT_LOGO} alt="Logo"/>
            </a>
          )}
          <FormattedHTMLMessage
            id="Layout.FooterText"
            values={{ currentYear: (new Date()).getFullYear() }}
          />
        </Col>
        <Col xs={12} sm="auto" md="auto" lg="auto" className="sts-ui-page-footer__navbar">
          <ul className="sts-ui-page-footer__navbar-list">
            <li className="sts-ui-page-footer__navbar-item">
              <RouteLink route="about" className="sts-ui-page-footer__navbar-link">
                <FormattedMessage id="Layout.AboutUs"/>
              </RouteLink>
            </li>
            {!hideFooterPartners && (
              <li className="sts-ui-page-footer__navbar-item">
                <RouteLink route="privacypolicy" className="sts-ui-page-footer__navbar-link">
                  <FormattedMessage id="Layout.PrivacyPolicy"/>
                </RouteLink>
              </li>
            )}
            <li className="sts-ui-page-footer__navbar-item">
              <RouteLink route="gtc" className="sts-ui-page-footer__navbar-link">
                <FormattedMessage id="Layout.GTC"/>
              </RouteLink>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

/* eslint-disable import/order, import/no-duplicates */
import S_TYPE_SWISS_TRAVEL_PASS_1X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS.webp';
import S_TYPE_SWISS_TRAVEL_PASS_2X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS@2x.webp';
import S_TYPE_SWISS_TRAVEL_PASS_3X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS@3x.webp';
import S_TYPE_SWISS_TRAVEL_PASS_FLEX_1X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS_FLEX.webp';
import S_TYPE_SWISS_TRAVEL_PASS_FLEX_2X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS_FLEX@2x.webp';
import S_TYPE_SWISS_TRAVEL_PASS_FLEX_3X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS_FLEX@3x.webp';
import S_TYPE_SWISS_HALF_FARE_CARD_1X from 'src/assets/srcset/overview/S_TYPE_SWISS_HALF_FARE_CARD.webp';
import S_TYPE_SWISS_HALF_FARE_CARD_2X from 'src/assets/srcset/overview/S_TYPE_SWISS_HALF_FARE_CARD@2x.webp';
import S_TYPE_SWISS_HALF_FARE_CARD_3X from 'src/assets/srcset/overview/S_TYPE_SWISS_HALF_FARE_CARD@3x.webp';
import S_TYPE_SWISS_TRAVEL_PASS_PROMO_1X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS_PROMO.webp';
import S_TYPE_SWISS_TRAVEL_PASS_PROMO_2X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS_PROMO@2x.webp';
import S_TYPE_SWISS_TRAVEL_PASS_PROMO_3X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS_PROMO@3x.webp';
import S_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_1X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO.webp';
import S_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_2X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO@2x.webp';
import S_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_3X from 'src/assets/srcset/overview/S_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO@3x.webp';
import S_TYPE_EXCURSION_GORNERGRAT_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_GORNERGRAT.webp';
import S_TYPE_EXCURSION_GORNERGRAT_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_GORNERGRAT@2x.webp';
import S_TYPE_EXCURSION_GORNERGRAT_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_GORNERGRAT@3x.webp';
import S_TYPE_EXCURSION_MATTERHORN_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_MATTERHORN.webp';
import S_TYPE_EXCURSION_MATTERHORN_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_MATTERHORN@2x.webp';
import S_TYPE_EXCURSION_MATTERHORN_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_MATTERHORN@3x.webp';
import S_TYPE_EXCURSION_PILATUS_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_PILATUS.webp';
import S_TYPE_EXCURSION_PILATUS_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_PILATUS@2x.webp';
import S_TYPE_EXCURSION_PILATUS_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_PILATUS@3x.webp';
import S_TYPE_EXCURSION_ROCHERS_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_ROCHERS.webp';
import S_TYPE_EXCURSION_ROCHERS_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_ROCHERS@2x.webp';
import S_TYPE_EXCURSION_ROCHERS_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_ROCHERS@3x.webp';
import S_TYPE_EXCURSION_SCHILTHORN_MURREN_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_SCHILTHORN_MURREN.webp';
import S_TYPE_EXCURSION_SCHILTHORN_MURREN_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_SCHILTHORN_MURREN@2x.webp';
import S_TYPE_EXCURSION_SCHILTHORN_MURREN_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_SCHILTHORN_MURREN@3x.webp';
import S_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_SCHILTHORN_STECHELBERG.webp';
import S_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_SCHILTHORN_STECHELBERG@2x.webp';
import S_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_SCHILTHORN_STECHELBERG@3x.webp';
import S_TYPE_EXCURSION_STANSERHORN_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_STANSERHORN.webp';
import S_TYPE_EXCURSION_STANSERHORN_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_STANSERHORN@2x.webp';
import S_TYPE_EXCURSION_STANSERHORN_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_STANSERHORN@3x.webp';
import S_TYPE_EXCURSION_TITLIS_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_TITLIS.webp';
import S_TYPE_EXCURSION_TITLIS_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_TITLIS@2x.webp';
import S_TYPE_EXCURSION_TITLIS_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_TITLIS@3x.webp';
import S_TYPE_EXCURSION_JUNGFRAUJOCH_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_JUNGFRAUJOCH.webp';
import S_TYPE_EXCURSION_JUNGFRAUJOCH_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_JUNGFRAUJOCH@2x.webp';
import S_TYPE_EXCURSION_JUNGFRAUJOCH_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_JUNGFRAUJOCH@3x.webp';
import S_TYPE_EXCURSION_GLACIER_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_GLACIER.webp';
import S_TYPE_EXCURSION_GLACIER_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_GLACIER@2x.webp';
import S_TYPE_EXCURSION_GLACIER_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_GLACIER@3x.webp';
import S_TYPE_REGIONAL_OBERLAND_PASS_1X from 'src/assets/srcset/overview/S_TYPE_REGIONAL_OBERLAND_PASS.webp';
import S_TYPE_REGIONAL_OBERLAND_PASS_2X from 'src/assets/srcset/overview/S_TYPE_REGIONAL_OBERLAND_PASS@2x.webp';
import S_TYPE_REGIONAL_OBERLAND_PASS_3X from 'src/assets/srcset/overview/S_TYPE_REGIONAL_OBERLAND_PASS@3x.webp';
import S_TYPE_REGIONAL_CENTRAL_PASS_1X from 'src/assets/srcset/overview/S_TYPE_REGIONAL_CENTRAL_PASS.webp';
import S_TYPE_REGIONAL_CENTRAL_PASS_2X from 'src/assets/srcset/overview/S_TYPE_REGIONAL_CENTRAL_PASS@2x.webp';
import S_TYPE_REGIONAL_CENTRAL_PASS_3X from 'src/assets/srcset/overview/S_TYPE_REGIONAL_CENTRAL_PASS@3x.webp';
import W_TYPE_SWISS_TRAVEL_PASS_1X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS.webp';
import W_TYPE_SWISS_TRAVEL_PASS_2X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS@2x.webp';
import W_TYPE_SWISS_TRAVEL_PASS_3X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS@3x.webp';
import W_TYPE_SWISS_TRAVEL_PASS_FLEX_1X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS_FLEX.webp';
import W_TYPE_SWISS_TRAVEL_PASS_FLEX_2X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS_FLEX@2x.webp';
import W_TYPE_SWISS_TRAVEL_PASS_FLEX_3X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS_FLEX@3x.webp';
import W_TYPE_SWISS_HALF_FARE_CARD_1X from 'src/assets/srcset/overview/W_TYPE_SWISS_HALF_FARE_CARD.webp';
import W_TYPE_SWISS_HALF_FARE_CARD_2X from 'src/assets/srcset/overview/W_TYPE_SWISS_HALF_FARE_CARD@2x.webp';
import W_TYPE_SWISS_HALF_FARE_CARD_3X from 'src/assets/srcset/overview/W_TYPE_SWISS_HALF_FARE_CARD@3x.webp';
import W_TYPE_SWISS_TRAVEL_PASS_PROMO_1X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS_PROMO.webp';
import W_TYPE_SWISS_TRAVEL_PASS_PROMO_2X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS_PROMO@2x.webp';
import W_TYPE_SWISS_TRAVEL_PASS_PROMO_3X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS_PROMO@3x.webp';
import W_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_1X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO.webp';
import W_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_2X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO@2x.webp';
import W_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_3X from 'src/assets/srcset/overview/W_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO@3x.webp';
import W_TYPE_EXCURSION_GORNERGRAT_1X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_GORNERGRAT.webp';
import W_TYPE_EXCURSION_GORNERGRAT_2X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_GORNERGRAT@2x.webp';
import W_TYPE_EXCURSION_GORNERGRAT_3X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_GORNERGRAT@3x.webp';
import W_TYPE_EXCURSION_MATTERHORN_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_MATTERHORN.webp';
import W_TYPE_EXCURSION_MATTERHORN_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_MATTERHORN@2x.webp';
import W_TYPE_EXCURSION_MATTERHORN_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_MATTERHORN@3x.webp';
import W_TYPE_EXCURSION_PILATUS_1X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_PILATUS.webp';
import W_TYPE_EXCURSION_PILATUS_2X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_PILATUS@2x.webp';
import W_TYPE_EXCURSION_PILATUS_3X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_PILATUS@3x.webp';
import W_TYPE_EXCURSION_ROCHERS_1X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_ROCHERS.webp';
import W_TYPE_EXCURSION_ROCHERS_2X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_ROCHERS@2x.webp';
import W_TYPE_EXCURSION_ROCHERS_3X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_ROCHERS@3x.webp';
import W_TYPE_EXCURSION_SCHILTHORN_MURREN_1X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_SCHILTHORN_MURREN.webp';
import W_TYPE_EXCURSION_SCHILTHORN_MURREN_2X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_SCHILTHORN_MURREN@2x.webp';
import W_TYPE_EXCURSION_SCHILTHORN_MURREN_3X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_SCHILTHORN_MURREN@3x.webp';
import W_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_1X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_SCHILTHORN_STECHELBERG.webp';
import W_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_2X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_SCHILTHORN_STECHELBERG@2x.webp';
import W_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_3X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_SCHILTHORN_STECHELBERG@3x.webp';
import W_TYPE_EXCURSION_STANSERHORN_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_STANSERHORN.webp';
import W_TYPE_EXCURSION_STANSERHORN_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_STANSERHORN@2x.webp';
import W_TYPE_EXCURSION_STANSERHORN_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_STANSERHORN@3x.webp';
import W_TYPE_EXCURSION_TITLIS_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_TITLIS.webp';
import W_TYPE_EXCURSION_TITLIS_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_TITLIS@2x.webp';
import W_TYPE_EXCURSION_TITLIS_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_TITLIS@3x.webp';
import W_TYPE_EXCURSION_JUNGFRAUJOCH_1X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_JUNGFRAUJOCH.webp';
import W_TYPE_EXCURSION_JUNGFRAUJOCH_2X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_JUNGFRAUJOCH@2x.webp';
import W_TYPE_EXCURSION_JUNGFRAUJOCH_3X from 'src/assets/srcset/overview/W_TYPE_EXCURSION_JUNGFRAUJOCH@3x.webp';
import W_TYPE_EXCURSION_GLACIER_1X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_GLACIER.webp';
import W_TYPE_EXCURSION_GLACIER_2X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_GLACIER@2x.webp';
import W_TYPE_EXCURSION_GLACIER_3X from 'src/assets/srcset/overview/S_TYPE_EXCURSION_GLACIER@3x.webp';
import W_TYPE_REGIONAL_OBERLAND_PASS_1X from 'src/assets/srcset/overview/W_TYPE_REGIONAL_OBERLAND_PASS.webp';
import W_TYPE_REGIONAL_OBERLAND_PASS_2X from 'src/assets/srcset/overview/W_TYPE_REGIONAL_OBERLAND_PASS@2x.webp';
import W_TYPE_REGIONAL_OBERLAND_PASS_3X from 'src/assets/srcset/overview/W_TYPE_REGIONAL_OBERLAND_PASS@3x.webp';
import W_TYPE_REGIONAL_CENTRAL_PASS_1X from 'src/assets/srcset/overview/W_TYPE_REGIONAL_CENTRAL_PASS.webp';
import W_TYPE_REGIONAL_CENTRAL_PASS_2X from 'src/assets/srcset/overview/W_TYPE_REGIONAL_CENTRAL_PASS@2x.webp';
import W_TYPE_REGIONAL_CENTRAL_PASS_3X from 'src/assets/srcset/overview/W_TYPE_REGIONAL_CENTRAL_PASS@3x.webp';
import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { ImageAttributes } from 'src/types/ImageAttributes';
import { ProductCode } from 'src/types/ProductCode';
import { Season } from 'src/types/Season';
import { TicketGroup } from 'src/types/TicketGroup';
import { TicketType } from 'src/types/TicketType';
import { parseImageData } from 'src/utils/image';

export const TICKETS_BY_GROUPS: ReadonlyRecord<TicketGroup, ReadonlyArray<ProductCode>> = {
  [TicketGroup.PUBLIC_TRANSPORT_PASSES]: [
    TicketType.SWISS_TRAVEL_PASS,
    TicketType.SWISS_TRAVEL_PASS_FLEX,
    TicketType.SWISS_HALF_FARE_CARD,
  ],
  [TicketGroup.MOUNTAIN_EXCURSION]: [
    TicketType.EXCURSION_JUNGFRAUJOCH,
    TicketType.EXCURSION_GORNERGRAT,
    TicketType.EXCURSION_MATTERHORN,
    TicketType.EXCURSION_PILATUS,
    TicketType.EXCURSION_ROCHERS,
    TicketType.EXCURSION_SCHILTHORN_MURREN,
    TicketType.EXCURSION_SCHILTHORN_STECHELBERG,
    TicketType.EXCURSION_STANSERHORN,
    TicketType.EXCURSION_TITLIS,
    TicketType.EXCURSION_GLACIER,
  ],
  [TicketGroup.REGIONAL_PASSES]: [
    TicketType.REGIONAL_OBERLAND_PASS,
    TicketType.REGIONAL_CENTRAL_PASS,
  ],
};

export const OVERVIEW_IMAGE: ReadonlyRecord<Season, ReadonlyRecord<TicketType, ImageAttributes>> = {
  [Season.SUMMER]: {
    [TicketType.SWISS_TRAVEL_PASS]: parseImageData(
      S_TYPE_SWISS_TRAVEL_PASS_1X,
      S_TYPE_SWISS_TRAVEL_PASS_2X,
      S_TYPE_SWISS_TRAVEL_PASS_3X,
    ),
    [TicketType.SWISS_TRAVEL_PASS_FLEX]: parseImageData(
      S_TYPE_SWISS_TRAVEL_PASS_FLEX_1X,
      S_TYPE_SWISS_TRAVEL_PASS_FLEX_2X,
      S_TYPE_SWISS_TRAVEL_PASS_FLEX_3X,
    ),
    [TicketType.SWISS_HALF_FARE_CARD]: parseImageData(
      S_TYPE_SWISS_HALF_FARE_CARD_1X,
      S_TYPE_SWISS_HALF_FARE_CARD_2X,
      S_TYPE_SWISS_HALF_FARE_CARD_3X,
    ),

    // FIXME: unsupported product types
    [TicketType.SWISS_TRAVEL_PASS_PROMO]: parseImageData(
      S_TYPE_SWISS_TRAVEL_PASS_PROMO_1X,
      S_TYPE_SWISS_TRAVEL_PASS_PROMO_2X,
      S_TYPE_SWISS_TRAVEL_PASS_PROMO_3X,
    ),
    [TicketType.SWISS_TRAVEL_PASS_FLEX_PROMO]: parseImageData(
      S_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_1X,
      S_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_2X,
      S_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_3X,
    ),

    [TicketType.EXCURSION_GORNERGRAT]: parseImageData(
      S_TYPE_EXCURSION_GORNERGRAT_1X,
      S_TYPE_EXCURSION_GORNERGRAT_2X,
      S_TYPE_EXCURSION_GORNERGRAT_3X,
    ),
    [TicketType.EXCURSION_MATTERHORN]: parseImageData(
      S_TYPE_EXCURSION_MATTERHORN_1X,
      S_TYPE_EXCURSION_MATTERHORN_2X,
      S_TYPE_EXCURSION_MATTERHORN_3X,
    ),
    [TicketType.EXCURSION_PILATUS]: parseImageData(
      S_TYPE_EXCURSION_PILATUS_1X,
      S_TYPE_EXCURSION_PILATUS_2X,
      S_TYPE_EXCURSION_PILATUS_3X,
    ),
    [TicketType.EXCURSION_ROCHERS]: parseImageData(
      S_TYPE_EXCURSION_ROCHERS_1X,
      S_TYPE_EXCURSION_ROCHERS_2X,
      S_TYPE_EXCURSION_ROCHERS_3X,
    ),
    [TicketType.EXCURSION_SCHILTHORN_MURREN]: parseImageData(
      S_TYPE_EXCURSION_SCHILTHORN_MURREN_1X,
      S_TYPE_EXCURSION_SCHILTHORN_MURREN_2X,
      S_TYPE_EXCURSION_SCHILTHORN_MURREN_3X,
    ),
    [TicketType.EXCURSION_SCHILTHORN_STECHELBERG]: parseImageData(
      S_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_1X,
      S_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_2X,
      S_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_3X,
    ),
    [TicketType.EXCURSION_STANSERHORN]: parseImageData(
      S_TYPE_EXCURSION_STANSERHORN_1X,
      S_TYPE_EXCURSION_STANSERHORN_2X,
      S_TYPE_EXCURSION_STANSERHORN_3X,
    ),
    [TicketType.EXCURSION_TITLIS]: parseImageData(
      S_TYPE_EXCURSION_TITLIS_1X,
      S_TYPE_EXCURSION_TITLIS_2X,
      S_TYPE_EXCURSION_TITLIS_3X,
    ),
    [TicketType.EXCURSION_JUNGFRAUJOCH]: parseImageData(
      S_TYPE_EXCURSION_JUNGFRAUJOCH_1X,
      S_TYPE_EXCURSION_JUNGFRAUJOCH_2X,
      S_TYPE_EXCURSION_JUNGFRAUJOCH_3X,
    ),
    [TicketType.EXCURSION_GLACIER]: parseImageData(
      S_TYPE_EXCURSION_GLACIER_1X,
      S_TYPE_EXCURSION_GLACIER_2X,
      S_TYPE_EXCURSION_GLACIER_3X,
    ),

    [TicketType.REGIONAL_OBERLAND_PASS]: parseImageData(
      S_TYPE_REGIONAL_OBERLAND_PASS_1X,
      S_TYPE_REGIONAL_OBERLAND_PASS_2X,
      S_TYPE_REGIONAL_OBERLAND_PASS_3X,
    ),
    [TicketType.REGIONAL_CENTRAL_PASS]: parseImageData(
      S_TYPE_REGIONAL_CENTRAL_PASS_1X,
      S_TYPE_REGIONAL_CENTRAL_PASS_2X,
      S_TYPE_REGIONAL_CENTRAL_PASS_3X,
    ),
  },
  [Season.WINTER]: {
    [TicketType.SWISS_TRAVEL_PASS]: parseImageData(
      W_TYPE_SWISS_TRAVEL_PASS_1X,
      W_TYPE_SWISS_TRAVEL_PASS_2X,
      W_TYPE_SWISS_TRAVEL_PASS_3X,
    ),
    [TicketType.SWISS_TRAVEL_PASS_FLEX]: parseImageData(
      W_TYPE_SWISS_TRAVEL_PASS_FLEX_1X,
      W_TYPE_SWISS_TRAVEL_PASS_FLEX_2X,
      W_TYPE_SWISS_TRAVEL_PASS_FLEX_3X,
    ),
    [TicketType.SWISS_HALF_FARE_CARD]: parseImageData(
      W_TYPE_SWISS_HALF_FARE_CARD_1X,
      W_TYPE_SWISS_HALF_FARE_CARD_2X,
      W_TYPE_SWISS_HALF_FARE_CARD_3X,
    ),

    // FIXME: unsupported product types
    [TicketType.SWISS_TRAVEL_PASS_PROMO]: parseImageData(
      W_TYPE_SWISS_TRAVEL_PASS_PROMO_1X,
      W_TYPE_SWISS_TRAVEL_PASS_PROMO_2X,
      W_TYPE_SWISS_TRAVEL_PASS_PROMO_3X,
    ),
    [TicketType.SWISS_TRAVEL_PASS_FLEX_PROMO]: parseImageData(
      W_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_1X,
      W_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_2X,
      W_TYPE_SWISS_TRAVEL_PASS_FLEX_PROMO_3X,
    ),

    [TicketType.EXCURSION_GORNERGRAT]: parseImageData(
      W_TYPE_EXCURSION_GORNERGRAT_1X,
      W_TYPE_EXCURSION_GORNERGRAT_2X,
      W_TYPE_EXCURSION_GORNERGRAT_3X,
    ),
    [TicketType.EXCURSION_MATTERHORN]: parseImageData(
      W_TYPE_EXCURSION_MATTERHORN_1X,
      W_TYPE_EXCURSION_MATTERHORN_2X,
      W_TYPE_EXCURSION_MATTERHORN_3X,
    ),
    [TicketType.EXCURSION_PILATUS]: parseImageData(
      W_TYPE_EXCURSION_PILATUS_1X,
      W_TYPE_EXCURSION_PILATUS_2X,
      W_TYPE_EXCURSION_PILATUS_3X,
    ),
    [TicketType.EXCURSION_ROCHERS]: parseImageData(
      W_TYPE_EXCURSION_ROCHERS_1X,
      W_TYPE_EXCURSION_ROCHERS_2X,
      W_TYPE_EXCURSION_ROCHERS_3X,
    ),
    [TicketType.EXCURSION_SCHILTHORN_MURREN]: parseImageData(
      W_TYPE_EXCURSION_SCHILTHORN_MURREN_1X,
      W_TYPE_EXCURSION_SCHILTHORN_MURREN_2X,
      W_TYPE_EXCURSION_SCHILTHORN_MURREN_3X,
    ),
    [TicketType.EXCURSION_SCHILTHORN_STECHELBERG]: parseImageData(
      W_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_1X,
      W_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_2X,
      W_TYPE_EXCURSION_SCHILTHORN_STECHELBERG_3X,
    ),
    [TicketType.EXCURSION_STANSERHORN]: parseImageData(
      W_TYPE_EXCURSION_STANSERHORN_1X,
      W_TYPE_EXCURSION_STANSERHORN_2X,
      W_TYPE_EXCURSION_STANSERHORN_3X,
    ),
    [TicketType.EXCURSION_TITLIS]: parseImageData(
      W_TYPE_EXCURSION_TITLIS_1X,
      W_TYPE_EXCURSION_TITLIS_2X,
      W_TYPE_EXCURSION_TITLIS_3X,
    ),
    [TicketType.EXCURSION_JUNGFRAUJOCH]: parseImageData(
      W_TYPE_EXCURSION_JUNGFRAUJOCH_1X,
      W_TYPE_EXCURSION_JUNGFRAUJOCH_2X,
      W_TYPE_EXCURSION_JUNGFRAUJOCH_3X,
    ),
    [TicketType.EXCURSION_GLACIER]: parseImageData(
      W_TYPE_EXCURSION_GLACIER_1X,
      W_TYPE_EXCURSION_GLACIER_2X,
      W_TYPE_EXCURSION_GLACIER_3X,
    ),

    [TicketType.REGIONAL_OBERLAND_PASS]: parseImageData(
      W_TYPE_REGIONAL_OBERLAND_PASS_1X,
      W_TYPE_REGIONAL_OBERLAND_PASS_2X,
      W_TYPE_REGIONAL_OBERLAND_PASS_3X,
    ),
    [TicketType.REGIONAL_CENTRAL_PASS]: parseImageData(
      W_TYPE_REGIONAL_CENTRAL_PASS_1X,
      W_TYPE_REGIONAL_CENTRAL_PASS_2X,
      W_TYPE_REGIONAL_CENTRAL_PASS_3X,
    ),
  },
};

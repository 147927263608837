import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { ProductMap } from 'src/codecs/combinators/ProductMap';
import { TariffMap } from 'src/codecs/combinators/TariffMap';
import { COUNTRY_CODE } from 'src/codecs/CountryCode';
import { CUSTOMER_TITLE } from 'src/codecs/CustomerTitle';
import { DATE_ONLY } from 'src/codecs/DateOnly';
import { INTEGER } from 'src/codecs/Integer';
import { PAYMENT_CODE } from 'src/codecs/PaymentCode';
import { PRODUCT_CODE } from 'src/codecs/ProductCode';
import { TICKET_AGE } from 'src/codecs/TicketAge';
import {
  CheckoutBillingFormData,
  CheckoutFormData,
  CheckoutPaymentFormData,
  CheckoutPersonalFormData,
  CheckoutTravelerFormData,
} from 'src/types/CheckoutFormData';

const CHECKOUT_BILLING_FORM_DATA: C.Codec<unknown, JsonRecord, CheckoutBillingFormData> = C.struct({
  title: C.nullable(CUSTOMER_TITLE),
  firstName: C.string,
  lastName: C.string,
  country: C.nullable(COUNTRY_CODE),
  email: C.string,
});

const CHECKOUT_TRAVELER_FORM_DATA: C.Codec<unknown, JsonRecord, CheckoutTravelerFormData> = C.struct({
  product: PRODUCT_CODE,
  tariff: TICKET_AGE,
  position: INTEGER,

  firstName: C.string,
  lastName: C.string,
  birthDate: C.nullable(DATE_ONLY),

  homeCountryCode: C.nullable(COUNTRY_CODE),
  homeFullCountryCode: C.nullable(COUNTRY_CODE),
});

const CHECKOUT_PERSONAL_FORM_DATA: C.Codec<unknown, JsonRecord, CheckoutPersonalFormData> = C.struct({
  billing: CHECKOUT_BILLING_FORM_DATA,
  travelers: ProductMap(TariffMap(C.readonly(C.array(CHECKOUT_TRAVELER_FORM_DATA)))),
});

const CHECKOUT_PAYMENT_FORM_DATA: C.Codec<unknown, JsonRecord, CheckoutPaymentFormData> = C.struct({
  cancellationProtection: C.boolean,
  paymentType: C.nullable(PAYMENT_CODE),

  agreement: C.boolean,
  optIn: C.boolean,
});

export const CHECKOUT_FORM_DATA: C.Codec<unknown, JsonRecord, CheckoutFormData> = C.struct({
  personal: CHECKOUT_PERSONAL_FORM_DATA,
  payment: CHECKOUT_PAYMENT_FORM_DATA,
});

import { ImageAttributes } from 'src/types/ImageAttributes';

export function parseImageData(
  imgSrc1x: string,
  imgSrc2x: string,
  imgSrc3x: string,
): ImageAttributes {
  return {
    src: imgSrc1x,
    srcSet: [
      `${imgSrc2x} 2x`,
      `${imgSrc2x} 768w`,
      `${imgSrc3x} 3x`,
      `${imgSrc3x} 1024w`,
      `${imgSrc1x} 360w`,
    ].join(', '),
    sizes: [
      '(min-width: 480px) 100vw',
      '(min-width: 768px) 345px',
      '(min-width: 992px) 298px',
      '(min-width: 1200px) 365px',
      '100vw',
    ].join(', '),
  };
}

import { createSelector } from 'reselect';
import { getBasketState } from 'src/selectors/getBasketState';
import { assertNotEmpty } from 'src/utils/assert';

export const getBasketData = createSelector([
  getBasketState,
], (state) => assertNotEmpty(
  state.details,
  'Basket state is not initialized',
  { state },
));

import * as D from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { Language } from 'src/types/Language';
import { Localization } from 'src/types/Localization';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchTranslations(language: Language): SagaIterator<Localization['messages']> {
  try {
    const response = yield* call(makeGetRequest, `${HOST_API}/i18n/ui-swissrailways/${encodeURIComponent(language)}`);
    return yield* call(decodeOrThrow, D.record(D.string), response.content);
  } catch (error) {
    throw new RuntimeError(`Could not fetch "${language}" translations`, {}, NativeError.wrap(error));
  }
}

import { createSelector } from 'reselect';
import { validate } from 'src/forms/utils/validate';
import { getProductFormData } from 'src/selectors/getProductFormData';
import { getProductFormRule } from 'src/selectors/getProductFormRule';

export const getProductInvalid = createSelector([
  getProductFormData,
  getProductFormRule,
], (formData, formRule) => {
  const errors = validate(formData, formRule);
  return Object.keys(errors).length > 0;
});

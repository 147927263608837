import * as C from 'io-ts/Codec';
import { Optional } from 'src/codecs/combinators/Optional';
import { DISCOUNT_CODE } from 'src/codecs/DiscountCode';
import { INTEGER } from 'src/codecs/Integer';
import { TicketFields } from 'src/types/TicketFields';

const TICKET_COUNT_PASSENGERS_VALUE = C.struct({
  adult: Optional(INTEGER),
  youth: Optional(INTEGER),
  child: Optional(INTEGER),
});

const TICKET_COUNT_PASSENGERS = C.struct({
  min: TICKET_COUNT_PASSENGERS_VALUE,
  max: TICKET_COUNT_PASSENGERS_VALUE,
});

const TICKET_ALLOW_AGES = C.struct({
  adult: C.boolean,
  youth: C.boolean,
  child: C.boolean,
});

const TICKET_DISPLAY_FIELDS = C.struct({
  class: C.boolean,
  duration: C.boolean,

  startDate: C.boolean,
  returnDate: C.boolean,

  agreement: C.boolean,
  familyCard: C.boolean,
  cardType: C.readonly(C.array(DISCOUNT_CODE)),
});

export const TICKET_FIELDS: C.Codec<unknown, unknown, TicketFields> = C.struct({
  allowAges: TICKET_ALLOW_AGES,
  displayFields: TICKET_DISPLAY_FIELDS,
  countPassengers: TICKET_COUNT_PASSENGERS,
});

import { SagaIterator } from 'redux-saga';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { ROUTE_CURRENCY } from 'src/routing/actions/RouteCurrency';
import { getCurrentState } from 'src/routing/selectors/getCurrentState';
import { getIsRoutingBlocked } from 'src/routing/selectors/getIsRoutingBlocked';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { put, select } from 'typed-redux-saga';

export function* currencyChangeSaga(
  action: GetTriggerActionType<typeof ROUTE_CURRENCY>,
): SagaIterator<void> {
  const isBlocked = yield* select(getIsRoutingBlocked);
  if (isBlocked) {
    return;
  }

  const state = yield* select(getCurrentState);
  if (!state) {
    return;
  }

  const currency = action.data;
  const location = state.location;

  yield* put(ROUTE_CHANGE.request(undefined, { location, currency }));
}

import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { DATE_ONLY } from 'src/codecs/DateOnly';
import { DISCOUNT_CODE } from 'src/codecs/DiscountCode';
import { INTEGER } from 'src/codecs/Integer';
import { TICKET_CLASS } from 'src/codecs/TicketClass';
import { TICKET_DURATION } from 'src/codecs/TicketDuration';
import { ProductFormData } from 'src/types/ProductFormData';

export const PRODUCT_FORM_DATA: C.Codec<unknown, JsonRecord, ProductFormData> = C.struct({
  startDate: C.nullable(DATE_ONLY),
  returnDate: C.nullable(DATE_ONLY),

  class: C.nullable(TICKET_CLASS),
  duration: C.nullable(TICKET_DURATION),

  adult: C.nullable(INTEGER),
  child: C.nullable(INTEGER),
  youth: C.nullable(INTEGER),

  cardType: C.nullable(DISCOUNT_CODE),
  familyCard: C.nullable(C.boolean),
  agreement: C.nullable(C.boolean),
});

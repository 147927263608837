import './CheckoutPersonalProductFormView.scss';
import { FormController } from '@form-ts/core';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CheckoutPersonalTravelerFormView } from 'src/components/checkout/CheckoutPersonalTravelerFormView';
import { FormattedDate } from 'src/components/common/FormattedDate';
import { Col, Row } from 'src/components/common/Grid';
import { ProductName } from 'src/components/common/ProductName';
import { TARIFF_LIST } from 'src/constants/tariff';
import { FormError } from 'src/forms/types/FormError';
import { CheckoutPersonalFormData } from 'src/types/CheckoutFormData';
import { CheckoutProduct } from 'src/types/CheckoutProduct';
import { CountryCode } from 'src/types/CountryCode';
import { ListOption } from 'src/types/ListOption';
import { isDefined } from 'src/utils/guard';

type Props = {
  readonly form: FormController<CheckoutPersonalFormData, FormError>;
  readonly product: CheckoutProduct;
  readonly ticketNotice: React.ReactElement | null;
  readonly customerCountries: ReadonlyArray<ListOption<CountryCode>>;
  readonly travelerCountries: ReadonlyArray<ListOption<CountryCode>>;
};

export function CheckoutPersonalProductFormView({
  form,
  product,
  ticketNotice,
  customerCountries,
  travelerCountries,
}: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-personal-product-form-view">
      <div className="sts-ui-checkout-personal-product-form-view__header">
        <div className="sts-ui-checkout-personal-product-form-view__title">
          <ProductName type={product.code}/>
        </div>
        <div className="sts-ui-checkout-personal-product-form-view__info">
          {product.form.startDate && (
            <div className="sts-ui-checkout-personal-product-form-view__column">
              <FormattedDate value={product.form.startDate}/>
            </div>
          )}
          {product.form.duration && (
            <div className="sts-ui-checkout-personal-product-form-view__column">
              <FormattedMessage
                id="Form.Checkout.BillingAddress.Period"
                values={{ PRODUCT: product.code, DURATION: product.form.duration.replace(/\D/g, '') }}
              />
            </div>
          )}
          {product.form.class && (
            <div className="sts-ui-checkout-personal-product-form-view__column">
              <FormattedHTMLMessage
                id="Form.Checkout.BillingAddress.Class"
                values={{ class: product.form.class }}
              />
            </div>
          )}
        </div>
      </div>

      {TARIFF_LIST.map((tariff) => {
        const count = product.form[tariff] ?? 0;
        return Array.from({ length: count }, (_, index) => (
          <div
            key={`${tariff}-${index}`}
            className="sts-ui-checkout-personal-product-form-view__traveler"
          >
            <CheckoutPersonalTravelerFormView
              field={(
                form.field
                  .at('travelers')
                  .at(product.code)
                  .guard(isDefined)
                  .at(tariff)
                  .guard(isDefined)
                  .at(index)
                  .guard(isDefined)
              )}
              product={product}
              tariff={tariff}
              customerCountries={customerCountries}
              travelerCountries={travelerCountries}
            />
          </div>
        ));
      }).flat(1)}

      {ticketNotice && (
        <div className="sts-ui-checkout-personal-product-form-view__notice">
          <Row>
            <Col xs={12} sm={2} md={3} lg={3}>
              {null}
            </Col>
            <Col xs={12} sm={10} md={5} lg={5}>
              {ticketNotice}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

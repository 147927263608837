import { createSelector } from 'reselect';
import { ROUTING_LANGUAGES } from 'src/routing/constants/RoutingLanguages';
import {
  ROUTING_INTERNAL_PAGES,
  ROUTING_PRIVATE_PAGES,
  ROUTING_PUBLIC_PAGES,
} from 'src/routing/constants/RoutingPages';
import { getCurrentState } from 'src/routing/selectors/getCurrentState';
import { RouteInfo } from 'src/routing/types/RouteInfo';
import { RouteName } from 'src/routing/types/RouteName';
import { assertNever } from 'src/utils/assert';

export const getCurrentRouteInfo = createSelector([
  getCurrentState,
], (state): RouteInfo => {
  if (state === null) {
    return assertNever('Router has no entered state', { state });
  }

  for (const language of ROUTING_LANGUAGES) {
    for (const routeName of ALL_ROUTE_NAMES) {
      if (state.match.state.name === `sts:ui:${routeName}:${language}`) {
        return {
          language: language,
          routeName: routeName,
          currency: state.currency,
          location: state.location,
        };
      }
    }
  }

  return assertNever(`Current location "${state.location.pathname}" doesn't match any public route`, { state });
});

const ALL_ROUTE_NAMES: ReadonlyArray<RouteName> = [
  ...ROUTING_INTERNAL_PAGES,
  ...ROUTING_PRIVATE_PAGES,
  ...ROUTING_PUBLIC_PAGES,
];

import ageGroupsTranslations from 'src/translations/ageGroupsTranslations.json';
import faqData from 'src/translations/faq.json';
import productAttributes from 'src/translations/productAttributes.json';
import productsDiscount from 'src/translations/productsDiscount.json';
import seoConfig from 'src/translations/seo.json';
import travelFreeAges from 'src/translations/travelFreeAges.json';
import { ConfigState } from 'src/types/ConfigState';

export const PRODUCT_DISCOUNT_NAMES: ConfigState['productsDiscounts'] = productsDiscount;

export const AGE_GROUPS_TRANSLATIONS: ConfigState['ageGroupsTranslations'] = ageGroupsTranslations;

export const SEO_CONFIG: ConfigState['seoConfig'] = seoConfig;

export const FAQ_DATA: ConfigState['faqData'] = faqData;

// @ts-expect-error https://github.com/microsoft/TypeScript/issues/31920
export const PRODUCT_ATTRIBUTES: ConfigState['productAttributes'] = productAttributes;

// @ts-expect-error https://github.com/microsoft/TypeScript/issues/31920
export const TRAVEL_FREE_AGES: ConfigState['travelFreeAges'] = travelFreeAges;

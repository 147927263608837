import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { ROUTING_STATES } from 'src/routing/constants/RoutingStates';
import { RouteNotFoundError } from 'src/routing/errors/RouteNotFoundError';
import { createNotFoundMatch } from 'src/routing/sagas/utils/createNotFoundMatch';
import { createTransitionUid } from 'src/routing/sagas/utils/createTransitionUid';
import { handleTransitionError } from 'src/routing/sagas/utils/handleTransitionError';
import { performPostTransition, performPreTransition } from 'src/routing/sagas/utils/performTransition';
import { syncTransitionTarget } from 'src/routing/sagas/utils/syncTransitionTarget';
import { getCurrentState } from 'src/routing/selectors/getCurrentState';
import { TransitionTarget } from 'src/routing/types/TransitionTarget';
import { matchState } from 'src/routing/utils/matchState';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, put, select } from 'typed-redux-saga';

export function* routeChangeSaga(
  { meta }: GetRequestActionType<typeof ROUTE_CHANGE>,
): SagaIterator<void> {
  let match = yield* call(matchState, ROUTING_STATES, meta.location.pathname);
  if (!match) {
    const error = new RouteNotFoundError(meta.location);
    yield* call(sentryCatch, error, 'warning');

    match = yield* call(createNotFoundMatch, meta);
  }

  const from = yield* select(getCurrentState);
  const to: TransitionTarget = {
    uid: yield* call(createTransitionUid),
    match: match,
    location: meta.location,
    currency: meta.currency,
  };

  try {
    yield* put(ROUTE_CHANGE.pending(meta));
    yield* call(performPreTransition, { to, from });
    yield* put(ROUTE_CHANGE.success(to, meta));
    yield* call(performPostTransition, { to, from });

    yield* call(syncTransitionTarget, to);
  } catch (error) {
    const wrapped = NativeError.wrap(error);

    yield* call(handleTransitionError, { to, from }, wrapped);
    yield* put(ROUTE_CHANGE.failure(wrapped, meta));
  }
}

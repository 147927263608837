import './Grid.scss';
import classNames from 'classnames';
import React from 'react';

type Width =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

type RowProps = {
  readonly className?: string;
  readonly children: React.ReactNode;
};

export function Row({ className, children }: RowProps): React.ReactElement {
  return (
    <div className={classNames(
      className,
      'sts-ui-row',
    )}
    >
      {children}
    </div>
  );
}

type ColProps = {
  readonly lg: Width | 'auto';
  readonly md: Width | 'auto';
  readonly sm: Width | 'auto';
  readonly xs: Width | 'auto';

  readonly className?: string;
  readonly children: React.ReactNode;
};

export function Col({ lg, md, sm, xs, className, children }: ColProps): React.ReactElement {
  return (
    <div className={classNames(
      className,
      'sts-ui-col',
      `sts-ui-col--lg-${lg}`,
      `sts-ui-col--md-${md}`,
      `sts-ui-col--sm-${sm}`,
      `sts-ui-col--xs-${xs}`,
    )}
    >
      {children}
    </div>
  );
}

import { pipe } from 'fp-ts/function';
import { Lens } from 'monocle-ts/Lens';
import { memoize } from 'src/utils/memoize';

const STRICT_VOID = (): boolean => true;
const STRICT_EQUALS = <A>(a: A, b: A): boolean => a === b;

export const ensureNonNull = pipe(<T>(): Lens<T, T | null> => ({
  get: (value) => value,
  set: (value) => (state) => value ?? state,
}), memoize({ equals: STRICT_VOID }));

export const defaultIfNull = pipe(<T>(ifnull: T): Lens<T | null, T> => ({
  get: (value) => value ?? ifnull,
  set: (value) => () => value,
}), memoize({ equals: STRICT_EQUALS }));

export const selectIfEqual = pipe(<T>(equal: T): Lens<T | null, boolean> => ({
  get: (value) => value === equal,
  set: (value) => () => (value ? equal : null),
}), memoize({ equals: STRICT_EQUALS }));

export const invariantValue = pipe(<S, V>(value: V): Lens<S, V> => ({
  get: () => value,
  set: () => (state) => state,
}), memoize({ equals: STRICT_EQUALS }));

import { Either, left, right } from 'fp-ts/Either';
import { createSelector } from 'reselect';
import { TARIFF_LIST } from 'src/constants/tariff';
import { getProductFields } from 'src/selectors/getProductFields';
import { getProductFormData } from 'src/selectors/getProductFormData';
import { getProductTicketPrice } from 'src/selectors/getProductTicketPrice';
import { ProductPriceErrorCode } from 'src/types/ProductPriceErrorCode';
import { ProductPriceList } from 'src/types/ProductPriceList';
import { mapProductPriceList } from 'src/utils/product/mapProductPriceList';

export const getProductPrice = createSelector([
  getProductFields,
  getProductFormData,
  getProductTicketPrice,
], (fields, formData, ticketPrice): Either<ProductPriceErrorCode, ProductPriceList> => {
  if (fields.displayFields.duration && formData.duration === null) {
    return left(ProductPriceErrorCode.INVALID_DURATION);
  }
  if (TARIFF_LIST.every((tariff) => !formData[tariff])) {
    return left(ProductPriceErrorCode.NO_TRAVELER);
  }
  if (ticketPrice.length === 0) {
    return left(ProductPriceErrorCode.NO_TICKET_AVAILABLE);
  }

  return right(mapProductPriceList(ticketPrice));
});

import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { Language } from 'src/types/Language';

export const ONETRUST_LANGUAGES: ReadonlyRecord<Language, string> = {
  'en': 'en',
  'de': 'de-ch',
  'it': 'it-ch',
  'es': 'es',
  'fr': 'fr-ch',
  'nl': 'nl',
  'ja': 'ja',
  'ko': 'ko',
  'zh-Hans': 'zh-hans',
  'zh-Hant': 'zh-hant',
};

import { ROUTING_LAYOUT_STATES } from 'src/routing/states/LayoutStates';
import { ROUTING_PRIVATE_STATES } from 'src/routing/states/PrivateStates';
import { ROUTING_PUBLIC_STATES } from 'src/routing/states/PublicStates';
import { ROUTING_SYSTEM_STATES } from 'src/routing/states/SystemStates';
import { RouteState } from 'src/routing/types/RouteState';

export const ROUTING_STATES: ReadonlyArray<RouteState> = [
  ...ROUTING_LAYOUT_STATES,
  ...ROUTING_PUBLIC_STATES,
  ...ROUTING_PRIVATE_STATES,
  ...ROUTING_SYSTEM_STATES,
];

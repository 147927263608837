import { BasketProduct } from 'src/types/BasketProduct';
import { ProductCode } from 'src/types/ProductCode';
import { ProductMap } from 'src/types/ProductMap';
import { TicketClass } from 'src/types/TicketClass';

export function upgradeBasketProduct(
  productType: ProductCode,
  basketProducts: ProductMap<BasketProduct>,
): ProductMap<BasketProduct> {
  const currentProduct = basketProducts[productType];
  if (!currentProduct) {
    return basketProducts;
  }

  const updatedProduct: BasketProduct = {
    ...currentProduct,
    formData: {
      ...currentProduct.formData,
      class: TicketClass.FIRST,
    },
  };
  return {
    ...basketProducts,
    [productType]: updatedProduct,
  };
}

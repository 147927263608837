import { LogicError } from 'src/errors/LogicError';
import { ROUTING_SITEMAP } from 'src/routing/constants/RoutingSitemap';
import { Language } from 'src/types/Language';

export function getRouteStatePath(
  lang: Language,
  name: keyof typeof ROUTING_SITEMAP,
  base: keyof typeof ROUTING_SITEMAP | null,
): string {
  const fullPath = ROUTING_SITEMAP[name][lang];
  if (base === null) {
    return fullPath;
  }

  const basePath = ROUTING_SITEMAP[base][lang];
  if (!fullPath.startsWith(basePath)) {
    throw new LogicError(`The route "${name}" is not a child of "${base}"`);
  }

  return fullPath.substring(basePath.length);
}

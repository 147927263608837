import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

const DECODER: D.Decoder<string, Json> = {
  decode: (input) => {
    try {
      return D.success(JSON.parse(input));
    } catch {
      return D.failure(input, 'JSON');
    }
  },
};
const ENCODER: E.Encoder<string, Json> = {
  encode: (value) => JSON.stringify(value),
};

export const JSON_STRING: C.Codec<string, string, Json> = C.make(DECODER, ENCODER);

import { array } from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { PAYMENT_TYPE } from 'src/codecs/PaymentType';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { ConfigDynamic } from 'src/types/ConfigDynamic';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchPaymentTypes(): SagaIterator<ConfigDynamic['paymentTypes']> {
  try {
    const response = yield* call(makeGetRequest, `${HOST_API}/payment-types-all`);
    return yield* call(decodeOrThrow, array(PAYMENT_TYPE), response.content);
  } catch (error) {
    throw new RuntimeError('Could not fetch payment types', {}, NativeError.wrap(error));
  }
}

import { SagaIterator } from 'redux-saga';
import { HttpError } from 'src/errors/HttpError';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { QueryRecord } from 'src/types/Query';
import { encodeQuery } from 'src/utils/query';
import { call } from 'typed-redux-saga';

export function* makeGetRequest(
  url: string,
  params?: QueryRecord,
): SagaIterator<{ content: unknown; headers: Headers }> {
  try {
    const fullUrl = new URL(url);

    if (params != null) {
      const search = yield* call(encodeQuery, params);
      fullUrl.search = search.toString();
    }

    const request: RequestInit = {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    };

    const response = yield* call(fetch, fullUrl, request);
    if (!response.ok) {
      throw new HttpError(response);
    }

    const content: unknown = yield* call({
      fn: response.json,
      context: response,
    });
    return {
      content: content,
      headers: response.headers,
    };
  } catch (error) {
    throw new RuntimeError(
      `Could not perform "GET ${url}" request`,
      { url, params },
      NativeError.wrap(error),
    );
  }
}

export function* makePostRequest(
  url: string,
  body: QueryRecord,
): SagaIterator<{ content: unknown; headers: Headers }> {
  try {
    const request: RequestInit = {
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      body: yield* call(encodeQuery, body),
    };

    const response = yield* call(fetch, url, request);
    if (!response.ok) {
      throw new HttpError(response);
    }

    const content: unknown = yield* call({
      fn: response.json,
      context: response,
    });
    return {
      content: content,
      headers: response.headers,
    };
  } catch (error) {
    throw new RuntimeError(
      `Could not perform "POST ${url}" request`,
      { url, body },
      NativeError.wrap(error),
    );
  }
}

import { LocationDescriptorObject } from 'history';
import { SagaIterator } from 'redux-saga';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { Location } from 'src/routing/types/Location';
import { logDebug } from 'src/sagas/utils/logging';
import { Currency } from 'src/types/Currency';
import { call, put } from 'typed-redux-saga';

export function* redirectTo(location: LocationDescriptorObject, currency: Currency): SagaIterator<void> {
  yield* call(logDebug, 'ROUTER. Redirecting to', { location, currency });
  yield* put(ROUTE_CHANGE.request(undefined, { location: resolveLocation(location), currency: currency }));
}

function resolveLocation(location: LocationDescriptorObject): Location {
  return {
    key: location.key,
    state: location.state,

    pathname: location.pathname ?? '/',
    search: location.search ?? '',
    hash: location.hash ?? '',
  };
}

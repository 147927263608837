import { BasketProductRequest } from 'src/types/BasketProductRequest';
import { ProductFormData } from 'src/types/ProductFormData';
import { TicketAge } from 'src/types/TicketAge';

export function mapBasketToApi(product: ProductFormData): BasketProductRequest[] {
  return TARIFF_LIST.map((tariff) => {
    const count = product[tariff];
    if (count == null || count < 1) {
      return [];
    }

    return Array.from({ length: count }, (): BasketProductRequest => ({
      tariff: tariff,

      startDate: product.startDate,
      returnDate: product.returnDate,

      class: product.class,
      duration: product.duration,

      cardType: product.cardType,
      familyCard: tariff === TicketAge.CHILD ? product.familyCard : null,
    }));
  }).flat(1);
}

const TARIFF_LIST = [
  TicketAge.ADULT,
  TicketAge.YOUTH,
  TicketAge.CHILD,
] as const;

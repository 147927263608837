import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { CURRENCY } from 'src/codecs/Currency';
import { INTEGER } from 'src/codecs/Integer';
import { ORDER_AMOUNT } from 'src/codecs/OrderAmount';
import { CheckoutOrder, CheckoutOrderData } from 'src/types/CheckoutOrder';

const CHECKOUT_ORDER_DATA: C.Codec<unknown, JsonRecord, CheckoutOrderData> = C.struct({
  refno: C.string,
  amount: ORDER_AMOUNT,
  currency: CURRENCY,
  affiliateId: INTEGER,
});

export const CHECKOUT_ORDER: C.Codec<unknown, JsonRecord, CheckoutOrder> = C.struct({
  url: C.string,
  data: CHECKOUT_ORDER_DATA,
});

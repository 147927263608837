import * as C from 'io-ts/Codec';
import { SagaIterator } from 'redux-saga';
import { BASKET_STORE } from 'src/codecs/BasketStore';
import { APP_VERSION } from 'src/constants/version';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageGet, storageSet } from 'src/sagas/utils/storage';
import { BasketStore } from 'src/types/BasketStore';
import { decodeOrGet } from 'src/utils/decodeOrGet';
import { call } from 'typed-redux-saga';

export function* loadBasketStore(): SagaIterator<BasketStore | null> {
  try {
    yield* call(logDebug, 'Loading basket store…');

    const storage = yield* call(storageGet, STORAGE_KEY);
    const decoded = yield* call(decodeOrGet, STORAGE_VAL, storage, null);

    yield* call(logDebug, 'Loading basket store… done', decoded);
    return decoded?.content ?? null;
  } catch (error) {
    yield* call(logError, 'Loading basket store… error', error);
    throw new RuntimeError('Could not load basket store', {}, NativeError.wrap(error));
  }
}

export function* saveBasketStore(store: BasketStore | null): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Saving basket store…', store);

    const encoded = store === null
      ? undefined
      : yield* call(STORAGE_VAL.encode, { version: APP_VERSION, content: store });
    yield* call(storageSet, STORAGE_KEY, encoded);

    yield* call(logDebug, 'Saving basket store… done');
  } catch (error) {
    yield* call(logError, 'Saving basket store… error', error);
    throw new RuntimeError('Could not save basket stor', {}, NativeError.wrap(error));
  }
}

const STORAGE_KEY = 'sts.basket';
const STORAGE_VAL = C.struct({
  version: C.literal(APP_VERSION),
  content: BASKET_STORE,
});

import { FormController } from '@form-ts/core';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CHECKOUT_PERSONAL_CHANGE, CHECKOUT_PERSONAL_SUBMIT } from 'src/actions/CheckoutActions';
import { CheckoutPersonalScreen } from 'src/components/checkout/CheckoutPersonalScreen';
import { FormError } from 'src/forms/types/FormError';
import { useAction } from 'src/hooks/useAction';
import { getCheckoutCustomerCountryList } from 'src/selectors/getCheckoutCustomerCountryList';
import { getCheckoutCustomerTitleList } from 'src/selectors/getCheckoutCustomerTitleList';
import { getCheckoutFormData } from 'src/selectors/getCheckoutFormData';
import { getCheckoutLoading } from 'src/selectors/getCheckoutLoading';
import { getCheckoutPersonalFormRule } from 'src/selectors/getCheckoutPersonalFormRule';
import { getCheckoutProductList } from 'src/selectors/getCheckoutProductList';
import { getCheckoutTravelerCountryList } from 'src/selectors/getCheckoutTravelerCountryList';
import { CheckoutPersonalFormData } from 'src/types/CheckoutFormData';

export function CheckoutPersonalPage(): React.ReactElement {
  const loading = useSelector(getCheckoutLoading);
  const formData = useSelector(getCheckoutFormData);
  const formRule = useSelector(getCheckoutPersonalFormRule);
  const products = useSelector(getCheckoutProductList);

  const customerTitles = useSelector(getCheckoutCustomerTitleList);
  const customerCountries = useSelector(getCheckoutCustomerCountryList);
  const travelerCountries = useSelector(getCheckoutTravelerCountryList);

  const changeForm = useAction(CHECKOUT_PERSONAL_CHANGE.request);
  const handleChange = useCallback((form: FormController<CheckoutPersonalFormData, FormError>) => {
    changeForm(form);
  }, [changeForm]);

  const submitForm = useAction(CHECKOUT_PERSONAL_SUBMIT.request);
  const handleSubmit = useCallback((form: FormController<CheckoutPersonalFormData, FormError>) => {
    submitForm(form);
  }, [submitForm]);

  return (
    <CheckoutPersonalScreen
      loading={loading}
      formData={formData.personal}
      formRule={formRule}
      products={products}
      customerTitles={customerTitles}
      customerCountries={customerCountries}
      travelerCountries={travelerCountries}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
}

import './Counter.scss';
import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';
import { Integer } from 'src/types/Integer';

type Props = {
  readonly id: string;
  readonly name: string;
  readonly value: Integer;
  readonly onBlur?: () => void;
  readonly onChange: (value: Integer) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly min: Integer;
  readonly max: Integer;
};

export function Counter({
  id,
  name,
  value,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  min,
  max,
}: Props): React.ReactElement {
  const handleIncrement = useCallback(() => {
    if (value >= max) {
      return;
    }

    onChange(value + 1);
  }, [value, max, onChange]);

  const handleDecrement = useCallback(() => {
    if (value <= min) {
      return;
    }

    onChange(value - 1);
  }, [value, min, onChange]);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  return (
    <div className="sts-ui-form-counter" data-invalid={invalid}>
      <button
        type="button"
        className="sts-ui-form-counter__button"
        onClick={handleDecrement}
        onBlur={onBlur}
        disabled={disabled || value <= min}
      >
        <div className="sts-ui-form-counter__sign">-</div>
      </button>

      <input
        ref={inputRef}
        id={id}
        name={name}
        type="text"
        value={value.toString()}
        readOnly={true}
        className={classNames(
          'sts-ui-form-counter__value',
          value !== 0 ? 'sts-ui-form-counter__value--active' : null,
        )}
      />

      <button
        type="button"
        className="sts-ui-form-counter__button"
        onClick={handleIncrement}
        onBlur={onBlur}
        disabled={disabled || value >= max}
      >
        <div className="sts-ui-form-counter__sign">+</div>
      </button>
    </div>
  );
}

import './CheckoutPaymentTicketDetailsFormView.scss';
import React from 'react';
import { CheckoutPaymentTicketPriceList } from 'src/components/checkout/CheckoutPaymentTicketPriceList';
import { CheckoutPaymentTicketTraveler } from 'src/components/checkout/CheckoutPaymentTicketTraveler';
import { TARIFF_LIST } from 'src/constants/tariff';
import { CheckoutPersonalFormData } from 'src/types/CheckoutFormData';
import { CheckoutProduct } from 'src/types/CheckoutProduct';
import { CheckoutTotal } from 'src/types/CheckoutTotal';

type Props = {
  readonly price: CheckoutTotal;
  readonly personal: CheckoutPersonalFormData;
  readonly products: ReadonlyArray<CheckoutProduct>;
};

export function CheckoutPaymentTicketDetailsFormView({
  price,
  products,
  personal,
}: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-payment-ticket-details-form-view">
      <div className="sts-ui-checkout-payment-ticket-details-form-view__traveler-list">
        {products.map((product) => TARIFF_LIST.map((tariff) => {
          const travelers = personal.travelers[product.code]?.[tariff];
          if (!travelers) {
            return null;
          }

          return travelers.map((traveler) => (
            <div
              key={`${traveler.product}-${traveler.tariff}-${traveler.position}`}
              className="sts-ui-checkout-payment-ticket-details-form-view__traveler-item"
            >
              <CheckoutPaymentTicketTraveler
                traveler={traveler}
              />
            </div>
          ));
        }))}
      </div>

      <div className="sts-ui-checkout-payment-ticket-details-form-view__ticket-list">
        {products.map((product) => {
          const tickets = price.ticketPrices[product.code];
          if (!tickets) {
            return null;
          }

          return (
            <div
              key={product.code}
              className="sts-ui-checkout-payment-ticket-details-form-view__ticket-item"
            >
              <CheckoutPaymentTicketPriceList
                product={product}
                tickets={tickets}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

import './ProductFormViewTripDate.scss';
import { FormController } from '@form-ts/core';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMarkdown } from 'src/components/common/FormattedMarkdown';
import { Col, Row } from 'src/components/common/Grid';
import { InfoModal } from 'src/components/common/InfoModal';
import { Typography } from 'src/components/common/Typography';
import { DatePickerField } from 'src/forms/fields/DatePickerField';
import { FormError } from 'src/forms/types/FormError';
import { ProductFormData } from 'src/types/ProductFormData';
import { TicketFields } from 'src/types/TicketFields';
import { TicketPricePeriod } from 'src/types/TicketPricePeriod';

type Props = {
  readonly form: FormController<ProductFormData, FormError>;
  readonly fields: TicketFields;
  readonly dates: ReadonlyArray<TicketPricePeriod>;
};

export function ProductFormViewTripDate({ form, fields, dates }: Props): React.ReactElement | null {
  const disabledStartDate = useCallback((date: Date) => {
    return dates.every((period) => (
      date < period.startDate ||
      date > period.endDate
    ));
  }, [dates]);

  const displayFields = fields.displayFields;
  if (!displayFields.startDate && !displayFields.returnDate) {
    return null;
  }

  return (
    <div className="sts-ui-product-form-view-trip-date">
      <Row>
        {displayFields.startDate && (
          <Col xs={12} sm={4} md={4} lg={3} className="sts-ui-product-form-view-trip-date__group">
            <div className="sts-ui-product-form-view-trip-date__label">
              <Typography as="label" variant="form-label">
                <FormattedMessage id="Form.Product.StartDate"/>
              </Typography>
            </div>
            <div className="sts-ui-product-form-view-trip-date__field">
              <DatePickerField
                field={form.field.at('startDate')}
                disabled={dates.length === 0}
                disabledDate={disabledStartDate}
              />
            </div>
          </Col>
        )}
        {displayFields.returnDate && (
          <Col xs={12} sm={4} md={4} lg={3} className="sts-ui-product-form-view-trip-date__group">
            <div className="sts-ui-product-form-view-trip-date__label">
              <Typography as="label" variant="form-label">
                <FormattedMessage id="Form.Product.ReturnDate"/>
              </Typography>

              <div className="sts-ui-product-form-view-trip-date__info">
                <InfoModal>
                  <FormattedMarkdown id="products/returnDate.md"/>
                </InfoModal>
              </div>
            </div>
            <div className="sts-ui-product-form-view-trip-date__field">
              <DatePickerField
                field={form.field.at('returnDate')}
                disabled={true}
              />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}

import { LocationChangeAction } from 'redux-first-history';
import { SagaIterator } from 'redux-saga';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getCurrentState } from 'src/routing/selectors/getCurrentState';
import { getIsRoutingBlocked } from 'src/routing/selectors/getIsRoutingBlocked';
import { getBasketCurrency } from 'src/selectors/getBasketCurrency';
import { put, select } from 'typed-redux-saga';

export function* locationChangeSaga(action: LocationChangeAction): SagaIterator<void> {
  const isBlocked = yield* select(getIsRoutingBlocked);
  if (isBlocked) {
    return;
  }

  const state = yield* select(getCurrentState);

  const location = action.payload.location;
  const currency = state
    ? yield* select(getCurrentCurrency)
    : yield* select(getBasketCurrency);

  yield* put(ROUTE_CHANGE.request(undefined, { location, currency }));
}

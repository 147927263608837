import { Ord } from 'fp-ts/number';
import { TARIFF_LIMITS, TARIFF_LIST } from 'src/constants/tariff';
import { FormRule } from 'src/forms/types/FormRule';
import * as R from 'src/forms/utils/rules';
import { ProductFormData } from 'src/types/ProductFormData';
import { TicketAge } from 'src/types/TicketAge';
import { TicketFields } from 'src/types/TicketFields';
import { TicketPricePeriod } from 'src/types/TicketPricePeriod';
import { TicketValidity } from 'src/types/TicketValidity';

export function createProductFormRule(
  productFields: TicketFields,
  productValidity: TicketValidity,
  productPricePeriods: ReadonlyArray<TicketPricePeriod>,
): FormRule<ProductFormData> {
  const validCardType = productValidity.cardType ?? productFields.displayFields.cardType;
  const validClass = productValidity.class?.map((it) => it.value) ?? [];
  const validDuration = productValidity.duration?.map((it) => it.value) ?? [];

  const formRule = R.struct<ProductFormData>({
    startDate: !productFields.displayFields.startDate
      ? R.pass()
      : R.allOf([
        R.required(),
        R.nullable(R.allOf([
          R.date(),
          R.check((startDate) => productPricePeriods.some((period) => (
            startDate >= period.startDate &&
            startDate <= period.endDate
          ))),
        ])),
      ]),
    returnDate: !productFields.displayFields.returnDate
      ? R.pass()
      : R.allOf([
        R.required(),
        R.nullable(R.date()),
      ]),

    class: !productFields.displayFields.class
      ? R.pass()
      : R.allOf([
        R.required(),
        R.nullable(R.oneOf(validClass)),
      ]),
    duration: !productFields.displayFields.duration
      ? R.pass()
      : R.allOf([
        R.required(),
        R.nullable(R.oneOf(validDuration)),
      ]),

    adult: !productFields.allowAges.adult
      ? R.pass()
      : R.allOf([
        R.required(),
        R.nullable(R.allOf([
          R.gte(productFields.countPassengers.min.adult ?? 0, Ord),
          R.lte(productFields.countPassengers.max.adult ?? TARIFF_LIMITS[TicketAge.ADULT], Ord),
        ])),
      ]),
    child: !productFields.allowAges.child
      ? R.pass()
      : R.allOf([
        R.required(),
        R.nullable(R.allOf([
          R.gte(productFields.countPassengers.min.child ?? 0, Ord),
          R.lte(productFields.countPassengers.max.child ?? TARIFF_LIMITS[TicketAge.CHILD], Ord),
        ])),
      ]),
    youth: !productFields.allowAges.youth
      ? R.pass()
      : R.allOf([
        R.required(),
        R.nullable(R.allOf([
          R.gte(productFields.countPassengers.min.youth ?? 0, Ord),
          R.lte(productFields.countPassengers.max.youth ?? TARIFF_LIMITS[TicketAge.YOUTH], Ord),
        ])),
      ]),

    agreement: !productFields.displayFields.agreement
      ? R.pass()
      : R.allOf([
        R.required(),
        R.nullable(R.equals(true)),
      ]),

    cardType: validCardType.length === 0
      ? R.pass()
      : R.nullable(R.oneOf(validCardType)),
    familyCard: !productFields.displayFields.familyCard
      ? R.pass()
      : R.required(),
  });

  return R.allOf([
    formRule,
    travelerCount(productFields),
  ]);
}

function travelerCount(
  productFields: TicketFields,
): FormRule<ProductFormData> {
  return (formData) => {
    const totalTravelerCount = TARIFF_LIST
      .filter((tariff) => productFields.allowAges[tariff])
      .map((tariff) => formData[tariff] ?? 0)
      .reduce((sum, count) => sum + count, 0);
    if (totalTravelerCount > 0) {
      return [];
    }

    return TARIFF_LIST
      .filter((tariff) => productFields.allowAges[tariff])
      .map((tariff) => ({
        code: 'invalid',
        path: tariff,
        value: formData[tariff],

        message: 'No traveler selected',
        context: {},
      }));
  };
}

import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchBasketMinimalStartDate } from 'src/sagas/basket/utils/fetchBasketMinimalStartDate';
import { fetchBasketProducts } from 'src/sagas/basket/utils/fetchBasketProducts';
import { fetchBasketUpgradeDetails } from 'src/sagas/basket/utils/fetchBasketUpgradeDetails';
import { fetchProductListData } from 'src/sagas/common/utils/fetchProductListData';
import { getProductTypes } from 'src/selectors/getProductTypes';
import { BasketData } from 'src/types/BasketData';
import { BasketProduct } from 'src/types/BasketProduct';
import { Currency } from 'src/types/Currency';
import { Language } from 'src/types/Language';
import { ProductMap } from 'src/types/ProductMap';
import { isNotNull } from 'src/utils/guard';
import { all, call, select } from 'typed-redux-saga';

export function* fetchBasketData(
  currency: Currency,
  language: Language,
  basket: ProductMap<BasketProduct>,
): SagaIterator<BasketData> {
  try {
    const productTypes = yield* select(getProductTypes);

    const products = yield* call(
      fetchBasketProducts,
      currency,
      language,
      basket,
    );
    const upgrades = yield* all(products.map((product) => callEffect(
      fetchBasketUpgradeDetails,
      currency,
      product,
    )));

    const {
      upsells,
      minimalStartDate,
    } = yield* all({
      upsells: callEffect(
        fetchProductListData,
        currency,
        productTypes
          .filter((it) => basket[it.code] == null)
          .map((it) => it.code),
        null,
      ),
      minimalStartDate: callEffect(
        fetchBasketMinimalStartDate,
        currency,
        basket,
      ),
    });

    return {
      products: products,
      upgrades: upgrades.filter(isNotNull),
      upsells: upsells,
      minimalStartDate: minimalStartDate,

      removing: null,
      upgrading: null,
    };
  } catch (error) {
    throw new RuntimeError('Could not fetch Basket data', {}, NativeError.wrap(error));
  }
}

import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/ReadonlyArray';
import { createSelector } from 'reselect';
import { BASKET_PRODUCT_ORD } from 'src/constants/ord';
import { getCheckoutFields } from 'src/selectors/getCheckoutFields';
import { getCheckoutProducts } from 'src/selectors/getCheckoutProducts';
import { CheckoutProduct } from 'src/types/CheckoutProduct';
import { assertNotEmpty } from 'src/utils/assert';

export const getCheckoutProductList = createSelector([
  getCheckoutProducts,
  getCheckoutFields,
], (products, fields): ReadonlyArray<CheckoutProduct> => {
  return pipe(
    products,
    A.sort(BASKET_PRODUCT_ORD),
    A.map((product) => ({
      code: product.code,
      form: product.form,

      fields: assertNotEmpty(
        fields[product.code],
        `No traveler fields defined for "${product.code}"`,
        { product, fields },
      ),

      familyCardCount: product.familyCardCount,
      familyCardDiscount: product.familyCardDiscount,
    })),
  );
});

import './ProductTeaserList.scss';
import React from 'react';
import { ProductTeaserCrossProduct } from 'src/components/product/ProductTeaserCrossProduct';
import { ProductTeaserValidityMap } from 'src/components/product/ProductTeaserValidityMap';
import { ProductAttibutesTeasers } from 'src/types/ProductAttibutes';

type Props = {
  readonly teasers: ProductAttibutesTeasers;
};

export function ProductTeaserList({ teasers }: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-teaser-list">
      <div className="sts-ui-product-teaser-list__item">
        <ProductTeaserCrossProduct crossProduct={teasers.crossProduct}/>
      </div>
      <div className="sts-ui-product-teaser-list__item">
        <ProductTeaserValidityMap validityMap={teasers.validityMap}/>
      </div>
    </div>
  );
}

import { TARIFF_LIST } from 'src/constants/tariff';
import { BasketProductDetails } from 'src/types/BasketProductDetails';
import { CheckoutFormData, CheckoutTravelerFormData } from 'src/types/CheckoutFormData';
import { TariffMap } from 'src/types/TariffMap';

export function resolveCheckoutFormData(
  products: ReadonlyArray<BasketProductDetails>,
  formData: CheckoutFormData,
): CheckoutFormData {
  return {
    payment: formData.payment,
    personal: {
      billing: formData.personal.billing,
      travelers: products.reduce<CheckoutFormData['personal']['travelers']>((productMap, product) => {
        const tariffs = TARIFF_LIST.reduce<TariffMap<CheckoutTravelerFormData[]>>((tariffMap, tariff) => {
          const count = product.form[tariff];
          if (!count) {
            return tariffMap;
          }

          const travelers = Array.from({ length: count }, (_, index): CheckoutTravelerFormData => {
            const storedForm = formData.personal.travelers[product.code]?.[tariff]?.[index];
            return {
              tariff: tariff,
              product: product.code,
              position: index,

              firstName: storedForm?.firstName ?? '',
              lastName: storedForm?.lastName ?? '',
              birthDate: storedForm?.birthDate ?? null,

              homeCountryCode: storedForm?.homeCountryCode ?? null,
              homeFullCountryCode: storedForm?.homeFullCountryCode ?? null,
            };
          });

          return {
            ...tariffMap,
            [tariff]: travelers,
          };
        }, {});

        return {
          ...productMap,
          [product.code]: tariffs,
        };
      }, {}),
    },
  };
}

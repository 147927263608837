import { CheckoutFormData } from 'src/types/CheckoutFormData';
import { OrderRequest, OrderTravelerRequest } from 'src/types/OrderRequest';

export function mapOrderToApi(formData: CheckoutFormData): OrderRequest {
  return {
    paymentType: formData.payment.paymentType,
    cancellationProtection: formData.payment.cancellationProtection,

    billing: {
      email: formData.personal.billing.email,
      title: formData.personal.billing.title,
      firstName: formData.personal.billing.firstName,
      lastName: formData.personal.billing.lastName,
      countryCode: formData.personal.billing.country,
    },
    travelers: Object.values(formData.personal.travelers)
      .map((tariffMap) => Object.values((tariffMap)))
      .flat(2)
      .map((traveler): OrderTravelerRequest => ({
        type: traveler.product,
        tariff: traveler.tariff,

        firstName: traveler.firstName,
        lastName: traveler.lastName,
        birthDate: traveler.birthDate,
        homeCountryCode: traveler.homeFullCountryCode ?? traveler.homeCountryCode,
      })),
  };
}

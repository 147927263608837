import { LocationDescriptorObject } from 'history';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCurrentRouteInfo } from 'src/routing/selectors/getCurrentRouteInfo';
import { RouteName } from 'src/routing/types/RouteName';
import { getRouteFullPath } from 'src/routing/utils/getRouteFullPath';
import { Language } from 'src/types/Language';

type BaseProps = Omit<
React.AnchorHTMLAttributes<HTMLAnchorElement>,
| 'href'
| 'hrefLang'
>;
type OwnProps = {
  readonly children: React.ReactNode;
  readonly replace?: boolean;

  readonly anchor?: string;
  readonly query?: string;

  readonly route?: RouteName;
  readonly locale?: Language;
};

type AllProps =
  & BaseProps
  & OwnProps;

export function RouteLink({
  locale,
  route,
  anchor,
  query,
  ...props
}: AllProps): React.ReactElement {
  const current = useSelector(getCurrentRouteInfo);

  const language = locale ?? current.language;
  const target: LocationDescriptorObject = {
    pathname: getRouteFullPath(language, route ?? current.routeName),
    search: query?.replace(/^\?/, ''),
    hash: anchor?.replace(/^#/, ''),
  };

  return <Link to={target} hrefLang={language} {...props}/>;
}

import './MenuDropdown.scss';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { LANGUAGE_LIST } from 'src/constants/language';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';

export function MobileDropdownLanguage(): React.ReactElement {
  const language = useSelector(getCurrentLanguage);

  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div className="sts-ui-mobile-dropdown" data-state={open ? 'open' : undefined}>
      <button type="button" onClick={handleToggle} className="sts-ui-mobile-dropdown__button">
        <div className="sts-ui-mobile-dropdown__value">
          {LANGUAGE_LIST.find((it) => it.value === language)?.title}
        </div>
        <div className="sts-ui-mobile-dropdown__caret"/>
      </button>
      <ul className="sts-ui-mobile-dropdown__menu">
        {LANGUAGE_LIST.map((item) => (
          <li key={item.value}>
            <RouteLink locale={item.value} className="sts-ui-mobile-dropdown__item">
              {item.title}
            </RouteLink>
          </li>
        ))}
      </ul>
    </div>
  );
}

import './Collapsible.scss';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { bind } from 'src/utils/bind';
import { transitionEndListener } from 'src/utils/transitionEndListener';

type Props = {
  readonly expanded: boolean;
  readonly children: React.ReactNode;
};

export class Collapsible extends React.Component<Props> {
  private readonly node: React.RefObject<HTMLDivElement> = React.createRef();

  public render(): React.ReactElement {
    const { expanded, children } = this.props;

    return (
      <CSSTransition
        in={expanded}
        nodeRef={this.node}
        classNames={CLASS_NAMES}
        timeout={EMPTY_TIMEOUTS}
        addEndListener={this.transitionEndListener}
        appear={false}
        mountOnEnter={false}
        unmountOnExit={true}
        onExit={this.setContentHeight}
        onEnter={this.setContentHeight}
        onExited={this.cleanupElement}
        onEntered={this.cleanupElement}
      >
        <div ref={this.node} className="sts-ui-collapsible">
          {children}
        </div>
      </CSSTransition>
    );
  }

  @bind
  private setContentHeight(): void {
    const content = this.node.current;
    if (content) {
      const bodyHeight = content.firstElementChild?.clientHeight ?? 0;
      content.style.setProperty('--sts-ui-collapsible-height', `${bodyHeight}px`);
    }
  }

  @bind
  private cleanupElement(): void {
    const content = this.node.current;
    if (content) {
      content.style.removeProperty('--sts-ui-collapsible-height');
    }
  }

  @bind
  private transitionEndListener(done: () => void): void {
    const content = this.node.current;
    if (content) {
      transitionEndListener(content, done);
    }
  }
}

const EMPTY_TIMEOUTS = {};
const CLASS_NAMES = {
  enter: 'sts-ui-collapsible--show',
  exit: 'sts-ui-collapsible--hide',
};

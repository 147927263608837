import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { fetchProductPrices } from 'src/sagas/product/utils/fetchProductPrices';
import { fetchProductValidity } from 'src/sagas/product/utils/fetchProductValidity';
import { getBasketProducts } from 'src/selectors/getBasketProducts';
import { getProductData } from 'src/selectors/getProductData';
import { ProductCode } from 'src/types/ProductCode';
import { ProductData } from 'src/types/ProductData';
import { ProductFormData } from 'src/types/ProductFormData';
import { updateProductFormData } from 'src/utils/product/updateProductFormData';
import { call, select } from 'typed-redux-saga';

export function* modifyProductData(
  productType: ProductCode,
  productForm: ProductFormData,
): SagaIterator<ProductData> {
  try {
    const currency = yield* select(getCurrentCurrency);
    const language = yield* select(getCurrentLanguage);

    const product = yield* select(getProductData);
    const basket = yield* select(getBasketProducts);

    const validityDate = (
      productForm.startDate ??
      product.minStartDate.startDate
    );
    const validity = product.formData.startDate === validityDate
      ? product.validity
      : yield* call(fetchProductValidity, productType, currency, language, validityDate);

    const actualFormData: ProductFormData = yield* call(
      updateProductFormData,
      product.fields,
      validity,
      basket,
      product.formData,
      productForm,
    );

    const ticketForm: ProductFormData = {
      ...actualFormData,
      startDate: validityDate,
      agreement: true,
    };
    const ticketPrice = yield* call(
      fetchProductPrices,
      productType,
      ticketForm,
      product.fields,
      product.pricePeriods,
      validity,
      currency,
    );

    return {
      ...product,
      validity: validity,
      ticketPrice: ticketPrice,
      formData: actualFormData,
    };
  } catch (error) {
    throw new RuntimeError('Could not modify product data', { productType, productForm }, NativeError.wrap(error));
  }
}

import { createSelector } from 'reselect';
import { getHomepageState } from 'src/selectors/getHomepageState';
import { assertNotEmpty } from 'src/utils/assert';

export const getHomepageData = createSelector([
  getHomepageState,
], (state) => assertNotEmpty(
  state.data,
  'Homepage state is not initialized',
  { state },
));

import { createSelector } from 'reselect';
import { getBasketData } from 'src/selectors/getBasketData';
import { getProductTypes } from 'src/selectors/getProductTypes';
import { ProductCode } from 'src/types/ProductCode';

export const getBasketUnavailableProducts = createSelector([
  getBasketData,
  getProductTypes,
], (data, productTypes): ReadonlySet<ProductCode> => {
  const result = new Set<ProductCode>();

  for (const { code } of productTypes) {
    if (data.minimalStartDate[code]?.startDate == null) {
      result.add(code);
    }
  }

  return result;
});

import { Location } from 'src/routing/types/Location';
import { TransitionTarget } from 'src/routing/types/TransitionTarget';
import { Currency } from 'src/types/Currency';
import { createActions } from 'src/utils/createActions';

export type RouteChangeMeta = {
  readonly location: Location;
  readonly currency: Currency;
};

export const ROUTE_CHANGE = createActions<void, TransitionTarget, RouteChangeMeta>('ROUTE_CHANGE');

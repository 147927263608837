import './ErrorBoundary.scss';
import React from 'react';
import { ErrorMessage } from 'src/components/error/ErrorMessage';
import { PRODUCTION } from 'src/constants/application';
import { BaseError } from 'src/errors/BaseError';
import { NativeError } from 'src/errors/NativeError';
import { RouterError } from 'src/routing/views/RouterError';
import { sentryCatch } from 'src/utils/sentryCatch';

type Props = {
  readonly children: React.ReactNode;
};
type State = {
  readonly error: BaseError | undefined;
  readonly errorInfo: React.ErrorInfo | undefined;
};

export class ErrorBoundary extends React.Component<Props, State> {
  public readonly state: State = {
    error: undefined,
    errorInfo: undefined,
  };

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    sentryCatch(error);

    this.setState(() => ({
      error: NativeError.wrap(error),
      errorInfo: errorInfo,
    }));
  }

  public render(): React.ReactNode {
    const { error, errorInfo } = this.state;
    if (!error || !errorInfo) {
      const { children } = this.props;
      return children;
    }

    if (PRODUCTION) {
      return <RouterError error={error}/>;
    }

    return (
      <div className="sts-ui-error-boundary">
        <ErrorMessage
          error={error}
          errorInfo={errorInfo}
        />
      </div>
    );
  }
}

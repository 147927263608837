import { DecodeError, draw } from 'io-ts/Decoder';
import { LogicError } from 'src/errors/LogicError';

export class DecodingError extends LogicError {
  public constructor(
    public readonly value: unknown,
    public readonly error: DecodeError,
  ) {
    super(`Decoding errors:\n${draw(error)}`, { value, error });
  }

  public get name(): string {
    return 'DecodingError';
  }
}

// NOTE: should be compatible with ProductCode
export enum TicketType {
  SWISS_TRAVEL_PASS = 'swissPass',
  SWISS_TRAVEL_PASS_FLEX = 'swissFlexiPass',
  SWISS_HALF_FARE_CARD = 'swissHalfFareCard',

  SWISS_TRAVEL_PASS_PROMO = 'swissPassPromo',
  SWISS_TRAVEL_PASS_FLEX_PROMO = 'swissFlexiPassPromo',

  EXCURSION_GORNERGRAT = 'excursionGornergrat',
  EXCURSION_MATTERHORN = 'excursionMatterhornParadise',
  EXCURSION_PILATUS = 'excursionPilatus',
  EXCURSION_ROCHERS = 'excursionRochersDeNaye',
  EXCURSION_SCHILTHORN_MURREN = 'excursionSchilthornMurren',
  EXCURSION_SCHILTHORN_STECHELBERG = 'excursionSchilthornFromStechelberg',
  EXCURSION_STANSERHORN = 'excursionStanserhorn',
  EXCURSION_TITLIS = 'excursionTitlis',
  EXCURSION_JUNGFRAUJOCH = 'excursionJungfraujoch',
  EXCURSION_GLACIER = 'excursionGlacier',

  REGIONAL_OBERLAND_PASS = 'regionalBerneseOberlandPass',
  REGIONAL_CENTRAL_PASS = 'regionalCentralSwissPass',
}

import { FieldMap, FormController } from '@form-ts/core';
import * as Eq from 'fp-ts/Eq';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/ReadonlyRecord';
import { useEffect } from 'react';
import { FormError } from 'src/forms/types/FormError';
import { FormRule } from 'src/forms/types/FormRule';
import { validate } from 'src/forms/utils/validate';
import { isDefined } from 'src/utils/guard';

export function useFormValidator<TData>(
  form: FormController<TData, FormError>,
  rule: FormRule<TData>,
): void {
  useEffect(() => form.middleware((nextState) => {
    const nextValues = nextState.values;
    const prevValues = form.currentState.values;

    if (nextValues === prevValues) {
      return nextState;
    }

    const nextErrors = validate(nextValues, rule);
    const prevErrors = form.currentState.errors;

    return pipe(
      nextState,
      form.errors.set(mergeErrors(prevErrors, nextErrors)),
    );
  }), [form, rule]);
}

function mergeErrors(
  prevErrors: FieldMap<FormError>,
  nextErrors: FieldMap<FormError>,
): FieldMap<FormError> {
  return pipe(
    nextErrors,
    R.filter(isDefined),
    R.mapWithIndex((path, nextError) => {
      const prevError = prevErrors[path];
      if (!prevError) {
        return nextError;
      }

      return EQ_FORM_ERROR.equals(prevError, nextError)
        ? prevError
        : nextError;
    }),
  );
}

const EQ_FORM_ERROR: Eq.Eq<FormError> = Eq.struct({
  code: Eq.eqStrict,
  path: Eq.eqStrict,

  value: Eq.eqStrict,
  message: Eq.eqStrict,
});

import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchProductPrices } from 'src/sagas/product/utils/fetchProductPrices';
import { BasketProductDetails } from 'src/types/BasketProductDetails';
import { BasketUpgradeDetails } from 'src/types/BasketUpgradeDetails';
import { Currency } from 'src/types/Currency';
import { TicketClass } from 'src/types/TicketClass';
import { call } from 'typed-redux-saga';

export function* fetchBasketUpgradeDetails(
  currency: Currency,
  product: BasketProductDetails,
): SagaIterator<BasketUpgradeDetails | null> {
  if (product.form.class !== TicketClass.SECOND) {
    return null;
  }

  try {
    const prices = yield* call(
      fetchProductPrices,
      product.code,
      { ...product.form, class: TicketClass.FIRST },
      product.fields,
      product.pricePeriods,
      product.validity,
      currency,
    );

    return {
      code: product.code,
      prices: prices,
    };
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Basket upgrade details',
      { currency, product },
      NativeError.wrap(error),
    );
  }
}

import { BaseError } from 'src/errors/BaseError';

export class NativeError extends BaseError {
  public static wrap(error: unknown): BaseError {
    if (error instanceof BaseError) {
      return error;
    }
    if (error instanceof Error) {
      return new NativeError(error);
    }
    if (typeof error === 'string') {
      return new NativeError(new Error(error));
    }

    return new NativeError(new Error(`Unknown error value "${String(error)}"`));
  }

  private constructor(public readonly error: Error) {
    super(error.message);
    this.stack = error.stack;
  }

  public get name(): string {
    return this.error.name;
  }
}

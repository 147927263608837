import * as C from 'io-ts/Codec';
import { Optional } from 'src/codecs/combinators/Optional';
import { ProductMap } from 'src/codecs/combinators/ProductMap';
import { DATE_ONLY } from 'src/codecs/DateOnly';
import { TicketMinimalStartDate } from 'src/types/TicketMinimalStartDate';

export const TICKET_MINIMAL_START_DATE: C.Codec<unknown, unknown, TicketMinimalStartDate> = C.struct({
  startDate: Optional(DATE_ONLY),
  shiftTickets: Optional(ProductMap(DATE_ONLY)),
});

import React from 'react';
import { useSelector } from 'react-redux';
import { PaymentFailureScreen } from 'src/components/payment/PaymentFailureScreen';
import { getPaymentData } from 'src/selectors/getPaymentData';

export function PaymentFailurePage(): React.ReactElement {
  const payment = useSelector(getPaymentData);

  return (
    <PaymentFailureScreen
      payment={payment}
    />
  );
}

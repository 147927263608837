import { ROUTING_LANGUAGES } from 'src/routing/constants/RoutingLanguages';
import {
  ROUTING_PRIVATE_PAGES,
  ROUTING_PUBLIC_PAGES,
} from 'src/routing/constants/RoutingPages';
import { ROUTING_SITEMAP } from 'src/routing/constants/RoutingSitemap';
import { RouteDescriptor } from 'src/routing/types/RouteDescriptor';

export function getRouteDescriptor(
  pathname: string,
): RouteDescriptor | null {
  return PATHNAME_MAP.get(pathname) ?? null;
}

const PATHNAME_MAP: ReadonlyMap<string, RouteDescriptor> = new Map(
  [...ROUTING_PRIVATE_PAGES, ...ROUTING_PUBLIC_PAGES].map((routeName) => ROUTING_LANGUAGES.map((language) => {
    const pathname = ROUTING_SITEMAP[routeName][language];
    const descriptor = { routeName, language };

    return [pathname, descriptor] as const;
  })).flat(1),
);

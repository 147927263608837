import { isAfter } from 'date-fns/isAfter';
import { SagaIterator } from 'redux-saga';
import { AFFILIATE_INIT } from 'src/actions/AffiliateActions';
import { DEFAULT_AFFILIATE_ID } from 'src/constants/application';
import { SEARCH_ENGINES_DOMAINS } from 'src/constants/searchEngine';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { applyThemeSettings } from 'src/routing/hooks/initializers/applyThemeSettings';
import { initGoogleTagManager } from 'src/routing/hooks/initializers/initGoogleTagManager';
import { getBrowserLocation } from 'src/routing/selectors/getBrowserLocation';
import { getReferrer } from 'src/routing/selectors/getReferrer';
import { Location } from 'src/routing/types/Location';
import { getRouteDescriptor } from 'src/routing/utils/getRouteDescriptor';
import { fetchAffiliate } from 'src/sagas/affiliate/utils/fetchAffiliate';
import { saveAffiliateData } from 'src/sagas/affiliate/utils/saveAffiliateData';
import { loadAffiliateStore } from 'src/sagas/affiliate/utils/storeAffiliateData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getIsBasketEmpty } from 'src/selectors/getIsBasketEmpty';
import { Affiliate } from 'src/types/Affiliate';
import { dateNow } from 'src/utils/date';
import { call, put, select } from 'typed-redux-saga';

export function* initAffiliate(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Initializing affiliate…');

    // read URL params directly from URL since we likely didn't enter a route
    const location = yield* select(getBrowserLocation);
    const referrer = yield* select(getReferrer);

    const routeDesc = yield* call(getRouteDescriptor, location.pathname);
    const urlSearchParams = getSearchParams(location);

    const isBasketEmpty = yield* select(getIsBasketEmpty);
    const storedAffiliate = yield* call(loadAffiliateStore);

    const urlLocale = urlSearchParams.get('locale');
    const urlCurrency = urlSearchParams.get('currency');
    const urlAffiliateId = urlSearchParams.get('affiliateid') || (referrerBelongsSearchEngine(referrer) ? DEFAULT_AFFILIATE_ID : null);
    const urlIsIframe = urlSearchParams.get('isiframe');

    const now = yield* call(dateNow);
    const isDateExpired = storedAffiliate
      ? isAfter(now, storedAffiliate.expiresAt)
      : true;
    const storageAffiliateId = !isBasketEmpty || !isDateExpired
      ? storedAffiliate?.id ?? null
      : null;

    const loadedAffiliate = yield* call(fetchAffiliate, {
      urlAffiliateId: urlAffiliateId,
      storageAffiliateId: storageAffiliateId,

      language: urlLocale ?? routeDesc?.language ?? storedAffiliate?.language ?? null,
      currency: urlCurrency ?? storedAffiliate?.currency ?? null,
    });
    const resolvedAffiliate: Affiliate = {
      ...loadedAffiliate,
      isIframe: loadedAffiliate.isIframe || Boolean(urlIsIframe),
    };

    yield* call(applyThemeSettings, resolvedAffiliate);
    yield* call(initGoogleTagManager, resolvedAffiliate);
    yield* call(saveAffiliateData, resolvedAffiliate);

    yield* put(AFFILIATE_INIT.trigger(resolvedAffiliate));
    yield* call(logDebug, 'Initializing affiliate… done', resolvedAffiliate);
  } catch (error) {
    yield* call(logError, 'Initializing affiliate… error', error);
    throw new RuntimeError('Could not initialize affiliate', {}, NativeError.wrap(error));
  }
}

function getSearchParams(location: Location): URLSearchParams {
  const result = new URLSearchParams();
  new URLSearchParams(location.search).forEach((val, key) => {
    result.append(key.toLowerCase(), val);
  });
  return result;
}

function referrerBelongsSearchEngine(referrer: string): boolean {
  try {
    const hostname = new URL(referrer).hostname;
    return SEARCH_ENGINES_DOMAINS.some((domain) => (
      hostname === domain ||
      hostname.endsWith(`.${domain}`)
    ));
  } catch (error) {
    return false;
  }
}

import './PaymentCancelScreen.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Typography } from 'src/components/common/Typography';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { PaymentStore } from 'src/types/PaymentStore';

type Props = {
  readonly payment: PaymentStore;
};

export function PaymentCancelScreen({ payment }: Props): React.ReactElement {
  return (
    <div className="sts-ui-payment-cancel-screen">
      <div className="sts-ui-payment-cancel-screen__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="Payment.PaymentCancellation"/>
        </Typography>
      </div>

      <div className="sts-ui-payment-cancel-screen__content">
        <FormattedHTMLMessage
          id="Payment.YouCancelledYourOrder"
          values={{ orderId: payment.order.data.refno }}
        />
      </div>

      <div className="sts-ui-payment-cancel-screen__actions">
        <RouteLink route="checkout:payment" className="sts-ui-primary-button">
          <FormattedMessage id="Payment.Back"/>
          <ArrowRightIcon/>
        </RouteLink>
      </div>
    </div>
  );
}

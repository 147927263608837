import { ROUTING_SITEMAP } from 'src/routing/constants/RoutingSitemap';
import { RouteName } from 'src/routing/types/RouteName';
import { Language } from 'src/types/Language';
import { assertDefined } from 'src/utils/assert';

export function getRouteFullPath(
  language: Language,
  routeName: RouteName,
): string {
  // NOTE: additional check for JS usage
  return assertDefined(
    ROUTING_SITEMAP?.[routeName]?.[language],
    `No URL configuration defined for "${language}"/"${routeName}"`,
  );
}

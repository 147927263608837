import './CheckoutCountryName.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCheckoutCustomerCountries } from 'src/selectors/getCheckoutCustomerCountries';
import { CountryCode } from 'src/types/CountryCode';

type Props = {
  readonly code: CountryCode;
};

export function CheckoutCountryName({ code }: Props): React.ReactElement {
  const countries = useSelector(getCheckoutCustomerCountries);

  return (
    <span className="sts-ui-checkout-country-name">
      {countries[code]}
    </span>
  );
}

import { FieldMap } from '@form-ts/core';
import { Foldable } from 'fp-ts/ReadonlyArray';
import { fromFoldableMap } from 'fp-ts/ReadonlyRecord';
import { first } from 'fp-ts/Semigroup';
import { FormError } from 'src/forms/types/FormError';
import { FormRule, FormRuleContext } from 'src/forms/types/FormRule';

export function validate<T>(data: T, rule: FormRule<T>): FieldMap<FormError> {
  const errors = rule(data, ROOT_CONTEXT);
  return fromFoldableMap(first<FormError>(), Foldable)(errors, (error) => [error.path, error]);
}

const ROOT_CONTEXT: FormRuleContext = {
  path: [],
};

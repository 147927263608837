import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLocalizationProductAttributes } from 'src/selectors/getLocalizationProductAttributes';
import { ProductCode } from 'src/types/ProductCode';

type Props = {
  readonly type: ProductCode;
};

export function ProductCardFeatures({ type }: Props): React.ReactElement {
  const attributes = useSelector(getLocalizationProductAttributes);

  return (
    <ul>
      {attributes[type].cardFeatures.map((feature, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <FormattedHTMLMessage id={feature}/>
        </li>
      ))}
    </ul>
  );
}

import { SagaIterator } from 'redux-saga';
import { getIsDebugMode } from 'src/selectors/getIsDebugMode';
import { call, select } from 'typed-redux-saga';

export function* logDebug(message: string, context?: unknown): SagaIterator<void> {
  const debugMode = yield* select(getIsDebugMode);
  if (!debugMode) {
    return;
  }

  yield* call({
    // eslint-disable-next-line no-console
    fn: console.debug,
    context: console,
  }, message, context);
}

export function* logInfo(message: string, context?: unknown): SagaIterator<void> {
  yield* call({
    // eslint-disable-next-line no-console
    fn: console.info,
    context: console,
  }, message, context);
}

export function* logError(message: string, context?: unknown): SagaIterator<void> {
  yield* call({
    // eslint-disable-next-line no-console
    fn: console.error,
    context: console,
  }, message, context);
}

import { createSelector } from 'reselect';
import { WebSite, WithContext } from 'schema-dts';
import { getAffiliateData } from 'src/selectors/getAffiliateData';

export const getWebsiteSchemaOrg = createSelector([
  getAffiliateData,
], (affiliate): WithContext<WebSite> => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  'url': window.origin,
  'name': affiliate.appThemeSettings.pageTitle || undefined,
}));

import { SagaIterator } from 'redux-saga';
import { BASKET_EMPTY } from 'src/actions/BasketActions';
import { PAYMENT_FETCH } from 'src/actions/PaymentActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { redirectToHomepage } from 'src/routing/sagas/utils/redirectToHomepage';
import { Transition } from 'src/routing/types/Transition';
import { saveBasketStore } from 'src/sagas/basket/utils/storeBasketData';
import { saveCheckoutStore } from 'src/sagas/checkout/utils/storeCheckoutData';
import { pushPurchaseEvent } from 'src/sagas/payment/utils/pushPurchaseEvent';
import { loadPaymentStore, savePaymentStore } from 'src/sagas/payment/utils/storePaymentData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { Language } from 'src/types/Language';
import { call, put } from 'typed-redux-saga';

export function* fetchPaymentSuccess(language: Language, transition: Transition): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching Payment Success data…');

    const storage = yield* call(loadPaymentStore);
    if (storage === null) {
      yield* call(logDebug, 'Fetching Payment Success data… skip');
      yield* call(redirectToHomepage, transition.to.currency, language);
      return;
    }

    // remove stored Basket
    yield* put(BASKET_EMPTY.trigger());
    yield* call(saveBasketStore, null);

    // remove stored Order
    yield* call(saveCheckoutStore, null);
    yield* call(savePaymentStore, null);

    // push GTM events
    yield* call(pushPurchaseEvent, storage);

    yield* call(logDebug, 'Fetching Payment Success data… done', storage);
    yield* put(PAYMENT_FETCH.trigger(storage));
  } catch (error) {
    yield* call(logError, 'Fetching Payment Success data… error', error);
    throw new RuntimeError('Could not fetch Payment Success data', {}, NativeError.wrap(error));
  }
}

import './MobileMenuIndicator.scss';
import classNames from 'classnames';
import React from 'react';

type Props = {
  readonly open: boolean;
};

export function MobileMenuIndicator({ open }: Props): React.ReactElement {
  return (
    <div
      className={classNames(
        'sts-ui-mobile-menu-indicator',
        open ? 'sts-ui-mobile-menu-indicator--open' : null,
      )}
    >
      <div className="sts-ui-mobile-menu-indicator__line-1"/>
      <div className="sts-ui-mobile-menu-indicator__line-2"/>
      <div className="sts-ui-mobile-menu-indicator__line-3"/>
    </div>
  );
}

import { AFFILIATE_INIT } from 'src/actions/AffiliateActions';
import { AffiliateState } from 'src/types/AffiliateState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: AffiliateState = {
  data: null,
};

export const affiliateReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (AFFILIATE_INIT.is(action)) {
    return { ...state, data: action.data };
  }

  return state;
});

import { createSelector } from 'reselect';
import { FAQPage, Question, WithContext } from 'schema-dts';
import { getFaqData } from 'src/selectors/getFaqData';
import { getIntlMessages } from 'src/selectors/getIntlMessages';

export const getFaqSchemaOrg = createSelector([
  getFaqData,
  getIntlMessages,
], (faq, messages): WithContext<FAQPage> => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  'mainEntity': faq.map((chapter) => chapter.sections.map((section): Question => ({
    '@type': 'Question',
    'name': messages[section.header],
    'acceptedAnswer': {
      '@type': 'Answer',
      'text': messages[section.content],
    },
  }))).flat(1),
}));

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLocalizationProductAttributes } from 'src/selectors/getLocalizationProductAttributes';
import { ProductCode } from 'src/types/ProductCode';

export function useProductName(product: ProductCode): string {
  const intl = useIntl();
  const attributes = useSelector(getLocalizationProductAttributes);

  return intl.formatMessage({ id: attributes[product].name });
}

const NAME_CHARACTERS = '\\-A-Za-z0-9_.\'`' +
  '\\u00C0-\\u00C4\\u00C7-\\u00CB\\u00CD-\\u00CF\\u00D4\\u00D6\\u00D9\\u00DB\\u00DC\\u00DF' +
  '\\u00E0-\\u00E4\\u00E7-\\u00EB\\u00ED-\\u00EF\\u00F4\\u00F6\\u00F9\\u00FB\\u00FC';

const ADDRESS_CHARACTERS = '\\-A-Za-z0-9_\\(\\)\\/.,"#&:\'`+' +
  '\\u00C0-\\u00C4\\u00C7-\\u00CB\\u00CD-\\u00CF\\u00D4\\u00D6\\u00D9\\u00DB\\u00DC\\u00DF' +
  '\\u00E0-\\u00E4\\u00E7-\\u00EB\\u00ED-\\u00EF\\u00F4\\u00F6\\u00F9\\u00FB\\u00FC';

export const NAME_INPUT_PATTERN = `^[${NAME_CHARACTERS}]+(?:[${NAME_CHARACTERS} ]*[${NAME_CHARACTERS}])?$`;
export const ADDRESS_INPUT_PATTERN = `^[${ADDRESS_CHARACTERS}]+(?:[${ADDRESS_CHARACTERS} ]*[${ADDRESS_CHARACTERS}])?$`;

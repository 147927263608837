// eslint-disable-next-line @typescript-eslint/ban-types
export function bind<T extends Function>(
  target: object,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<T>,
): TypedPropertyDescriptor<T> {
  return {
    configurable: true,
    get: function getter(this: T): T {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const bound: T = descriptor.value!.bind(this);

      Object.defineProperty(this, propertyKey, {
        value: bound,
        configurable: true,
        writable: true,
      });

      return bound;
    },
  };
}

import { createSelector } from 'reselect';
import { FAQPage, Product, WithContext } from 'schema-dts';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getIntlMessages } from 'src/selectors/getIntlMessages';
import { getLocalizationProductAttributes } from 'src/selectors/getLocalizationProductAttributes';
import { getProductMinPrice } from 'src/selectors/getProductMinPrice';
import { getProductType } from 'src/selectors/getProductType';

export const getProductSchemaOrg = createSelector([
  getIntlMessages,
  getProductType,
  getProductMinPrice,
  getLocalizationProductAttributes,
  getCurrentCurrency,
], (messages, type, minPrice, attributes, currency): ReadonlyArray<WithContext<FAQPage | Product>> | null => {
  if (!minPrice) {
    return null;
  }

  const attrs = attributes[type];
  if (!attrs) {
    return null;
  }

  return [{
    '@context': 'https://schema.org',
    '@type': 'Product',
    'name': messages[attrs.name],
    'description': messages[attrs.formDescription],
    'offers': {
      '@type': 'Offer',
      'price': minPrice.price.toNumber(),
      'priceCurrency': currency,
    },
  }, {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': attrs.faqSections.map((section) => ({
      '@type': 'Question',
      'name': messages[section.header],
      'acceptedAnswer': {
        '@type': 'Answer',
        'text': messages[section.content],
      },
    })),
  }];
});

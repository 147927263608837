import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { Optional } from 'src/codecs/combinators/Optional';
import { DECIMAL } from 'src/codecs/Decimal';
import { DISCOUNT_CODE } from 'src/codecs/DiscountCode';
import { TICKET_AGE } from 'src/codecs/TicketAge';
import { TICKET_CLASS } from 'src/codecs/TicketClass';
import { TicketPrice } from 'src/types/TicketPrice';

export const TICKET_PRICE: C.Codec<unknown, JsonRecord, TicketPrice> = C.struct({
  tariff: TICKET_AGE,
  class: Optional(TICKET_CLASS),
  price: DECIMAL,

  duration: Optional(C.string),
  discounts: C.readonly(C.array(DISCOUNT_CODE)),
});

import { createSelector } from 'reselect';
import { getProductState } from 'src/selectors/getProductState';
import { assertNotEmpty } from 'src/utils/assert';

export const getProductData = createSelector([
  getProductState,
], (state) => assertNotEmpty(
  state.data,
  'Product state is not initialized',
  { state },
));

import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { PRODUCT_CODE } from 'src/codecs/ProductCode';
import { TICKET_FIELDS } from 'src/codecs/TicketFields';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { ProductCode } from 'src/types/ProductCode';
import { TicketFields } from 'src/types/TicketFields';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchProductFields(
  code: ProductCode,
): SagaIterator<TicketFields> {
  try {
    const request = yield* call(REQUEST.encode, { type: code });
    const response = yield* call(makeGetRequest, `${HOST_API}/ticket-fields`, request);
    return yield* call(decodeOrThrow, TICKET_FIELDS, response.content);
  } catch (error) {
    throw new RuntimeError('Could not fetch product fields', { code }, NativeError.wrap(error));
  }
}

const REQUEST = E.struct({
  type: PRODUCT_CODE,
});

import { captureException, SeverityLevel, withScope } from '@sentry/browser';
import { NativeError } from 'src/errors/NativeError';

export function sentryCatch(
  error: unknown,
  level: SeverityLevel = 'error',
): void {
  withScope((scope) => {
    // TODO: don't we need a user/affiliate/language/currency info here?
    const context = NativeError.wrap(error).toJSON();

    scope.setLevel(level);
    scope.setExtra('error_context', context);

    captureException(error);
  });
}

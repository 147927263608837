import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import SBB_LOGO from 'src/assets/img/footer/sbb-logo.svg';
import SWISS_TOURISM_LOGO from 'src/assets/img/footer/switzerland_logo_flag.svg';
import HOTELLERIE_SUISSE_DE_FR_1X from 'src/assets/srcset/footer/HS/HS.png';
import HOTELLERIE_SUISSE_DE_FR_2X from 'src/assets/srcset/footer/HS/HS@2x.png';
import HOTELLERIE_SUISSE_DE_FR_3X from 'src/assets/srcset/footer/HS/HS@3x.png';
import HOTELLERIE_SUISSE_DEFAULT_1X from 'src/assets/srcset/footer/SHA/SHA.png';
import HOTELLERIE_SUISSE_DEFAULT_2X from 'src/assets/srcset/footer/SHA/SHA@2x.png';
import HOTELLERIE_SUISSE_DEFAULT_3X from 'src/assets/srcset/footer/SHA/SHA@3x.png';
import { ImageAttributes } from 'src/types/ImageAttributes';
import { Language } from 'src/types/Language';
import { PartnerKey } from 'src/types/PartnerKey';
import { PartnerLink } from 'src/types/PartnerLink';
import { parseImageData } from 'src/utils/image';

const imageSrcPartnerSBBDefault: ImageAttributes = { src: SBB_LOGO };
const imageSrcPartnerSwissTourismDefault: ImageAttributes = { src: SWISS_TOURISM_LOGO };

const logoPartnerHotellerieSuisseDefault = parseImageData(
  HOTELLERIE_SUISSE_DEFAULT_1X,
  HOTELLERIE_SUISSE_DEFAULT_2X,
  HOTELLERIE_SUISSE_DEFAULT_3X,
);
const logoPartnerHotellerieSuisseDeFr = parseImageData(
  HOTELLERIE_SUISSE_DE_FR_1X,
  HOTELLERIE_SUISSE_DE_FR_2X,
  HOTELLERIE_SUISSE_DE_FR_3X,
);

export const PARTNER_LINKS: ReadonlyRecord<Language, ReadonlyRecord<PartnerKey, PartnerLink>> = {
  'en': {
    hotellerieSuisse: {
      link: 'https://www.hotelleriesuisse.ch/de/',
      logo: logoPartnerHotellerieSuisseDefault,
    },
    sbb: {
      link: 'https://www.sbb.ch/en',
      logo: imageSrcPartnerSBBDefault,
    },
    swissTourism: {
      link: 'http://www.myswitzerland.com/en/home.html',
      logo: imageSrcPartnerSwissTourismDefault,
    },
  },
  'de': {
    hotellerieSuisse: {
      link: 'https://www.hotelleriesuisse.ch/de/',
      logo: logoPartnerHotellerieSuisseDeFr,
    },
    sbb: {
      link: 'https://www.sbb.ch/de',
      logo: imageSrcPartnerSBBDefault,
    },
    swissTourism: {
      link: 'https://www.myswitzerland.com/de-ch/home.html',
      logo: imageSrcPartnerSwissTourismDefault,
    },
  },
  'it': {
    hotellerieSuisse: {
      link: 'https://www.hotelleriesuisse.ch/it/',
      logo: logoPartnerHotellerieSuisseDefault,
    },
    sbb: {
      link: 'https://www.sbb.ch/it',
      logo: imageSrcPartnerSBBDefault,
    },
    swissTourism: {
      link: 'https://www.myswitzerland.com/it-ch/home.html',
      logo: imageSrcPartnerSwissTourismDefault,
    },
  },
  'fr': {
    hotellerieSuisse: {
      link: 'https://www.hotelleriesuisse.ch/fr/',
      logo: logoPartnerHotellerieSuisseDeFr,
    },
    sbb: {
      link: 'https://www.sbb.ch/fr',
      logo: imageSrcPartnerSBBDefault,
    },
    swissTourism: {
      link: 'https://www.myswitzerland.com/fr-ch/accueil.html',
      logo: imageSrcPartnerSwissTourismDefault,
    },
  },
  'es': {
    hotellerieSuisse: {
      link: 'https://www.hotelleriesuisse.ch/fr/',
      logo: logoPartnerHotellerieSuisseDefault,
    },
    sbb: {
      link: 'https://www.sbb.ch/en',
      logo: imageSrcPartnerSBBDefault,
    },
    swissTourism: {
      link: 'https://www.myswitzerland.com/es/inicio.html',
      logo: imageSrcPartnerSwissTourismDefault,
    },
  },
  'nl': {
    hotellerieSuisse: {
      link: 'https://www.hotelleriesuisse.ch/de/',
      logo: logoPartnerHotellerieSuisseDefault,
    },
    sbb: {
      link: 'https://www.sbb.ch/en',
      logo: imageSrcPartnerSBBDefault,
    },
    swissTourism: {
      link: 'https://www.myswitzerland.com/nl/meetings/home.html',
      logo: imageSrcPartnerSwissTourismDefault,
    },
  },
  'ja': {
    hotellerieSuisse: {
      link: 'https://www.hotelleriesuisse.ch/de/',
      logo: logoPartnerHotellerieSuisseDefault,
    },
    sbb: {
      link: 'https://www.sbb.ch/en',
      logo: imageSrcPartnerSBBDefault,
    },
    swissTourism: {
      link: 'https://www.myswitzerland.com/ja/meetings/home.html',
      logo: imageSrcPartnerSwissTourismDefault,
    },
  },
  'ko': {
    hotellerieSuisse: {
      link: 'https://www.hotelleriesuisse.ch/de/',
      logo: logoPartnerHotellerieSuisseDefault,
    },
    sbb: {
      link: 'https://www.sbb.ch/en',
      logo: imageSrcPartnerSBBDefault,
    },
    swissTourism: {
      link: 'https://www.myswitzerland.com/ko/meetings/home.html',
      logo: imageSrcPartnerSwissTourismDefault,
    },
  },
  'zh-Hans': {
    hotellerieSuisse: {
      link: 'https://www.hotelleriesuisse.ch/de/',
      logo: logoPartnerHotellerieSuisseDefault,
    },
    sbb: {
      link: 'https://www.sbb.ch/en',
      logo: imageSrcPartnerSBBDefault,
    },
    swissTourism: {
      link: 'https://www.myswitzerland.com/zh-Hans/meetings/home.html',
      logo: imageSrcPartnerSwissTourismDefault,
    },
  },
  'zh-Hant': {
    hotellerieSuisse: {
      link: 'https://www.hotelleriesuisse.ch/de/',
      logo: logoPartnerHotellerieSuisseDefault,
    },
    sbb: {
      link: 'https://www.sbb.ch/en',
      logo: imageSrcPartnerSBBDefault,
    },
    swissTourism: {
      link: 'https://www.myswitzerland.com/zh-Hant/meetings/home.html',
      logo: imageSrcPartnerSwissTourismDefault,
    },
  },
} as const;

export const PROVEN_EXPERT_LINKS: ReadonlyRecord<Language, string> = {
  'en': 'https://www.provenexpert.com/en-gb/switzerland-travel-centre/',
  'de': 'https://www.provenexpert.com/de-de/switzerland-travel-centre/',
  'it': 'https://www.provenexpert.com/it-it/switzerland-travel-centre/',
  'es': 'https://www.provenexpert.com/en-gb/switzerland-travel-centre/',
  'fr': 'https://www.provenexpert.com/fr-fr/switzerland-travel-centre/',
  'nl': 'https://www.provenexpert.com/en-gb/switzerland-travel-centre/',
  'ja': 'https://www.provenexpert.com/en-gb/switzerland-travel-centre/',
  'ko': 'https://www.provenexpert.com/en-gb/switzerland-travel-centre/',
  'zh-Hans': 'https://www.provenexpert.com/en-gb/switzerland-travel-centre/',
  'zh-Hant': 'https://www.provenexpert.com/en-gb/switzerland-travel-centre/',
};

import './GuideScreen.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Typography } from 'src/components/common/Typography';

export function GuideScreen(): React.ReactElement {
  return (
    <div className="sts-ui-guide-screen">
      <div className="sts-ui-guide-screen__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="Guide.STPF.Page.Title"/>
        </Typography>
      </div>
      <div className="sts-ui-guide-screen__page-subtitle">
        <Typography variant="page-subtitle" as="p">
          <FormattedHTMLMessage id="Guide.STPF.Page.Description"/>
        </Typography>
      </div>

      <div className="sts-ui-guide-screen__content">
        <div className="sts-ui-guide-screen__steps">
          <div className="sts-ui-guide-screen__step">
            <div className="sts-ui-guide-screen__step-num">1</div>
            <div className="sts-ui-guide-screen__step-content">
              <FormattedHTMLMessage id="Guide.STPF.Step.1"/>
            </div>
          </div>
          <div className="sts-ui-guide-screen__step">
            <div className="sts-ui-guide-screen__step-num">2</div>
            <div className="sts-ui-guide-screen__step-content">
              <FormattedHTMLMessage id="Guide.STPF.Step.2"/>
            </div>
          </div>
          <div className="sts-ui-guide-screen__step">
            <div className="sts-ui-guide-screen__step-num">3</div>
            <div className="sts-ui-guide-screen__step-content">
              <FormattedHTMLMessage id="Guide.STPF.Step.3"/>
            </div>
          </div>
          <div className="sts-ui-guide-screen__step">
            <div className="sts-ui-guide-screen__step-num">4</div>
            <div className="sts-ui-guide-screen__step-content">
              <FormattedHTMLMessage id="Guide.STPF.Step.4"/>
            </div>
          </div>
        </div>

        <p>
          <FormattedHTMLMessage id="Guide.STPF.Further.Text"/>
        </p>
      </div>
    </div>
  );
}

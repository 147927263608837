import { SagaIterator } from 'redux-saga';
import { saveBasketStore } from 'src/sagas/basket/utils/storeBasketData';
import { getBasketCurrency } from 'src/selectors/getBasketCurrency';
import { getBasketProducts } from 'src/selectors/getBasketProducts';
import { call, select } from 'typed-redux-saga';

export function* saveBasketData(): SagaIterator<void> {
  const products = yield* select(getBasketProducts);
  const currency = yield* select(getBasketCurrency);

  yield* call(saveBasketStore, {
    currency,
    products,
  });
}

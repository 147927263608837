import './RegionalPassesScreen.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Typography } from 'src/components/common/Typography';
import { ProductGridView } from 'src/components/product-list/ProductGridView';
import { ProductSecondaryCard } from 'src/components/product-list/ProductSecondaryCard';
import { ProductListItem } from 'src/types/ProductListItem';

type Props = {
  readonly products: ReadonlyArray<ProductListItem>;
};

export function RegionalPassesScreen({ products }: Props): React.ReactElement {
  return (
    <div className="sts-ui-regional-passes-screen">
      <div className="sts-ui-regional-passes-screen__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="RegionalPasses.Page.Title"/>
        </Typography>
      </div>
      <div className="sts-ui-regional-passes-screen__page-subtitle">
        <Typography variant="page-subtitle" as="p">
          <FormattedHTMLMessage id="RegionalPasses.Page.Description"/>
        </Typography>
      </div>

      <div className="sts-ui-regional-passes-screen__content">
        <ProductGridView>
          {products.map((product) => (
            <ProductSecondaryCard
              key={product.code}
              code={product.code}
              price={product.price}
            />
          ))}
        </ProductGridView>
      </div>
    </div>
  );
}

import { ONE_TRUST_READY } from 'src/actions/OneTrustActions';
import { OneTrustState } from 'src/types/OneTrustState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: OneTrustState = {
  ready: false,
};

export const oneTrustReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (ONE_TRUST_READY.is(action)) {
    return { ready: true };
  }

  return state;
});

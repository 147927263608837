import * as C from 'io-ts/Codec';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { ProductMap } from 'src/codecs/combinators/ProductMap';
import { CURRENCY } from 'src/codecs/Currency';
import { PRODUCT_CODE } from 'src/codecs/ProductCode';
import { TICKET_PRICE } from 'src/codecs/TicketPrice';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { ProductMap as ProductMapType } from 'src/types/ProductMap';
import { TicketPrice } from 'src/types/TicketPrice';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchProductsPriceTables(
  currency: Currency,
  products: ReadonlyArray<ProductCode>,
): SagaIterator<ProductMapType<TicketPrice[] | null>> {
  try {
    const request = yield* call(REQUEST.encode, { types: products, currency: currency });
    const response = yield* call(makeGetRequest, `${HOST_API}/ticket-price-per-persons`, request);
    return yield* call(decodeOrThrow, RESPONSE, response.content);
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch products price table',
      { currency, products },
      NativeError.wrap(error),
    );
  }
}

const REQUEST = E.struct({
  currency: CURRENCY,
  types: E.readonly(E.array(PRODUCT_CODE)),
});

const RESPONSE = ProductMap(C.nullable(C.array(TICKET_PRICE)));

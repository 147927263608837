import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { Language } from 'src/types/Language';

export const FORMAT_DATE_SHORT = 'dd.MM.yyyy';
export const FORMAT_DATE_LONG: ReadonlyRecord<Language, string> = {
  'en': 'dd MMMM yyyy',
  'it': 'dd MMMM yyyy',
  'es': 'dd MMMM yyyy',
  'fr': 'dd MMMM yyyy',
  'nl': 'dd MMMM yyyy',
  'de': 'dd MMMM yyyy',
  'ja': 'dd MMMM yyyy',
  'ko': 'dd MMMM yyyy',
  'zh-Hans': 'dd/MM/yy',
  'zh-Hant': 'dd/MM/yy',
};

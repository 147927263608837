import { SearchCondition } from 'src/types/SearchCondition';
import { SearchProduct } from 'src/types/SearchProduct';
import { TicketType } from 'src/types/TicketType';

export const PERIOD_4_DAYS = '1-4day';
export const PERIOD_WEEK = 'week';
export const PERIOD_WEEK_PLUS = 'weekPlus';

export const FREQUENCY_EVERY_DAY = 'everyday';
export const FREQUENCY_SOME_DAYS = 'somedays';

export const EXCURSION_YES = 'yes';
export const EXCURSION_NO = 'no';
export const EXCURSION_UNKNOWN = 'unknown';

export const SEARCH_PRODUCTS: ReadonlyArray<SearchProduct> = [
  TicketType.SWISS_TRAVEL_PASS,
  TicketType.SWISS_TRAVEL_PASS_FLEX,
  TicketType.SWISS_HALF_FARE_CARD,
];

export const SEARCH_CONDITIONS: Readonly<Record<SearchProduct, ReadonlyArray<SearchCondition>>> = {
  [TicketType.SWISS_TRAVEL_PASS]: [
    {
      weight: 1,
      period: PERIOD_4_DAYS,
      frequency: FREQUENCY_SOME_DAYS,
      excursion: EXCURSION_YES,
    },
    {
      weight: 1,
      period: PERIOD_4_DAYS,
      frequency: FREQUENCY_SOME_DAYS,
      excursion: EXCURSION_UNKNOWN,
    },
    {
      weight: 0,
      period: PERIOD_4_DAYS,
      frequency: FREQUENCY_EVERY_DAY,
      excursion: null,
    },
    {
      weight: 0,
      period: PERIOD_WEEK,
      frequency: FREQUENCY_EVERY_DAY,
      excursion: null,
    },
    {
      weight: 0,
      period: PERIOD_WEEK_PLUS,
      frequency: FREQUENCY_EVERY_DAY,
      excursion: null,
    },
  ],
  [TicketType.SWISS_TRAVEL_PASS_FLEX]: [
    {
      weight: 0,
      period: PERIOD_4_DAYS,
      frequency: FREQUENCY_SOME_DAYS,
      excursion: null,
    },
    {
      weight: 0,
      period: PERIOD_WEEK,
      frequency: FREQUENCY_SOME_DAYS,
      excursion: null,
    },
    {
      weight: 0,
      period: PERIOD_WEEK_PLUS,
      frequency: FREQUENCY_SOME_DAYS,
      excursion: null,
    },
  ],
  [TicketType.SWISS_HALF_FARE_CARD]: [
    {
      weight: 2,
      period: PERIOD_4_DAYS,
      frequency: FREQUENCY_SOME_DAYS,
      excursion: EXCURSION_YES,
    },
    {
      weight: 1,
      period: PERIOD_4_DAYS,
      frequency: FREQUENCY_SOME_DAYS,
      excursion: EXCURSION_NO,
    },
    {
      weight: 2,
      period: PERIOD_4_DAYS,
      frequency: FREQUENCY_SOME_DAYS,
      excursion: EXCURSION_UNKNOWN,
    },
    {
      weight: 1,
      period: PERIOD_WEEK,
      frequency: FREQUENCY_EVERY_DAY,
      excursion: null,
    },
    {
      weight: 1,
      period: PERIOD_WEEK,
      frequency: FREQUENCY_SOME_DAYS,
      excursion: null,
    },
    {
      weight: 1,
      period: PERIOD_WEEK_PLUS,
      frequency: FREQUENCY_EVERY_DAY,
      excursion: null,
    },
    {
      weight: 1,
      period: PERIOD_WEEK_PLUS,
      frequency: FREQUENCY_SOME_DAYS,
      excursion: null,
    },
  ],
};

import { ProductCode } from 'src/types/ProductCode';
import { ProductData } from 'src/types/ProductData';
import { ProductFormData } from 'src/types/ProductFormData';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const PRODUCT_FETCH = createTrigger<ProductData, ProductCode>('PRODUCT_FETCH');
export const PRODUCT_RESET = createTrigger<void>('PRODUCT_RESET');

export const PRODUCT_CHANGE =
  createActions<ProductFormData, ProductData, ProductCode>('PRODUCT_CHANGE');
export const PRODUCT_SUBMIT =
  createActions<ProductFormData, ProductData, ProductCode>('PRODUCT_SUBMIT');

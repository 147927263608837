import AMERICAN_EXPRESS from 'src/assets/img/payment-types/AMERICAN_EXPRESS.png';
import DINERS from 'src/assets/img/payment-types/DINERS.png';
import MAESTRO from 'src/assets/img/payment-types/MAESTRO.png';
import MASTER_CARD from 'src/assets/img/payment-types/MASTER_CARD.png';
import SOFORT from 'src/assets/img/payment-types/SOFORT.png';
import VISA from 'src/assets/img/payment-types/VISA.png';
import { PaymentAttributes } from 'src/types/PaymentAttributes';
import { PaymentCode } from 'src/types/PaymentCode';

export const PAYMENT_TYPES: ReadonlyMap<PaymentCode, PaymentAttributes> = new Map([
  ['AMERICAN_EXPRESS', {
    image: AMERICAN_EXPRESS,
    title: 'PaymentType.AMERICAN_EXPRESS',
  }],
  ['VISA', {
    image: VISA,
    title: 'PaymentType.VISA',
  }],
  ['DINERS', {
    image: DINERS,
    title: 'PaymentType.DINERS',
  }],
  ['MAESTRO', {
    image: MAESTRO,
    title: 'PaymentType.MAESTRO',
  }],
  ['MASTER_CARD', {
    image: MASTER_CARD,
    title: 'PaymentType.MASTER_CARD',
  }],
  ['SOFORT', {
    image: SOFORT,
    title: 'PaymentType.SOFORT',
  }],
]);

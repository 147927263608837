import './ProductFormViewAgreement.scss';
import { FormController } from '@form-ts/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckboxField } from 'src/forms/fields/CheckboxField';
import { FormError } from 'src/forms/types/FormError';
import { defaultIfNull } from 'src/forms/utils/transform';
import { ProductFormData } from 'src/types/ProductFormData';
import { TicketFields } from 'src/types/TicketFields';

type Props = {
  readonly form: FormController<ProductFormData, FormError>;
  readonly fields: TicketFields;
};

export function ProductFormViewAgreement({ form, fields }: Props): React.ReactElement | null {
  if (!fields.displayFields.agreement) {
    return null;
  }

  return (
    <div className="sts-ui-product-form-view-agreement">
      <CheckboxField
        field={form.field.at('agreement').transform(defaultIfNull(false))}
        label={<FormattedMessage id="Form.Product.IConfirmNotUseBySwissCitizen"/>}
      />
    </div>
  );
}

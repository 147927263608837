import './MobileMenuModal.scss';
import React from 'react';
import ReactModal, { Classes, Styles } from 'react-modal';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly children: React.ReactNode;
};

export function MobileMenuModal({ open, onClose, children }: Props): React.ReactElement {
  return (
    <ReactModal
      isOpen={open}
      style={NO_STYLE}
      className={CONTENT_CLASES}
      overlayClassName={OVERLAY_CLASES}
      bodyOpenClassName="sts-ui-root--no-scroll"
      htmlOpenClassName="sts-ui-root--no-scroll"
      preventScroll={true}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onClose}
    >
      {children}
    </ReactModal>
  );
}

const NO_STYLE: Styles = {
  overlay: {},
  content: {},
};
const OVERLAY_CLASES: Classes = {
  base: 'sts-ui-mobile-menu-modal__overlay',
  afterOpen: 'sts-ui-mobile-menu-modal__overlay--open',
  beforeClose: 'sts-ui-mobile-menu-modal__overlay--close',
};
const CONTENT_CLASES: Classes = {
  base: 'sts-ui-mobile-menu-modal__content',
  afterOpen: 'sts-ui-mobile-menu-modal__content--open',
  beforeClose: 'sts-ui-mobile-menu-modal__content--close',
};

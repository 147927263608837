import './BasketIndicator.scss';
import React from 'react';
import { BasketIcon } from 'src/components/icons/BasketIcon';
import { Integer } from 'src/types/Integer';

type Props = {
  readonly count: Integer;
};

export function BasketIndicator({ count }: Props): React.ReactElement {
  return (
    <div className="sts-ui-basket-indicator">
      <div className="sts-ui-basket-indicator__icon">
        <BasketIcon/>
      </div>
      <div className="sts-ui-basket-indicator__count">
        {count}
      </div>
    </div>
  );
}

import { BasketProduct } from 'src/types/BasketProduct';
import { Integer } from 'src/types/Integer';
import { ProductCode } from 'src/types/ProductCode';
import { ProductMap } from 'src/types/ProductMap';
import { TicketType } from 'src/types/TicketType';

export function countFamilyCards(basket: ProductMap<BasketProduct>): Integer {
  return Math.max(
    [...PRODUCT_TYPE_WITH_FAMILY_CARD_DEFAULT].reduce((result, type) => {
      const product = basket[type];
      if (!product) {
        return result;
      }

      const childCount = product.formData.child ?? 0;
      return Math.max(result, childCount);
    }, 0),

    [...PRODUCT_TYPE_WITH_FAMILY_CARD_OPTIONAL].reduce((result, type) => {
      const product = basket[type];
      if (!product || !product.formData.familyCard) {
        return result;
      }

      const childCount = product.formData.child ?? 0;
      return Math.max(result, childCount);
    }, 0),
  );
}

const PRODUCT_TYPE_WITH_FAMILY_CARD_DEFAULT = new Set<ProductCode>([
  TicketType.SWISS_HALF_FARE_CARD,
]);

const PRODUCT_TYPE_WITH_FAMILY_CARD_OPTIONAL = new Set<ProductCode>([
  // TicketType.SWISS_TRAVEL_PASS_PROMO,
  // TicketType.SWISS_TRAVEL_PASS_FLEX_PROMO,

  TicketType.SWISS_TRAVEL_PASS,
  TicketType.SWISS_TRAVEL_PASS_FLEX,
]);

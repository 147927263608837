import { SagaIterator } from 'redux-saga';
import { BASKET_REMOVE } from 'src/actions/BasketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { fetchBasketData } from 'src/sagas/basket/utils/fetchBasketData';
import { saveBasketData } from 'src/sagas/basket/utils/saveBasketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getBasketProducts } from 'src/selectors/getBasketProducts';
import { removeBasketProduct } from 'src/utils/basket/removeBasketProduct';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* basketRemoveSaga(
  action: GetRequestActionType<typeof BASKET_REMOVE>,
): SagaIterator<void> {
  const productType = action.meta;

  try {
    yield* call(logDebug, `Removing basket product "${productType}"…`);
    yield* put(BASKET_REMOVE.pending(productType));

    yield* call(delay, 500);
    const currentProducts = yield* select(getBasketProducts);
    const updatedProducts = yield* call(removeBasketProduct, productType, currentProducts);

    const currency = yield* select(getCurrentCurrency);
    const language = yield* select(getCurrentLanguage);

    const basket = yield* call(
      fetchBasketData,
      currency,
      language,
      updatedProducts,
    );

    yield* call(logDebug, `Removing basket product "${productType}"… done`, basket);
    yield* put(BASKET_REMOVE.success(basket, productType));
    yield* call(saveBasketData);
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not remove basket product',
      { productType },
      NativeError.wrap(error),
    ));

    yield* call(logError, `Removing basket product "${productType}"… error`, error);
    yield* put(BASKET_REMOVE.failure(NativeError.wrap(error), productType));
  }
}

import { SagaIterator } from 'redux-saga';
import {
  CHECKOUT_FETCH,
  CHECKOUT_PAYMENT_CHANGE,
  CHECKOUT_PAYMENT_SUBMIT,
  CHECKOUT_PERSONAL_CHANGE,
  CHECKOUT_PERSONAL_SUBMIT,
} from 'src/actions/CheckoutActions';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { checkoutPageSaga } from 'src/sagas/checkout/checkoutPageSaga';
import { cancel, take, takeLatest } from 'typed-redux-saga';

export function* checkoutSaga(): SagaIterator<void> {
  while (true) {
    yield* take(CHECKOUT_FETCH.is);

    const task = yield* takeLatest([
      CHECKOUT_PERSONAL_CHANGE.isRequest,
      CHECKOUT_PERSONAL_SUBMIT.isRequest,
      CHECKOUT_PAYMENT_CHANGE.isRequest,
      CHECKOUT_PAYMENT_SUBMIT.isRequest,
    ], checkoutPageSaga);

    // freeze the page state when user triggers navigation
    yield* take(ROUTE_CHANGE.isPending);
    yield* cancel(task);
  }
}

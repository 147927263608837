/* eslint-disable import/no-import-module-exports */
import { composeWithDevTools } from '@redux-devtools/extension';
import { constVoid } from 'fp-ts/function';
import { createBrowserHistory } from 'history';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { applyMiddleware, createStore, Middleware, Reducer, Store } from 'redux';
import { createReduxHistoryContext, RouterState } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from 'src/reducers/rootReducer';
import { Root } from 'src/Root';
import { rootSaga } from 'src/sagas/rootSaga';
import { RootState } from 'src/types/RootState';
import { sentryCatch } from 'src/utils/sentryCatch';

export function init(
  rootElement: HTMLElement,
): void {
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
    reduxTravelling: false,
    savePreviousLocations: 5,
    routerReducerKey: 'router',
  });

  const sagaMiddleware = createSagaMiddleware({
    onError(error: Error, { sagaStack }) {
      /* eslint-disable no-console */
      console.error(error);
      console.error(sagaStack);

      sentryCatch(error);
    },
  });

  const store = rootStore(routerReducer, [
    sagaMiddleware,
    routerMiddleware,
  ]);

  let sagaTask = sagaMiddleware.run(rootSaga);

  const reactRoot = createRoot(rootElement);
  const reduxHistory = createReduxHistory(store);

  const renderApp = (RootElement: typeof Root): void => reactRoot.render((
    <RootElement
      store={store}
      history={reduxHistory}
    />
  ));

  renderApp(Root);

  // Hot Module Replacement API
  if (import.meta.hot) {
    import.meta.hot.accept('./Root', (newModule) => {
      if (!newModule) {
        return;
      }

      // eslint-disable-next-line
      const nextRoot: typeof Root = newModule.Root;
      renderApp(nextRoot);
    });
    import.meta.hot.accept('./sagas/rootSaga', (newModule) => {
      if (!newModule) {
        return;
      }

      sagaTask.cancel();
      sagaTask.toPromise().then(() => {
        // eslint-disable-next-line
        const nextSaga: typeof rootSaga = newModule.rootSaga
        sagaTask = sagaMiddleware.run(nextSaga);

        // NOTE: trigger history after HMR
        reduxHistory.replace(reduxHistory.location);
      }, constVoid);
    });
    import.meta.hot.accept('./reducers/rootReducer', (newModule) => {
      if (!newModule) {
        return;
      }

      // eslint-disable-next-line
      const nextReducer: typeof rootReducer = newModule.rootReducer;
      store.replaceReducer(nextReducer(routerReducer));
    });
  }
}

function rootStore(
  routerReducer: Reducer<RouterState>,
  middlewares: ReadonlyArray<Middleware>,
): Store<RootState> {
  return createStore(
    rootReducer(routerReducer),
    composeWithDevTools({ name: 'STS UI', trace: false })(applyMiddleware(...middlewares)),
  );
}

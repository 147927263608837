import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { checkCheckoutPersonalData } from 'src/sagas/checkout/utils/checkCheckoutPersonalData';
import { fetchCheckoutPersonalData } from 'src/sagas/checkout/utils/fetchCheckoutPersonalData';
import { fetchCheckoutTotalPrice } from 'src/sagas/checkout/utils/fetchCheckoutTotalPrice';
import { BasketProduct } from 'src/types/BasketProduct';
import { CheckoutData } from 'src/types/CheckoutData';
import { Currency } from 'src/types/Currency';
import { Language } from 'src/types/Language';
import { ProductMap } from 'src/types/ProductMap';
import { call } from 'typed-redux-saga';

export function* fetchCheckoutPaymentData(
  currency: Currency,
  language: Language,
  basket: ProductMap<BasketProduct>,
): SagaIterator<CheckoutData | null> {
  try {
    const personalData = yield* call(
      fetchCheckoutPersonalData,
      currency,
      language,
      basket,
    );
    if (personalData === null) {
      return null;
    }

    const isValid = yield* call(checkCheckoutPersonalData, personalData);
    if (!isValid) {
      return null;
    }

    const totalPrice = yield* call(
      fetchCheckoutTotalPrice,
      currency,
      language,
      basket,
      personalData.formData,
    );

    return {
      ...personalData,
      totalPrice,
    };
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Checkout Payment data',
      { currency, language, basket },
      NativeError.wrap(error),
    );
  }
}

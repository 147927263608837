import { SagaIterator } from 'redux-saga';
import { BASKET_FETCH } from 'src/actions/BasketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { Transition } from 'src/routing/types/Transition';
import { fetchBasketData } from 'src/sagas/basket/utils/fetchBasketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getBasketProducts } from 'src/selectors/getBasketProducts';
import { Language } from 'src/types/Language';
import { call, put, select } from 'typed-redux-saga';

export function* fetchBasket(language: Language, transition: Transition): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching Basket data…');

    const currency = transition.to.currency;
    const products = yield* select(getBasketProducts);

    const data = yield* call(fetchBasketData, currency, language, products);

    yield* call(logDebug, 'Fetching Basket data… done', data);
    yield* put(BASKET_FETCH.trigger(data));
  } catch (error) {
    yield* call(logError, 'Fetching Basket data… error', error);
    throw new RuntimeError('Could not fetch Basket data', {}, NativeError.wrap(error));
  }
}

import { SagaIterator } from 'redux-saga';
import { redirectTo } from 'src/routing/sagas/utils/redirectTo';
import { Transition } from 'src/routing/types/Transition';
import { getRouteFullPath } from 'src/routing/utils/getRouteFullPath';
import { getAffiliateLanguage } from 'src/selectors/getAffiliateLanguage';
import { sentryLog } from 'src/utils/sentryLog';
import { call, cancel, select } from 'typed-redux-saga';

export function* redirectToDefaultPage(transition: Transition): SagaIterator<void> {
  const currency = transition.to.currency;
  const language = yield* select(getAffiliateLanguage);
  const pathname = yield* call(getRouteFullPath, language, 'homepage');

  yield* call(sentryLog, 'Redirect to default URL', {
    level: 'info',
    fingerprint: [
      'redirect',
      'default',
    ],
    context: {
      source: transition.to.location,
      target: { currency, pathname },
    },
  });

  yield* call(redirectTo, { pathname }, currency);
  yield* cancel();
}

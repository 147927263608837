import { Language } from 'src/types/Language';

export const ROUTING_LANGUAGES: ReadonlyArray<Language> = [
  'en',
  'de',
  'it',
  'es',
  'fr',
  'nl',
  'ja',
  'ko',
  'zh-Hans',
  'zh-Hant',
];

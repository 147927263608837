import { createSelector } from 'reselect';
import { getCheckoutCustomerCountryList } from 'src/selectors/getCheckoutCustomerCountryList';
import { getCheckoutTravelerCountries } from 'src/selectors/getCheckoutTravelerCountries';
import { CountryCode } from 'src/types/CountryCode';
import { ListOption } from 'src/types/ListOption';

export const getCheckoutTravelerCountryList = createSelector([
  getCheckoutTravelerCountries,
  getCheckoutCustomerCountryList,
], (travelerCountries, fullCountryList): ReadonlyArray<ListOption<CountryCode>> => {
  const available = new Set(travelerCountries);
  return fullCountryList.filter((it) => available.has(it.value));
});

import React from 'react';
import { useSelector } from 'react-redux';
import { PaymentSuccessScreen } from 'src/components/payment/PaymentSuccessScreen';
import { getIsIframeMode } from 'src/selectors/getIsIframeMode';
import { getPaymentData } from 'src/selectors/getPaymentData';
import { getPaymentProducts } from 'src/selectors/getPaymentProducts';

export function PaymentSuccessPage(): React.ReactElement {
  const payment = useSelector(getPaymentData);
  const products = useSelector(getPaymentProducts);
  const isIframe = useSelector(getIsIframeMode);

  return (
    <PaymentSuccessScreen
      payment={payment}
      products={products}
      isIframe={isIframe}
    />
  );
}

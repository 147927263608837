import { SagaIterator } from 'redux-saga';
import { PRODUCT_FETCH } from 'src/actions/ProductActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { Transition } from 'src/routing/types/Transition';
import { fetchProductData } from 'src/sagas/product/utils/fetchProductData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getBasketProducts } from 'src/selectors/getBasketProducts';
import { Language } from 'src/types/Language';
import { ProductCode } from 'src/types/ProductCode';
import { call, put, select } from 'typed-redux-saga';

export function* fetchProduct(
  type: ProductCode,
  language: Language,
  transition: Transition,
): SagaIterator<void> {
  try {
    yield* call(logDebug, `Fetching product "${type}"…`);

    const currency = transition.to.currency;
    const basket = yield* select(getBasketProducts);
    const product = yield* call(fetchProductData, type, currency, language, basket);

    yield* call(logDebug, `Fetching product "${type}"… done`, product);
    yield* put(PRODUCT_FETCH.trigger(product, type));
  } catch (error) {
    yield* call(logError, `Fetching product "${type}"… error`, error);
    throw new RuntimeError('Could not fetch product', { type }, NativeError.wrap(error));
  }
}

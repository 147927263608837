import './HubSpotForm.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'src/utils/bind';

type Props = {
  readonly region: string;
  readonly portalId: string;
  readonly formId: string;
};

export class HubSpotForm extends React.Component<Props> {
  private readonly script: HTMLScriptElement = window.document.createElement('script');

  private readonly uniqueId = `form-${Math.random().toString(36).substring(2)}`;

  public componentDidMount(): void {
    this.script.src = '//js.hsforms.net/forms/embed/v2.js';
    this.script.addEventListener('load', this.embedForm);

    window.document.body.appendChild(this.script);
  }

  public componentWillUnmount(): void {
    this.script.removeEventListener('load', this.embedForm);
    window.document.body.removeChild(this.script);
  }

  public render(): React.ReactElement {
    return (
      <div id={this.uniqueId} className="sts-ui-hubspot-form">
        <div className="sts-ui-hubspot-form__loader">
          <FormattedMessage id="Loader.Loading"/>
        </div>
      </div>
    );
  }

  @bind
  private embedForm(): void {
    const { region, portalId, formId } = this.props;

    try {
      window.hbspt.forms.create({
        region: region,
        portalId: portalId,
        formId: formId,
        target: `#${this.uniqueId}`,

        cssClass: 'sts-ui-hubspot-form__form',
        submitButtonClass: 'sts-ui-hubspot-form__submit',
      });
    } catch {
      // noop
    }
  }
}

import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchProductsMinimalStartDates } from 'src/sagas/common/utils/fetchProductsMinimalStartDates';
import { getProductTypes } from 'src/selectors/getProductTypes';
import { BasketProduct } from 'src/types/BasketProduct';
import { Currency } from 'src/types/Currency';
import { ProductMap } from 'src/types/ProductMap';
import { TicketMinimalStartDate } from 'src/types/TicketMinimalStartDate';
import { call, select } from 'typed-redux-saga';

export function* fetchBasketMinimalStartDate(
  currency: Currency,
  basket: ProductMap<BasketProduct>,
): SagaIterator<ProductMap<TicketMinimalStartDate>> {
  try {
    const products = yield* select(getProductTypes);
    return yield* call(
      fetchProductsMinimalStartDates,
      currency,
      products.map((it) => it.code),
      basket,
    );
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Basket minimal start date',
      { currency, basket },
      NativeError.wrap(error),
    );
  }
}

import { createSelector } from 'reselect';
import { getBasketState } from 'src/selectors/getBasketState';
import { assertNotEmpty } from 'src/utils/assert';

export const getBasketCurrency = createSelector([
  getBasketState,
], (basket) => assertNotEmpty(
  basket.currency,
  'Basket currency is not initialized',
));

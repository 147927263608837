import './Stepper.scss';
import classNames from 'classnames';
import React from 'react';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { RouteName } from 'src/routing/types/RouteName';
import { Integer } from 'src/types/Integer';

export type StepperItem = {
  readonly title: React.ReactNode;
  readonly route: RouteName | null;
};

type Props = {
  readonly items: ReadonlyArray<StepperItem>;
  readonly active: Integer;
};

export function Stepper({ items, active }: Props): React.ReactElement {
  return (
    <ul className="sts-ui-stepper">
      {items.map((item, index) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={classNames({
            'sts-ui-stepper__step': true,
            'sts-ui-stepper__step--current': index === active,
            'sts-ui-stepper__step--completed': index < active,
            'sts-ui-stepper__step--disabled': index > active,
          })}
        >
          <div className="sts-ui-stepper__content">
            {item.route !== null && (
              <RouteLink className="sts-ui-stepper__link" route={item.route}>
                <div className="sts-ui-stepper__counter">
                  {index + 1}
                </div>
                <div className="sts-ui-stepper__title">
                  {item.title}
                </div>
              </RouteLink>
            )}
            {item.route === null && (
              <div className="sts-ui-stepper__item">
                <div className="sts-ui-stepper__counter">
                  {index + 1}
                </div>
                <div className="sts-ui-stepper__title">
                  {item.title}
                </div>
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
}

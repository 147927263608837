import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getGtmTrackingEnabled } from 'src/selectors/getGtmTrackingEnabled';
import { getOverrideGtmTrackingId } from 'src/selectors/getOverrideGtmTrackingId';
import { Affiliate } from 'src/types/Affiliate';
import { injectGtm } from 'src/utils/gtm';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, select } from 'typed-redux-saga';

export function* initGoogleTagManager(affiliate: Affiliate): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Initializing GTM…');

    const gtmTrackingEnabled = yield* select(getGtmTrackingEnabled);
    if (!gtmTrackingEnabled) {
      yield* call(logDebug, 'Initializing GTM… skip');
      return;
    }

    const overrideGtmTrackingId = yield* select(getOverrideGtmTrackingId);
    const gtmTrackingId = overrideGtmTrackingId || affiliate.gtmTrackingId;
    if (!gtmTrackingId) {
      yield* call(logDebug, 'Initializing GTM… skip');
      return;
    }

    yield* call(injectGtm, gtmTrackingId);
    yield* call(logDebug, 'Initializing GTM… done', affiliate);
  } catch (error) {
    yield* call(logError, 'Initializing GTM… error', error);
    yield* call(sentryCatch, new RuntimeError('Could not initialize GTM', { affiliate }, NativeError.wrap(error)));
  }
}

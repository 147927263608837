import { CONFIG_FETCH } from 'src/actions/ConfigActions';
import {
  AGE_GROUPS_TRANSLATIONS,
  FAQ_DATA,
  PRODUCT_ATTRIBUTES,
  PRODUCT_DISCOUNT_NAMES,
  SEO_CONFIG,
  TRAVEL_FREE_AGES,
} from 'src/constants/config';
import { ConfigState } from 'src/types/ConfigState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: ConfigState = {
  productTypes: null,
  paymentTypes: null,

  seoConfig: SEO_CONFIG,
  faqData: FAQ_DATA,
  productsDiscounts: PRODUCT_DISCOUNT_NAMES,
  ageGroupsTranslations: AGE_GROUPS_TRANSLATIONS,
  productAttributes: PRODUCT_ATTRIBUTES,
  travelFreeAges: TRAVEL_FREE_AGES,
};

export const configReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (CONFIG_FETCH.is(action)) {
    return {
      ...state,
      productTypes: action.data.productTypes,
      paymentTypes: action.data.paymentTypes,
    };
  }

  return state;
});

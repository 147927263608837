import { pipe } from 'fp-ts/function';
import * as D from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { PRODUCT_TYPE } from 'src/codecs/ProductType';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { ConfigDynamic } from 'src/types/ConfigDynamic';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { isNotNull } from 'src/utils/guard';
import { call } from 'typed-redux-saga';

export function* fetchProductTypes(): SagaIterator<ConfigDynamic['productTypes']> {
  try {
    const response = yield* call(makeGetRequest, `${HOST_API}/ticket-types`);
    return yield* call(decodeOrThrow, PRODUCT_TYPE_LIST, response.content);
  } catch (error) {
    throw new RuntimeError('Could not fetch product types', {}, NativeError.wrap(error));
  }
}

const PRODUCT_TYPE_EXTENDED = pipe(
  D.struct({ code: D.string }),
  D.parse(() => D.success(null)),
);
const PRODUCT_TYPE_LIST = pipe(
  D.array(D.union(PRODUCT_TYPE, PRODUCT_TYPE_EXTENDED)),
  D.parse((list) => D.success(list.filter(isNotNull))),
);

import './ProductCardInformation.scss';
import React from 'react';
import { ProductCardTooltip } from 'src/components/common/ProductCardTooltip';
import { InfoOutlinedIcon } from 'src/components/icons/InfoOutlinedIcon';
import { ProductCode } from 'src/types/ProductCode';

type Props = {
  readonly code: ProductCode;
  readonly title: React.ReactNode;
};

export function ProductCardInformation({ code, title }: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-card-information">
      <div className="sts-ui-product-card-information__trigger">
        <div className="sts-ui-product-card-information__icon">
          <InfoOutlinedIcon/>
        </div>
        <div className="sts-ui-product-card-information__tooltip">
          <ProductCardTooltip type={code}/>
        </div>
      </div>
      <div className="sts-ui-product-card-information__text">
        {title}
      </div>
    </div>
  );
}

import { createSelector } from 'reselect';
import { INTL_LOCALES } from 'src/constants/locale';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';

export const getIntlCollator = createSelector([
  getCurrentLanguage,
], (language) => new Intl.Collator(INTL_LOCALES[language], {
  caseFirst: 'lower',
  ignorePunctuation: true,
  usage: 'sort',
  sensitivity: 'base',
}));

import './RouterError.scss';
import React from 'react';
import { BaseError } from 'src/errors/BaseError';

type Props = {
  readonly error: BaseError;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function RouterError({ error }: Props): React.ReactElement {
  return (
    <div className="sts-ui-router-error">
      <div className="sts-ui-router-error__body">
        <div className="sts-ui-router-error__code">
          <h3>An internal server error occurred.</h3>
          <h1><span>5</span><span>0</span><span>0</span></h1>
        </div>
        <h2 className="sts-ui-router-error__text">
          We are sorry, something went wrong.<br/>
          We&apos;re looking to see what happened.
        </h2>
        <div className="sts-ui-router-error__action">
          <a href="/" className="sts-ui-primary-button">
            Home page
          </a>
        </div>
      </div>
    </div>
  );
}

import { addDays } from 'date-fns/addDays';
import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { saveAffiliateStore } from 'src/sagas/affiliate/utils/storeAffiliateData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { Affiliate } from 'src/types/Affiliate';
import { dateNow } from 'src/utils/date';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call } from 'typed-redux-saga';

export function* saveAffiliateData(affiliate: Affiliate): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Saving affiliate data…', affiliate);

    if (!affiliate.lifeTime) {
      yield* call(logDebug, 'Saving affiliate data… skip');
      yield* call(saveAffiliateStore, null);
      return;
    }

    const now = yield* call(dateNow);
    const expiresAt = addDays(now, affiliate.lifeTime.toNumber());

    yield* call(saveAffiliateStore, {
      expiresAt: expiresAt,
      id: affiliate.affiliateId,
      currency: affiliate.currency,
      language: affiliate.language,
    });

    yield* call(logDebug, 'Saving affiliate data… done', affiliate);
  } catch (error) {
    yield* call(logError, 'Saving affiliate data… error', error);
    yield* call(sentryCatch, new RuntimeError('Could not save affiliate data', { affiliate }, NativeError.wrap(error)));
  }
}

import { createSelector } from 'reselect';
import { getProductData } from 'src/selectors/getProductData';

export const getProductUndeliverable = createSelector([
  getProductData,
], ({ type, minStartDate }) => {
  const shiftTickets = minStartDate.shiftTickets;
  if (shiftTickets === null) {
    return false;
  }

  const isShiftedProductChanging = shiftTickets[type] != null;
  return Object.keys(shiftTickets).length !== 0 && !isShiftedProductChanging;
});

import './MarkdownPage.scss';
import React from 'react';
import { FormattedMarkdown } from 'src/components/common/FormattedMarkdown';

type Props = {
  readonly file: string;
};

export function MarkdownPage({ file }: Props): React.ReactElement {
  return (
    <div className="sts-ui-markdown-page">
      <FormattedMarkdown id={file}/>
    </div>
  );
}

import './CheckoutNavigation.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Stepper } from 'src/components/common/Stepper';

type Props = {
  readonly current: 'personal' | 'payment';
};

export function CheckoutNavigation({ current }: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-navigation">
      <Stepper
        active={current === 'personal' ? 0 : 1}
        items={[
          {
            title: <FormattedMessage id="Menu.Checkout.BillingInformation"/>,
            route: current === 'personal' ? null : 'checkout:personal',
          },
          {
            title: <FormattedMessage id="Menu.Checkout.Payment"/>,
            route: null,
          },
        ]}
      />
    </div>
  );
}

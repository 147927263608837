import { SagaIterator } from 'redux-saga';
import { RouteChangeMeta } from 'src/routing/actions/RouteChange';
import { ROUTING_STATES } from 'src/routing/constants/RoutingStates';
import { fetchLocalization } from 'src/routing/hooks/resolvers/fetchLocalization';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { getCurrentState } from 'src/routing/selectors/getCurrentState';
import { RouteMatch } from 'src/routing/types/RouteMatch';
import { RouteState } from 'src/routing/types/RouteState';
import { getAffiliateLanguage } from 'src/selectors/getAffiliateLanguage';
import { assertDefined } from 'src/utils/assert';
import { select } from 'typed-redux-saga';

export function* createNotFoundMatch(meta: RouteChangeMeta): SagaIterator<RouteMatch> {
  const current = yield* select(getCurrentState);
  const language = current
    ? yield* select(getCurrentLanguage)
    : yield* select(getAffiliateLanguage);

  const global = assertDefined(
    ROUTING_STATES.find((it) => it.name === 'sts:ui:global'),
    'Could not find global routing state',
  );
  const state: RouteState = {
    url: meta.location.pathname,
    name: `sts:ui:notfound:${language}`,
    parent: 'sts:ui:global',
    hooks: {
      onEntering: [{
        name: 'locale',
        deps: ['globals'],
        saga: fetchLocalization.bind(null, language),
      }],
      onRetaining: [{
        name: 'locale',
        deps: ['globals'],
        saga: fetchLocalization.bind(null, language),
      }],
    },
  };

  return {
    state: state,
    params: {},
    parent: {
      state: global,
      params: {},
      parent: null,
    },
  };
}

import { identity, pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { Integer } from 'src/types/Integer';

const DECODER: D.Decoder<unknown, Integer> = pipe(
  D.number,
  D.parse((input) => {
    return Number.isSafeInteger(input)
      ? D.success(input)
      : D.failure(input, 'Integer');
  }),
);

const ENCODER: E.Encoder<number, Integer> = {
  encode: identity,
};

export const INTEGER: C.Codec<unknown, number, Integer> = C.make(
  DECODER,
  ENCODER,
);

import { Action } from 'redux';
import { ROUTE_DESTROY } from 'src/routing/actions/RouteDestroy';
import { RoutingState } from 'src/routing/types/RoutingState';

export function routeDestroyReducer(
  state: RoutingState,
  action: Action,
): RoutingState {
  if (ROUTE_DESTROY.is(action)) {
    return {
      isSynchronizing: false,
      isTransitioning: false,
      currentState: null,
      transitionError: action.data,
    };
  }

  return state;
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLocalizationProductAttributes } from 'src/selectors/getLocalizationProductAttributes';
import { ProductCode } from 'src/types/ProductCode';

type Props = {
  readonly type: ProductCode;
};

export function ProductName({ type }: Props): React.ReactElement {
  const attributes = useSelector(getLocalizationProductAttributes);

  return <FormattedMessage id={attributes[type].name}/>;
}

import { SagaIterator } from 'redux-saga';
import { EXCURSIONS_FETCH } from 'src/actions/ExcursionsActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { Transition } from 'src/routing/types/Transition';
import { fetchProductListData } from 'src/sagas/common/utils/fetchProductListData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getProductTypes } from 'src/selectors/getProductTypes';
import { ProductCode } from 'src/types/ProductCode';
import { call, put, select } from 'typed-redux-saga';

export function* fetchExcursions(transition: Transition): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching Excursions data…');

    const currency = transition.to.currency;
    const products = yield* select(getProductTypes);

    const productList = yield* call(
      fetchProductListData,
      currency,
      products
        .filter((it) => PRODUCT_CODES.includes(it.code))
        .map((it) => it.code),
      null,
    );

    yield* call(logDebug, 'Fetching Excursions data… done', productList);
    yield* put(EXCURSIONS_FETCH.trigger(productList));
  } catch (error) {
    yield* call(logError, 'Fetching Excursions data… error', error);
    throw new RuntimeError('Could not fetch Excursions data', {}, NativeError.wrap(error));
  }
}

const PRODUCT_CODES: ReadonlyArray<ProductCode> = [
  'excursionJungfraujoch',
  'excursionGornergrat',
  'excursionMatterhornParadise',
  'excursionPilatus',
  'excursionSchilthornMurren',
  'excursionSchilthornFromStechelberg',
  'excursionStanserhorn',
  'excursionTitlis',
  'excursionGlacier',
  'excursionRochersDeNaye',
];

import './Checkbox.scss';
import React, { useCallback, useRef } from 'react';
import { CheckIcon } from 'src/components/icons/CheckIcon';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';

type Props = {
  readonly id: string;
  readonly name: string;
  readonly value: boolean;
  readonly label: React.ReactNode;
  readonly onBlur?: () => void;
  readonly onChange: (value: boolean) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
};

export function Checkbox({
  id,
  name,
  value,
  label,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
}: Props): React.ReactElement {
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  }, [onChange]);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  return (
    <div className="sts-ui-form-checkbox" data-invalid={invalid}>
      <label className="sts-ui-form-checkbox__check">
        <input
          ref={inputRef}
          id={id}
          name={name}
          className="sts-ui-form-checkbox__input"
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={handleChange}
          onBlur={onBlur}
        />
        <div className="sts-ui-form-checkbox__icon">
          {value && <CheckIcon/>}
        </div>
      </label>
      <div className="sts-ui-form-checkbox__text">
        <label
          className="sts-ui-form-checkbox__label"
          htmlFor={id}
        >
          {label}
        </label>
      </div>
    </div>
  );
}

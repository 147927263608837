import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchProductsMinimalStartDates } from 'src/sagas/common/utils/fetchProductsMinimalStartDates';
import { BasketProduct } from 'src/types/BasketProduct';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { ProductMap } from 'src/types/ProductMap';
import { TicketMinimalStartDate } from 'src/types/TicketMinimalStartDate';
import { assertNotEmpty } from 'src/utils/assert';
import { call } from 'typed-redux-saga';

export function* fetchProductMinStartDate(
  code: ProductCode,
  currency: Currency,
  basket: ProductMap<BasketProduct>,
): SagaIterator<TicketMinimalStartDate> {
  try {
    const content = yield* call(
      fetchProductsMinimalStartDates,
      currency,
      [code],
      basket,
    );

    return assertNotEmpty(
      content[code],
      `No ticket "${code}" minimal start date data`,
      { content },
    );
  } catch (error) {
    throw new RuntimeError('Could not fetch product minimal start date', { code, currency, basket }, NativeError.wrap(error));
  }
}

/* eslint-disable import/order */
import 'array-flat-polyfill';
import 'url-search-params-polyfill';
/* eslint-enable import/order */

import './index.scss';
import ReactModal from 'react-modal';
import { init } from 'src/init';
import { assertNotEmpty } from 'src/utils/assert';
import { sentryInit } from 'src/utils/sentryInit';

sentryInit();

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

const root = assertNotEmpty(
  window.document.getElementById('application'),
  'Root element is not present in DOM',
);

ReactModal.setAppElement(root);
init(root);

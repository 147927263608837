import React from 'react';
import { Helmet } from 'react-helmet-async';
import { IntlProvider as Base } from 'react-intl';
import { useSelector } from 'react-redux';
import { INTL_LOCALES } from 'src/constants/locale';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { getIntlMessages } from 'src/selectors/getIntlMessages';

type Props = {
  readonly children: React.ReactNode;
};

export function IntlProvider({ children }: Props): React.ReactElement {
  const language = useSelector(getCurrentLanguage);
  const messages = useSelector(getIntlMessages);

  return (
    <Base locale={INTL_LOCALES[language]} messages={messages}>
      <Helmet>
        <html lang={language}/>
      </Helmet>

      {children}
    </Base>
  );
}

import { SagaIterator } from 'redux-saga';
import { LOCALIZATION_FETCH } from 'src/actions/LocalizationActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchTranslations } from 'src/sagas/localization/utils/fetchTranslations';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { Language } from 'src/types/Language';
import { call, put } from 'typed-redux-saga';

export function* fetchLocalization(language: Language): SagaIterator<void> {
  try {
    yield* call(logDebug, `Fetching localization "${language}"…`);

    const messages = yield* call(fetchTranslations, language);
    const localization = { messages };

    yield* call(logDebug, `Fetching localization "${language}"… done`, localization);
    yield* put(LOCALIZATION_FETCH.trigger(localization, language));
  } catch (error) {
    yield* call(logError, `Fetching localization "${language}"… error`, error);
    throw new RuntimeError('Could not fetch localization data', {}, NativeError.wrap(error));
  }
}

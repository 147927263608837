import * as C from 'io-ts/Codec';
import { SagaIterator } from 'redux-saga';
import { PAYMENT_STORE } from 'src/codecs/PaymentStore';
import { APP_VERSION } from 'src/constants/version';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageGet, storageSet } from 'src/sagas/utils/storage';
import { PaymentStore } from 'src/types/PaymentStore';
import { decodeOrGet } from 'src/utils/decodeOrGet';
import { call } from 'typed-redux-saga';

export function* loadPaymentStore(): SagaIterator<PaymentStore | null> {
  try {
    yield* call(logDebug, 'Loading payment store…');

    const storage = yield* call(storageGet, STORAGE_KEY);
    const decoded = yield* call(decodeOrGet, STORAGE_VAL, storage, null);

    yield* call(logDebug, 'Loading payment store… done', decoded);
    return decoded?.content ?? null;
  } catch (error) {
    yield* call(logError, 'Loading payment store… error', error);
    throw new RuntimeError('Could not load payment store', {}, NativeError.wrap(error));
  }
}

export function* savePaymentStore(store: PaymentStore | null): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Saving payment store…', store);

    const encoded = store === null
      ? undefined
      : yield* call(STORAGE_VAL.encode, { version: APP_VERSION, content: store });
    yield* call(storageSet, STORAGE_KEY, encoded);

    yield* call(logDebug, 'Saving payment store… done');
  } catch (error) {
    yield* call(logError, 'Saving payment store… error', error);
    throw new RuntimeError('Could not save payment stor', {}, NativeError.wrap(error));
  }
}

const STORAGE_KEY = 'sts.payment';
const STORAGE_VAL = C.struct({
  version: C.literal(APP_VERSION),
  content: PAYMENT_STORE,
});

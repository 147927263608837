import { createSelector } from 'reselect';
import { getProductData } from 'src/selectors/getProductData';
import { getProductFormData } from 'src/selectors/getProductFormData';

export const getProductFamilyCardVisible = createSelector([
  getProductData,
  getProductFormData,
], ({ fields }, formData) => {
  if (!fields.displayFields.familyCard) {
    return false;
  }

  const child = formData.child ?? 0;
  const adult = formData.adult ?? 0;
  const youth = formData.youth ?? 0;

  return child > 0 && (adult > 0 || youth > 0);
});

import { TARIFF_LIST } from 'src/constants/tariff';
import { BasketProductDetails } from 'src/types/BasketProductDetails';
import { CheckoutFormData, CheckoutTravelerFormData } from 'src/types/CheckoutFormData';
import { TariffMap } from 'src/types/TariffMap';

export function createCheckoutFormData(
  products: ReadonlyArray<BasketProductDetails>,
): CheckoutFormData {
  return {
    payment: {
      cancellationProtection: true,
      paymentType: null,

      agreement: false,
      optIn: false,
    },
    personal: {
      billing: {
        email: '',
        title: null,
        country: null,
        firstName: '',
        lastName: '',
      },
      travelers: products.reduce<CheckoutFormData['personal']['travelers']>((productMap, product) => {
        const tariffs = TARIFF_LIST.reduce<TariffMap<CheckoutTravelerFormData[]>>((tariffMap, tariff) => {
          const count = product.form[tariff];
          if (!count) {
            return tariffMap;
          }

          const travelers = Array.from({ length: count }, (_, index): CheckoutTravelerFormData => ({
            tariff: tariff,
            product: product.code,
            position: index,

            firstName: '',
            lastName: '',
            birthDate: null,

            homeCountryCode: null,
            homeFullCountryCode: null,
          }));

          return {
            ...tariffMap,
            [tariff]: travelers,
          };
        }, {});

        return {
          ...productMap,
          [product.code]: tariffs,
        };
      }, {}),
    },
  };
}

import './Popover.scss';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  Placement,
  FloatingPortal,
  FloatingFocusManager,
  UseFloatingReturn,
  UseInteractionsReturn,
} from '@floating-ui/react';
import classNames from 'classnames';
import { constVoid } from 'fp-ts/function';
import * as React from 'react';
import { LogicError } from 'src/errors/LogicError';

type PopoverOptions = {
  readonly open: boolean;
  readonly onToggle?: (open: boolean) => void;

  readonly modal: boolean;
  readonly placement: Placement;
};

type PopoverContextData = {
  readonly floating: UseFloatingReturn<HTMLElement>;
  readonly interactions: UseInteractionsReturn;

  readonly open: boolean;
  readonly onToggle: (open: boolean) => void;

  readonly modal: boolean;
  readonly placement: Placement;
};

type PopoverProps = PopoverOptions & {
  readonly children: React.ReactNode;
};

type PopoverTriggerProps = {
  readonly children: React.ReactNode;
};

const PopoverContext = React.createContext<PopoverContextData | null>(null);

export function usePopover({ placement, modal, open, onToggle = constVoid }: PopoverOptions): PopoverContextData {
  const floating = useFloating<HTMLElement>({
    placement: placement,
    open: open,
    onOpenChange: onToggle,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const click = useClick(floating.context, { enabled: true });
  const dismiss = useDismiss(floating.context);
  const role = useRole(floating.context);

  const interactions = useInteractions([click, dismiss, role]);

  return React.useMemo(() => ({
    floating,
    interactions,

    open,
    onToggle,

    modal,
    placement,
  }), [open, onToggle, interactions, floating, modal, placement]);
}

export function usePopoverContext(): PopoverContextData {
  const context = React.useContext(PopoverContext);
  if (context === null) {
    throw new LogicError('Popover components must be wrapped in <Popover />');
  }

  return context;
}

export function Popover({ children, ...props }: PopoverProps): React.ReactElement {
  const popover = usePopover(props);

  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  );
}

export const PopoverTrigger = React.forwardRef<
HTMLButtonElement,
React.HTMLProps<HTMLButtonElement> & PopoverTriggerProps
>(({ children, ...props }, propRef) => {
  const context = usePopoverContext();
  const ref = useMergeRefs([context.floating.refs.setReference, propRef]);

  return (
    <button
      ref={ref}
      type="button"
      data-state={context.open ? 'open' : 'closed'}
      className={classNames('sts-ui-popover-trigger', props.className)}
      {...context.interactions.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

export const PopoverContent = React.forwardRef<
HTMLDivElement,
React.HTMLProps<HTMLDivElement>
>(({ style, ...props }, propRef) => {
  const { floating, interactions, modal } = usePopoverContext();
  const ref = useMergeRefs([floating.refs.setFloating, propRef]);

  if (!floating.context.open) {
    return null;
  }

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floating.context} modal={modal}>
        <div
          ref={ref}
          style={{ ...floating.context.floatingStyles, ...style }}
          className={classNames('sts-ui-popover-content', props.className)}
          {...interactions.getFloatingProps(props)}
        >
          {props.children}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  );
});

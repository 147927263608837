import './ConditionsScreen.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Typography } from 'src/components/common/Typography';

export function ConditionsScreen(): React.ReactElement {
  return (
    <div className="sts-ui-conditions-screen">
      <div className="sts-ui-conditions-screen__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="Conditions.Page.Title"/>
        </Typography>
      </div>

      <div className="sts-ui-conditions-screen__content">
        <p>
          <FormattedHTMLMessage id="Conditions.Page.BlockText.1"/>
        </p>

        <div>
          <FormattedHTMLMessage id="Conditions.Page.List.1.Title"/>
        </div>
        <ul>
          <li>
            <FormattedHTMLMessage id="Conditions.Page.List.1.Item.1"/>
          </li>
          <li>
            <FormattedHTMLMessage id="Conditions.Page.List.1.Item.2"/>
          </li>
          <li>
            <FormattedHTMLMessage id="Conditions.Page.List.1.Item.3"/>
          </li>
        </ul>

        <div>
          <FormattedHTMLMessage id="Conditions.Page.List.2.Title"/>
        </div>
        <ul>
          <li>
            <FormattedHTMLMessage id="Conditions.Page.List.2.Item.1"/>
          </li>
          <li>
            <FormattedHTMLMessage id="Conditions.Page.List.2.Item.2"/>
          </li>
          <li>
            <FormattedHTMLMessage id="Conditions.Page.List.2.Item.3"/>
          </li>
        </ul>

        <div>
          <FormattedHTMLMessage id="Conditions.Page.BlockText.2"/>
        </div>
      </div>
    </div>
  );
}

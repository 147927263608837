import { FormController } from '@form-ts/core';
import { pipe } from 'fp-ts/function';
import { useEffect } from 'react';
import { FormError } from 'src/forms/types/FormError';
import { FormResolver } from 'src/forms/types/FormResolver';

export function useFormResolver<TData>(
  form: FormController<TData, FormError>,
  resolver: FormResolver<TData>,
): void {
  useEffect(() => form.middleware((nextState) => {
    const nextValues = nextState.values;
    const prevValues = form.currentState.values;

    if (nextValues === prevValues) {
      return nextState;
    }

    const syncValues = resolver(prevValues, nextValues);
    return pipe(
      nextState,
      form.values.set(syncValues),
    );
  }), [form, resolver]);
}

import { useMemo } from 'react';
import { Integer } from 'src/types/Integer';
import { ListOption } from 'src/types/ListOption';

export function useDateList(): ReadonlyArray<ListOption<Integer>> {
  return useMemo(() => Array.from({ length: 31 }, (_, index) => ({
    value: index + 1,
    title: (index + 1).toString().padStart(2, '0'),
  })), []);
}

import './NotFoundScreen.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HOMEPAGE_BACKGROUND } from 'src/constants/images';
import { RouteLink } from 'src/routing/containers/RouteLink';

export function NotFoundScreen(): React.ReactElement {
  return (
    <div className="sts-ui-not-found-screen">
      <img
        className="sts-ui-not-found-screen__img"
        {...HOMEPAGE_BACKGROUND}
        alt=""
      />

      <div className="sts-ui-not-found-screen__body">
        <div className="sts-ui-not-found-screen__code">
          <h3><FormattedMessage id="NotFound.Page.Title"/></h3>
          <h1><span>4</span><span>0</span><span>4</span></h1>
        </div>
        <h2 className="sts-ui-not-found-screen__text">
          <FormattedMessage id="NotFound.Page.Description"/>
        </h2>
        <div className="sts-ui-not-found-screen__action">
          <RouteLink route="homepage" className="sts-ui-primary-button">
            <FormattedMessage id="NotFound.Page.GoToHomepage"/>
          </RouteLink>
        </div>
      </div>
    </div>
  );
}

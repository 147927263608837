import './ProductPriceTable.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { TARIFF_LIST } from 'src/constants/tariff';
import { ProductCode } from 'src/types/ProductCode';
import { TicketAge } from 'src/types/TicketAge';
import { TicketClass } from 'src/types/TicketClass';
import { TicketPrice } from 'src/types/TicketPrice';

type Props = {
  readonly type: ProductCode;
  readonly priceTable: ReadonlyArray<TicketPrice>;
};

export class ProductPriceTable extends React.PureComponent<Props> {
  public render(): React.ReactElement | null {
    const { priceTable } = this.props;
    if (priceTable.length === 0) {
      return null;
    }

    const byDuration = priceTable.some((it) => it.duration);
    if (byDuration) {
      return (
        <div className="sts-ui-product-price-table">
          {this.renderTableByDuration()}
        </div>
      );
    } else {
      return (
        <div className="sts-ui-product-price-table">
          {this.renderTableByTariff()}
        </div>
      );
    }
  }

  private renderTableByDuration(): React.ReactNode {
    const { type, priceTable } = this.props;

    const hasFirstClass = priceTable.some((it) => it.class === TicketClass.FIRST);
    const hasSecondClass = priceTable.some((it) => it.class === TicketClass.SECOND);

    return TARIFF_LIST
      .filter((tariff) => priceTable.some((it) => it.tariff === tariff))
      .map((tariff) => (
        <table key={tariff} className="sts-ui-product-price-table__table">
          <thead>
            <tr className="sts-ui-product-price-table__head">
              <th className="sts-ui-product-price-table__col-text">
                {TARIFF_NAMES[tariff]}
              </th>
              {hasFirstClass && (
                <th className="sts-ui-product-price-table__col-amount">
                  <FormattedHTMLMessage id="Overview.HeaderFirstClass"/>
                </th>
              )}
              {hasSecondClass && (
                <th className="sts-ui-product-price-table__col-amount">
                  <FormattedHTMLMessage id="Overview.HeaderSecondClass"/>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {priceTable
              .filter((it) => it.tariff === tariff)
              .sort((a, b) => parseInt(a.duration ?? '0', 10) - parseInt(b.duration ?? '0', 10))
              .reduce<TicketPrice[][]>((groups, price) => {
                const prev = groups[groups.length - 1];
                if (!prev || prev[0].duration !== price.duration) {
                  groups.push([price]);
                } else {
                  prev.push(price);
                }
                return groups;
              }, [])
              .map((tickets) => {
                const duration = tickets[0].duration;
                const firstClass = tickets.find((it) => it.class === TicketClass.FIRST);
                const secondClass = tickets.find((it) => it.class === TicketClass.SECOND);

                return (
                  <tr key={duration} className="sts-ui-product-price-table__row">
                    <td className="sts-ui-product-price-table__col-text">
                      <FormattedMessage
                        id="Overview.Duration"
                        values={{
                          PRODUCT: type,
                          DURATION: duration?.replace(/\D/g, ''),
                        }}
                      />
                    </td>
                    {hasFirstClass && (
                      <td className="sts-ui-product-price-table__col-amount">
                        {firstClass
                          ? <FormattedPrice value={firstClass.price}/>
                          : EMPTY_CELL}
                      </td>
                    )}
                    {hasSecondClass && (
                      <td className="sts-ui-product-price-table__col-amount">
                        {secondClass
                          ? <FormattedPrice value={secondClass.price}/>
                          : EMPTY_CELL}
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      ));
  }

  private renderTableByTariff(): React.ReactNode {
    const { priceTable } = this.props;

    return (
      <table className="sts-ui-product-price-table__table">
        <thead>
          <tr className="sts-ui-product-price-table__head">
            <th className="sts-ui-product-price-table__col-text">
              <FormattedMessage id="ProductPage.PriceTable.Header.Tariff"/>
            </th>
            <th className="sts-ui-product-price-table__col-amount">
              <FormattedMessage id="ProductPage.PriceTable.Header.Price"/>
            </th>
          </tr>
        </thead>
        <tbody>
          {TARIFF_LIST.map((tariff) => {
            const price = priceTable.find((it) => it.tariff === tariff);
            if (!price) {
              return null;
            }

            return (
              <tr key={tariff} className="sts-ui-product-price-table__row">
                <td className="sts-ui-product-price-table__col-text">
                  {TARIFF_NAMES[tariff]}
                </td>
                <td className="sts-ui-product-price-table__col-amount">
                  <FormattedPrice value={price.price}/>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

const EMPTY_CELL = <span>&nbsp;</span>;

const TARIFF_NAMES = {
  [TicketAge.ADULT]: <FormattedMessage id="Overview.Adult"/>,
  [TicketAge.YOUTH]: <FormattedMessage id="Overview.Youth"/>,
  [TicketAge.CHILD]: <FormattedMessage id="Overview.Child"/>,
};

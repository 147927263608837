import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { CURRENCY } from 'src/codecs/Currency';
import { LANGUAGE } from 'src/codecs/Language';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { CheckoutData } from 'src/types/CheckoutData';
import { Currency } from 'src/types/Currency';
import { Language } from 'src/types/Language';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchCheckoutTitles(
  currency: Currency,
  language: Language,
): SagaIterator<CheckoutData['titles']> {
  try {
    const request = yield* call(REQUEST.encode, { language, currency });
    const response = yield* call(makeGetRequest, `${HOST_API}/customer-titles`, request);
    return yield* call(decodeOrThrow, D.record(D.string), response.content);
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Checkout titles',
      { currency, language },
      NativeError.wrap(error),
    );
  }
}

const REQUEST = E.struct({
  currency: CURRENCY,
  language: LANGUAGE,
});

import { createSelector } from 'reselect';
import { getAffiliateData } from 'src/selectors/getAffiliateData';
import { assertNotEmpty } from 'src/utils/assert';

export const getAffiliateLanguage = createSelector([
  getAffiliateData,
], (affiliate) => assertNotEmpty(
  affiliate.language,
  'Affiliate data is not loaded',
  { affiliate },
));

import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchProductsMinimalStartDates } from 'src/sagas/common/utils/fetchProductsMinimalStartDates';
import { BasketProduct } from 'src/types/BasketProduct';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { ProductMap as ProductMapType } from 'src/types/ProductMap';
import { TicketMinimalStartDate } from 'src/types/TicketMinimalStartDate';
import { call } from 'typed-redux-saga';

export function* fetchNonDeliverableProducts(
  currency: Currency,
  products: ReadonlyArray<ProductCode>,
  basket: ProductMapType<BasketProduct> | null,
): SagaIterator<ProductMapType<TicketMinimalStartDate>> {
  try {
    const startDates = yield* call(fetchProductsMinimalStartDates, currency, products, basket);
    return Object.entries(startDates).reduce<ProductMapType<TicketMinimalStartDate>>((invalid, [code, startDate]) => {
      if (!startDate.startDate || startDate.shiftTickets !== null) {
        return { ...invalid, [code]: startDate };
      } else {
        return invalid;
      }
    }, {});
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch non-deliverable products',
      { currency, products, basket },
      NativeError.wrap(error),
    );
  }
}

import { createSelector } from 'reselect';
import { getCheckoutCustomerCountries } from 'src/selectors/getCheckoutCustomerCountries';
import { getCheckoutCustomerTitles } from 'src/selectors/getCheckoutCustomerTitles';
import { getCheckoutFields } from 'src/selectors/getCheckoutFields';
import { getCheckoutProducts } from 'src/selectors/getCheckoutProducts';
import { getCheckoutTravelerCountries } from 'src/selectors/getCheckoutTravelerCountries';
import { createCheckoutPersonalFormRule } from 'src/utils/checkout/createCheckoutPersonalFormRule';

export const getCheckoutPersonalFormRule = createSelector([
  getCheckoutProducts,
  getCheckoutCustomerTitles,
  getCheckoutCustomerCountries,
  getCheckoutTravelerCountries,
  getCheckoutFields,
], createCheckoutPersonalFormRule);

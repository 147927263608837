import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { CURRENCY } from 'src/codecs/Currency';
import { DATE_TIME } from 'src/codecs/DateTime';
import { LANGUAGE } from 'src/codecs/Language';
import { AffiliateStore } from 'src/types/AffiliateStore';

export const AFFILIATE_STORE: C.Codec<unknown, JsonRecord, AffiliateStore> = C.struct({
  expiresAt: DATE_TIME,
  id: C.string,
  currency: CURRENCY,
  language: LANGUAGE,
});

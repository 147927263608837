import { createSelector } from 'reselect';
import { getProductFields } from 'src/selectors/getProductFields';
import { getProductPricePeriods } from 'src/selectors/getProductPricePeriods';
import { getProductValidity } from 'src/selectors/getProductValidity';
import { createProductFormRule } from 'src/utils/product/createProductFormRule';

export const getProductFormRule = createSelector([
  getProductFields,
  getProductValidity,
  getProductPricePeriods,
], createProductFormRule);

import { configure, done, start } from 'nprogress';
import { SagaIterator } from 'redux-saga';
import { LOADER_HIDE, LOADER_SHOW } from 'src/loader/actions/LoaderActions';
import { call, take } from 'typed-redux-saga';

export function* loaderSaga(): SagaIterator<void> {
  const VISIBLE = new Map<string, null>();

  yield* call(configure, {
    showSpinner: false,
  });

  while (true) {
    const action = yield* take([LOADER_SHOW.is, LOADER_HIDE.is]);

    const key = action.meta;
    const show = LOADER_SHOW.is(action);

    if (show) {
      if (VISIBLE.has(key)) {
        continue;
      }

      VISIBLE.set(key, null);
      if (VISIBLE.size === 1) {
        yield* call(start);
      }
    } else {
      if (!VISIBLE.has(key)) {
        continue;
      }

      VISIBLE.delete(key);
      if (VISIBLE.size === 0) {
        yield* call(done);
      }
    }
  }
}

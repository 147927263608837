import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { Textbox } from 'src/forms/components/Textbox';
import { FormError } from 'src/forms/types/FormError';

type Props<TData> = {
  readonly field: FieldPath<TData, FormError, string>;
  readonly type: string;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly formatError?: (error: FormError) => string;
};

export function TextboxField<TData>({
  field,
  type,
  disabled,
  placeholder,
  formatError,
}: Props<TData>): React.ReactElement {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);
  const validity = error !== undefined && formatError !== undefined ? formatError(error) : undefined;

  const handleChange = useCallback((nextValue: string) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(nextValue),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <Textbox
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      type={type}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      invalid={invalid}
      disabled={disabled}
      validity={validity}
      placeholder={placeholder}
    />
  );
}

import React from 'react';

export function BasketIcon(): React.ReactElement {
  return (
    <svg width="1em" height="1em" viewBox="0 0 1024 960" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M492.298 890.726c0 49.338-40.439 89.334-90.323 89.334s-90.323-39.996-90.323-89.334c0-49.338 40.439-89.334 90.323-89.334s90.323 39.996 90.323 89.334zM1026.559 890.726c0 49.338-40.439 89.334-90.323 89.334s-90.323-39.996-90.323-89.334c0-49.338 40.439-89.334 90.323-89.334s90.323 39.996 90.323 89.334zM1022.224 650.7049999999999h-669.834l-264.251-622.879c-9.704-22.887-36.577-33.962-60.215-24.364-23.141 9.598-34.089 36.177-24.385 59.556l274.204 646.505c5.225 12.551 15.676 21.411 27.619 25.348 6.967 4.184 15.178 6.891 24.136 6.891h692.726c25.131 0 45.784-20.426 45.784-45.282 0-25.348-20.652-45.775-45.784-45.775z"
      />
    </svg>
  );
}

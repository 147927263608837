import './Textbox.scss';
import React, { useCallback, useRef } from 'react';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';

type Props = {
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly value: string;
  readonly onBlur?: () => void;
  readonly onChange: (value: string) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly placeholder?: string;
};

export function Textbox({
  id,
  name,
  type,
  value,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  placeholder,
}: Props): React.ReactElement {
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  return (
    <input
      ref={inputRef}
      id={id}
      name={name}
      type={type}
      className="sts-ui-form-textbox"
      data-invalid={invalid}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
}

import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { TicketClass } from 'src/types/TicketClass';

const VALUES = {
  1: TicketClass.FIRST,
  2: TicketClass.SECOND,
} as const;

const DECODER: D.Decoder<unknown, TicketClass> = pipe(
  D.literal(1, 2, '1', '2'),
  D.parse((input) => D.success(VALUES[input])),
);

export const TICKET_CLASS = C.make(DECODER, E.id());

import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useId } from 'react';
import { Checkbox } from 'src/forms/components/Checkbox';
import { FormError } from 'src/forms/types/FormError';

type Props<TData> = {
  readonly field: FieldPath<TData, FormError, boolean>;
  readonly label: React.ReactNode;
  readonly disabled?: boolean;
  readonly formatError?: (error: FormError) => string;
};

export function CheckboxField<TData>({
  field,
  label,
  disabled,
  formatError,
}: Props<TData>): React.ReactElement {
  // NOTE: multiple inputs with the same name
  const uuid = useId();

  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);
  const validity = error !== undefined && formatError !== undefined ? formatError(error) : undefined;

  const handleChange = useCallback((nextValue: boolean) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(nextValue),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <Checkbox
      id={`${field.form.name}.${field.path}.${uuid}`}
      name={`${field.path}.${uuid}`}
      label={label}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      invalid={invalid}
      disabled={disabled}
      validity={validity}
    />
  );
}

import './HomepageBackgroundImage.scss';
import React from 'react';
import { HOMEPAGE_BACKGROUND } from 'src/constants/images';

export function HomepageBackgroundImage(): React.ReactElement {
  return (
    <img
      className="sts-ui-homepage-background-image"
      {...HOMEPAGE_BACKGROUND}
      loading="lazy"
      alt=""
    />
  );
}

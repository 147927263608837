import { QueryParam, QueryRecord } from 'src/types/Query';

export function encodeQuery(query: QueryRecord): URLSearchParams {
  return Object.entries(query).reduce((url, [key, val]) => {
    if (Array.isArray(val)) {
      val.filter(isNotEmptyValue).forEach((it) => url.append(`${key}[]`, String(it)));
    } else if (isNotEmptyValue(val)) {
      url.set(key, String(val));
    }

    return url;
  }, new URLSearchParams());
}

function isNotEmptyValue(value: QueryParam | ReadonlyArray<QueryParam>): boolean {
  return (
    value !== null &&
    value !== false
  );
}

import { createSelector } from 'reselect';
import { getAffiliateState } from 'src/selectors/getAffiliateState';
import { assertNotEmpty } from 'src/utils/assert';

export const getAffiliateData = createSelector([
  getAffiliateState,
], (state) => assertNotEmpty(
  state.data,
  'Affiliate data is not loaded',
  { state },
));

import { SagaIterator } from 'redux-saga';
import { HOMEPAGE_FETCH } from 'src/actions/HomepageActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { Transition } from 'src/routing/types/Transition';
import { fetchProductListData } from 'src/sagas/common/utils/fetchProductListData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getProductTypes } from 'src/selectors/getProductTypes';
import { call, put, select } from 'typed-redux-saga';

export function* fetchHomepage(transition: Transition): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching homepage data…');

    const currency = transition.to.currency;
    const products = yield* select(getProductTypes);

    const productList = yield* call(
      fetchProductListData,
      currency,
      products.map((it) => it.code),
      null,
    );

    yield* call(logDebug, 'Fetching homepage data… done', productList);
    yield* put(HOMEPAGE_FETCH.trigger(productList));
  } catch (error) {
    yield* call(logError, 'Fetching homepage data… error', error);
    throw new RuntimeError('Could not fetch homepage data', {}, NativeError.wrap(error));
  }
}

import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchProductsPriceTables } from 'src/sagas/common/utils/fetchProductsPriceTables';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { TicketPrice } from 'src/types/TicketPrice';
import { call } from 'typed-redux-saga';

export function* fetchProductPriceTable(
  code: ProductCode,
  currency: Currency,
): SagaIterator<TicketPrice[]> {
  try {
    const content = yield* call(
      fetchProductsPriceTables,
      currency,
      [code],
    );
    return content[code] ?? [];
  } catch (error) {
    throw new RuntimeError('Could not fetch product price table', { code, currency }, NativeError.wrap(error));
  }
}

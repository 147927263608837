import './PaymentTypes.scss';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { PAYMENT_TYPES } from 'src/constants/payment';
import { getPaymentTypes } from 'src/selectors/getPaymentTypes';

export function PaymentTypes(): React.ReactElement {
  const intl = useIntl();
  const paymentTypes = useSelector(getPaymentTypes);

  return (
    <ul className="sts-ui-payment-types">
      {paymentTypes.map((it) => {
        const attrs = PAYMENT_TYPES.get(it.name);
        if (!attrs) {
          return null;
        }

        return (
          <li key={it.name} className="sts-ui-payment-types__item">
            <img
              src={attrs.image}
              alt={intl.formatMessage({ id: attrs.title })}
              className="sts-ui-payment-types__logo"
            />
          </li>
        );
      })}
    </ul>
  );
}

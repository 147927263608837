import { Action } from 'redux';
import { ROUTE_SYNCHRONOIZE } from 'src/routing/actions/RouteSynchronize';
import { RoutingState } from 'src/routing/types/RoutingState';

export function routeSynchonizeReducer(
  state: RoutingState,
  action: Action,
): RoutingState {
  if (ROUTE_SYNCHRONOIZE.is(action)) {
    return {
      ...state,
      isSynchronizing: action.data,
    };
  }

  return state;
}

import './ProductFormViewPriceList.scss';
import { Either, isLeft } from 'fp-ts/Either';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DiscountList } from 'src/components/common/DiscountList';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { TARIFF_LIST } from 'src/constants/tariff';
import { Integer } from 'src/types/Integer';
import { ProductCode } from 'src/types/ProductCode';
import { ProductPrice } from 'src/types/ProductPrice';
import { ProductPriceErrorCode } from 'src/types/ProductPriceErrorCode';
import { ProductPriceList } from 'src/types/ProductPriceList';
import { TicketAge } from 'src/types/TicketAge';
import { TicketType } from 'src/types/TicketType';
import { assertNever } from 'src/utils/assert';

type Props = {
  readonly type: ProductCode;
  readonly ticketPrice: Either<ProductPriceErrorCode, ProductPriceList>;
  readonly bookedFamilyCardCount: Integer;
};

export function ProductFormViewPriceList({
  type,
  ticketPrice,
  bookedFamilyCardCount,
}: Props): React.ReactElement {
  if (isLeft(ticketPrice)) {
    return (
      <div className="sts-ui-product-form-view-price-list">
        <div className="sts-ui-product-form-view-price-list__error">
          {getErrorMessage(ticketPrice.left)}
        </div>
      </div>
    );
  }

  const { tariffs, totalPrice } = ticketPrice.right;

  return (
    <div className="sts-ui-product-form-view-price-list">
      <table className="sts-ui-product-form-view-price-list__table">
        <thead>
          <tr>
            <td className="sts-ui-product-form-view-price-list__label">
              {/* FIXME: untranslated message */}
              Ticket
            </td>
            <td className="sts-ui-product-form-view-price-list__price">
              {/* FIXME: untranslated message */}
              Price
            </td>
          </tr>
        </thead>

        <tbody>
          {TARIFF_LIST.map((traveler) => {
            const tariff = tariffs.find((it) => it.age === traveler);
            if (!tariff) {
              return null;
            }

            return (
              <tr key={traveler}>
                <td className="sts-ui-product-form-view-price-list__label">
                  {getTariffMessage(type, tariff)} {getDiscountMessage(type, tariff)}
                </td>
                <td className="sts-ui-product-form-view-price-list__price">
                  <FormattedPrice value={tariff.price}/>
                </td>
              </tr>
            );
          })}
          {bookedFamilyCardCount > 0 && (
            <tr>
              <td className="sts-ui-product-form-view-price-list__label">
                <FormattedMessage
                  id="Form.Product.UpToXChildTravelFree"
                  values={{ countFamilyCards: bookedFamilyCardCount }}
                />
              </td>
              <td className="sts-ui-product-form-view-price-list__price">
                <FormattedPrice value={0}/>
              </td>
            </tr>
          )}
        </tbody>

        <tfoot>
          <tr>
            <td className="sts-ui-product-form-view-price-list__label">
              <FormattedMessage id="Form.Product.ForAllTravellers"/>
            </td>
            <td className="sts-ui-product-form-view-price-list__price">
              <FormattedPrice value={totalPrice}/>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

function getTariffMessage(
  code: ProductCode,
  tariff: ProductPrice,
): React.ReactElement {
  if (code === TicketType.SWISS_HALF_FARE_CARD && tariff.age === TicketAge.ADULT) {
    return (
      <FormattedMessage
        id="Form.Product.TariffPriceHalfFareCard"
        values={{ count: tariff.count }}
      />
    );
  }
  if (code === TicketType.SWISS_HALF_FARE_CARD && tariff.age === TicketAge.CHILD) {
    return (
      <FormattedMessage
        id="Form.Product.TariffPriceFamilyCard"
        values={{ count: tariff.count }}
      />
    );
  }

  switch (tariff.age) {
  case TicketAge.ADULT: {
    return (
      <FormattedMessage
        id="Form.Product.TariffPriceAdult"
        values={{ count: tariff.count }}
      />
    );
  }

  case TicketAge.YOUTH: {
    return (
      <FormattedMessage
        id="Form.Product.TariffPriceYouth"
        values={{ count: tariff.count }}
      />
    );
  }

  case TicketAge.CHILD: {
    return (
      <FormattedMessage
        id="Form.Product.TariffPriceChildren"
        values={{ count: tariff.count }}
      />
    );
  }

  default:
    return assertNever('Unknown tariff name', { tariff });
  }
}

function getDiscountMessage(
  code: ProductCode,
  tariff: ProductPrice,
): React.ReactElement | null {
  if (tariff.discounts.length === 0) {
    return null;
  }

  return (
    <FormattedMessage
      id="Payment.DiscountCard"
      values={{
        discountCard: (
          <DiscountList
            product={code}
            discounts={tariff.discounts}
          />
        ),
      }}
    />
  );
}

function getErrorMessage(
  code: ProductPriceErrorCode,
): React.ReactElement {
  switch (code) {
  case ProductPriceErrorCode.INVALID_DURATION: {
    return (
      <FormattedMessage id="Form.Product.Error.InvalidDuration"/>
    );
  }

  case ProductPriceErrorCode.NO_TRAVELER: {
    return (
      <FormattedMessage id="Form.Product.Error.NoTravelers"/>
    );
  }

  case ProductPriceErrorCode.NO_TICKET_AVAILABLE: {
    return (
      <FormattedMessage id="Form.Product.ThereArentAvailableTickets"/>
    );
  }

  default:
    return assertNever('Unknown price error code', { code });
  }
}

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { HOST_CANONICAL } from 'src/constants/application';
import { ROUTING_LANGUAGES } from 'src/routing/constants/RoutingLanguages';
import { getCurrentRouteInfo } from 'src/routing/selectors/getCurrentRouteInfo';
import { getWebsiteSchemaOrg } from 'src/routing/selectors/getWebsiteSchemaOrg';
import { getRouteFullPath } from 'src/routing/utils/getRouteFullPath';
import { getSeoConfig } from 'src/selectors/getSeoConfig';

export function RouteSeo(): React.ReactElement {
  const intl = useIntl();

  const route = useSelector(getCurrentRouteInfo);
  const seoConfig = useSelector(getSeoConfig);
  const schemaOrg = useSelector(getWebsiteSchemaOrg);

  const pageConfig = seoConfig[route.routeName];

  return (
    <Helmet>
      <title>{intl.formatMessage({ id: pageConfig.title })}</title>
      <script type="application/ld+json">
        {JSON.stringify(schemaOrg)}
      </script>

      {pageConfig.description && (
        <meta
          name="description"
          content={intl.formatMessage({ id: pageConfig.description })}
        />
      )}
      {pageConfig.canonical && (
        <link
          rel="canonical"
          href={`${HOST_CANONICAL}${route.location.pathname}`}
        />
      )}
      {pageConfig.hrefLang && ROUTING_LANGUAGES
        .map((language) => (
          <link
            key={language}
            rel="alternate"
            href={`${HOST_CANONICAL}${getRouteFullPath(language, route.routeName)}`}
            hrefLang={language}
          />
        ))}
      {pageConfig.hrefLang && (
        <link
          rel="alternate"
          href={`${HOST_CANONICAL}${getRouteFullPath('en', route.routeName)}`}
          hrefLang="x-default"
        />
      )}
    </Helmet>
  );
}

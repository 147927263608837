export function injectGtm(gtmTrackingId: string): void {
  const [trackingIds = '', hostName = 'www.googletagmanager.com'] = gtmTrackingId.split('|', 2);
  if (trackingIds.length === 0) {
    return;
  }

  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    'event': 'gtm.js',
  });

  const head = window.document.head;
  trackingIds.split(',').forEach((trackingId) => {
    const scriptEl = window.document.createElement('script');
    scriptEl.async = true;
    scriptEl.src = `https://${hostName}/gtm.js?id=${encodeURIComponent(trackingId)}`;
    head.appendChild(scriptEl);
  });
}

import { Action } from 'redux';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { RoutingState } from 'src/routing/types/RoutingState';

export function routeChangeReducer(
  state: RoutingState,
  action: Action,
): RoutingState {
  if (ROUTE_CHANGE.isPending(action)) {
    return {
      ...state,
      isTransitioning: true,
    };
  }

  if (ROUTE_CHANGE.isSuccess(action)) {
    return {
      ...state,
      isTransitioning: false,
      currentState: action.data,
      transitionError: null,
    };
  }

  if (ROUTE_CHANGE.isFailure(action)) {
    return {
      ...state,
      isTransitioning: false,
      transitionError: action.data,
    };
  }

  return state;
}

import { getOrElse } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { DecodeError, Decoder } from 'io-ts/Decoder';
import { DecodingError } from 'src/errors/DecodingError';

export function decodeOrThrow<I, A>(decoder: Decoder<I, A>, input: I): A {
  return pipe(
    decoder.decode(input),
    getOrElse<DecodeError, A>((error) => {
      throw new DecodingError(input, error);
    }),
  );
}

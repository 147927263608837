import './HomepageSearchForm.scss';
import { useForm, useFormWatch } from '@form-ts/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'src/components/common/Typography';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import * as Tickets from 'src/constants/search';
import { SwitcherField } from 'src/forms/fields/SwitcherField';
import { useFormValidator } from 'src/forms/hooks/useFormValidator';
import { FormError } from 'src/forms/types/FormError';
import { FormRule } from 'src/forms/types/FormRule';
import * as R from 'src/forms/utils/rules';
import { validate } from 'src/forms/utils/validate';
import { SearchQuery } from 'src/types/SearchQuery';

type Props = {
  readonly onSubmit: (query: SearchQuery) => void;
};

export function HomepageSearchForm({ onSubmit }: Props): React.ReactElement {
  const form = useForm<SearchQuery, FormError>('homepage.search', {
    reinitialize: false,
    initialValues: DEFAULT_FORM_DATA,
    initialErrors: validate(DEFAULT_FORM_DATA, SEARCH_FORM_SCHEMA),
  });

  useFormValidator(form, SEARCH_FORM_SCHEMA);
  const formErrors = useFormWatch(form, form.errors.get);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(form.currentState.values);
  }, [form, onSubmit]);

  return (
    <form
      onSubmit={handleSubmit}
      className="sts-ui-homepage-search-form"
    >
      <div className="sts-ui-homepage-search-form__group">
        <div className="sts-ui-homepage-search-form__label">
          <Typography as="label" variant="form-label">
            <FormattedMessage id="Form.Search.HowLongWillYouStayInSwitzerland"/>
          </Typography>
        </div>
        <div className="sts-ui-homepage-search-form__field">
          <SwitcherField
            field={form.field.at('period')}
            options={OPTIONS_PERIOD}
          />
        </div>
      </div>
      <div className="sts-ui-homepage-search-form__group">
        <div className="sts-ui-homepage-search-form__label">
          <Typography as="label" variant="form-label">
            <FormattedMessage id="Form.Search.HowOftenWillYouTravelByTrain"/>
          </Typography>
        </div>
        <div className="sts-ui-homepage-search-form__field">
          <SwitcherField
            field={form.field.at('frequency')}
            options={OPTIONS_FREQUENCY}
          />
        </div>
      </div>
      <div className="sts-ui-homepage-search-form__group">
        <div className="sts-ui-homepage-search-form__label">
          <Typography as="label" variant="form-label">
            <FormattedMessage id="Form.Search.DoYouPlanAnyMountainExcursion"/>
          </Typography>
        </div>
        <div className="sts-ui-homepage-search-form__field">
          <SwitcherField
            field={form.field.at('excursion')}
            options={OPTIONS_EXCURSION}
          />
        </div>
      </div>

      <div className="sts-ui-homepage-search-form__actions">
        <button
          type="submit"
          className="sts-ui-primary-button"
          disabled={Object.keys(formErrors).length !== 0}
        >
          <FormattedMessage id="Form.Search.Search"/>
          <ArrowRightIcon/>
        </button>
      </div>
    </form>
  );
}

const DEFAULT_FORM_DATA: SearchQuery = {
  period: null,
  frequency: null,
  excursion: null,
};

const SEARCH_FORM_SCHEMA: FormRule<SearchQuery> = R.struct({
  period: R.required(),
  frequency: R.required(),
  excursion: R.required(),
});

const OPTIONS_PERIOD = [
  {
    label: <FormattedMessage id="Form.Search.Options.1-4day"/>,
    value: Tickets.PERIOD_4_DAYS,
  },
  {
    label: <FormattedMessage id="Form.Search.Options.OneWeek"/>,
    value: Tickets.PERIOD_WEEK,
  },
  {
    label: <FormattedMessage id="Form.Search.Options.OneWeekPlus"/>,
    value: Tickets.PERIOD_WEEK_PLUS,
  },
] as const;

const OPTIONS_FREQUENCY = [
  {
    label: <FormattedMessage id="Form.Search.Options.EveryDay"/>,
    value: Tickets.FREQUENCY_EVERY_DAY,
  },
  {
    label: <FormattedMessage id="Form.Search.Options.SomeDays"/>,
    value: Tickets.FREQUENCY_SOME_DAYS,
  },
] as const;

const OPTIONS_EXCURSION = [
  {
    label: <FormattedMessage id="Form.Search.Options.Yes"/>,
    value: Tickets.EXCURSION_YES,
  },
  {
    label: <FormattedMessage id="Form.Search.Options.No"/>,
    value: Tickets.EXCURSION_NO,
  },
  {
    label: <FormattedMessage id="Form.Search.Options.DontKnow"/>,
    value: Tickets.EXCURSION_UNKNOWN,
  },
] as const;

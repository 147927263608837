import { SagaIterator } from 'redux-saga';
import { redirectTo } from 'src/routing/sagas/utils/redirectTo';
import { getRouteFullPath } from 'src/routing/utils/getRouteFullPath';
import { Currency } from 'src/types/Currency';
import { Language } from 'src/types/Language';
import { call, cancel } from 'typed-redux-saga';

export function* redirectToBasketPage(
  currency: Currency,
  language: Language,
): SagaIterator<void> {
  const pathname = yield* call(getRouteFullPath, language, 'basket');

  yield* call(redirectTo, { pathname }, currency);
  yield* cancel();
}

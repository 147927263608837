type DoneCallback = (event: TransitionEvent | AnimationEvent) => void;

export function transitionEndListener(node: HTMLElement, done: DoneCallback): void {
  const doneCallback: DoneCallback = (event) => {
    if (event.target === event.currentTarget) {
      done(event);
    }
  };

  node.addEventListener('transitionend', doneCallback, false);
  node.addEventListener('animationend', doneCallback, false);
}

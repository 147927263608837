import { SagaIterator } from 'redux-saga';
import { LogicError } from 'src/errors/LogicError';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getAffiliateData } from 'src/selectors/getAffiliateData';
import { getFuseDeck } from 'src/selectors/getFuseDeck';
import { injectScript } from 'src/utils/injectScript';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, select } from 'typed-redux-saga';

export const FUSEDECK_SNIPPET = `(function (f, u, s, e_, d, e, c, k, n_) {
    try{
        var cos = document.cookie.split(';');
        for (var i = 0; i < cos.length; i++) {var co = cos[i].trim().split('=');if (co[0] == 'fdTrackingType'+e) {c = co[1];}if (co[0] == 'fdEnvId') {k = co[1];}}
        f[d] = f[d] || [];window.addEventListener("load",function(){fdWinLoaded=!0});
        var p = u.getElementsByTagName(s)[0]; var sc = u.createElement(s); sc.async = true;
        sc.src = e_ + '?p=' + e + '&l=' + (d != null ? d : '') + (c != null ? '&c=' + c : '') + (k != null ? '&e=' + k : '') + '&h=' + encodeURIComponent(location.href) + (n_ != null ? '&a=' + encodeURIComponent(n_) : '');
        p.parentNode.insertBefore(sc, p);
    }catch(e){}
})(window, document, 'script', 'https://io.fusedeck.net/d/tm.js', null, 'LSJzMkjqJ4', null, null, null);`;

export function* initFuseDeck(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Initializing FuseDeck script…');

    const affiliate = yield* select(getAffiliateData);
    if (!affiliate.appThemeSettings.fusedeckSnippet) {
      yield* call(logDebug, 'Initializing FuseDeck analytics/tracking… skip');
      return;
    }

    // avoid re-initialization
    let globalFuseDeck = yield* select(getFuseDeck);
    if (globalFuseDeck == null) {
      yield* call(injectScript, {}, FUSEDECK_SNIPPET);
    }

    globalFuseDeck = yield* select(getFuseDeck);
    if (globalFuseDeck == null) {
      throw new LogicError('FuseDeck is not available');
    }

    yield* call(logDebug, 'Initializing FuseDeck script… done');
  } catch (error) {
    yield* call(logError, 'Initializing FuseDeck script… error', error);
    yield* call(sentryCatch, new RuntimeError('Could not initialize FuseDeck script', {}, NativeError.wrap(error)));
  }
}

import './NewsletterScreen.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { HubSpotForm } from 'src/components/common/HubSpotForm';
import { Typography } from 'src/components/common/Typography';
import { Language } from 'src/types/Language';

type Props = {
  readonly language: Language;
};

export function NewsletterScreen({ language }: Props): React.ReactElement {
  return (
    <div className="sts-ui-newsletter-screen">
      <div className="sts-ui-newsletter-screen__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="Newsletter.Page.Title"/>
        </Typography>
      </div>
      <div className="sts-ui-newsletter-screen__page-subtitle">
        <Typography variant="page-subtitle" as="p">
          <FormattedHTMLMessage id="Newsletter.Page.Description"/>
        </Typography>
      </div>

      <div className="sts-ui-newsletter-screen__content">
        <HubSpotForm
          key={language}
          region="na1"
          portalId="2657592"
          formId={FORM_ID[language]}
        />
      </div>
    </div>
  );
}

const FORM_ID: Record<Language, string> = {
  'de': '53278c57-3d47-4aa0-ac6a-4edb297bd358',
  'en': 'af8edbd7-905b-4757-ae7b-bbcf59f1853e',
  'es': 'd2668cf3-b5f6-49c9-a05d-49c20e88fc1b',
  'fr': '02be79a3-7b99-4162-bdbe-536b7432a6bb',
  'it': 'e0618658-736b-44ef-951a-45a0efb8265f',
  'ja': 'eea8c59c-ba59-4440-b462-78f273e42708',
  'ko': '4ba2ea50-f568-444f-a3a7-182cc29294c4',
  'nl': 'bfc44836-dcf9-48ab-9953-e624dc15d22d',
  'zh-Hans': '1d0cad15-6478-4c26-a3ff-5d83fb6792f6',
  'zh-Hant': 'da5ce755-2186-4afb-a9dd-4fb582d426b4',
};

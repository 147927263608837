import { AnyAction } from 'redux';
import { BaseError } from 'src/errors/BaseError';

type ActionHelper<TRequest, TSuccess, TMeta = void> = {
  request(data: TRequest, meta: TMeta): ActionRequest<TRequest, TMeta>;
  pending(meta: TMeta): ActionPending<TMeta>;
  success(data: TSuccess, meta: TMeta): ActionSuccess<TSuccess, TMeta>;
  failure(error: BaseError, meta: TMeta): ActionFailure<TMeta>;

  isRequest(action: AnyAction): action is ActionRequest<TRequest, TMeta>;
  isPending(action: AnyAction): action is ActionPending<TMeta>;
  isSuccess(action: AnyAction): action is ActionSuccess<TSuccess, TMeta>;
  isFailure(action: AnyAction): action is ActionFailure<TMeta>;
};

type ActionBase<TData, TMeta = void> = {
  readonly type: string;
  readonly data: TData;
  readonly meta: TMeta;
};

type ActionRequest<TRequest, TMeta = void> = ActionBase<TRequest, TMeta>;
type ActionPending<TMeta = void> = ActionBase<void, TMeta>;
type ActionSuccess<TSuccess, TMeta = void> = ActionBase<TSuccess, TMeta>;
type ActionFailure<TMeta = void> = ActionBase<BaseError, TMeta>;

export type GetRequestActionType<TActions extends ActionHelper<unknown, unknown, unknown>>
    = TActions extends ActionHelper<infer R, unknown, infer M>
      ? ActionRequest<R, M>
      : never;
export type GetPendingActionType<TActions extends ActionHelper<unknown, unknown, unknown>>
    = TActions extends ActionHelper<unknown, unknown, infer M>
      ? ActionPending<M>
      : never;
export type GetSuccessActionType<TActions extends ActionHelper<unknown, unknown, unknown>>
    = TActions extends ActionHelper<unknown, infer S, infer M>
      ? ActionSuccess<S, M>
      : never;
export type GetFailureActionType<TActions extends ActionHelper<unknown, unknown, unknown>>
    = TActions extends ActionHelper<unknown, unknown, infer M>
      ? ActionFailure<M>
      : never;

export function createActions<
  TRequest,
  TSuccess,
  TMeta = void,
>(type: string): ActionHelper<TRequest, TSuccess, TMeta> {
  const REQUEST = `${type}_REQUEST`;
  const PENDING = `${type}_PENDING`;
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return {
    isRequest: (action): action is ActionRequest<TRequest, TMeta> => action.type === REQUEST,
    isPending: (action): action is ActionPending<TMeta> => action.type === PENDING,
    isSuccess: (action): action is ActionSuccess<TSuccess, TMeta> => action.type === SUCCESS,
    isFailure: (action): action is ActionFailure<TMeta> => action.type === FAILURE,

    request: (data, meta) => ({
      type: REQUEST,
      data: data,
      meta: meta,
    }),
    pending: (meta) => ({
      type: PENDING,
      data: undefined,
      meta: meta,
    }),
    success: (data, meta) => ({
      type: SUCCESS,
      data: data,
      meta: meta,
    }),
    failure: (data, meta) => ({
      type: FAILURE,
      data: data,
      meta: meta,
    }),
  };
}

import { Decimal } from 'decimal.js-light';
import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { DECIMAL } from 'src/codecs/Decimal';

const DECODER: D.Decoder<unknown, Decimal> = pipe(
  DECIMAL,
  D.parse((input) => D.success(input.div(100))),
);

const ENCODER: E.Encoder<string, Decimal> = {
  encode: (value) => value.mul(100).toString(),
};

export const ORDER_AMOUNT = C.make(DECODER, ENCODER);

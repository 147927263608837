import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { BASKET_PRODUCT } from 'src/codecs/BasketProduct';
import { ProductMap } from 'src/codecs/combinators/ProductMap';
import { CURRENCY } from 'src/codecs/Currency';
import { BasketStore } from 'src/types/BasketStore';

export const BASKET_STORE: C.Codec<unknown, JsonRecord, BasketStore> = C.struct({
  currency: CURRENCY,
  products: ProductMap(BASKET_PRODUCT),
});

import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { BASKET_REMOVE, BASKET_UPGRADE } from 'src/actions/BasketActions';
import { basketRemoveSaga } from 'src/sagas/basket/basketRemoveSaga';
import { basketUpgradeSaga } from 'src/sagas/basket/basketUpgradeSaga';
import { call } from 'typed-redux-saga';

export function* basketPageSaga(action: Action): SagaIterator<void> {
  if (BASKET_REMOVE.isRequest(action)) {
    yield* call(basketRemoveSaga, action);
  }
  if (BASKET_UPGRADE.isRequest(action)) {
    yield* call(basketUpgradeSaga, action);
  }
}

import { createSelector } from 'reselect';
import { FormRule } from 'src/forms/types/FormRule';
import * as R from 'src/forms/utils/rules';
import { getCheckoutPaymentTypes } from 'src/selectors/getCheckoutPaymentTypes';
import { CheckoutPaymentFormData } from 'src/types/CheckoutFormData';

export const getCheckoutPaymentFormRule = createSelector([
  getCheckoutPaymentTypes,
], (paymentTypes): FormRule<CheckoutPaymentFormData> => {
  const PAYMENT_TYPE_RULE = R.oneOf(paymentTypes.map((it) => it.name));

  return R.struct({
    cancellationProtection: R.pass(),
    paymentType: R.allOf([
      R.required(),
      R.nullable(PAYMENT_TYPE_RULE),
    ]),

    agreement: R.equals(true),
    optIn: R.pass(),
  });
});

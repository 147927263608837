import { SagaIterator } from 'redux-saga';
import { BASKET_INIT_STORAGE } from 'src/actions/BasketActions';
import { loadBasketStore, saveBasketStore } from 'src/sagas/basket/utils/storeBasketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* initBasket(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Initializing basket…');

    const basket = yield* call(loadBasketStore);
    if (basket === null) {
      yield* call(logDebug, 'Initializing basket… skip');
      return;
    }

    yield* put(BASKET_INIT_STORAGE.trigger(basket));
    yield* call(logDebug, 'Initializing basket… done', basket);
  } catch (error) {
    // TODO: do we need to log such cases to Sentry??
    yield* call(logError, 'Initializing basket… error', error);
    yield* call(saveBasketStore, null);
  }
}

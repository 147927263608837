import { createSelector } from 'reselect';
import { getCheckoutCustomerCountries } from 'src/selectors/getCheckoutCustomerCountries';
import { getIntlCollator } from 'src/selectors/getIntlCollator';
import { CountryCode } from 'src/types/CountryCode';
import { ListOption } from 'src/types/ListOption';

export const getCheckoutCustomerCountryList = createSelector([
  getCheckoutCustomerCountries,
  getIntlCollator,
], (countryMap, collator): ReadonlyArray<ListOption<CountryCode>> => {
  return Object.entries(countryMap)
    .map(([value, title]) => ({ value, title }))
    .sort((a, b) => collator.compare(a.title, b.title));
});

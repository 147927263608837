import { SagaIterator } from 'redux-saga';
import { ROUTING_LANGUAGES } from 'src/routing/constants/RoutingLanguages';
import { ROUTING_LEGACY_URL_MAP } from 'src/routing/constants/RoutingLegacyUrlMap';
import { redirectTo } from 'src/routing/sagas/utils/redirectTo';
import { Transition } from 'src/routing/types/Transition';
import { getRouteFullPath } from 'src/routing/utils/getRouteFullPath';
import { logError } from 'src/sagas/utils/logging';
import { sentryLog } from 'src/utils/sentryLog';
import { call, cancel } from 'typed-redux-saga';

export function* redirectFromLegacyUrl(transition: Transition): SagaIterator<void> {
  try {
    const legacyUrl = new URL(
      transition.to.location.hash.replace(/^#/, ''),
      window.origin,
    );

    for (const language of ROUTING_LANGUAGES) {
      for (const [legacyPath, routeName] of Object.entries(ROUTING_LEGACY_URL_MAP)) {
        if (legacyUrl.pathname !== `/${language}/${legacyPath}`) {
          continue;
        }

        const currency = transition.to.currency;
        const pathname = yield* call(getRouteFullPath, language, routeName);

        yield* call(sentryLog, `Redirect from hash-based URL: "${transition.to.location.hash}"`, {
          level: 'info',
          fingerprint: [
            'redirect',
            'legacy',
          ],
          context: {
            source: transition.to.location,
            target: {
              currency: currency,
              pathname: pathname,
              search: legacyUrl.search,
              hash: legacyUrl.hash,
            },
          },
        });

        yield* call(redirectTo, { pathname: pathname, search: legacyUrl.search, hash: legacyUrl.hash }, currency);
        yield* cancel();
      }
    }
  } catch (error) {
    yield* call(logError, 'Could not handle legacy URL redirect', error);
  }
}

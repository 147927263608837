import { ROUTING_LANGUAGES } from 'src/routing/constants/RoutingLanguages';
import { passThrought } from 'src/routing/hooks/resolvers/passThrought';
import { RouteState } from 'src/routing/types/RouteState';
import { getRouteStatePath } from 'src/routing/utils/getRouteStatePath';

export const ROUTING_SYSTEM_STATES: ReadonlyArray<RouteState> = ROUTING_LANGUAGES.map((language): RouteState[] => [
  {
    url: getRouteStatePath(language, 'notfound', 'basepath'),
    name: `sts:ui:notfound:${language}`,
    parent: `sts:ui:basepath:${language}`,
    hooks: {
      onEntering: [{
        name: 'notfound',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
      onRetaining: [{
        name: 'notfound',
        deps: ['globals', 'locale'],
        saga: passThrought,
      }],
    },
  },
]).flat(1);

import { SagaIterator } from 'redux-saga';
import { AFFILIATE } from 'src/codecs/Affiliate';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { Affiliate } from 'src/types/Affiliate';
import { AffiliateRequest } from 'src/types/AffiliateRequest';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchAffiliate(request: AffiliateRequest): SagaIterator<Affiliate> {
  try {
    yield* call(logDebug, 'Fetching affiliate data…', request);

    const response = yield* call(makeGetRequest, `${HOST_API}/affiliate-info`, request);
    const affiliate = yield* call(decodeOrThrow, AFFILIATE, response.content);

    yield* call(logDebug, 'Fetching affiliate data… done', affiliate);
    return affiliate;
  } catch (error) {
    yield* call(logError, 'Fetching affiliate data… error', error);
    throw new RuntimeError('Could not fetch affiliate data', {}, NativeError.wrap(error));
  }
}

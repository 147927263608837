import './BirthDate.scss';
import { Ord } from 'fp-ts/Date';
import { pipe } from 'fp-ts/function';
import { clamp } from 'fp-ts/Ord';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'src/forms/components/Select';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';
import { useDateList } from 'src/hooks/useDateList';
import { useMonthList } from 'src/hooks/useMonthList';
import { BirthDate as Value } from 'src/types/BirthDate';
import { Integer } from 'src/types/Integer';
import { ListOption } from 'src/types/ListOption';

type Props = {
  readonly id: string;
  readonly name: string;
  readonly value: Date | null;
  readonly onBlur?: () => void;
  readonly onChange: (value: Date | null) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly min: Date;
  readonly max: Date;
};

export function BirthDate({
  id,
  name,
  value,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  min,
  max,
}: Props): React.ReactElement {
  const touched = useRef(new Set<string>());
  const dateList = useDateList();
  const monthList = useMonthList();

  const [viewValue, setViewValue] = useState(() => fromDate(value));
  useEffect(() => {
    setViewValue(fromDate(value));
  }, [value]);

  const yyOptions = useMemo(() => getYearList(
    min,
    max,
  ), [min, max]);

  const mmOptions = useMemo(() => getMonthList(
    viewValue,
    monthList,
    min,
    max,
  ), [min, max, viewValue, monthList]);

  const ddOptions = useMemo(() => getDateList(
    viewValue,
    dateList,
    min,
    max,
  ), [min, max, viewValue, dateList]);

  const handleBlur = useCallback(() => {
    if (touched.current.size === 3) {
      onBlur?.();
    }
  }, [onBlur]);

  const handleBlurDD = useCallback(() => {
    touched.current.add('dd');
    handleBlur();
  }, [handleBlur]);
  const handleBlurMM = useCallback(() => {
    touched.current.add('mm');
    handleBlur();
  }, [handleBlur]);
  const handleBlurYY = useCallback(() => {
    touched.current.add('yy');
    handleBlur();
  }, [handleBlur]);

  const handleChange = useCallback((nextViewValue: Value) => {
    const nextValue = toDate(nextViewValue);
    setViewValue(nextViewValue);
    onChange(nextValue === null ? null : pipe(nextValue, clamp(Ord)(min, max)));
  }, [min, max, onChange]);

  const handleChangeDD = useCallback((dd: Integer | null) => {
    handleChange({ ...viewValue, dd });
  }, [viewValue, handleChange]);
  const handleChangeMM = useCallback((mm: Integer | null) => {
    handleChange({ ...viewValue, mm });
  }, [viewValue, handleChange]);
  const handleChangeYY = useCallback((yy: Integer | null) => {
    handleChange({ ...viewValue, yy });
  }, [viewValue, handleChange]);

  const inputRef = useRef<HTMLButtonElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  return (
    <div className="sts-ui-form-birth-date" data-invalid={invalid}>
      <div className="sts-ui-form-birth-date__dd">
        <div className="sts-ui-form-birth-date__label">
          <label htmlFor={`${id}.dd`}>
            <FormattedMessage id="FormElements.BirthdayPicker.SelectDayFromList"/>
          </label>
        </div>
        <div className="sts-ui-form-birth-date__field">
          <Select
            id={`${id}.dd`}
            name={`${name}.dd`}
            value={viewValue.dd}
            options={ddOptions}
            onBlur={handleBlurDD}
            onChange={handleChangeDD}
            invalid={invalid}
            disabled={disabled}
            clearable={false}
            validity={validity}
            placeholder={<FormattedMessage id="FormElements.BirthdayPicker.SelectDayFromList"/>}
          />
        </div>
      </div>
      <div className="sts-ui-form-birth-date__mm">
        <div className="sts-ui-form-birth-date__label">
          <label htmlFor={`${id}.mm`}>
            <FormattedMessage id="FormElements.BirthdayPicker.SelectMonth"/>
          </label>
        </div>
        <div className="sts-ui-form-birth-date__field">
          <Select
            id={`${id}.mm`}
            name={`${name}.mm`}
            value={viewValue.mm}
            options={mmOptions}
            onBlur={handleBlurMM}
            onChange={handleChangeMM}
            invalid={invalid}
            disabled={disabled}
            clearable={false}
            placeholder={<FormattedMessage id="FormElements.BirthdayPicker.SelectMonth"/>}
          />
        </div>
      </div>

      <div className="sts-ui-form-birth-date__yy">
        <div className="sts-ui-form-birth-date__label">
          <label htmlFor={`${id}.yy`}>
            <FormattedMessage id="FormElements.BirthdayPicker.SelectYear"/>
          </label>
        </div>
        <div className="sts-ui-form-birth-date__field">
          <Select
            id={`${id}.yy`}
            name={`${name}.yy`}
            value={viewValue.yy}
            options={yyOptions}
            onBlur={handleBlurYY}
            onChange={handleChangeYY}
            invalid={invalid}
            disabled={disabled}
            clearable={false}
            placeholder={<FormattedMessage id="FormElements.BirthdayPicker.SelectYear"/>}
          />
        </div>
      </div>
    </div>
  );
}

function fromDate(date: Date | null): Value {
  return date === null
    ? { dd: null, mm: null, yy: null }
    : { dd: date.getDate(), mm: date.getMonth() + 1, yy: date.getFullYear() };
}

function toDate(date: Value): Date | null {
  if (date.dd === null || date.mm === null || date.yy === null) {
    return null;
  }

  return new Date(
    date.yy,
    date.mm - 1,
    Math.min(date.dd, getDaysInMonth(date.yy, date.mm)),
    0,
    0,
    0,
    0,
  );
}

function getDaysInMonth(yy: Integer, mm: Integer): Integer {
  // new Date(2024, 8, 0) == new Date(2024, 7, 31)
  return new Date(yy, mm, 0, 0, 0, 0, 0).getDate();
}

function getYearList(
  minDate: Date,
  maxDate: Date,
): ReadonlyArray<ListOption<Integer>> {
  const minYY = minDate.getFullYear();
  const maxYY = maxDate.getFullYear();

  return Array.from({ length: maxYY - minYY + 1 }, (_, i) => ({
    value: maxYY - i,
    title: (maxYY - i).toString(),
  }));
}

function getMonthList(
  value: Value,
  options: ReadonlyArray<ListOption<Integer>>,
  minDate: Date,
  maxDate: Date,
): ReadonlyArray<ListOption<Integer>> {
  if (value.yy === null) {
    return options;
  }

  const valYY = value.yy;
  const minYY = minDate.getFullYear();
  const maxYY = maxDate.getFullYear();

  if (valYY < maxYY && valYY > minYY) {
    return options;
  }
  if (valYY > maxYY || valYY < minYY) {
    return [];
  }

  const minMM = minDate.getMonth() + 1;
  const maxMM = maxDate.getMonth() + 1;

  return options.filter((option) => !(
    (valYY === minYY && option.value < minMM) ||
    (valYY === maxYY && option.value > maxMM)
  ));
}

function getDateList(
  value: Value,
  options: ReadonlyArray<ListOption<Integer>>,
  minDate: Date,
  maxDate: Date,
): ReadonlyArray<ListOption<Integer>> {
  if (value.yy === null || value.mm === null) {
    return options;
  }

  const daysInMonth = getDaysInMonth(value.yy, value.mm);

  const valMMYY = value.yy * 1000 + value.mm;
  const minMMYY = minDate.getFullYear() * 1000 + minDate.getMonth() + 1;
  const maxMMYY = maxDate.getFullYear() * 1000 + maxDate.getMonth() + 1;

  if (valMMYY < maxMMYY && valMMYY > minMMYY) {
    return options.slice(0, daysInMonth);
  }
  if (valMMYY > maxMMYY || valMMYY < minMMYY) {
    return [];
  }

  const minDD = minDate.getDate();
  const maxDD = maxDate.getDate();

  return options.slice(0, daysInMonth).filter((option) => !(
    (valMMYY === minMMYY && option.value < minDD) ||
    (valMMYY === maxMMYY && option.value > maxDD)
  ));
}

import './RouterLoader.scss';
import React from 'react';

export function RouterLoader(): React.ReactElement {
  return (
    <div className="sts-ui-router-loader">
      <div className="sts-ui-router-loader__spinner">
        <div className="sts-ui-router-loader__bounce"/>
        <div className="sts-ui-router-loader__bounce"/>
        <div className="sts-ui-router-loader__bounce"/>
      </div>
    </div>
  );
}

import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { Affiliate } from 'src/types/Affiliate';
import { setGlobalStyleProperty, updateFavicons } from 'src/utils/domUtils';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call } from 'typed-redux-saga';

export function* applyThemeSettings(affiliate: Affiliate): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Applying affiliate theme…');

    if (affiliate.appThemeSettings.faviconUrl) {
      yield* call(updateFavicons, affiliate.appThemeSettings.faviconUrl);
    }

    // TODO: some CSS variables are missing in `appThemeSettings`
    const cssVariables = {
      '--sts-button-color-text-default': affiliate.appThemeSettings.buttonTextColor,
      '--sts-button-color-text-disable': undefined,
      '--sts-button-color-text-hover': affiliate.appThemeSettings.buttonHoverColor,
      '--sts-button-color-text-focus': affiliate.appThemeSettings.buttonHoverColor,

      '--sts-button-color-background-default': affiliate.appThemeSettings.buttonBackgroundColor,
      '--sts-button-color-background-disable': undefined,
      '--sts-button-color-background-hover': affiliate.appThemeSettings.buttonBackgroundColor,
      '--sts-button-color-background-focus': affiliate.appThemeSettings.buttonBackgroundColor,

      '--sts-button-color-border-default': affiliate.appThemeSettings.buttonBackgroundColor,
      '--sts-button-color-border-disable': undefined,
      '--sts-button-color-border-hover': affiliate.appThemeSettings.buttonBackgroundColor,
      '--sts-button-color-border-focus': affiliate.appThemeSettings.buttonBackgroundColor,

      '--sts-header-color-text': affiliate.appThemeSettings.headerTextColor,
      '--sts-header-color-text-hover': affiliate.appThemeSettings.headerTextHoverColor,
      '--sts-header-color-background': affiliate.appThemeSettings.headerColor,

      '--sts-layout-main-background': affiliate.appThemeSettings.hideMainBackground ? 'transparent' : undefined,
    };
    for (const [cssVarName, cssVarValue] of Object.entries(cssVariables)) {
      if (cssVarValue) {
        yield* call(setGlobalStyleProperty, cssVarName, cssVarValue);
      }
    }

    yield* call(logDebug, 'Applying affiliate theme… done', affiliate);
  } catch (error) {
    yield* call(logError, 'Applying affiliate theme… error', error);
    yield* call(sentryCatch, new RuntimeError('Could not apply theme settings', { affiliate }, NativeError.wrap(error)));
  }
}

import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { BASKET_PRODUCT } from 'src/codecs/BasketProduct';
import { CHECKOUT_FORM_DATA } from 'src/codecs/CheckoutFormData';
import { CHECKOUT_ORDER } from 'src/codecs/CheckoutOrder';
import { CHECKOUT_TOTAL } from 'src/codecs/CheckoutTotal';
import { ProductMap } from 'src/codecs/combinators/ProductMap';
import { PaymentStore } from 'src/types/PaymentStore';

export const PAYMENT_STORE: C.Codec<unknown, JsonRecord, PaymentStore> = C.struct({
  basket: ProductMap(BASKET_PRODUCT),
  checkout: CHECKOUT_FORM_DATA,
  order: CHECKOUT_ORDER,
  total: CHECKOUT_TOTAL,
});

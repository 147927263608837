import { createSelector } from 'reselect';
import { getPaymentState } from 'src/selectors/getPaymentState';
import { assertNotEmpty } from 'src/utils/assert';

export const getPaymentData = createSelector([
  getPaymentState,
], (state) => assertNotEmpty(
  state.data,
  'Payment state is not initialized',
  { state },
));

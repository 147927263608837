import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { TicketDuration } from 'src/types/TicketDuration';

const DECODER: D.Decoder<unknown, TicketDuration> = pipe(
  D.string,
  D.refine((input): input is TicketDuration => /^\d{1,3}d$/.test(input), 'TicketDuration'),
);

export const TICKET_DURATION: C.Codec<unknown, string, TicketDuration> = C.make(DECODER, E.id());

import './PaymentSuccessSocialLinks.scss';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { SOCIAL_LINKS } from 'src/constants/socials';

export function PaymentSuccessSocialLinks(): React.ReactElement {
  return (
    <div className="sts-ui-payment-success-social-links">
      <div className="sts-ui-payment-success-social-links__title">
        <FormattedHTMLMessage id="Payment.Zurich.ShareYourExperiencesAndImpressions"/>
      </div>
      <div className="sts-ui-payment-success-social-links__links">
        {SOCIAL_LINKS.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="sts-ui-payment-success-social-links__link" key={index}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <img src={item.img} alt=""/>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

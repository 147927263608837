import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getConfigState } from 'src/selectors/getConfigState';
import { DiscountCode } from 'src/types/DiscountCode';
import { ProductCode } from 'src/types/ProductCode';

type Props = {
  readonly product: ProductCode;
  readonly discount: DiscountCode;
};

export function DiscountName({ product, discount }: Props): React.ReactElement {
  const { productsDiscounts } = useSelector(getConfigState);

  const productKey = productsDiscounts[product]?.[discount];
  if (productKey) {
    return <FormattedMessage id={productKey}/>;
  }

  const defaultKey = productsDiscounts.otherTickets[discount];
  return <FormattedMessage id={defaultKey}/>;
}

import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { Integer } from 'src/types/Integer';
import { TicketAge } from 'src/types/TicketAge';

export const TARIFF_LIST: ReadonlyArray<TicketAge> = [
  TicketAge.ADULT,
  TicketAge.YOUTH,
  TicketAge.CHILD,
];

export const TARIFF_LIMITS: ReadonlyRecord<TicketAge, Integer> = {
  [TicketAge.ADULT]: 20,
  [TicketAge.YOUTH]: 20,
  [TicketAge.CHILD]: 5,
};

import { AssertionError } from 'src/errors/AssertionError';
import { isDefined, isNotEmpty, isNotNull } from 'src/utils/guard';

export function assertDefined<TValue>(
  value: TValue | undefined,
  message: string,
  context?: unknown,
): TValue {
  if (isDefined(value)) {
    return value;
  }

  throw new AssertionError(message, context);
}

export function assertNotNull<TValue>(
  value: TValue | null,
  message: string,
  context?: unknown,
): TValue {
  if (isNotNull(value)) {
    return value;
  }

  throw new AssertionError(message, context);
}

export function assertNotEmpty<TValue>(
  value: TValue | null | undefined,
  message: string,
  context?: unknown,
): TValue {
  if (isNotEmpty(value)) {
    return value;
  }

  throw new AssertionError(message, context);
}

export function assertNever(
  message: string,
  context?: unknown,
): never {
  throw new AssertionError(message, context);
}

import {
  init,
  makeBrowserOfflineTransport,
  makeFetchTransport,
  Breadcrumbs,
  GlobalHandlers,
  HttpContext,
  LinkedErrors,
} from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';
import { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE } from 'src/constants/application';

export function sentryInit(): void {
  init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
    transport: makeBrowserOfflineTransport(makeFetchTransport),

    debug: false,
    enabled: true,

    normalizeDepth: 0,
    maxValueLength: 100_000,

    defaultIntegrations: false,
    integrations: [
      new HttpContext(),
      new Breadcrumbs({
        dom: true,
        xhr: true,
        fetch: true,
        console: true,
        history: true,
        sentry: true,
      }),
      new GlobalHandlers({
        onerror: true,
        onunhandledrejection: true,
      }),
      new ExtraErrorData({
        depth: 1_000,
      }),
      new LinkedErrors({
        key: 'previous',
        limit: 100,
      }),
    ],

    sampleRate: 1.0,
    tracesSampleRate: 1.0,
  });
}

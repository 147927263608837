import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import {
  CHECKOUT_PAYMENT_CHANGE,
  CHECKOUT_PAYMENT_SUBMIT,
  CHECKOUT_PERSONAL_CHANGE,
  CHECKOUT_PERSONAL_SUBMIT,
} from 'src/actions/CheckoutActions';
import { checkoutPaymentChangeSaga } from 'src/sagas/checkout/checkoutPaymentChangeSaga';
import { checkoutPaymentSubmitSaga } from 'src/sagas/checkout/checkoutPaymentSubmitSaga';
import { checkoutPersonalChangeSaga } from 'src/sagas/checkout/checkoutPersonalChangeSaga';
import { checkoutPersonalSubmitSaga } from 'src/sagas/checkout/checkoutPersonalSubmitSaga';
import { call } from 'typed-redux-saga';

export function* checkoutPageSaga(action: Action): SagaIterator<void> {
  if (CHECKOUT_PERSONAL_CHANGE.isRequest(action)) {
    yield* call(checkoutPersonalChangeSaga, action);
  }
  if (CHECKOUT_PERSONAL_SUBMIT.isRequest(action)) {
    yield* call(checkoutPersonalSubmitSaga, action);
  }

  if (CHECKOUT_PAYMENT_CHANGE.isRequest(action)) {
    yield* call(checkoutPaymentChangeSaga, action);
  }
  if (CHECKOUT_PAYMENT_SUBMIT.isRequest(action)) {
    yield* call(checkoutPaymentSubmitSaga, action);
  }
}

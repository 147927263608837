import { createSelector } from 'reselect';
import { getConfigState } from 'src/selectors/getConfigState';
import { assertNotEmpty } from 'src/utils/assert';

export const getPaymentTypes = createSelector([
  getConfigState,
], (state) => assertNotEmpty(
  state.paymentTypes,
  'Payment types are not initialized',
  { state },
));

import './FaqSectionView.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Collapsible } from 'src/components/common/Collapsible';
import { FaqSectionStateIcon } from 'src/components/faq/FaqSectionStateIcon';
import { FaqSection } from 'src/types/FaqSection';
import { bind } from 'src/utils/bind';

type Props = {
  readonly section: FaqSection;
};
type State = {
  readonly expanded: boolean;
};

export class FaqSectionView extends React.Component<Props, State> {
  public readonly state: State = {
    expanded: false,
  };

  public render(): React.ReactElement {
    const { section } = this.props;
    const { expanded } = this.state;

    return (
      <div className="sts-ui-faq-section-view">
        <button
          type="button"
          className="sts-ui-faq-section-view__header"
          onClick={this.handleToggle}
        >
          <div className="sts-ui-faq-section-view__header-text">
            <FormattedMessage id={section.header}/>
          </div>
          <div className="sts-ui-faq-section-view__header-icon">
            <FaqSectionStateIcon expanded={expanded}/>
          </div>
        </button>

        <Collapsible expanded={expanded}>
          <div className="sts-ui-faq-section-view__content">
            <FormattedHTMLMessage id={section.content}/>
          </div>
        </Collapsible>
      </div>
    );
  }

  @bind
  private handleToggle(): void {
    this.setState((state) => ({ expanded: !state.expanded }));
  }
}

import { SagaIterator } from 'redux-saga';
import { BASKET_FETCH, BASKET_REMOVE, BASKET_UPGRADE } from 'src/actions/BasketActions';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { basketPageSaga } from 'src/sagas/basket/basketPageSaga';
import { cancel, take, takeLatest } from 'typed-redux-saga';

export function* basketSaga(): SagaIterator<void> {
  while (true) {
    yield* take(BASKET_FETCH.is);

    const task = yield* takeLatest([
      BASKET_REMOVE.isRequest,
      BASKET_UPGRADE.isRequest,
    ], basketPageSaga);

    // freeze the page state when user triggers navigation
    yield* take([ROUTE_CHANGE.isPending]);
    yield* cancel(task);
  }
}

import { createSelector } from 'reselect';
import { getRegionalPassesState } from 'src/selectors/getRegionalPassesState';
import { assertNotEmpty } from 'src/utils/assert';

export const getRegionalPassesData = createSelector([
  getRegionalPassesState,
], (state) => assertNotEmpty(
  state.data,
  'Regional Passes state is not initialized',
  { state },
));

import { pipe } from 'fp-ts/function';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { BASKET_REQUEST } from 'src/codecs/BasketRequest';
import { CURRENCY } from 'src/codecs/Currency';
import { JSON_STRING } from 'src/codecs/JsonString';
import { PRODUCT_CODE } from 'src/codecs/ProductCode';
import { TICKET_PRICE_PERIOD } from 'src/codecs/TicketPricePeriod';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { BasketProduct } from 'src/types/BasketProduct';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { ProductMap } from 'src/types/ProductMap';
import { TicketPricePeriod } from 'src/types/TicketPricePeriod';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchProductPricePeriods(
  code: ProductCode,
  currency: Currency,
  basket: ProductMap<BasketProduct>,
): SagaIterator<TicketPricePeriod[]> {
  try {
    const request = yield* call(REQUEST.encode, { type: code, currency: currency, basket: basket });
    const response = yield* call(makeGetRequest, `${HOST_API}/ticket-price-periods`, request);
    return yield* call(decodeOrThrow, RESPONSE, response.content);
  } catch (error) {
    throw new RuntimeError('Could not fetch product price periods', { code, currency, basket }, NativeError.wrap(error));
  }
}

const REQUEST = E.struct({
  type: PRODUCT_CODE,
  currency: CURRENCY,
  basket: pipe(BASKET_REQUEST, E.compose(JSON_STRING)),
});

const RESPONSE = D.array(TICKET_PRICE_PERIOD);

import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchProductFields } from 'src/sagas/product/utils/fetchProductFields';
import { fetchProductMinPrice } from 'src/sagas/product/utils/fetchProductMinPrice';
import { fetchProductMinStartDate } from 'src/sagas/product/utils/fetchProductMinStartDate';
import { fetchProductPricePeriods } from 'src/sagas/product/utils/fetchProductPricePeriods';
import { fetchProductPrices } from 'src/sagas/product/utils/fetchProductPrices';
import { fetchProductPriceTable } from 'src/sagas/product/utils/fetchProductPriceTable';
import { fetchProductValidity } from 'src/sagas/product/utils/fetchProductValidity';
import { getProductState } from 'src/selectors/getProductState';
import { BasketProduct } from 'src/types/BasketProduct';
import { Currency } from 'src/types/Currency';
import { Language } from 'src/types/Language';
import { ProductCode } from 'src/types/ProductCode';
import { ProductData } from 'src/types/ProductData';
import { ProductFormData } from 'src/types/ProductFormData';
import { ProductMap } from 'src/types/ProductMap';
import { createProductFormData } from 'src/utils/product/createProductFormData';
import { all, call, select } from 'typed-redux-saga';

export function* fetchProductData(
  code: ProductCode,
  currency: Currency,
  language: Language,
  basket: ProductMap<BasketProduct>,
): SagaIterator<ProductData> {
  try {
    const product = yield* select(getProductState);

    const {
      fields,
      priceTable,
      pricePeriods,
      minPrice,
      minStartDate,
    } = yield* all({
      fields: callEffect(fetchProductFields, code),

      priceTable: callEffect(fetchProductPriceTable, code, currency),
      pricePeriods: callEffect(fetchProductPricePeriods, code, currency, basket),

      minPrice: callEffect(fetchProductMinPrice, code, currency),
      minStartDate: callEffect(fetchProductMinStartDate, code, currency, basket),
    });

    const basketFormData = basket[code]?.formData ?? null;
    const storedFormData = product.data?.type === code ? product.data.formData : basketFormData;

    const validityDate = (
      storedFormData?.startDate ??
      minStartDate.startDate
    );
    const validity = yield* call(
      fetchProductValidity,
      code,
      currency,
      language,
      validityDate,
    );

    const actualFormData: ProductFormData = (
      storedFormData ??
      (yield* call(createProductFormData, fields, validity, basket))
    );

    const ticketForm: ProductFormData = {
      ...actualFormData,
      startDate: validityDate,
      agreement: true,
    };
    const ticketPrice = yield* call(
      fetchProductPrices,
      code,
      ticketForm,
      fields,
      pricePeriods,
      validity,
      currency,
    );

    const result: ProductData = {
      type: code,
      fields: fields,

      minPrice: minPrice,
      minStartDate: minStartDate,

      priceTable: priceTable,
      pricePeriods: pricePeriods,

      validity: validity,
      ticketPrice: ticketPrice,

      formData: actualFormData,
    };
    return result;
  } catch (error) {
    throw new RuntimeError('Could not fetch product data', { code }, NativeError.wrap(error));
  }
}

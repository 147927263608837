import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { PRODUCT_CHANGE, PRODUCT_SUBMIT } from 'src/actions/ProductActions';
import { productChangeSaga } from 'src/sagas/product/productChangeSaga';
import { productSubmitSaga } from 'src/sagas/product/productSubmitSaga';
import { call } from 'typed-redux-saga';

export function* productPageSaga(action: Action): SagaIterator<void> {
  if (PRODUCT_CHANGE.isRequest(action)) {
    yield* call(productChangeSaga, action);
  }
  if (PRODUCT_SUBMIT.isRequest(action)) {
    yield* call(productSubmitSaga, action);
  }
}

import { SagaIterator } from 'redux-saga';
import { REGIONAL_PASSES_RESET } from 'src/actions/RegionalPassesActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { RouteMatch } from 'src/routing/types/RouteMatch';
import { Transition } from 'src/routing/types/Transition';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* resetRegionalPasses(transition: Transition): SagaIterator<void> {
  if (isOnSameView(transition.to.match)) {
    // change language or currency
    return;
  }

  try {
    yield* call(logDebug, 'Resetting Regional Passes data…');
    yield* put(REGIONAL_PASSES_RESET.trigger());
    yield* call(logDebug, 'Resetting Regional Passes data… done');
  } catch (error) {
    yield* call(logError, 'Resetting Regional Passes data… error', error);
    throw new RuntimeError('Could not reset Regional Passes data', {}, NativeError.wrap(error));
  }
}

function isOnSameView(match: RouteMatch): boolean {
  if (match.state.name.startsWith('sts:ui:regionalpasses:')) {
    return true;
  }

  return match.parent
    ? isOnSameView(match.parent)
    : false;
}

export class BaseError extends Error {
  public constructor(
    message: string,
    public readonly context?: unknown,
    public readonly previous?: BaseError,
  ) {
    super(message);

    // see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#support-for-newtarget
    Object.setPrototypeOf(this, new.target.prototype);
  }

  public toJSON(): Record<string, unknown> {
    return {
      name: this.name,
      message: this.message,
      stack: this.stack,
      context: this.context,
      previous: this.previous,
    };
  }
}

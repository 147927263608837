import './MenuDropdown.scss';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/common/Popover';
import { CURRENCY_LIST } from 'src/constants/currency';
import { useAction } from 'src/hooks/useAction';
import { ROUTE_CURRENCY } from 'src/routing/actions/RouteCurrency';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { Currency } from 'src/types/Currency';

export function MenuDropdownCurrency(): React.ReactElement {
  const currency = useSelector(getCurrentCurrency);
  const changeCurrency = useAction(ROUTE_CURRENCY.trigger);

  const [open, setOpen] = useState(false);
  const handleClick = useCallback((value: Currency) => {
    changeCurrency(value);
    setOpen(false);
  }, [changeCurrency]);

  return (
    <Popover
      open={open}
      onToggle={setOpen}
      modal={true}
      placement="bottom"
    >
      <PopoverTrigger className="sts-ui-menu-dropdown">
        <div className="sts-ui-menu-dropdown__value">
          {currency}
        </div>
        <div className="sts-ui-menu-dropdown__caret"/>
      </PopoverTrigger>
      <PopoverContent className="sts-ui-menu-dropdown__menu">
        <ul>
          {CURRENCY_LIST.map((item) => (
            <li key={item}>
              <button
                type="button"
                className="sts-ui-menu-dropdown__item"
                onClick={() => handleClick(item)}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
      </PopoverContent>
    </Popover>
  );
}

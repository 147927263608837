import React from 'react';
import { Route, Switch } from 'react-router';
import { BasketPage } from 'src/components/basket/BasketPage';
import { CheckoutPaymentPage } from 'src/components/checkout/CheckoutPaymentPage';
import { CheckoutPersonalPage } from 'src/components/checkout/CheckoutPersonalPage';
import { ConditionsPage } from 'src/components/conditions/ConditionsPage';
import { ContactPage } from 'src/components/contact/ContactPage';
import { ExcursionsPage } from 'src/components/excursions/ExcursionsPage';
import { FaqPage } from 'src/components/faq/FaqPage';
import { GuidePage } from 'src/components/guide/GuidePage';
import { HomepagePage } from 'src/components/homepage/HomepagePage';
import { PageContainer } from 'src/components/layout/PageContainer';
import { PageContent } from 'src/components/layout/PageContent';
import { PageLayout } from 'src/components/layout/PageLayout';
import { MarkdownPage } from 'src/components/markdown-page/MarkdownPage';
import { NewsletterPage } from 'src/components/newsletter/NewsletterPage';
import { NotFoundScreen } from 'src/components/not-found/NotFoundScreen';
import { PaymentCancelPage } from 'src/components/payment/PaymentCancelPage';
import { PaymentFailurePage } from 'src/components/payment/PaymentFailurePage';
import { PaymentSuccessPage } from 'src/components/payment/PaymentSuccessPage';
import { ProductPage } from 'src/components/product/ProductPage';
import { RegionalPassesPage } from 'src/components/regionalPasses/RegionalPassesPage';
import { ROUTING_LANGUAGES } from 'src/routing/constants/RoutingLanguages';
import { ROUTING_PRODUCTS } from 'src/routing/constants/RoutingProducts';
import { RouteScroller } from 'src/routing/containers/RouteScroller';
import { RouteSwitch } from 'src/routing/containers/RouteSwitch';
import { getRouteFullPath } from 'src/routing/utils/getRouteFullPath';

export function Sitemap(): React.ReactElement {
  return (
    <RouteSwitch>
      <Switch>
        {PUBLIC_PAGE_MAP.map(({ name, view }) => (
          <Route
            key={name}
            path={ROUTING_LANGUAGES.map((language) => getRouteFullPath(language, name))}
            sensitive={true}
            exact={true}
            strict={true}
          >
            {view}
          </Route>
        ))}
        <Route path="*">
          <RouteScroller>
            <PageLayout>
              <NotFoundScreen/>
            </PageLayout>
          </RouteScroller>
        </Route>
      </Switch>
    </RouteSwitch>
  );
}

const PUBLIC_PAGE_MAP = [
  {
    name: 'homepage',
    view: (
      <RouteScroller>
        <PageLayout>
          <HomepagePage/>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'excursions',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <ExcursionsPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'regionalpasses',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <RegionalPassesPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'faq',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <FaqPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'guide',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <GuidePage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'conditions',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <ConditionsPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'legalnotice',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <MarkdownPage file="layout/impressumZurich.md"/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'privacypolicy',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <MarkdownPage file="layout/privacyPolicyZurich.md"/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'gtc',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <MarkdownPage file="terms/confirmZurich.md"/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'about',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <MarkdownPage file="layout/aboutUsZurich.md"/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'contact',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <ContactPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'newsletter',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <NewsletterPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },

  {
    name: 'basket',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <BasketPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'checkout:personal',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <CheckoutPersonalPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>

    ),
  },
  {
    name: 'checkout:payment',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <CheckoutPaymentPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>

    ),
  },

  {
    name: 'payment:cancel',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <PaymentCancelPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'payment:failure',
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <PaymentFailurePage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },
  {
    name: 'payment:success',
    view: (
      <RouteScroller>
        <PageLayout variant="clean">
          <PageContainer>
            <PageContent>
              <PaymentSuccessPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  },

  ...ROUTING_PRODUCTS.map((productCode) => ({
    name: `product:${productCode}` as const,
    view: (
      <RouteScroller>
        <PageLayout>
          <PageContainer>
            <PageContent>
              <ProductPage/>
            </PageContent>
          </PageContainer>
        </PageLayout>
      </RouteScroller>
    ),
  })),
] as const;

import './ProductFormViewDiscount.scss';
import { FormController } from '@form-ts/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DiscountName } from 'src/components/common/DiscountName';
import { FormattedMarkdown } from 'src/components/common/FormattedMarkdown';
import { InfoModal } from 'src/components/common/InfoModal';
import { CheckboxField } from 'src/forms/fields/CheckboxField';
import { FormError } from 'src/forms/types/FormError';
import { invariantValue, selectIfEqual } from 'src/forms/utils/transform';
import { ProductCode } from 'src/types/ProductCode';
import { ProductFormData } from 'src/types/ProductFormData';
import { TicketFields } from 'src/types/TicketFields';
import { TicketValidity } from 'src/types/TicketValidity';

type Props = {
  readonly type: ProductCode;
  readonly form: FormController<ProductFormData, FormError>;
  readonly fields: TicketFields;
  readonly validity: TicketValidity;
  readonly showFamilyCardDiscount: boolean;
};

export function ProductFormViewDiscount({
  type,
  form,
  fields,
  validity,
  showFamilyCardDiscount,
}: Props): React.ReactElement | null {
  const discounts = validity.cardType ?? fields.displayFields.cardType;
  if (discounts.length === 0) {
    return null;
  }

  return (
    <div className="sts-ui-product-form-view-discount">
      <h5 className="sts-ui-product-form-view-discount__title">
        <FormattedMessage id="Form.Product.DiscountTitle"/>
      </h5>

      {discounts.map((discount) => (
        <div key={discount} className="sts-ui-product-form-view-discount__item">
          <CheckboxField
            field={form.field.at('cardType').transform(selectIfEqual(discount))}
            label={(
              <div className="sts-ui-product-form-view-discount__item-text">
                <DiscountName product={type} discount={discount}/>
              </div>
            )}
          />
        </div>
      ))}
      {showFamilyCardDiscount && (
        <div className="sts-ui-product-form-view-discount__item">
          <CheckboxField
            field={form.field.at('cardType').transform(invariantValue(true))}
            disabled={true}
            label={(
              <div className="sts-ui-product-form-view-discount__item-text">
                <DiscountName product={type} discount="familyCard"/>

                <div className="sts-ui-product-form-view-discount__item-info">
                  <InfoModal>
                    <FormattedMarkdown id="products/familyCard.md"/>
                  </InfoModal>
                </div>
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
}

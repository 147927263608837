import './MobileMenuContent.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { MobileDropdownCurrency } from 'src/components/common/MobileDropdownCurrency';
import { MobileDropdownLanguage } from 'src/components/common/MobileDropdownLanguage';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { getIsIframeMode } from 'src/selectors/getIsIframeMode';

export function MobileMenuContent(): React.ReactElement {
  const isIframe = useSelector(getIsIframeMode);

  return (
    <div className="sts-ui-mobile-menu-content">
      <ul className="sts-ui-mobile-menu-content__nav">
        {isIframe && (
          <li>
            <RouteLink route="homepage">
              <FormattedMessage id="Menu.Navigation.Homepage"/>
            </RouteLink>
          </li>
        )}
        <li>
          <RouteLink route="product:swissPass">
            <FormattedMessage id="Menu.Navigation.SwissTravelPass"/>
          </RouteLink>
        </li>
        <li>
          <RouteLink route="product:swissFlexiPass">
            <FormattedMessage id="Menu.Navigation.SwissTravelPassFlex"/>
          </RouteLink>
        </li>
        <li>
          <RouteLink route="product:swissHalfFareCard">
            <FormattedMessage id="Menu.Navigation.SwissHalfFareCard"/>
          </RouteLink>
        </li>
        <li>
          <RouteLink route="excursions">
            <FormattedMessage id="Menu.Navigation.Excursions"/>
          </RouteLink>
        </li>
      </ul>

      {!isIframe && (
        <ul className="sts-ui-mobile-menu-content__top">
          <li className="sts-ui-mobile-menu-content__currency">
            <MobileDropdownCurrency/>
          </li>
          <li className="sts-ui-mobile-menu-content__language">
            <MobileDropdownLanguage/>
          </li>
          <li className="sts-ui-mobile-menu-content__contact">
            <RouteLink route="contact">
              <FormattedMessage id="Layout.Header.Contact"/>
            </RouteLink>
          </li>
        </ul>
      )}
    </div>
  );
}

import './Switcher.scss';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { CheckIcon } from 'src/components/icons/CheckIcon';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';

type Option<TValue extends string | number> = {
  readonly value: TValue;
  readonly label: React.ReactNode;
};

type Props<TValue extends string | number> = {
  readonly id: string;
  readonly name: string;
  readonly value: TValue | null;
  readonly options: ReadonlyArray<Option<TValue>>;
  readonly onBlur?: () => void;
  readonly onChange: (value: TValue) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly className?: string;
};

export function Switcher<TValue extends string | number>({
  id,
  name,
  value,
  options,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  className,
}: Props<TValue>): React.ReactElement {
  const inputRef = useRef<HTMLButtonElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  return (
    <div className={classNames(className, 'sts-ui-form-switcher')} data-invalid={invalid}>
      {options.map((option, index) => (
        <button
          id={`${id}-${index}`}
          name={`${name}-${index}`}
          key={option.value}
          ref={index === 0 ? inputRef : undefined}
          type="button"
          className={classNames(
            'sts-ui-form-switcher__button',
            option.value === value ? 'sts-ui-form-switcher__button--checked' : null,
          )}
          onClick={() => onChange(option.value)}
          onBlur={onBlur}
          disabled={disabled}
        >
          <div className="sts-ui-form-switcher__icon">
            {option.value === value && <CheckIcon/>}
          </div>
          <div className="sts-ui-form-switcher__label">
            {option.label}
          </div>
        </button>
      ))}
    </div>
  );
}

import { BasketProduct } from 'src/types/BasketProduct';
import { ProductCode } from 'src/types/ProductCode';
import { ProductMap } from 'src/types/ProductMap';

export function removeBasketProduct(
  productType: ProductCode,
  basketProducts: ProductMap<BasketProduct>,
): ProductMap<BasketProduct> {
  const { [productType]: removed, ...restProducts } = basketProducts;
  return restProducts;
}
